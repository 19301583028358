/* eslint-disable react/no-array-index-key */
import { Slider, SliderMark } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const FixedOptionsSlider = ({
  labels,
  currentIndex,
  onIndexChange,
  children,
  sliderMarkProps,
  ...rest
}) => {
  function transform(index) {
    switch (index) {
      case 0:
        return undefined
      case labels.length - 1:
        return 'translateX(-100%)'
      default:
        return `translateX(-50%)`
    }
  }

  return (
    <Slider
      id="slider"
      min={0}
      max={labels.length - 1}
      value={currentIndex}
      onChange={onIndexChange}
      {...rest}
    >
      {labels.map((label, index) => (
        <SliderMark
          key={index}
          value={index}
          mt="1"
          transform={transform(index)}
          {...sliderMarkProps}
        >
          {label}
        </SliderMark>
      ))}

      {typeof children === 'function'
        ? children(labels[currentIndex], currentIndex)
        : children ?? null}
    </Slider>
  )
}

FixedOptionsSlider.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  currentIndex: PropTypes.number.isRequired,
  onIndexChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  sliderMarkProps: PropTypes.object,
}
