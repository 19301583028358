import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { mutateCompanySettings } from '../../services/api/palantir'

/**
 * Custom hook for mutating company settings.
 * @returns {Mutation} The mutation object.
 * @param {CompanySettingsPayload} payload
 * @param {string} payload.companyUUID
 * @param {string} payload.name
 * @param {string} payload.size
 */
export const useMutateCompanySettings = () => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['companySettings', token],
    /**
     * @param {CompanyCancelInvitePayload} payload
     */
    mutationFn: (payload) => mutateCompanySettings(token, payload),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ['companySettings'],
      })
    },
  })
}
