import { useEffect } from 'react'
import { returning, useRedirect } from './core'
import { useAuth0IsRegistered } from '../hooks/auth0/useAuth0IsRegistered'
import { useAuthToken } from '../hooks/store/useAuthToken'
import { useCheckRouteActive } from '../hooks/router/useCheckRouteActive'
import { ROUTE_BILLING, ROUTE_BILLING_STATUS } from '../util/routes'
import { useFetchBillings } from '../hooks/palantir/useFetchBillings'
import { BILLING_ACTIVE } from '../util/billingStatus'
import { useSharedState } from '../hooks/store/useSharedState'

const whitelist = [{ priority: 0, expr: /^.*$/ }]

const blacklist = [
  { priority: 1, expr: ROUTE_BILLING },
  { priority: 1, expr: ROUTE_BILLING_STATUS },
]

export const NoBillingPlanActive = ({ children }) => {
  const token = useAuthToken()
  const active = useCheckRouteActive(whitelist, blacklist)
  const redirect = useRedirect()
  const { isRegistered, isLoading: isRegisterLoading } = useAuth0IsRegistered()
  const { billings, isLoading: isBillingLoading } = useFetchBillings()
  const isBillingActive =
    billings && billings?.some((a) => a?.status === BILLING_ACTIVE)
  const isLoading = isRegisterLoading || isBillingLoading
  const { billingStarted } = useSharedState()

  useEffect(() => {
    if (active && token && !isLoading && isRegistered && !isBillingActive) {
      redirect(billingStarted ? ROUTE_BILLING_STATUS : ROUTE_BILLING)
    }
  }, [
    active,
    isLoading,
    isRegistered,
    redirect,
    token,
    isBillingActive,
    billingStarted,
  ])

  return returning(children, active, isLoading)
}

NoBillingPlanActive.isCheckingOut = false
