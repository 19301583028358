import { useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import posthog from 'posthog-js'
import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import jwtDecode from 'jwt-decode'
import { useMutateSignup } from '../hooks/palantir'
import signPatternWhite from '../assets/sign-patter-white.svg'
import logo from '../assets/logo.svg'
import { SignupForm } from '../components/domain/signup/SignupForm'
import { SignupLoading } from '../components/domain/signup/SignupLoading'
import { useMutateResendVerificationEmail } from '../hooks/palantir/useMutateResendVerificationEmail'
import { useAuth0UpdateAccessToken } from '../hooks/auth0/useAuth0UpdateAccessToken'
import { useFetchSignedUp } from '../hooks/palantir/useFetchSignedUp'
import { useAuthToken } from '../hooks/store/useAuthToken'

export const SignupPage = () => {
  const [signedUp, setSignedUp] = useState(false)
  const [checkForSignedUp, setCheckForSignedUp] = useState(false)
  const { mutate: mutateSignup, isLoading } = useMutateSignup()
  const { mutate: mutateResendEmail, isLoading: isLoadingResend } =
    useMutateResendVerificationEmail()
  const updateToken = useAuth0UpdateAccessToken()
  const { user } = useAuth0()
  const token = useAuthToken()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.get('refresh') === 'true') {
      console.warn('Forcing token refresh with URL params')
      updateToken('off')
    }
  }, [searchParams, updateToken])

  const client = useQueryClient()
  const sub = useMemo(() => (!token ? null : jwtDecode(token).sub), [token])

  useFetchSignedUp({
    refetchInterval: !signedUp && checkForSignedUp ? 2000 : false,
    async onSuccess(data) {
      if (data.signedUp) {
        await updateToken('off')
        await client.refetchQueries(['auth0-getUser', sub])
        await client.refetchQueries(['auth0-isRegistered', sub])
        await client.refetchQueries(['billing'])
        setSignedUp(data.signedUp)
      }
    },
  })
  const signupLoading = (checkForSignedUp && !signedUp) || isLoading
  const refreshUser = async () => {
    await updateToken('off')
  }

  const { email_verified: emailVerified, email } = user
  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = {
      firstName: event.target.elements.firstName.value,
      lastName: event.target.elements.lastName.value,
      position: event.target.elements.position.value,
      phone: event.target.elements.phone.value,
      companyName: event.target.elements.companyName.value,
      companySize: event.target.elements.companySize.value,
      email,
      url: event.target.elements.url.value,
      auth0Id: sub,
    }
    posthog.capture('signup', data)
    setCheckForSignedUp(true)
    mutateSignup(data)
  }

  const handleResendEmail = async () => {
    await updateToken('off')
    if (!emailVerified) {
      mutateResendEmail({ auth0Id: sub })
    }
  }

  return (
    <Flex position="relative">
      <Box
        w="50%"
        position="fixed"
        backgroundColor="primary.500"
        backgroundImage={`url(${signPatternWhite})`}
        backgroundSize="94%"
        backgroundRepeat="no-repeat"
        h="calc(100vh - 56px)"
        transform="matrix(-1, 0, 0, 1, 0, 0)"
        display={{ base: 'none', md: 'block' }}
      />
      <Flex
        position="absolute"
        top="0"
        right="0"
        w={{ base: '100%', md: '50%' }}
        bg="surface.500"
        px={{ base: 16, md: 24 }}
        pt={{ base: 8, xl: 4 }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {signupLoading && (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            h="calc(100vh - 70px)"
          >
            <SignupLoading />
          </Flex>
        )}
        {!emailVerified && !signupLoading && (
          <Flex
            h="calc(100vh - 70px)"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Image src={logo} alt="Revend Logo" w="245px" display="block" />
            <Heading fontSize={{ base: 20, md: 24 }} my={4} textAlign="center">
              Welcome to Revend!
            </Heading>
            <Flex
              h="60%"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="column"
            >
              <Text
                fontSize={{ base: 16, md: 18 }}
                mb="28px"
                textAlign="center"
              >
                We just need to verify your email before you can get started.
                <br />
                Check your inbox (and the spam folder) and click the link in the
                email with subject &quot;Welcome to Revend&quot;.
                <br />
                <br />
                If you have not received the email after 5 minutes, click the
                button below to have it sent again to you.
              </Text>
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                flexDirection="column"
              >
                <Button
                  textAlign="center"
                  onClick={refreshUser}
                  borderRadius="0"
                  width="353px"
                  mb={2}
                  textTransform="uppercase"
                >
                  I have verified my email
                </Button>
                <Button
                  isLoading={isLoadingResend}
                  textAlign="center"
                  onClick={handleResendEmail}
                  borderRadius="0"
                  width="353px"
                  textTransform="uppercase"
                >
                  Resend verification email
                </Button>
              </Flex>
            </Flex>
          </Flex>
        )}
        {emailVerified && !signupLoading && (
          <Flex alignItems="center" flexDirection="column">
            <Image src={logo} alt="Revend Logo" w="245px" display="block" />
            <Heading fontSize={{ base: 20, md: 24 }} my={4} textAlign="center">
              Welcome to Revend!
            </Heading>
            <Text fontSize={{ base: 12, md: 14 }} mb="28px" textAlign="center">
              We need to know a bit about you and your company to get started.
            </Text>
            <SignupForm isLoading={signupLoading} onSubmit={handleSubmit} />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
