import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'
import { useAuthToken } from '../store/useAuthToken'
import { useSub } from '../etc/useSub'
import { auth0Retrier } from './core'

/**
 * Determines if the user is registered based on the presence of the properties field in the app_metadata.
 *
 * ``isRegistered`` is true if the user is registered.
 * ``isLoading`` is true if the request is still in progress.
 * ``isInvalid`` is true if the user is not logged in.
 *
 * @return {{ isRegistered: boolean, isLoading: boolean, isInvalid: boolean }}
 */
export const useAuth0IsRegistered = () => {
  const token = useAuthToken()
  const sub = useSub()

  const { data: isRegistered, isLoading } = useQuery({
    queryKey: ['auth0-isRegistered', sub],
    enabled: !!sub,
    queryFn: () =>
      Axios.get(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${sub}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) => !!res.data?.app_metadata?.properties?.length),
    retryDelay: (_, error) => auth0Retrier(error, 'useAuth0IsRegistered'),
  })

  return {
    isRegistered,
    isLoading,
    isInvalid: !sub,
  }
}
