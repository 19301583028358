import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  HStack,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEventTypeLabel } from '../../../../../hooks/etc/useEventTypeLabel'
import { FixedOptionsSlider } from '../../../../ui-kit/FixedOptionsSlider'
import { closestIndex } from '../../../../../util/etc/closest'
import { EventTypeIncidentSettingPreview } from './EventTypeIncidentSettingPreview'
import {
  warmupTimeOptionsNumber,
  warmupTimeOptions,
  sensitivityOptionsNumber,
  windowSizeOptionsNumber,
  windowSizeOptions,
  sensitivityOptionsLabels,
  defaultWarmupTime,
} from './const'

const OptionContainer = ({ title, subtitle, children, ...rest }) => (
  <Flex
    flexDir={['column', 'column', 'column', 'column', 'column', 'row']}
    {...rest}
  >
    <Box minW="30%" pr={4}>
      {title && (
        <Heading
          as="h3"
          fontSize="xs"
          fontWeight={500}
          color="primary.dark.400"
        >
          {title}
        </Heading>
      )}
      {subtitle && (
        <Text fontSize="xs" color="primary.dark.300" mt={title ? 1 : 0}>
          {subtitle}
        </Text>
      )}
    </Box>
    <Box minW="70%" pl={[3, 3, 3, 3, 3, 0]}>
      {children}
    </Box>
  </Flex>
)

OptionContainer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
}

/**
 * @param eventType
 * @param config {IncidentConfiguration}
 * @param setConfig {(payload: [string, IncidentConfiguration])=>void}
 * @param rest {object}
 * @constructor
 */
export const EventTypeSection = ({ eventType, config, setConfig, ...rest }) => {
  const flags = useFlags()
  const label = useEventTypeLabel()

  const warmupTimeState = useMemo(() => {
    const idx = warmupTimeOptionsNumber.indexOf(
      config.warmup_time ?? defaultWarmupTime
    )
    return idx === -1
      ? closestIndex(
          config.warmup_time ?? defaultWarmupTime,
          warmupTimeOptionsNumber
        )
      : idx
  }, [config])

  const setWarmupTimeState = useCallback(
    (index) => {
      setConfig([
        eventType,
        { ...config, warmup_time: warmupTimeOptionsNumber[index] },
      ])
    },
    [config, eventType, setConfig]
  )

  const sensitivityState = useMemo(() => {
    const idx = sensitivityOptionsNumber.indexOf(config.incident_sensitivity)
    return idx === -1
      ? closestIndex(config.incident_sensitivity, sensitivityOptionsNumber)
      : idx
  }, [config.incident_sensitivity])

  const setSensitivityState = useCallback(
    (index) => {
      setConfig([
        eventType,
        { ...config, incident_sensitivity: sensitivityOptionsNumber[index] },
      ])
    },
    [config, eventType, setConfig]
  )

  const setAlertWhen = (key) => (event) => {
    setConfig([eventType, { ...config, [key]: event.target.checked }])
  }

  const windowSizeState = useMemo(() => {
    const idx = windowSizeOptionsNumber.indexOf(
      config.sliding_window_length_hours
    )
    return idx === -1
      ? closestIndex(
          config.sliding_window_length_hours,
          windowSizeOptionsNumber
        )
      : idx
  }, [config.sliding_window_length_hours])

  const setWindowSizeState = useCallback(
    (index) => {
      setConfig([
        eventType,
        {
          ...config,
          sliding_window_length_hours: windowSizeOptionsNumber[index],
        },
      ])
    },
    [config, eventType, setConfig]
  )

  return (
    <HStack alignItems="stretch" {...rest}>
      <Box w="75%" mr={8}>
        <Heading as="h2" fontSize="sm" fontWeight={700}>
          {label(eventType)}
        </Heading>
        <VStack alignItems="stretch" gap={8} mt={4} overflowX="hidden">
          <OptionContainer
            title="Notification warmup time"
            subtitle="Time space for grouping anomalies into incidents"
          >
            <FixedOptionsSlider
              onIndexChange={setWarmupTimeState}
              currentIndex={warmupTimeState}
              labels={warmupTimeOptions}
              colorScheme="primary"
              sliderMarkProps={{ fontSize: 'xs' }}
              mt={4}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </FixedOptionsSlider>
          </OptionContainer>

          <OptionContainer
            title="Window size"
            subtitle="Choose the time span you want to see your incidents chart"
          >
            <FixedOptionsSlider
              onIndexChange={setWindowSizeState}
              currentIndex={windowSizeState}
              labels={windowSizeOptions}
              colorScheme="primary"
              sliderMarkProps={{ fontSize: 'xs' }}
              mt={4}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </FixedOptionsSlider>
          </OptionContainer>
          <OptionContainer
            title="Sensitivity configuration"
            subtitle="Configure how sensible each event needs to be"
          >
            <FixedOptionsSlider
              onIndexChange={setSensitivityState}
              currentIndex={sensitivityState}
              labels={sensitivityOptionsLabels}
              colorScheme="primary"
              sliderMarkProps={{ fontSize: 'xs' }}
              mt={4}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </FixedOptionsSlider>
          </OptionContainer>
          <OptionContainer title="Alert when">
            <Stack
              spacing={[1, 5]}
              direction={['column', 'row']}
              mt={4}
              sx={{
                '& span': {
                  fontSize: 'xs',
                  fontWeight: 500,
                },
              }}
            >
              {flags.aboveAverageSwitch && (
                <Checkbox
                  onChange={setAlertWhen('notify_when_above_average')}
                  isChecked={config.notify_when_above_average}
                >
                  Above average
                </Checkbox>
              )}

              {flags.aboveMaximumSwitch && (
                <Checkbox
                  onChange={setAlertWhen('notify_when_above_maximum')}
                  isChecked={config.notify_when_above_maximum}
                >
                  Above maximum
                </Checkbox>
              )}
              {flags.belowAverageSwitch && (
                <Checkbox
                  onChange={setAlertWhen('notify_when_below_average')}
                  isChecked={config.notify_when_below_average}
                >
                  Below average
                </Checkbox>
              )}
              <Checkbox
                onChange={setAlertWhen('notify_when_below_minimum')}
                isChecked
                isReadOnly
              >
                Below minimum
              </Checkbox>
              {flags.notifyTestsSwitch && (
                <Checkbox
                  onChange={setAlertWhen('notify_only_when_test_fails')}
                  isChecked={config.notify_only_when_test_fails}
                >
                  When tests fail
                </Checkbox>
              )}
            </Stack>
          </OptionContainer>
        </VStack>
      </Box>
      <Box h="100%">
        {flags.numenorIncidentConfigPreview && (
          <EventTypeIncidentSettingPreview
            eventTypeSettings={config}
            eventType={eventType}
          />
        )}
      </Box>
    </HStack>
  )
}

EventTypeSection.propTypes = {
  eventType: PropTypes.string.isRequired,
  setConfig: PropTypes.func.isRequired,
  config: PropTypes.shape({
    event_type: PropTypes.string.isRequired,
    incident_sensitivity: PropTypes.number.isRequired,
    notify_only_when_test_fails: PropTypes.bool.isRequired,
    notify_when_above_average: PropTypes.bool.isRequired,
    notify_when_above_maximum: PropTypes.bool.isRequired,
    notify_when_below_average: PropTypes.bool.isRequired,
    notify_when_below_minimum: PropTypes.bool.isRequired,
    sliding_window_length_hours: PropTypes.number.isRequired,
    snooze_until: PropTypes.string.isRequired,
    updated_at: PropTypes.string,
    uuid: PropTypes.string.isRequired,
    warmup_time: PropTypes.number.isRequired,
  }).isRequired,
}
