import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

/**
 * Takes an array of whitelist and blacklist and compares them to the current path.
 *
 * It chooses the first match for each list and compares the priority.
 * Case both the blacklist and the whitelist match, the priority is compared. If the whitelist has a higher or equal priority, the result will be true.
 *
 * @param whitelist {Array.<{priority: number, expr: RegExp | string}>}
 * @param blacklist {Array.<{priority: number, expr: RegExp | string}>}
 * @returns {boolean}
 */
export const useCheckRouteActive = (whitelist, blacklist) => {
  const location = useLocation()

  return useMemo(() => {
    const path = location.pathname.endsWith('/')
      ? location.pathname.slice(0, location.pathname.length - 1)
      : location.pathname
    const whitelistMatch = [...whitelist]
      .sort((z, a) => a.priority - z.priority)
      .find(({ expr }) => expr === path.toLowerCase() || expr.test?.(path))
    const blacklistMatch = [...blacklist]
      .sort((z, a) => a.priority - z.priority)
      .find(({ expr }) => expr === path.toLowerCase() || expr.test?.(path))
    if (!whitelistMatch) return false // If nothing matches the whitelist, the route can never apply
    if (!blacklistMatch) return true // If something matches whitelist but nothing matches blacklist, the route will apply
    return whitelistMatch.priority >= blacklistMatch.priority // If both are matched, we compare priorities
  }, [location.pathname, whitelist, blacklist])
}
