import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useFetchNotificationSettings } from '../palantir'
import { useCustomerUUID } from '../store/useCustomerUUID'

/**
 * @typedef {(eventType: string, localizedFallback?: string)=>string} EventTypeLabelTranslator
 */

/**
 * Returns a function that returns the label for the given event type.
 * @param fallbackMap {Object.<string, string>|undefined} Fallback map for the case the event type configurations are not loaded yet.
 * @param enableDefaultNames {boolean} If true, returns the default names for the event types that are not configured.
 * @return {EventTypeLabelTranslator}
 */
export const useEventTypeLabel = (
  fallbackMap = undefined,
  enableDefaultNames = true
) => {
  const [customerUUID] = useCustomerUUID()
  const { data } = useFetchNotificationSettings({ customerUUID })
  const { t } = useTranslation()

  const getFallback = useCallback(
    (eventType, localizedFallback) => {
      if (fallbackMap?.[eventType]) return fallbackMap[eventType]

      if (enableDefaultNames) {
        const key = `eventTypeLabels.${eventType.replaceAll(/[.:]/g, '-')}`
        const translated = t(key)
        if (translated !== key) return translated
      }

      return localizedFallback ?? eventType
    },
    [enableDefaultNames, fallbackMap, t]
  )

  return useCallback(
    (eventType, localizedFallback = eventType) =>
      data?.event_labels?.[eventType] ||
      getFallback(eventType, localizedFallback),
    [data?.event_labels, getFallback]
  )
}
