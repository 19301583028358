import { useCallback, useEffect, useState } from 'react'

/**
 *
 * @param elementRef {React.MutableRefObject<HTMLElement>}
 * @return {{toggleFullscreen: ()=>void, isFullscreen: boolean}}
 */
export const useFullscreen = (elementRef) => {
  const [isFullscreen, setFullscreen] = useState(false)

  const toggleFullscreen = useCallback(() => {
    if (!elementRef.current) return
    return document.fullscreenElement
      ? document.exitFullscreen()
      : elementRef.current.requestFullscreen()
  }, [elementRef])

  useEffect(() => {
    const fn = () => setFullscreen(!!document.fullscreenElement)
    document.addEventListener('fullscreenchange', fn)
    return () => document.removeEventListener('fullscreenchange', fn)
  }, [])

  return {
    isFullscreen,
    toggleFullscreen,
  }
}
