import { useSelector } from 'react-redux'

/**
 * @return {{
 * customerUUID: string|null,
 * hoveredIncident: string|null,
 * slideoutIncident: string|null,
 * }}
 */
export const useSharedState = () => useSelector((state) => state.shared)
