import { MINUTE_IN_MS } from '../TimeUtils'

/**
 *
 * @param incident
 * @param format {'ms-timestamp'|'s-timestamp'|'date'|'iso-string'}
 * @return {{start: string, end: string}|{start: number, end: number}|{start: Date, end: Date}}
 */
export const incidentDateRange = (incident, format) => {
  const { when, duration_in_minutes: durationInMinutes } = incident
  const start = Date.parse(when)
  const end = start + durationInMinutes * MINUTE_IN_MS

  switch (format) {
    case 'ms-timestamp': {
      return { start, end }
    }
    case 'date': {
      return { start: new Date(start), end: new Date(end) }
    }
    case 'iso-string': {
      return {
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
      }
    }
    case 's-timestamp': {
      return { start: Math.trunc(start / 1000), end: Math.trunc(end / 1000) }
    }
    default: {
      return { start, end }
    }
  }
}
