import { Box, Tag } from '@chakra-ui/react'

export const InviteStatusTag = (status) => {
  let bgColor = ''
  let statusLabel = ''
  let textColor = ''

  switch (status) {
    case 'pending':
      bgColor = 'warning'
      statusLabel = 'Invitation Sent'
      textColor = 'text'
      break
    case 'accepted':
      bgColor = 'primary.400'
      statusLabel = 'Actived'
      textColor = 'surface.500'
      break
    case 'expired':
      bgColor = 'danger'
      statusLabel = 'Expired'
      textColor = 'surface.500'
      break
    case 'cancelled':
      bgColor = 'primary.dark.100'
      statusLabel = 'Disabled'
      textColor = 'text'
      break
    default:
      break
  }

  return (
    <Box>
      <Tag
        variant="solid"
        bg={bgColor}
        color={textColor}
        size="sm"
        borderRadius={3}
        px="12px"
        py="2px"
      >
        {statusLabel}
      </Tag>
    </Box>
  )
}
