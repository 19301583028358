import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  HStack,
  Button,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react'
import { useFetchPropertySettings } from '../../../../../hooks/palantir'
import { PropertyTable } from './PropertyTable'
import { PropertyInviteSettings } from './InviteSettings'
import { PropertyCreateModal } from './PropertyCreateModal'

export const PropertySettings = ({ companyUUID, onLoading }) => {
  const [propertiesData, setPropertiesData] = useState({
    total: 0,
    data: [],
  })
  const [openPropertyCreateModal, setPropertyCreateModal] = useState(false)

  const { isFetching: isPropertySettingsLoading } = useFetchPropertySettings(
    { companyUUID },
    {
      onSuccess(data) {
        setPropertiesData(data)
      },
    }
  )

  useEffect(() => {
    onLoading(isPropertySettingsLoading)
  }, [isPropertySettingsLoading, onLoading])

  return (
    <Box>
      <HStack mt="0.75rem" alignItems="baseline">
        <Button
          h="30px"
          w="120px"
          mb="3"
          bgColor="primary.500"
          color="white"
          borderRadius="2px"
          fontWeight="700"
          fontSize="12px"
          onClick={() => setPropertyCreateModal(true)}
        >
          Add property
        </Button>
      </HStack>
      <HStack mt="0.75rem">
        <Tabs pt={2} h="full" w="800px" overflow="hidden">
          <TabList borderBottom="solid 1px var(--chakra-colors-chakra-border-color)">
            <Tab fontSize={15}>On this company</Tab>
            <Tab fontSize={15}>Invites</Tab>
          </TabList>

          <TabPanels>
            <TabPanel p="0">
              <PropertyCreateModal
                companyUUID={companyUUID}
                isOpen={openPropertyCreateModal}
                onClose={() => setPropertyCreateModal(false)}
              />
              <HStack mt="0.75rem">
                <PropertyTable propertiesData={propertiesData.data} />
              </HStack>
            </TabPanel>
            <TabPanel p="0">
              <HStack mt="0.75rem">
                <PropertyInviteSettings
                  companyUUID={companyUUID}
                  onLoading={onLoading}
                />
              </HStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </Box>
  )
}

PropertySettings.propTypes = {
  companyUUID: PropTypes.string,
  onLoading: PropTypes.func,
}
