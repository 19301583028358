import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useCustomerUUID } from '../../../../../hooks/store/useCustomerUUID'
import { useFetchNotificationSettings } from '../../../../../hooks/palantir'
import { useMutateNotificationSettings } from '../../../../../hooks/palantir/useMutateNotificationSettings'

export const StoreActions = {
  SET_UPPER_BOUND: 'SET_UPPER_BOUND',
  SET_LOWER_BOUND: 'SET_LOWER_BOUND',
  SET_ALL: 'SET_ALL',
  SET_KEY: 'SET_KEY',
  DELETE_KEY: 'DELETE_KEY',
}

export const usePriorityTiersStore = () => {
  const [customerUUID] = useCustomerUUID()
  const { data: remoteConfiguration, isLoading } = useFetchNotificationSettings(
    { customerUUID }
  )

  const { mutate: mutateNotificationSettings, isLoading: isSaving } =
    useMutateNotificationSettings()

  const [tiers, dispatch] = useReducer((state, [action, payload]) => {
    switch (action) {
      case StoreActions.SET_UPPER_BOUND: {
        const [key, upperBound] = payload
        const allKeys = Object.keys(state)
        const index = allKeys.indexOf(key)
        const nextKey = allKeys[index + 1]

        // Prevents setting an upper bound that is higher than the upper bound of the next tier
        if (state[nextKey] && upperBound + 1 > state[nextKey].upper_bound) {
          return state
        }

        const merge = {
          [key]: { ...state[key], upper_bound: upperBound },
        }

        // Adapts the lower bound of the next tier to the upper bound of the current tier
        if (index !== allKeys.length - 1) {
          merge[nextKey] = {
            ...state[nextKey],
            lower_bound: upperBound + 1,
          }
        }

        return { ...state, ...merge }
      }
      case StoreActions.SET_LOWER_BOUND: {
        const [key, lowerBound] = payload
        const allKeys = Object.keys(state)
        const index = allKeys.indexOf(key)
        const prevKey = allKeys[index - 1]

        // Prevents setting a lower bound that is lower than the lower bound of the previous tier
        if (state[prevKey] && lowerBound <= state[prevKey].lower_bound) {
          return state
        }

        const merge = {
          [key]: { ...state[key], lower_bound: lowerBound },
        }

        // Adapts the upper bound of the previous tier to the lower bound of the current tier
        if (index > 0) {
          merge[prevKey] = {
            ...state[prevKey],
            upper_bound: lowerBound - 1,
          }
        }

        return { ...state, ...merge }
      }
      case StoreActions.DELETE_KEY: {
        const { [payload]: _, ...rest } = state
        return rest
      }
      case StoreActions.SET_ALL: {
        return payload
      }
      case StoreActions.SET_KEY: {
        return { ...state, [payload[0]]: payload[1] }
      }
      default: {
        console.warn(`Unknown action: ${action}`)
        return state
      }
    }
  }, {})

  const areTiersValid = useMemo(() => {
    try {
      Object.values(tiers).reduce(
        (
          previousUpper,
          { upper_bound: upperBound, lower_bound: lowerBound }
        ) => {
          if (previousUpper < lowerBound && upperBound >= lowerBound) {
            return upperBound
          }
          throw new Error('Invalid priority tiers: Invalid bounds')
        },
        -1
      )

      return true
    } catch (_) {
      /* get out of reduce */
      return false
    }
  }, [tiers])

  const saveRemote = useCallback(() => {
    mutateNotificationSettings({
      customerUUID,
      priorityTiers: tiers,
    })
  }, [tiers, customerUUID, mutateNotificationSettings])

  useEffect(() => {
    if (remoteConfiguration?.priority_tiers) {
      dispatch([StoreActions.SET_ALL, remoteConfiguration.priority_tiers])
    }
  }, [remoteConfiguration?.priority_tiers])

  return useMemo(() => {
    return {
      tiers,
      dispatch,
      isLoading,
      isSaving,
      saveRemote,
      areTiersValid,
    }
  }, [isLoading, isSaving, saveRemote, tiers, areTiersValid])
}
