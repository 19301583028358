import { returning } from './core'
import { useCheckRouteActive } from '../hooks/router/useCheckRouteActive'
import { useAuth0IsRegistered } from '../hooks/auth0/useAuth0IsRegistered'
import { SpinnerWithText } from '../components/domain/shared/SpinnerWithText'

const whitelist = [{ priority: 0, expr: /^.*$/ }]

const blacklist = []

export const IsRegisteredLoadingGuard = ({ children }) => {
  const active = useCheckRouteActive(whitelist, blacklist)
  const { isLoading, isInvalid } = useAuth0IsRegistered()

  return returning(
    active && isLoading && !isInvalid ? (
      <SpinnerWithText>Loading...</SpinnerWithText>
    ) : (
      children
    ),
    active,
    isLoading
  )
}
