/**
 * @param url {string}
 * @param slashStart {boolean?} undefined will leave it as is.
 * @param slashEnd {boolean?} undefined will leave it as is.
 */
export const normalizeUrlSlashes = (url, { slashStart, slashEnd } = {}) => {
  let newUrl = String(url)

  if (slashStart !== undefined) {
    if (slashStart && !newUrl.startsWith('/')) newUrl = `/${newUrl}`

    if (!slashStart && newUrl.startsWith('/')) {
      newUrl = newUrl.slice(1, newUrl.length)
    }
  }

  if (slashEnd !== undefined) {
    if (slashEnd && !newUrl.endsWith('/')) newUrl += '/'

    if (!slashEnd && url !== '' && url !== '/' && newUrl.endsWith('/')) {
      newUrl = newUrl.slice(0, newUrl.length - 1)
    }
  }

  return newUrl
}
