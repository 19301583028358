const findCheckoutRobotEvent = (eventType) => {
  const eventTypes = {
    loadHomepage: 'Home page load',
    'ec.click': 'Product clicks',
    'ec.detail': 'Product detail page views',
    'ec.add': 'Add to carts',
    'ec.checkout': 'Checkouts initiated',
    'ec.checkout.1': 'Checkout step 1 - Begin checkout',
    'ec.checkout.2': 'Checkout step 2 - Add shipping info',
    'ec.checkout.3': 'Checkout step 3 - Add payment info',
    'ec.purchase': 'Purchase',
  }

  if (eventType.startsWith('ec.checkout.')) {
    const stepNumber = parseInt(eventType.slice(-1), 10)
    if (!stepNumber.isNaN && stepNumber >= 4) {
      return `Checkout step ${stepNumber}`
    }
  }

  return eventTypes[eventType] || eventType
}

export const checkoutRobotIncidentFormatter = (data) => {
  const testFailed = data.test_steps.filter((x) => x.status === 'ERROR')[0]
  if (!testFailed) return null

  return [
    formatBrokenStepInformation(testFailed),
    formatStepResultInformation(data.test_steps, data),
  ]
}

const formatBrokenStepInformation = (testFailed) => {
  const title = 'Broken step:'
  const body = `⚠️ ${findCheckoutRobotEvent(testFailed.event_type)}`
  return { title, body }
}

const formatStepResultInformation = (testSteps) =>
  testSteps.map((step) => {
    let color
    switch (step.status) {
      case 'SUCCESS':
        color = 'success'
        break
      case 'ERROR':
        color = 'danger'
        break
      default:
        color = 'lightgrey'
    }

    const event = findCheckoutRobotEvent(step.event_type)
    return { color, event }
  })
