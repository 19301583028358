import { Card, Box, Text, Button, Image, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import PropTypes from 'prop-types'

import { GoogleModal } from './other-integrations/GoogleModal'
import { SettingsCard } from './SettingsCard'

export const OtherIntegrations = ({ customerUUID }) => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <SettingsCard
      title="Other Integrations"
      subtitle="Integrations with other tools"
    >
      <GoogleModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        customerUUID={customerUUID}
      />
      <Card padding="4" maxW="420px">
        <Flex justifyContent="space-between" w="full">
          <Flex gap="4">
            <Box
              width="40px"
              height="40px"
              boxShadow="md"
              borderRadius="md"
              display="flex"
            >
              <Image src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" />
            </Box>
            <Box>
              <Text fontWeight="bold" fontSize="sm">
                Google
              </Text>
              <Text fontSize="xs">Track your store&apos;s performance</Text>
            </Box>
          </Flex>
          <Button
            h="30px"
            w="120px"
            bgColor="primary.500"
            color="white"
            borderRadius="2px"
            fontWeight="700"
            fontSize="12px"
            alignSelf="center"
            onClick={() => setOpenModal(true)}
          >
            Connect
          </Button>
        </Flex>
        <Text mt={4} fontWeight="400" color="primary.dark.300" fontSize="xs">
          Optimize online advertising campaigns by making data-driven decisions
          on which ads to boost and which to scale back, ensuring maximum return
          on investment and keep track of your customers.
        </Text>
      </Card>
    </SettingsCard>
  )
}

OtherIntegrations.propTypes = {
  customerUUID: PropTypes.string.isRequired,
}
