import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { useFetchWeeklyIncidents } from '../../../hooks/palantir/useFetchWeeklyIncidents'
import { DashboardWeeklyIncidentCard } from './DashboardWeeklyIncidentCard'
import { useDashboardState } from '../../../hooks/store/useDashboardState'
import { useCustomerUUID } from '../../../hooks/store/useCustomerUUID'

export const DashboardWeeklyIncidentsSection = (props) => {
  const [customerUUID] = useCustomerUUID()
  const { endDate } = useDashboardState()
  const { data, isLoading } = useFetchWeeklyIncidents({
    customerUUID,
    date: endDate,
  })

  return (
    <Card as="section" px={4} py={4} overflow="auto" {...props}>
      <CardHeader p={0} pb={4}>
        <Flex>
          <Heading fontSize={16} mb={0} flexGrow={1}>
            Weekly Incidents
          </Heading>
          {data?.date && (
            <Flex alignItems="start">
              <Text as="span" fontSize={10} color="primary.dark.300">
                As of{' '}
                {new Date(data.date).toLocaleDateString(undefined, {
                  month: 'short',
                  day: 'numeric',
                })}
              </Text>
            </Flex>
          )}
        </Flex>
      </CardHeader>

      <CardBody p={1}>
        <Skeleton isLoaded={!isLoading}>
          <DashboardWeeklyIncidentCard
            histogram={data?.histogram ?? []}
            lastWeekCount={data?.lastWeekIncidentCount ?? 0}
            currentWeekCount={data?.currentWeekIncidentCount ?? 0}
            title="All Incidents"
          />
        </Skeleton>
      </CardBody>
    </Card>
  )
}
