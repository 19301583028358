import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { scaleTime } from '@visx/scale'
import { AxisBottom } from '@visx/axis'
import { DAY_IN_MS } from '../../../util/TimeUtils'

const FORMATTERS = {
  SHORT_PERIOD: (date) =>
    date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }),
  MEDIUM_PERIOD: (date) =>
    date.toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
  LONG_PERIOD: (date) =>
    date.toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
    }),
}

const axisBottomComponent = ({ ticks }) =>
  ticks.map((tick) => (
    <g key={`tick-${tick.value}`} className="visx-group visx-axis-tick">
      <text
        x={tick.from.x}
        y={tick.from.y + 20}
        fontSize={12}
        fill="#001334"
        fontWeight="700"
        textAnchor="middle"
        style={{ userSelect: 'none' }}
      >
        {tick.formattedValue}
      </text>
    </g>
  ))

export const ChartXAxis = ({ start, end, width, ...rest }) => {
  const xScale = useMemo(
    () => scaleTime({ domain: [start, end] }),
    [start, end]
  )
  xScale.range([0, width])

  const formatter = useMemo(() => {
    const diffInDays = (end - start) / DAY_IN_MS
    if (diffInDays <= 5) return FORMATTERS.SHORT_PERIOD
    if (diffInDays <= 14) return FORMATTERS.MEDIUM_PERIOD
    return FORMATTERS.LONG_PERIOD
  }, [start, end])

  return (
    <svg width={width} height={32} {...rest}>
      <AxisBottom
        scale={xScale}
        tickFormat={formatter}
        ticksComponent={axisBottomComponent}
        numTicks={Math.round(width / 128)}
        hideAxisLine
      />
    </svg>
  )
}

ChartXAxis.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  width: PropTypes.number.isRequired,
}
