import { useMemo } from 'react'

/**
 * Memoises a date object
 *
 * @param constructor {Date|number|string|undefined}
 * @return {Date}
 */
export const useDate = (constructor = undefined) => {
  const dependency =
    constructor && typeof constructor === 'object' && 'getTime' in constructor
      ? constructor.getTime()
      : constructor
  return useMemo(
    () => (dependency === undefined ? new Date() : new Date(dependency)),
    [dependency]
  )
}
