/* eslint-disable unicorn/filename-case */
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { SharedActions } from '../../store/slices/sharedSlice'

/**
 * @return {[string, (value: string)=>void]}
 */
export const useCustomerUUID = () => {
  const dispatch = useDispatch()
  const value = useSelector((state) => state.shared.customerUUID)
  const setValue = useCallback(
    (newUUID) => {
      dispatch(SharedActions.setCustomerUUID(newUUID))
    },
    [dispatch]
  )

  return [value, setValue]
}
