import { createSlice } from '@reduxjs/toolkit'
import { validateDateRange } from '../../util/validations/validateDateRange'
import { WEEK_IN_MS } from '../../util/TimeUtils'

const START_DATE = new Date(Date.now() - WEEK_IN_MS).toISOString()
const END_DATE = new Date().toISOString()

const initialState = {
  startDate: START_DATE,
  endDate: END_DATE,
  zoomStartDate: START_DATE,
  zoomEndDate: END_DATE,
  eventTypes: ['ec.detail'], // We are manually adding 'ec.detail' because as a business rule its required to start selected
}

const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    resetZoomDateRange(state) {
      state.zoomStartDate = state.startDate
      state.zoomEndDate = state.endDate
    },
    setDateRange(state, action) {
      const { startDate, endDate } = action.payload
      if (startDate) {
        state.startDate = state.zoomStartDate = new Date(
          startDate
        ).toISOString()
      }
      if (endDate) {
        state.endDate = state.zoomEndDate = new Date(endDate).toISOString()
      }

      if (
        startDate &&
        endDate &&
        !validateDateRange(startDate, endDate, false)
      ) {
        console.warn('Attempting to set invalid date range', {
          startDate,
          endDate,
        })
      }
    },
    setZoomDateRange(state, action) {
      const { startDate, endDate } = action.payload
      if (startDate) state.zoomStartDate = new Date(startDate).toISOString()
      if (endDate) state.zoomEndDate = new Date(endDate).toISOString()

      if (
        startDate &&
        endDate &&
        !validateDateRange(startDate, endDate, false)
      ) {
        console.warn('Attempting to set invalid date range', {
          startDate,
          endDate,
        })
      }
    },
    setEventTypes(state, action) {
      state.eventTypes = action.payload
    },
    resetEventTypes(state) {
      state.eventTypes = initialState.eventTypes
    },
  },
})

export const { reducer: incidentsReducer, actions: IncidentsActions } =
  incidentsSlice
