import { Box, Center, Skeleton, Text, VStack } from '@chakra-ui/react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useMemo, useState } from 'react'
import { localPoint } from '@visx/event'
import PropTypes from 'prop-types'
import { SingleLineIncidentChart } from '../../revend-charts/SingleLineIncidentChart'
import {
  useFetchEventCountData,
  useFetchIncident,
} from '../../../hooks/palantir'
import {
  TimeUtils,
  HOUR_IN_MS,
  MINUTE_IN_MS,
  SECOND_IN_MS,
} from '../../../util/TimeUtils'
import { prepareIncidentAnomaliesForChart } from '../../../util/revend/prepareIncidentAnomaliesForChart'
import { groupBy } from '../../../util/etc/groupBy'

const HEIGHT = 195

export const IncidentSlideoutControlledChart = ({
  incidentUUID,
  eventType,
}) => {
  const { data: incident, isLoading: isIncidentLoading } = useFetchIncident({
    incidentUUID,
  })

  const anomalies = useMemo(() => {
    if (!incident) return []
    const sortedAnomalies = [...incident.anomalies].sort(
      (a, b) =>
        Date.parse(
          a.current.window_start_ts
            ? a.current.window_start_ts
            : a.current.window_start
        ) -
        Date.parse(
          b.current.window_start_ts
            ? a.current.window_start_ts
            : a.current.window_start
        )
    )

    return groupBy(sortedAnomalies, (anomaly) => anomaly.event_type)[eventType]
  }, [eventType, incident])

  const start = anomalies[0]?.current?.window_start_ts
    ? TimeUtils.timeMs(anomalies[0].current.window_start_ts) * SECOND_IN_MS -
      HOUR_IN_MS
    : TimeUtils.timeMs(anomalies[0]?.current?.window_start) - HOUR_IN_MS

  const end = anomalies[anomalies.length - 1]?.current?.window_end_ts
    ? TimeUtils.timeMs(anomalies[anomalies.length - 1].current.window_end_ts) *
        SECOND_IN_MS +
      MINUTE_IN_MS * 10
    : TimeUtils.timeMs(anomalies[anomalies.length - 1].current.window_end) +
      MINUTE_IN_MS * 10

  const { data: eventCountData, isLoading: isEventCountDataLoading } =
    useFetchEventCountData({
      customerUUID: incident?.customer_uuid,
      startDate: start || 0,
      endDate: end || 0,
      eventType,
    })

  const isLoading = isIncidentLoading || isEventCountDataLoading

  const preparedAnomalies = useMemo(
    () =>
      prepareIncidentAnomaliesForChart([{ anomalies }], {
        start,
        end,
        eventType,
      }),
    [anomalies, end, eventType, start]
  )

  const [tooltipX, setTooltipX] = useState()

  const handleChartHover = (event) => {
    if (!event) return setTooltipX(null)
    const { x } = localPoint(event) || { x: 0 }
    setTooltipX(x)
  }

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <Skeleton h={HEIGHT} w={width} isLoaded={!isLoading} borderRadius="lg">
          {eventCountData?.length && incident ? (
            <Box>
              <SingleLineIncidentChart
                width={width}
                height={HEIGHT}
                chartMarginLeft={24}
                chartMarginBottom={20}
                tooltipX={tooltipX}
                data={eventCountData}
                incidents={[incident]}
                anomalies={preparedAnomalies}
                onChartHover={handleChartHover}
                showAxisBottom
                showAxisLeft
              />
            </Box>
          ) : (
            <Box bg="#efefef" borderRadius="lg" h={HEIGHT} w={width}>
              <Center h="100%">
                <VStack>
                  <Text mb={0}>No data for this period yet.</Text>
                </VStack>
              </Center>
            </Box>
          )}
        </Skeleton>
      )}
    </AutoSizer>
  )
}

IncidentSlideoutControlledChart.propTypes = {
  incidentUUID: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
}
