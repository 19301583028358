import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import posthog from 'posthog-js'

import { App } from './App'
import { reportWebVitals } from './helpers/reportWebVitals'
import { Auth0ProviderWithHistory } from './providers/AuthProvider'
import './i18n'
import './styles/fonts.css'
import './styles/global.css'

const run = async () => {
  posthog.init('phc_U2moH7idVX6pciC2neSjptqXPmvb0AKsfBCWAdUdhRU', {
    api_host: 'https://eu.posthog.com',
  })

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT,
  })

  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <LDProvider>
            <App />
          </LDProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </React.StrictMode>
  )
}

run()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
