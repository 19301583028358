import {
  Box,
  Button,
  Circle,
  Flex,
  Square,
  Tab,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import posthog from 'posthog-js'
import signPatternWhite from '../assets/sign-patter-white.svg'
import { useCustomerUUID } from '../hooks/store/useCustomerUUID'
import { SharedActions } from '../store/slices/sharedSlice'

export const BillingPage = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const [selectedPlan, setSelectedPlan] = useState(0)
  const [customerUUID] = useCustomerUUID()
  const client = useQueryClient()
  const { user } = useAuth0()
  const dispatch = useDispatch()
  const plans = [
    {
      name: 'Grow',
      description: 'up to 500k pageviews/month',
      priceMonthly: '149',
      priceYearly: '125',
      monthlyLink: 'https://billing.revend.ai/b/fZe29V43Z5YI1qw5kl',
      yearlyLink: 'https://billing.revend.ai/b/3cs01NcAvbj27OU6os',
    },
    {
      name: 'Scale',
      description: 'up to 1M pageviews/month',
      priceMonthly: '350',
      priceYearly: '290',
      monthlyLink: 'https://billing.revend.ai/b/6oEg0L583drafhmbIK',
      yearlyLink: 'https://billing.revend.ai/b/00g4i32ZV72M6KQaEJ',
    },
    {
      name: 'Enterprise',
      description: 'over 1M pageviews/month',
      priceMonthly: 'Custom',
      priceYearly: 'Custom',
      monthlyLink: 'https://www.revend.ai/form-get-revend-custom',
      yearlyLink: 'https://www.revend.ai/form-get-revend-custom',
    },
  ]

  const benefits = [
    {
      description: 'AI-based anomaly detection & monitoring',
      plan: 0,
      showForOtherPlans: true,
    },
    {
      description: '3 month data retention',
      plan: 0,
      showForOtherPlans: false,
    },
    {
      description: '6 month data retention',
      plan: 1,
      showForOtherPlans: false,
    },
    {
      description: '6 month data retention',
      plan: 2,
      showForOtherPlans: false,
    },
    {
      description: 'Slack support',
      plan: 0,
      showForOtherPlans: true,
    },
    {
      description: 'Custom integration',
      plan: 1,
      showForOtherPlans: true,
    },
    {
      description: 'Account manager',
      plan: 2,
      showForOtherPlans: true,
    },
  ]

  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  const choosePlan = async () => {
    const link =
      tabIndex === 0
        ? plans[selectedPlan].yearlyLink
        : plans[selectedPlan].monthlyLink
    await client.invalidateQueries({
      queryKey: ['billing'],
    })
    dispatch(SharedActions.setBillingStarted(true))
    // TODO: Replace with proper Redux action
    posthog.capture('begin_checkout', {
      selectedPlan,
      customerUUID,
      email: user.email,
    })
    window.location.replace(
      `${link}?client_reference_id=${customerUUID}&prefilled_email=${encodeURIComponent(
        user?.email
      )}`
    )
  }

  return (
    <Flex position="relative">
      <Box
        w="50%"
        position="fixed"
        backgroundColor="primary.500"
        backgroundImage={`url(${signPatternWhite})`}
        backgroundSize="94%"
        backgroundRepeat="no-repeat"
        h="calc(100vh - 56px)"
        transform="matrix(-1, 0, 0, 1, 0, 0)"
        display={{ base: 'none', md: 'block' }}
      />
      <Flex
        w={{ base: '100%', md: '50%' }}
        position="absolute"
        top="0"
        right="0"
        bg="surface.500"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          px={{ base: 12, md: 16 }}
          pt={{ base: 8, xl: 4 }}
          pb={4}
          alignItems="center"
          flexDirection="column"
        >
          <Text fontWeight="bold" fontSize="2xl" pb={6}>
            Choose your monitoring plan
          </Text>
          <Text textAlign="center" fontSize="sm" pb={10} px={20}>
            Our plans grow with your traffic. <br />
            <br />
            Use the monthly pageviews metric in your analytics platform to
            select the right one. <br />
            No need to sweat - if you pick the wrong one we will downgrade or
            upgrade you automatically to the correct tier after your first
            month.
          </Text>
          <Tabs
            variant="fullbg.noborder"
            index={tabIndex}
            onChange={handleTabsChange}
            pb="12"
          >
            <Flex flexDirection="row" bg="grey-lines" borderRadius="8px" p={2}>
              <Tab>Yearly (17% off)</Tab>
              <Tab>Monthly</Tab>
            </Flex>
          </Tabs>

          <VStack gap={6} w="100%" pb={8}>
            {plans.map((plan, index) => (
              <Flex
                px={6}
                py={4}
                justifyContent="space-between"
                w="100%"
                border="1.5px solid"
                borderRadius="12px"
                borderColor="primary.400"
                onClick={() => setSelectedPlan(index)}
                bg={selectedPlan === index ? 'primary.200' : 'white'}
              >
                <Flex alignItems="center">
                  {selectedPlan === index ? (
                    <Circle size="20px" bg="primary.500" color="white" mr={4}>
                      <CheckIcon boxSize={3} />
                    </Circle>
                  ) : (
                    <Circle size="20px" bg="grey-lines" mr={4} />
                  )}

                  <Box>
                    <Text
                      color={selectedPlan === index ? 'primary.500' : ''}
                      fontSize="lg"
                      fontWeight="bold"
                    >
                      {plan.name}
                    </Text>
                    <Text fontSize="sm">{plan.description}</Text>
                  </Box>
                </Flex>
                <Flex alignItems="center">
                  <Text
                    fontSize="xl"
                    fontWeight="bold"
                    color={selectedPlan === index ? 'primary.500' : ''}
                  >
                    €{tabIndex === 1 ? plan.priceMonthly : plan.priceYearly}
                  </Text>
                  <Text
                    as="span"
                    fontWeight="bold"
                    color={selectedPlan === index ? 'primary.500' : ''}
                  >
                    /month
                  </Text>
                </Flex>
              </Flex>
            ))}
          </VStack>
          <Flex
            px={8}
            py={6}
            flexDirection="column"
            w="100%"
            border="2px solid"
            borderRadius="16px"
            borderColor="primary.400"
            boxShadow="0px 4px 16px 0px rgba(0, 0, 0, 0.16)"
            mb={10}
          >
            <Text fontSize="lg" fontWeight="bold" pb={2}>
              {plans[selectedPlan].name}
            </Text>
            <Text pb={5}>Brands finding their groove.</Text>
            <VStack alignItems="start" gap={5}>
              {benefits.map((benefit) => {
                const showBenefit =
                  selectedPlan === benefit.plan || benefit.showForOtherPlans
                return showBenefit ? (
                  <Flex>
                    <Square
                      size="26px"
                      bg="primary.400"
                      color="white"
                      borderRadius="6px"
                      opacity={selectedPlan >= benefit.plan ? '1' : '0.5'}
                      mr={2}
                    >
                      <CheckIcon />
                    </Square>
                    <Text
                      fontWeight="bold"
                      opacity={selectedPlan >= benefit.plan ? '1' : '0.5'}
                    >
                      {benefit.description}
                    </Text>
                  </Flex>
                ) : (
                  ''
                )
              })}
            </VStack>
          </Flex>
          <Button
            bg="primary.500"
            color="white"
            textTransform="uppercase"
            borderRadius="2px"
            width="100%"
            _hover={{ bg: 'primary.600' }}
            mb={10}
            onClick={async () => choosePlan()}
          >
            CONTINUE WITH {plans[selectedPlan].name.toUpperCase()}
            {tabIndex === 0 ? ' YEARLY' : ' MONTHLY'}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
