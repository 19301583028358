/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
// Must disable this rule because I need to make an img tag interactive
// Cannot use eslint-disable-next-line because if I do so the JSX won't compile

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CloseButton,
  Collapse,
  Divider,
  Flex,
  Heading,
  Skeleton,
  Slide,
  Spacer,
  Text,
  Tooltip,
  VStack,
  Circle,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import {
  useFetchIncident,
  useFetchIncidentFunnel,
} from '../../../hooks/palantir'
import { Dot } from '../../ui-kit/Dot'
import { IncidentPriorityBadge } from '../shared/incidents/IncidentPriorityBadge'
import { IncidentFunnel } from '../shared/incidents/IncidentFunnel'
import { groupBy } from '../../../util/etc/groupBy'
import { compareEventTypes } from '../../../util/revend/compareEventTypes'
import { IncidentSlideoutLineChartCard } from './IncidentSlideoutLineChartCard'
import { IncidentSlideoutLabelIncident } from './IncidentSlideoutLabelIncident'
import {
  IncidentSlideoutStatusCheckChart,
  IncidentSlideoutStatusCheckUpDowntime,
} from './IncidentSlideoutStatusCheckChart'
import { currencySymbol } from '../../../util/etc/currencySymbol'
import { formatAlertTypeMessage } from '../../../util/revend/formatAlertTypeMessage'
import { useSharedState } from '../../../hooks/store/useSharedState'
import { SharedActions } from '../../../store'
import { useEventTypeLabel } from '../../../hooks/etc/useEventTypeLabel'
import { createFunnelEventMessage } from '../../../util/revend/createFunnelEventMessage'
import { checkoutRobotIncidentFormatter } from '../../../util/revend/checkoutRobotIncidentFormatter'

export const IncidentSlideout = () => {
  const dispatch = useDispatch()
  const eventTypeLabel = useEventTypeLabel()
  const { slideoutIncident } = useSharedState()
  const { data: incident, isLoading: isIncidentLoading } = useFetchIncident({
    incidentUUID: slideoutIncident,
  })
  const { data: funnel, isLoading: isFunnelLoading } = useFetchIncidentFunnel({
    incidentUUID: slideoutIncident,
  })

  const whenText = useMemo(
    () =>
      incident
        ? new Date(incident.when).toLocaleString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })
        : '',
    [incident]
  )

  const isCheckoutRobotEvent = useMemo(() => {
    if (!incident) return null
    return incident.event_type === 'checkout_robot'
  }, [incident])

  const isStatusCheckEvent = useMemo(() => {
    if (!incident) return null
    return incident.event_type === 'check.status'
  }, [incident])

  const handleClose = () => {
    dispatch(SharedActions.setSlideoutIncident(null))
  }

  const anomalies = useMemo(
    () =>
      incident
        ? groupBy(
            incident.anomalies.sort((a, b) =>
              compareEventTypes(a.event_type, b.event_type)
            ),
            (anomaly) => anomaly.event_type
          )
        : {},
    [incident]
  )

  const incidentTitle = useMemo(() => {
    if (!incident) return null
    return incident.is_funnel
      ? createFunnelEventMessage(
          incident.anomalies.map((a) => a.event_type),
          eventTypeLabel
        )
      : eventTypeLabel(incident.event_type)
  }, [incident, eventTypeLabel])

  const testStepsIndicator = useMemo(() => {
    if (!incident) return null
    return incident.test_steps ? checkoutRobotIncidentFormatter(incident) : null
  }, [incident])

  const testStepsMessage = (() => {
    if (!incident) return null
    const { failed_test_step: failedTestStep } = incident

    return !failedTestStep ? (
      <>Checkout is not impacted</>
    ) : (
      <>
        An issue at{' '}
        <Text as="span" fontWeight="bold">
          {eventTypeLabel(failedTestStep?.event_type)}
        </Text>{' '}
        is stopping people from buying
      </>
    )
  })()

  const openScreenshot = () => {
    const screenshot = incident?.failed_test_step?.screenshot
    if (screenshot) {
      window.open(screenshot, '_blank')
    }
  }

  const funnelComponent = useMemo(() => {
    if (!isIncidentLoading && !incident?.is_funnel) return null
    if (!isFunnelLoading && !funnel) return null

    return (
      <Skeleton isLoaded={!isIncidentLoading && !isFunnelLoading}>
        <Card variant="fullOutlined" borderRadius="xl">
          <CardHeader px={6} py={4}>
            <Text as="span" textTransform="uppercase" fontWeight={700}>
              Funnel
            </Text>
          </CardHeader>

          <CardBody px={6} py={4}>
            <IncidentFunnel
              detail={{
                type: incident?.funnel?.detail ?? 'normal',
                value: funnel?.detail ?? 0,
              }}
              add={{
                type: incident?.funnel?.add ?? 'normal',
                value: funnel?.add ?? 0,
              }}
              checkout={{
                type: incident?.funnel?.checkout ?? 'normal',
                value: funnel?.checkout ?? 0,
              }}
              purchase={{
                type: incident?.funnel?.purchase ?? 'normal',
                value: 0,
              }}
            />
          </CardBody>
        </Card>
      </Skeleton>
    )
  }, [
    funnel,
    incident?.funnel,
    incident?.is_funnel,
    isFunnelLoading,
    isIncidentLoading,
  ])

  if (!incident) return null

  const dotColor = () => {
    if (isIncidentLoading) return 'grey-lines'
    return incident.status === 'closed' ? 'success' : 'danger'
  }

  return (
    // Using margins with height differences instead of padding to make the elements behind it clickable
    <Slide
      direction="right"
      in={!!slideoutIncident}
      style={{
        border: 'none',
        zIndex: 10,
        width: 'fit-content',
        height: 'calc(100vh - 32px)',
        margin: '16px',
      }}
      unmountOnExit
    >
      <Card h="100%" w="512px" fontSize={14}>
        <CardHeader p={6}>
          <Flex alignItems="center">
            <Dot bgColor={dotColor()} />
            <Heading fontSize={18} mx={4} fontWeight={600}>
              {incidentTitle}
            </Heading>
            <Spacer />
            <IncidentPriorityBadge
              priority={incident.priority}
              alignSelf="start"
            />
            <CloseButton onClick={handleClose} />
          </Flex>
        </CardHeader>
        <CardBody pt={0} px={6} overflow="auto">
          <VStack alignItems="stretch">
            <Flex
              bg="grey-lines"
              border="solid 1px"
              borderColor="primary.300"
              alignItems="center"
              justifyContent="space-between"
              borderRadius="xl"
              px={6}
              py={4}
            >
              <Text as="span" textTransform="uppercase" fontWeight={700}>
                Alert Type
              </Text>
              <Text as="span" fontSize={12}>
                {incident.alert_type}
              </Text>
            </Flex>

            <Card variant="fullOutlined" borderRadius="xl">
              <CardHeader px={6} py={4}>
                <Flex alignItems="start" justifyContent="space-between">
                  <Text as="span" textTransform="uppercase" fontWeight={700}>
                    Incident
                  </Text>
                  <Text as="span" fontSize={12} maxW="70%">
                    {incidentTitle}{' '}
                    {incident.status === 'closed' ? 'were' : 'are'}{' '}
                    {formatAlertTypeMessage(incident, undefined, 'range')}
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody px={0} py={4}>
                {incident.total_revenue_impact !== null && (
                  <>
                    <Flex
                      alignItems="top"
                      justifyContent="space-between"
                      px={6}
                    >
                      <Text as="span" fontWeight={700} color="primary.dark.400">
                        Revenue impact
                      </Text>
                      <VStack gap={3} color="primary.dark.400">
                        <Text as="span">
                          {currencySymbol(incident.currency)}
                          {incident.total_revenue_impact.toFixed(2)} so far
                        </Text>
                        {incident.revenue_impact_per_minute !== null && (
                          <Text as="span">
                            {currencySymbol(incident.currency)}
                            {incident.revenue_impact_per_minute.toFixed(2)} per
                            minute
                          </Text>
                        )}
                      </VStack>
                    </Flex>
                    <Divider my={4} />
                  </>
                )}

                <Flex alignItems="top" justifyContent="space-between" px={6}>
                  <Text as="span" fontWeight={700} color="primary.dark.400">
                    When
                  </Text>
                  <VStack gap={3} color="primary.dark.400" alignItems="end">
                    <Text as="span">{whenText}</Text>
                    <Text as="span">{testStepsMessage}</Text>
                  </VStack>
                </Flex>
              </CardBody>
            </Card>

            {!isStatusCheckEvent && testStepsIndicator && (
              <Card variant="fullOutlined" borderRadius="xl">
                <CardHeader px={6} py={4}>
                  <Flex alignItems="start">
                    <Text as="span" textTransform="uppercase" fontWeight={700}>
                      CHECKOUT ROBOT
                    </Text>
                  </Flex>
                </CardHeader>
                <CardBody px={0} py={4}>
                  <Flex
                    alignItems="start"
                    justifyContent="space-between"
                    px={6}
                  >
                    <Text as="span" fontWeight={700} color="primary.dark.400">
                      {testStepsIndicator[0].title}
                    </Text>
                    <Text as="span" fontWeight={400} color="primary.dark.400">
                      {testStepsIndicator[0].body}
                    </Text>
                  </Flex>
                  <Divider my={4} />
                  <Flex alignItems="start" px={6}>
                    <Text as="span" fontWeight={400} color="primary.dark.400">
                      {testStepsIndicator[1].map((test) => (
                        <Flex gap="4" key={test.event} alignItems="center">
                          <Circle size="16px" bg={test.color} />
                          <Text fontSize="sm" fontWeight="400" py={2}>
                            {test.event}
                          </Text>
                        </Flex>
                      ))}
                    </Text>
                  </Flex>
                  {incident?.failed_test_step?.screenshot && (
                    <Tooltip label="Click to open in new tab" hasArrow>
                      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                      <img
                        src={incident.failed_test_step.screenshot}
                        alt="Screenshot of the error on your shop's website"
                        style={{
                          width: '100%',
                          padding: 12,
                          paddingBottom: 0,
                          cursor: 'pointer',
                        }}
                        onClick={openScreenshot}
                        onKeyDown={openScreenshot}
                        role="button"
                        tabIndex={0}
                        aria-roledescription="Opens screenshot in new tab"
                      />
                    </Tooltip>
                  )}
                </CardBody>
              </Card>
            )}

            {!isStatusCheckEvent && !isCheckoutRobotEvent && funnelComponent}

            {!isStatusCheckEvent &&
              !isCheckoutRobotEvent &&
              Object.entries(anomalies).map(
                ([eventType, anomaliesByEventType]) => (
                  <IncidentSlideoutLineChartCard
                    incidentUUID={slideoutIncident}
                    eventType={eventType}
                    anomalies={anomaliesByEventType}
                  />
                )
              )}

            {isStatusCheckEvent && (
              <>
                <Card variant="fullOutlined" borderRadius="xl">
                  <CardBody px={6} py={4} border={0}>
                    <Text as="span" fontWeight={700} color="primary.dark.400">
                      Your shop uptime during the last 24h
                    </Text>
                    <IncidentSlideoutStatusCheckChart
                      incidentUUID={slideoutIncident}
                      eventType={incident.event_type}
                    />
                    <Text
                      as="span"
                      fontSize={12}
                      fontWeight={400}
                      color="primary.dark.300"
                      mt={20}
                    >
                      Red sections indicate downtime.
                    </Text>
                  </CardBody>
                </Card>
                <Card variant="fullOutlined" borderRadius="xl">
                  <CardBody px={6} py={4} border={0}>
                    <Text as="span" fontWeight={700} color="primary.dark.400">
                      Your store&apos;s up and downtime in the past 3 months:
                    </Text>
                    <IncidentSlideoutStatusCheckUpDowntime
                      incidentUUID={slideoutIncident}
                      eventType={incident.event_type}
                    />
                  </CardBody>
                </Card>
              </>
            )}

            <Collapse in={!isCheckoutRobotEvent && !incident.label}>
              <IncidentSlideoutLabelIncident incidentUUID={slideoutIncident} />
            </Collapse>

            {!isCheckoutRobotEvent && incident.label && (
              <Box
                bg="grey-lines"
                border="solid 1px"
                borderColor="primary.300"
                borderRadius="xl"
                px={6}
                py={4}
                fontSize={14}
              >
                {incident.label === 'confirmed' && (
                  <Text>🛑 This is confirmed as a real problem.</Text>
                )}
                {incident.label === 'false_positive' && (
                  <Text>
                    ✅ Nothing to worry about! This incident has been reported
                    as a false positive.
                  </Text>
                )}
              </Box>
            )}
          </VStack>
        </CardBody>
      </Card>
    </Slide>
  )
}
