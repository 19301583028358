import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import step1 from '../../../assets/onboarding/step1.png'
import step2 from '../../../assets/onboarding/step2.png'
import kpi from '../../../assets/onboarding/kpi.png'
import funnel from '../../../assets/onboarding/funnel.png'
import { Carousel } from '../shared/Carousel'

export const NotificationStep = ({ previous, next }) => (
  <Flex maxWidth="100%" overflow="hidden" flexDirection="column">
    <Flex pr={14} flexDirection="column">
      <Text fontWeight="700" color="primary.500" fontSize="lg">
        Hello, Revend notification
      </Text>

      <Text fontSize="sm" mt={4}>
        A Revend notification is fired when the monitoring picks up a strong,
        enduring deviation from what Revend considers are normal activity levels
        on your store.
      </Text>
    </Flex>
    <Carousel alignSelf="center" overflow="hidden" maxWidth="706px" mt={10}>
      <Box height="100%">
        <Flex
          bg="white"
          borderRadius="md"
          p={6}
          flexDirection="column"
          overflow="hidden"
          height="100%"
        >
          <Text fontWeight="700" color="primary.500" fontSize="lg" pb={6}>
            1. The incident
          </Text>
          <Box margin="auto">
            <Image src={step1} />
            <Text fontSize="sm" mt={6}>
              Revend raises an alert when it has detected at least 2 consecutive
              1-hour intervals where a metric has acted abnormally. A new
              interval is produced every five minutes with a summary of all
              events in the last 60 minutes. So if you get a new alert, that
              means we have looked at all events in the past 65 minutes before
              reporting.
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box height="100%">
        <Flex
          bg="white"
          borderRadius="md"
          p={6}
          flexDirection="column"
          overflow="hidden"
          height="100%"
        >
          <Text fontWeight="700" color="primary.500" fontSize="lg" pb={6}>
            2. The status bar
          </Text>
          <Box margin="auto">
            <Image src={step2} />
            <UnorderedList m={0} mt={6}>
              <ListItem fontSize="sm">
                A red bar means the incident is still affecting your website,
                and the KPI is still not back to normal levels
              </ListItem>
              <ListItem fontSize="sm">
                A green bar means the incident is no longer affecting your
                website, and the KPI is back within the expected range for
                normal operations
              </ListItem>
            </UnorderedList>
          </Box>
        </Flex>
      </Box>
      <Box height="100%">
        <Flex
          bg="white"
          borderRadius="md"
          p={6}
          flexDirection="column"
          overflow="hidden"
          height="100%"
        >
          <Text fontWeight="700" color="primary.500" fontSize="lg" pb={6}>
            3. The details
          </Text>
          <Box margin="auto">
            <UnorderedList m={0}>
              <ListItem fontSize="sm">
                📣{' '}
                <Text as="span" fontWeight="bold" fontSize="sm">
                  Event type:{' '}
                </Text>
                What event is under scrutiny? This can be each event you are
                tracking, including custom events. (e.g. Add to cart, checkout,
                product page views)
              </ListItem>
              <ListItem fontSize="sm" mt={3}>
                ⏱️{' '}
                <Text as="span" fontWeight="bold" fontSize="sm">
                  When:{' '}
                </Text>
                Over what period of time is the outlier in your KPI observed?
                This interval auto-updates for every 5 minutes the incidents
                persists. Remember that we look at 1 hour of data for every data
                point, so if an alert has been open for 5 minutes, this means we
                have looked at 65 minutes worth of data.
              </ListItem>
              <ListItem fontSize="sm" mt={3}>
                📉📈📊{' '}
                <Text as="span" fontWeight="bold" fontSize="sm">
                  Model metrics:{' '}
                </Text>
                These four figures tell you what value we observe (current) and
                what Revend{`'`}s models estimate as normal behavior of your KPI
                based on the historical behavior of that KPI.
              </ListItem>
            </UnorderedList>
          </Box>
        </Flex>
      </Box>
      <Box height="100%">
        <Flex
          bg="white"
          borderRadius="md"
          p={6}
          flexDirection="column"
          overflow="hidden"
          height="100%"
        >
          <Text fontWeight="700" color="primary.500" fontSize="lg" pb={6}>
            4. The feedback buttons
          </Text>
          <Box margin="auto">
            <Text fontSize="sm">
              Confirm or report notifications to train your personal Revend
              model. Pressing the right button today can save you days of
              revenue tomorrow . Before pressing either of these buttons, make
              sure you know what to confirm first.
            </Text>
            <UnorderedList m={0} mt={6}>
              <ListItem fontSize="sm">
                <Text as="span" fontWeight="bold" fontSize="sm">
                  Confirm issue:{' '}
                </Text>
                Press this button when you have received the notification and
                confirmed that it detected an actual problem.
              </ListItem>
              <ListItem fontSize="sm" mt={4}>
                <Text as="span" fontWeight="bold" fontSize="sm">
                  Report as false positive:{' '}
                </Text>
                Press this button when you have checked the issue, and confirmed
                that it did not detect a real problem. This means you have done
                the research outlined below, and did not see any problems when
                confirming all of the 3 possibilities above. It will be counted
                as a hard false positive.
              </ListItem>
              <ListItem fontSize="sm" mt={4}>
                <Text as="span" fontWeight="bold" fontSize="sm">
                  Don{`'`}t press anything:{' '}
                </Text>
                If you ignore a message and don{`'`}t give feedback, Revend{`'`}
                s algorithm will treat it as a soft false positive. Don{`'`}t
                worry if you miss reporting on a notification once in a while,
                Revend will always take into account which messages you interact
                with, and adjust your model accordingly.
              </ListItem>
            </UnorderedList>
          </Box>
        </Flex>
      </Box>
      <Box height="100%">
        <Flex
          bg="white"
          borderRadius="md"
          p={6}
          flexDirection="column"
          overflow="hidden"
          height="100%"
        >
          <Text fontWeight="700" color="primary.500" fontSize="lg" pb={6}>
            5. Funnel graph
          </Text>
          <Flex margin="auto" flexDirection="column">
            <Text fontSize="sm" mb={5}>
              The funnel graph illustrates the steps involved in making a
              purchase, which are divided into four parts: detail, add to cart,
              checkout, and purchase.Similar to a status bar, each step is
              represented by a color indicating whether everything is running
              smoothly (green), seeing some decline (yellow), or if there{`'`}s
              an issue (red). Additionally we have a Total Conversion Indicator,
              which represents the percentage of customers who successfully
              complete a step and move on to purchase.
            </Text>
            <Image src={funnel} margin="0 auto" />
          </Flex>
        </Flex>
      </Box>
      <Box height="100%">
        <Flex
          bg="white"
          borderRadius="md"
          p={6}
          flexDirection="column"
          overflow="hidden"
          height="100%"
        >
          <Text fontWeight="700" color="primary.500" fontSize="lg" pb={6}>
            6. KPI graph
          </Text>
          <Flex margin="auto" flexDirection="column">
            <Text fontSize="sm" mb={5}>
              We designed these graphs to give you a quick and clear overview of
              why a notification was fired, how the affected KPI metric has
              behaved, and what action to take next. Check the link below for
              further details of the information displayed on the graphs.
            </Text>
            <Image src={kpi} margin="0 auto" />
          </Flex>
        </Flex>
      </Box>
    </Carousel>
    <Flex
      bg="white"
      padding={6}
      mt={6}
      justifyContent="space-between"
      alignItems="center"
      borderRadius="md"
    >
      <Text fontSize="sm">
        For more details on the elements of a Revend notification,
        <Link
          href="https://www.revend.ai/resources/anatomy-of-a-revend-message"
          isExternal
          color="primary.500"
        >
          click here. <ExternalLinkIcon mx="2px" />
        </Link>
      </Text>
    </Flex>
    <Box alignSelf="end" mt={10}>
      <Button
        variant="outline.500"
        colorScheme="primary"
        bg="white"
        borderRadius="sm"
        width="110px"
        height="32px"
        onClick={previous}
      >
        Previous
      </Button>
      <Button
        borderRadius="sm"
        width="110px"
        height="32px"
        ml={4}
        onClick={next}
      >
        Next
      </Button>
    </Box>
  </Flex>
)

NotificationStep.propTypes = {
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
}
