import { useQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchBilling } from '../../services/api/palantir'

/**
 * Fetches if billing is active from Palantir.
 *
 */
export const useFetchBillings = (queryOptions = undefined) => {
  const token = useAuthToken()

  const {
    data: billings,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['billing'],
    queryFn: () => fetchBilling(token).then((res) => res.data.billings),
    enabled: !!token,
    ...queryOptions,
  })

  return {
    billings,
    isLoading,
    refetch,
  }
}
