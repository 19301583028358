import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { fetchIncident } from '../../services/api/palantir'
import { useAuthToken } from '../store/useAuthToken'

/**
 * @param incidentUUID {string}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 * @param enabled {boolean}
 */
export const useFetchIncident = (
  { incidentUUID },
  queryOptions = undefined,
  { enabled = true } = {}
) => {
  const token = useAuthToken()
  const isRequestValid = !!incidentUUID && enabled

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchIncident: invalid request parameters.', {
        incidentUUID,
      })
    }
  }, [isRequestValid, incidentUUID])

  return useQuery({
    queryKey: ['incident', { incidentUUID }],
    queryFn: () =>
      fetchIncident(token, { incidentUUID })
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.status === 404) return null
          throw err
        }),
    enabled: !!token && isRequestValid,
    ...queryOptions,
  })
}
