import { useNavigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import { config } from '../config'
import { useCheckRouteActive } from '../hooks/router/useCheckRouteActive'
import { ROUTE_SIGNUP } from '../util/routes'

export const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate()
  const isSignup = useCheckRouteActive(
    [{ priority: 0, expr: ROUTE_SIGNUP }],
    [{ priority: -1, expr: /^.*$/ }]
  )

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={config.auth.domain}
      clientId={config.auth.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
      useRefreshTokensFallback
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope:
          'openid profile email read:current_user read:current_user_metadata',
        audience: `https://${config.auth.domain}/api/v2/`,
        screen_hint: isSignup ? 'signup' : 'login',
      }}
    >
      {children}
    </Auth0Provider>
  )
}

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.node.isRequired,
}
