import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, HStack, Input, Select, VStack } from '@chakra-ui/react'
import { SettingsCard } from './SettingsCard'
import { useMutateCompanySettings } from '../../../hooks/palantir'
import { HorizontalTab, HorizontalTabs } from '../../ui-kit/HorizontalTabs'
import { CompanyInviteSettings } from './global-settings/company/InviteSettings'
import { PropertySettings } from './global-settings/property/PropertySettings'

export const GlobalSettings = ({ selectedCompany, isFetchCompanyLoading }) => {
  const [activeHTab, setActiveHTab] = useState(0)
  const [loading, setLoading] = useState(false)

  const [companyInfo, setCompanyInfo] = useState(selectedCompany)

  useEffect(() => {
    setCompanyInfo(selectedCompany)
  }, [selectedCompany])

  const companySizeOptions = [
    { id: '0', label: '1-10 employees' },
    { id: '1', label: '11-50 employees' },
    { id: '2', label: '51-200 employees' },
    { id: '3', label: '200+ employees' },
  ]

  const { mutate: mutateSettings, isLoading: mutateSettingsLoading } =
    useMutateCompanySettings()

  const handleInputChange = (event) => {
    const { value } = event.target
    const { name } = event.target

    setCompanyInfo({ ...companyInfo, [name]: value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    mutateSettings({
      companyUUID: selectedCompany?.uuid,
      ...companyInfo,
    })
  }

  const handleLoading = (value) => {
    setLoading(value)
  }

  useEffect(() => {
    setLoading(mutateSettingsLoading || isFetchCompanyLoading)
  }, [mutateSettingsLoading, isFetchCompanyLoading])

  return (
    <Box maxH="100%" h="100%">
      <HorizontalTabs
        activeIndex={activeHTab}
        onActiveIndexChange={setActiveHTab}
      >
        <HorizontalTab label="Company Information">
          <SettingsCard
            title="Company Information"
            subtitle="Basic configuration for data handling"
            isLoading={loading}
            saveFunction={handleSubmit}
            mb={16}
          >
            <VStack
              gap="1rem"
              alignItems="start"
              sx={{
                '& label': {
                  fontSize: 12,
                },
              }}
            >
              <Box>
                <label htmlFor="companyName">Company information</label>
                <HStack mt="0.75rem">
                  <Input
                    name="name"
                    width="355px"
                    h="40px"
                    value={companyInfo?.name || ''}
                    onChange={handleInputChange}
                  />
                  <Select
                    name="size"
                    value={companyInfo?.size || 0}
                    onChange={handleInputChange}
                    maxW="12.5rem"
                    h="40px"
                  >
                    {companySizeOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </HStack>
              </Box>
              <CompanyInviteSettings
                companyUUID={companyInfo?.uuid}
                onLoading={handleLoading}
              />
            </VStack>
          </SettingsCard>
        </HorizontalTab>
        <HorizontalTab label="Properties">
          <SettingsCard
            title="Properties"
            subtitle="Each store/domain you want to track is a property in Revend. If your store serves different countries,
            it is usually better to split your data per country with a different Revend property to account for the 
            different timezones and activity patterns."
            isLoading={loading}
            saveFunction={handleSubmit}
            mb={16}
          >
            <VStack
              gap="1rem"
              alignItems="start"
              sx={{
                '& label': {
                  fontSize: 12,
                },
              }}
            >
              <HStack mt="1.2rem">
                <PropertySettings
                  companyUUID={companyInfo?.uuid}
                  onLoading={handleLoading}
                />
              </HStack>
            </VStack>
          </SettingsCard>
        </HorizontalTab>
      </HorizontalTabs>
    </Box>
  )
}

GlobalSettings.propTypes = {
  selectedCompany: PropTypes.object,
  isFetchCompanyLoading: PropTypes.bool,
}
