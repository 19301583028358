import { useQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchGoogleConfig } from '../../services/api/palantir'

/**
 * Fetches google accounts informartion for the current customer.
 *
 */
export const useFetchGoogleConfig = (
  { customerUUID },
  queryOptions = undefined
) => {
  const token = useAuthToken()

  return useQuery({
    queryKey: ['googleConfig', { customerUUID }],
    queryFn: () =>
      fetchGoogleConfig(token, { customerUUID }).then((res) => res.data),
    enabled: !!token,
    ...queryOptions,
  })
}
