import 'react-calendar/dist/Calendar.css'
import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Divider, HStack, VStack } from '@chakra-ui/react'
import ReactCalendar from 'react-calendar'
import md5 from 'md5'
import { FloatLabel } from './FloatLabel'
import { SingleSelect } from './SingleSelect'
import { TimeUtils } from '../../util/TimeUtils'
import { DateInput } from './DateInput'
import { MonthInput } from './MonthInput'
import { MonthPicker } from './FullMonthPicker'
import { calendarDateComparePickerStyles } from '../../styles/calendarDateComparePicker.styles'

const Calendar = ({ isDark, ...rest }) => (
  <Box
    as={ReactCalendar}
    border="none !important"
    showNeighboringMonth={false}
    view="month"
    calendarType="US"
    sx={calendarDateComparePickerStyles(isDark)}
    {...rest}
  />
)

Calendar.propTypes = {
  isDark: PropTypes.bool,
}

export const CalendarDateComparePicker = ({
  baseDates,
  comparingDates,
  onBaseChange,
  onCompareChange,
  min,
  max,
  ...rest
}) => {
  const [baseDateStart, baseDateEnd] = baseDates ?? []
  const [comparingDateStart, comparingDateEnd] = comparingDates ?? []

  const [mode, setMode] = useState('Custom Range')

  const handleBaseChange = (dates) => {
    const [start, end] = Array.isArray(dates) ? dates : [dates, dates]

    if (comparingDateStart) {
      const amountOfDays = TimeUtils.differenceInCalendarDays(start, end)
      onCompareChange([
        comparingDateStart,
        TimeUtils.addDays(comparingDateStart, amountOfDays) - 1,
      ])
    }
    onBaseChange([start, end])
  }

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (mode) {
      case 'Day': {
        if (baseDateStart) {
          onBaseChange([
            TimeUtils.startOfLocalDay(baseDateStart),
            TimeUtils.endOfLocalDay(baseDateStart),
          ])
        }
        if (comparingDateStart) {
          onCompareChange([
            TimeUtils.startOfLocalDay(comparingDateStart),
            TimeUtils.endOfLocalDay(comparingDateStart),
          ])
        }
        break
      }

      case 'Week': {
        if (baseDateStart) {
          onBaseChange([
            TimeUtils.startOfLocalWeek(baseDateStart),
            TimeUtils.endOfLocalWeek(baseDateStart),
          ])
        }
        if (comparingDateStart) {
          onCompareChange([
            TimeUtils.startOfLocalWeek(comparingDateStart),
            TimeUtils.endOfLocalWeek(comparingDateStart),
          ])
        }
        break
      }

      case 'Month': {
        if (baseDateStart) {
          onBaseChange([
            TimeUtils.startOfLocalMonth(baseDateStart),
            TimeUtils.endOfLocalMonth(baseDateStart),
          ])
        }
        if (comparingDateStart) {
          onCompareChange([
            TimeUtils.startOfLocalMonth(comparingDateStart),
            TimeUtils.endOfLocalMonth(comparingDateStart),
          ])
        }
        break
      }
    }
  }, [mode, baseDateStart, comparingDateStart, onBaseChange, onCompareChange])

  const handleCompareChange = (dates) => {
    const [start, end] = Array.isArray(dates) ? dates : [dates, dates]
    onCompareChange([start, end])
  }

  const renderRangeDatePicker = (start, end, dispatcher) => (
    <HStack alignItems="stretch">
      <Box flexGrow={1}>
        <FloatLabel label="Start Date">
          <DateInput
            value={start}
            onChange={(date) => {
              dispatcher([date, end ?? date])
            }}
            bg="white"
          />
        </FloatLabel>
      </Box>
      <Box flexGrow={1}>
        <FloatLabel label="End Date">
          <DateInput
            value={end}
            onChange={(date) => {
              dispatcher([start ?? date, date])
            }}
            bg="white"
          />
        </FloatLabel>
      </Box>
    </HStack>
  )

  const renderSingleDatePicker = (start, dispatcher) => (
    <Box>
      <FloatLabel label="Date">
        <DateInput
          value={start}
          onChange={(date) => dispatcher([date, date])}
          bg="white"
        />
      </FloatLabel>
    </Box>
  )

  const renderMonthInput = (value, dispatcher) => (
    <Box>
      <FloatLabel label="Month">
        <MonthInput
          value={value}
          onChange={(date) => {
            dispatcher([
              TimeUtils.startOfLocalMonth(date),
              TimeUtils.endOfLocalMonth(date),
            ])
          }}
        />
      </FloatLabel>
    </Box>
  )

  const renderTimePickers = (start, end, dispatcher) => {
    switch (mode) {
      case 'Day':
        return renderSingleDatePicker(start, dispatcher)
      case 'Month':
        return renderMonthInput(start, dispatcher)
      default:
        return renderRangeDatePicker(start, end, dispatcher)
    }
  }

  const compareCalendarKey = useMemo(
    () => (comparingDates ? md5(comparingDates.join()) : ''),
    [comparingDates]
  )

  return (
    <HStack alignItems="stretch" {...rest}>
      {mode === 'Month' ? (
        <MonthPicker
          onChange={handleBaseChange}
          value={baseDateStart}
          minDate={min}
          maxDate={max}
          h="270px"
          w="350px"
        />
      ) : (
        <Calendar
          value={[baseDateStart, baseDateEnd]}
          onChange={handleBaseChange}
          minDate={min}
          maxDate={max}
          selectRange={mode === 'Custom Range'}
        />
      )}

      {mode === 'Month' ? (
        <MonthPicker
          onChange={handleCompareChange}
          value={comparingDateStart}
          minDate={min}
          maxDate={max}
          activeColor="primary.dark.500"
          h="270px"
          w="350px"
        />
      ) : (
        <Calendar
          key={compareCalendarKey}
          value={[comparingDateStart, comparingDateEnd]}
          onChange={handleCompareChange}
          minDate={min}
          maxDate={max}
          isDark
        />
      )}

      <VStack
        bg="grey-lines"
        gap={1}
        flexGrow={1}
        p={4}
        alignItems="stretch"
        minW="33%"
      >
        <FloatLabel label="Date Range">
          <SingleSelect
            onChange={setMode}
            value={mode}
            options={['Month', 'Week', 'Day', 'Custom Range']}
          />
        </FloatLabel>
        <Divider />
        {renderTimePickers(baseDateStart, baseDateEnd, onBaseChange)}
        <Divider />
        {renderTimePickers(
          comparingDateStart,
          comparingDateEnd,
          onCompareChange
        )}
        <HStack justifyContent="end">
          <Button
            size="xs"
            isDisabled={!comparingDateStart || !comparingDateEnd}
            onClick={() => onCompareChange([null, null])}
          >
            Clear Comparison
          </Button>
        </HStack>
      </VStack>
    </HStack>
  )
}

CalendarDateComparePicker.propTypes = {
  baseDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  comparingDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onBaseChange: PropTypes.func.isRequired,
  onCompareChange: PropTypes.func.isRequired,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
}
