import { useQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchGoogleConfigAccounts } from '../../services/api/palantir'

/**
 * Fetches google accounts data for the current customer.
 *
 */
export const useFetchGoogleAccounts = (
  { customerUUID },
  queryOptions = undefined
) => {
  const token = useAuthToken()

  return useQuery({
    queryKey: ['googleAccounts', { customerUUID }],
    queryFn: () =>
      fetchGoogleConfigAccounts(token, { customerUUID }).then(
        (res) => res.data
      ),
    enabled: !!token,
    ...queryOptions,
  })
}
