const WEIGHTS = Object.fromEntries(
  [
    'ec.click',
    'ec.detail',
    'ec.add',
    'ec.checkout',
    'ec.checkout.1',
    'ec.checkout.2',
    'ec.checkout.3',
    'ec.checkout.4',
    'ec.checkout.5',
    'ec.checkout.6',
    'ec.purchase',
  ].map((type, index) => [type, index])
)

const CLASSIFIER_WEIGHTS = Object.fromEntries(
  ['event', 'total_ratio', 'revenue'].map((type, index) => [type, index])
)

export const compareEventTypes = (a, b) => {
  const [aClassifier, aType] = a.includes(':') ? a.split(':') : ['event', a]
  const [bClassifier, bType] = b.includes(':') ? b.split(':') : ['event', b]

  const classifier = compare(aClassifier, bClassifier, CLASSIFIER_WEIGHTS)
  return classifier !== 0 ? classifier : compare(aType, bType, WEIGHTS)
}

const compare = (a, b, weightMap) => {
  const aWeight = weightMap[a] ?? Infinity
  const bWeight = weightMap[b] ?? Infinity

  if (aWeight !== Infinity && bWeight !== Infinity) {
    return aWeight - bWeight
  }
  if (aWeight !== Infinity) {
    return -1
  }
  if (bWeight !== Infinity) {
    return 1
  }

  return a.localeCompare(b)
}
