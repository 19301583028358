import { useSelector } from 'react-redux'

/**
 * @return {{
 * startDate: string,
 * endDate: string,
 * zoomStartDate: string,
 * zoomEndDate: string,
 * compareStartDate: string|null,
 * compareEndDate: string|null,
 * selectedIncident: string|null
 * }}
 */
export const useDashboardState = () => useSelector((state) => state.dashboard)
