import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  Flex,
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  useSteps,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import posthog from 'posthog-js'
import { useCustomerUUID } from '../hooks/store/useCustomerUUID'
import { TagManagerStep } from '../components/domain/onboarding/TagManagerStep'
import { useAuth0UserMetadata } from '../hooks/auth0'
import { ScriptTagStep } from '../components/domain/onboarding/ScriptTagStep'
import { OtherIntegrationsStep } from '../components/domain/onboarding/OtherIntegrationsStep'
import { NotificationStep } from '../components/domain/onboarding/NotificationStep'
import { SummaryStep } from '../components/domain/onboarding/SummaryStep'
import { useFetchIntegrationSettings } from '../hooks/palantir/useFetchIntegrationSettings'

const steps = [
  {
    title: 'Install tag',
    description: 'Add the Revend script to your theme or GTM',
  },
  {
    title: 'Test installation',
    description: 'Send events from your shop to Revend',
  },
  {
    title: 'Install notifications app',
    description: 'Add Revend to Slack, Teams or email',
  },
  {
    title: 'Crash course',
    description: 'Become an expert in Revend notifications',
  },
  {
    title: 'Done!',
    description: "What's next in your Revend journey",
  },
]

const CompletedCheck = () => (
  <CheckIcon
    bg="success"
    color="white"
    borderRadius="50%"
    padding="1"
    boxSize="5"
  />
)

const IncompleteCheck = () => (
  <CheckIcon
    bg="primary.dark.200"
    color="white"
    borderRadius="50%"
    padding="1"
    boxSize="5"
  />
)

export const OnboardingPage = () => {
  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  })

  useEffect(() => {
    posthog.capture('onboarding_progress', { step: steps[activeStep] })
  }, [activeStep])

  const [customerUUID, _setCustomerUUID] = useCustomerUUID()
  const { data: userMetadata } = useAuth0UserMetadata()

  const [integrationSettings, setIntegrationSettings] = useState({
    event_types: [],
    apps: [],
  })

  const { isFetching: _isFetchLoading } = useFetchIntegrationSettings(
    { customerUUID },
    {
      onSuccess(data) {
        setIntegrationSettings(data)
      },
    }
  )

  return (
    <Box mx={20} my={20}>
      <Flex columnGap={16} justifyContent="center">
        <Flex flexDirection="column" w="408px">
          <Box>
            <Text
              fontWeight="800"
              color="primary.dark.500"
              fontSize="3xl"
              mb={4}
            >
              {userMetadata?.company_name.length > 2
                ? `Hi, ${userMetadata?.company_name}!`
                : 'Hello!'}
            </Text>
            <Text
              fontWeight="600"
              color="primary.dark.500"
              fontSize="lg"
              mb="3"
            >
              Welcome to Revend!
            </Text>
            <Text fontWeight="500" color="primary.dark.500" fontSize="lg">
              Let&apos;s get you started in 5 minutes.
            </Text>
          </Box>
          <Box mt={10}>
            <Stepper
              index={activeStep}
              orientation="vertical"
              color="primary.dark.500"
            >
              {steps.map((step, index) => (
                <Step
                  key={step.title}
                  width="100%"
                  onClick={() => setActiveStep(index)}
                >
                  <StepIndicator>
                    <StepStatus
                      complete={<StepNumber />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box flex="1">
                    <Flex
                      ml={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box mr="4">
                        <StepTitle mb={2}>{step.title}</StepTitle>
                        <StepDescription mb="4">
                          {step.description}
                        </StepDescription>
                      </Box>
                      {(index === 0 || index === 1) && (
                        <CheckIcon
                          bg={
                            integrationSettings?.event_types?.length > 0
                              ? 'success'
                              : 'primary.dark.200'
                          }
                          color="white"
                          borderRadius="50%"
                          padding="1"
                          boxSize="5"
                        />
                      )}
                      {index === 2 && (
                        <CheckIcon
                          bg={
                            integrationSettings?.apps?.length > 0
                              ? 'success'
                              : 'primary.dark.200'
                          }
                          color="white"
                          borderRadius="50%"
                          padding="1"
                          boxSize="5"
                        />
                      )}
                      {(index === 3 || index === 4) && (
                        <StepStatus
                          complete={<CompletedCheck />}
                          incomplete={<IncompleteCheck />}
                          active={<IncompleteCheck />}
                        />
                      )}
                    </Flex>
                    <Divider />
                  </Box>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Flex>
        <Flex
          bg="#E9EEFF"
          borderRadius="16px"
          padding={10}
          alignItems="start"
          flexDirection="column"
          overflow="hidden"
          width="800px"
        >
          {activeStep === 0 && (
            <TagManagerStep customerUUID={customerUUID} next={goToNext} />
          )}
          {activeStep === 1 && (
            <ScriptTagStep
              eventTypes={integrationSettings.event_types}
              previous={goToPrevious}
              next={goToNext}
            />
          )}
          {activeStep === 2 && (
            <OtherIntegrationsStep
              customerUUID={customerUUID}
              previous={goToPrevious}
              next={goToNext}
            />
          )}
          {activeStep === 3 && (
            <NotificationStep previous={goToPrevious} next={goToNext} />
          )}
          {activeStep === 4 && <SummaryStep previous={goToPrevious} />}
        </Flex>
      </Flex>
    </Box>
  )
}
