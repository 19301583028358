import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useFetchBillings } from '../hooks/palantir/useFetchBillings'
import { IllustrationOk, IllustrationSearch } from '../components/illustrations'
import signPatternWhite from '../assets/sign-patter-white.svg'
import { BILLING_ACTIVE } from '../util/billingStatus'
import { LoadingSpinner } from '../components/ui-kit/LoadingSpinner'
import { ROUTE_BILLING, ROUTE_ONBOARDING } from '../util/routes'
import { SharedActions } from '../store/slices/sharedSlice'

export const BillingStatusPage = () => {
  const navigate = useNavigate()
  const [isWaiting, setIsWaiting] = useState(true)
  const dispatch = useDispatch()
  const { billings, isLoading: isFetching } = useFetchBillings({
    retry: false,
    cacheTime: 0,
    refetchInterval: 5000,
  })
  const isLoading = isWaiting || isFetching

  const isBillingActive =
    billings && billings?.some((b) => b.status === BILLING_ACTIVE)

  setTimeout(() => {
    setIsWaiting(false)
  }, 10000)

  useEffect(() => {
    if (isBillingActive) {
      setIsWaiting(false)
    }
  }, [isBillingActive])

  return (
    <Flex>
      <Box
        w="50%"
        backgroundColor="primary.500"
        backgroundImage={`url(${signPatternWhite})`}
        backgroundSize="97%"
        backgroundRepeat="no-repeat"
        transform="matrix(-1, 0, 0, 1, 0, 0)"
        display={{ base: 'none', md: 'block' }}
      />
      <Flex
        minH="calc(100vh - 56px)"
        w="50%"
        bg="surface.500"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          px={{ base: 12, md: 16 }}
          pt={{ base: 8, xl: 4 }}
          pb={4}
          alignItems="center"
          flexDirection="column"
          h="100%"
        >
          {isLoading && (
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              h="100%"
            >
              <LoadingSpinner color="primary.500" m="120px" />
            </Flex>
          )}
          {!isBillingActive && !isLoading && (
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              h="100%"
              maxW="464px"
            >
              <IllustrationSearch w="200px" h="200px" />
              <Text
                fontWeight="bold"
                fontSize="xl"
                pt={6}
                pb={6}
                color="primary.500"
              >
                We are still processing your payment...
              </Text>
              <Button
                bg="primary.500"
                color="white"
                textTransform="uppercase"
                borderRadius="2px"
                width="100%"
                _hover={{ bg: 'primary.600' }}
                mb={10}
                onClick={() => {
                  dispatch(SharedActions.setBillingStarted(false))
                  navigate(ROUTE_BILLING)
                }}
              >
                CANCEL & START OVER
              </Button>
            </Flex>
          )}
          {!isLoading && isBillingActive && (
            <>
              <Text fontWeight="bold" fontSize="2xl" pt={16}>
                Payment information
              </Text>
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                h="100%"
                maxW="464px"
              >
                <IllustrationOk w="200px" h="200px" />
                <Text
                  fontWeight="bold"
                  fontSize="2xl"
                  pt={6}
                  color="primary.500"
                >
                  Payment completed
                </Text>
                <Text fontSize="md" pt={8} pb={14} textAlign="center">
                  Thank you! Your payment was successfully completed. Proceed to
                  onboard onto Revend.
                </Text>
                <Button
                  bg="primary.500"
                  color="white"
                  textTransform="uppercase"
                  borderRadius="2px"
                  width="100%"
                  _hover={{ bg: 'primary.600' }}
                  mb={10}
                  onClick={() => navigate(ROUTE_ONBOARDING)}
                >
                  PROCEED TO REVEND
                </Button>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
