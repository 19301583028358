import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Spacer,
  Text,
  VStack,
  Heading,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useMemo, useState } from 'react'
import { localPoint } from '@visx/event'
import { LiaTimesSolid } from 'react-icons/lia'
import {
  useFetchEventCountData,
  useFetchIncident,
} from '../../../hooks/palantir'
import { formatAlertTypeMessage } from '../../../util/revend/formatAlertTypeMessage'
import { deviationPercentage } from '../../../util/etc/deviationPercentage'
import { SharedActions } from '../../../store'
import { formatIncidentDateTimeRange } from '../../../util/revend/formatIncidentDateTimeRange'
import { SingleLineIncidentChart } from '../../revend-charts/SingleLineIncidentChart'
import { incidentDateRange } from '../../../util/revend/incidentDateRange'
import { prepareIncidentAnomaliesForChart } from '../../../util/revend/prepareIncidentAnomaliesForChart'
import { HOUR_IN_MS, MINUTE_IN_MS } from '../../../util/TimeUtils'
import { createFunnelEventMessage } from '../../../util/revend/createFunnelEventMessage'
import { useEventTypeLabel } from '../../../hooks/etc/useEventTypeLabel'

export const DashboardIncidentDetailsCard = ({
  incidentUUID,
  onClose,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { data: incident, isFetching: isIncidentLoading } = useFetchIncident({
    incidentUUID,
  })

  const { start, end } = useMemo(
    () =>
      incident
        ? incidentDateRange(incident, 'ms-timestamp')
        : { start: 0, end: 0 },
    [incident]
  )

  const { data: eventCountData, isFetching: isEventCountDataLoading } =
    useFetchEventCountData(
      {
        customerUUID: incident?.customer_uuid,
        startDate: start - HOUR_IN_MS,
        endDate: end + 15 * MINUTE_IN_MS,
        eventType: incident?.event_type,
      },
      undefined,
      { defaultDisabled: !incident?.uuid }
    )

  const eventTypeLabel = useEventTypeLabel()
  const [tooltipX, setTooltipX] = useState()

  const handleChartHover = (event) => {
    if (!event) return setTooltipX(null)
    const { x } = localPoint(event) || { x: 0 }
    setTooltipX(x)
  }

  const preparedAnomalies = useMemo(
    () =>
      incident
        ? prepareIncidentAnomaliesForChart(
            [{ anomalies: incident.anomalies }],
            {
              start,
              end,
              eventType: incident.event_type,
            }
          )
        : [],
    [incident, end, start]
  )

  const incidentTitle = useMemo(() => {
    if (!incident) return null
    return incident.is_funnel
      ? createFunnelEventMessage(
          incident.anomalies.map((a) => a.event_type),
          eventTypeLabel
        )
      : eventTypeLabel(incident.event_type)
  }, [incident, eventTypeLabel])

  const renderInfo = (label, value, props = undefined) => (
    <Box minW="128px" {...props}>
      <Text textTransform="uppercase" fontWeight={600} fontSize={12}>
        {label}
      </Text>
      {!incident || isIncidentLoading || value === undefined ? (
        <Skeleton w="100%" h="22px" />
      ) : (
        <Text>{value}</Text>
      )}
    </Box>
  )

  const handleViewDetails = () => {
    dispatch(SharedActions.setSlideoutIncident(incidentUUID))
  }

  return (
    <Card bg="surface.500" {...rest}>
      <CardHeader py={4} pl={6} pr={2}>
        <Flex>
          <Box flexGrow={1}>
            {!incident || isIncidentLoading ? (
              <Skeleton w="100%" h="22px" />
            ) : (
              <Heading m={0} as="span" fontSize={16} fontWeight={700}>
                {incidentTitle} {incident.status === 'closed' ? 'were' : 'are'}{' '}
                {Math.round(
                  Math.abs(
                    deviationPercentage(incident.current, incident.min, 1)
                  ) * 100
                )}
                {'% '}
                {formatAlertTypeMessage(incident)}
              </Heading>
            )}
          </Box>
          <IconButton
            as={LiaTimesSolid}
            variant="ghost"
            aria-label="Close"
            h="16px"
            cursor="pointer"
            color="text"
            onClick={onClose}
          />
        </Flex>
      </CardHeader>
      <CardBody>
        <HStack mb="40px" alignItems="start" gap={4}>
          {renderInfo('Alert Type', incident?.alert_type, {
            _sx: { '& span:first-letter': { textTransform: 'uppercase' } },
          })}
          {renderInfo(
            'When',
            incident ? formatIncidentDateTimeRange(incident) : ''
          )}
          {renderInfo('Current', incident?.current)}
          {renderInfo('Maximum', incident?.min)}
          {renderInfo('Minimum', incident?.max)}
          {renderInfo('Average', incident?.mean?.toFixed(0))}
          <Spacer />
          <Button size="sm" borderRadius="sm" onClick={handleViewDetails}>
            View Details
          </Button>
        </HStack>
        <Box h="min(calc(100vh * 0.5), 512px)">
          <AutoSizer>
            {({ width, height }) => (
              <Skeleton
                h={height}
                w={width}
                isLoaded={!isEventCountDataLoading}
                borderRadius="lg"
              >
                {eventCountData?.length ? (
                  <Box>
                    <SingleLineIncidentChart
                      key={`${width}-${height}`}
                      width={width}
                      height={height}
                      chartMarginLeft={24}
                      chartMarginBottom={20}
                      tooltipX={tooltipX}
                      data={eventCountData}
                      incidents={[incident]}
                      anomalies={preparedAnomalies}
                      onChartHover={handleChartHover}
                      showAxisBottom
                      showAxisLeft
                    />
                  </Box>
                ) : (
                  <Box bg="#efefef" borderRadius="lg" h={height} w={width}>
                    <Center h="100%">
                      <VStack>
                        <Text mb={0}>No data for this period.</Text>
                      </VStack>
                    </Center>
                  </Box>
                )}
              </Skeleton>
            )}
          </AutoSizer>
        </Box>
      </CardBody>
    </Card>
  )
}

DashboardIncidentDetailsCard.propTypes = {
  incidentUUID: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}
