import { useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchOpenedIncidents } from '../../services/api/palantir'

/**
 * Fetches event types data from Palantir.
 *
 * @param customerUUID {string}
 * @param queryOptions {import("@tanstack/react-query").UseInfiniteQueryOptions}
 */
export function useFetchOpenedIncidents(
  { customerUUID },
  queryOptions = undefined
) {
  const token = useAuthToken()

  const isRequestValid = typeof customerUUID === 'string' && !!customerUUID

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchOpenedIncidents: invalid request parameters.', {
        customerUUID,
      })
    }
  }, [customerUUID, isRequestValid])

  return useInfiniteQuery({
    queryKey: ['openedIncidents', { customerUUID }],
    queryFn: ({ pageParam }) =>
      fetchOpenedIncidents(token, { customerUUID, page: pageParam ?? 0 }).then(
        (res) => res.data
      ),
    getNextPageParam: ({ nextPage }) => nextPage,
    enabled: !!token && isRequestValid,
    cacheTime: 0,
    ...queryOptions,
  })
}
