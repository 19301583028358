import { incidentDateRange } from './incidentDateRange'

/**
 * @type {Intl.DateTimeFormatOptions}
 */
const timeFormat = {
  hour: '2-digit',
  minute: '2-digit',
}

/**
 * @type {Intl.DateTimeFormatOptions}
 */
const dateFormat = {
  dateStyle: 'short',
}

/**
 * Formats an incident's start and end datetimes into a string.
 * @param incident {{ when: string, duration_in_minutes: number }}
 * @return {string}
 */
export const formatIncidentDateTimeRange = (incident) => {
  const { start, end } = incidentDateRange(incident, 'date')

  const dateStr = start.toLocaleDateString(undefined, dateFormat)
  const startStr = start.toLocaleTimeString(undefined, timeFormat)

  if (start.getTime() === end.getTime()) {
    return `${dateStr} ${startStr}`
  }

  const endStr = end.toLocaleTimeString(undefined, timeFormat)
  return `${dateStr} ${startStr} — ${endStr}`
}
