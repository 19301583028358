import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useAuthToken } from '../store/useAuthToken'
import { fetchIntegrationSettings } from '../../services/api/palantir'

/**
 * Fetches integration settings.
 *
 * @param customerUUID {string}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 * @return {import("@tanstack/react-query").UseQueryResult<object, unknown>}
 */
export function useFetchIntegrationSettings(
  { customerUUID },
  queryOptions = undefined
) {
  const token = useAuthToken()

  const isRequestValid = !!customerUUID

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchIntegrationSettings: invalid request parameters.', {
        customerUUID,
      })
    }
  }, [isRequestValid, customerUUID])

  return useQuery({
    queryKey: ['integrationSettings', { customerUUID }],
    queryFn: () =>
      fetchIntegrationSettings(token, { customerUUID }).then((res) => res.data),
    enabled: !!token && isRequestValid,
    ...queryOptions,
  })
}
