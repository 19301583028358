import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Provider } from 'react-redux'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { IntercomProvider } from 'react-use-intercom'
import { AppGuards } from './guards/AppGuards'
import { NavBar } from './components/domain/navbar/NavBar'
import { AppRouter } from './AppRouter'
import { SideBarProvider } from './components/domain/sidebar/SideBarProvider'
import { LdLoader } from './providers/LdLoader'
import { CustomerUUIDLoader } from './providers/CustomerUUIDLoader'
import { IncidentSlideout } from './components/domain/incident-slideout/IncidentSlideout'
import { LogRocket } from './providers/LogRocket'
import { store } from './store'
import { Auth0TokenProvider } from './providers/Auth0TokenProvider'
import { persister, queryClient } from './services/reactQuery'
import { ChakraProvider } from './providers/ChakraProvider'
import { SpinnerWithText } from './components/domain/shared/SpinnerWithText'

const App = () => (
  <Provider store={store}>
    <Auth0TokenProvider>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <ReactQueryDevtools initialIsOpen={false} />
        <ChakraProvider>
          <AppGuards>
            <LdLoader />
            <CustomerUUIDLoader />
            <LogRocket />
            <IntercomProvider
              appId={process.env.REACT_APP_INTERCOM_APP_ID}
              autoBoot
            >
              <NavBar />
              <SideBarProvider>
                <AppRouter />
                <IncidentSlideout />
              </SideBarProvider>
            </IntercomProvider>
          </AppGuards>
        </ChakraProvider>
      </PersistQueryClientProvider>
    </Auth0TokenProvider>
  </Provider>
)

const SecureApp = withAuthenticationRequired(App, {
  onRedirecting: () => <SpinnerWithText>Loading...</SpinnerWithText>,
})

export { SecureApp as App }
