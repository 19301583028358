import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
  IconButton,
  Stack,
} from '@chakra-ui/react'
import {
  PersonAddAlt,
  DeleteOutline,
  PowerSettingsNew,
  Edit,
} from '@mui/icons-material'
import { CopyButton } from '../../../../ui-kit/CopyToClipboard'
import { ConfirmationModal } from '../../../../ui-kit/ConfirmationModal'
import { useMutatePropertyDeactivate } from '../../../../../hooks/palantir'
import { InviteCreateModal } from './InviteCreateModal'
import { PropertyCreateModal } from './PropertyCreateModal'

export const PropertyTable = ({ propertiesData }) => {
  const [openDeactivateConfirmationModal, setDeactivateConfirmationModal] =
    useState(false)
  const [selectedProperty, setSelectedProperty] = useState()
  const [openInviteCreateModal, setInviteCreateModal] = useState(false)
  const [selectedPropertyUUID, setSelectedPropertyUUID] = useState()
  const [openPropertyCreateModal, setPropertyCreateModal] = useState(false)

  const handlePropertyDeactivate = (property) => {
    setSelectedProperty(property)
    setDeactivateConfirmationModal(true)
  }

  const handleOnDeativateConfirmation = () => {
    mutateDeactivate(
      {
        companyUUID: selectedProperty.company_uuid,
        propertyUUID: selectedProperty.uuid,
      },
      {
        onSuccess: () => {
          setDeactivateConfirmationModal(false)
          setSelectedProperty(null)
        },
      }
    )
  }

  const handlePropertyUpdate = (property) => {
    setSelectedProperty(property)
    setPropertyCreateModal(true)
  }

  const handlePropertyInvite = (propertyUUID) => {
    setSelectedPropertyUUID(propertyUUID)
    setInviteCreateModal(true)
  }

  const { mutate: mutateDeactivate, isLoading: mutateDeactivateLoading } =
    useMutatePropertyDeactivate()

  return (
    propertiesData?.length > 0 && (
      <Box w="100%" mt="3">
        <ConfirmationModal
          titleText="Deativate property?"
          subtitleText="Are you sure you want to deactivate this property? You won't be able to use it anymore"
          applyBtnText="Yes, deactivate"
          cancelBtnText="Cancel"
          isOpen={openDeactivateConfirmationModal}
          onClose={() => setDeactivateConfirmationModal(false)}
          onApply={() => handleOnDeativateConfirmation()}
          isLoading={mutateDeactivateLoading}
        />
        <PropertyCreateModal
          companyUUID={selectedProperty?.company_uuid}
          property={selectedProperty}
          isOpen={openPropertyCreateModal}
          onClose={() => {
            setPropertyCreateModal(false)
            setSelectedProperty(null)
          }}
        />
        <InviteCreateModal
          propertyUUID={selectedPropertyUUID}
          isOpen={openInviteCreateModal}
          onClose={() => setInviteCreateModal(false)}
        />
        <Table maxW="100%" overflowX="hidden">
          <Thead>
            <Tr>
              <Th color="primary.dark.300" ps="1">
                Name
              </Th>
              <Th color="primary.dark.300">Url</Th>
              <Th color="primary.dark.300">Api Key</Th>
              <Th color="primary.dark.300">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {propertiesData.map((property) => (
              <Tr key={property.uuid} fontSize="12px">
                <Td py="2" ps="1">
                  {property.name}
                </Td>
                {property.url && (
                  <Td py="2">
                    <Link href={property.url} isExternal>
                      {property.url}
                    </Link>
                  </Td>
                )}
                <Td py="2">
                  <CopyButton copyText={property.uuid} />
                </Td>
                <Td py="1" w="100px">
                  <Stack direction="row" spacing={0}>
                    <IconButton
                      aria-label="Delete"
                      title="Delete property"
                      variant="ghost"
                      size="sm"
                      isDisabled
                      color="primary.dark.400"
                      icon={<DeleteOutline fontSize="small" />}
                      display="none" // TODO: Remove when delete is implemented
                    />
                    <IconButton
                      aria-label="Edit"
                      title="Edit property"
                      variant="ghost"
                      size="sm"
                      color="primary.dark.400"
                      onClick={() => handlePropertyUpdate(property)}
                      icon={<Edit fontSize="small" />}
                    />
                    <IconButton
                      aria-label="Deactivate"
                      title="Deactivate property"
                      variant="ghost"
                      size="sm"
                      isDisabled={!property.is_active} // change this when handling deactivation toogle is implemented
                      color={
                        property.is_active ? 'primary.500' : 'primary.dark.400'
                      }
                      onClick={() => handlePropertyDeactivate(property)}
                      icon={<PowerSettingsNew fontSize="small" />}
                    />
                    <IconButton
                      aria-label="Invite"
                      title="Invite users to this property"
                      variant="ghost"
                      size="sm"
                      color="primary.dark.400"
                      icon={<PersonAddAlt />}
                      isDisabled={!property.is_active}
                      onClick={() => handlePropertyInvite(property.uuid)}
                    />
                  </Stack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    )
  )
}

PropertyTable.propTypes = {
  propertiesData: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
}
