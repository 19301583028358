/**
 * @param current {number}
 * @param minimum {number}
 * @param directionWord {?string}
 * @param boundaryWord {?string}
 * @return {'above the expected maximum'| 'below the expected minimum' | `${string} the expected ${string}`}
 */
export const formatAlertTypeMessage = (
  { current, minimum },
  directionWord = current > minimum ? 'above' : 'below',
  boundaryWord = current > minimum ? 'maximum' : 'minimum'
) => `${directionWord} the expected ${boundaryWord}`
