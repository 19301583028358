import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  useFetchIncidents,
  useFetchOpenedIncidents,
} from '../../../hooks/palantir'
import { DashboardIncidentCard } from './DashboardIncidentCard'
import { ScrollBasedHorizontalCarousel } from '../../ui-kit/ScrollBasedHorizontalCarousel'
import { LoadingSpinner } from '../../ui-kit/LoadingSpinner'
import { useDashboardState } from '../../../hooks/store/useDashboardState'
import { useCustomerUUID } from '../../../hooks/store/useCustomerUUID'
import { useSharedState } from '../../../hooks/store/useSharedState'
import { SharedActions } from '../../../store'

export const DashboardIncidentsSection = (props) => {
  const dispatch = useDispatch()

  const [customerUUID] = useCustomerUUID()
  const { hoveredIncident } = useSharedState()
  const { selectedIncident, startDate, endDate } = useDashboardState()
  const {
    data: _openIncidentsData,
    fetchNextPage,
    hasNextPage,
    isInitialLoading: loadingOpen,
    isFetchingNextPage,
  } = useFetchOpenedIncidents({ customerUUID })
  const { data: _recentIncidentsData, isInitialLoading: loadingRecent } =
    useFetchIncidents({ customerUUID, startDate, endDate })
  const [tabIndex, setTabIndex] = useState(0)

  const isLoading = loadingOpen || loadingRecent

  const openIncidentsData = useMemo(
    () => _openIncidentsData?.pages?.flatMap?.((page) => page.data) ?? [],
    [_openIncidentsData]
  )
  const recentIncidentsData = useMemo(
    () => _recentIncidentsData?.closed?.slice(0, 128) ?? [],
    [_recentIncidentsData]
  )

  const refCarouselOpenIncidents = useRef(null)
  const refCarouselRecentIncidents = useRef(null)

  // These two states control weather the carousel had already focused on selectedIncident. One state each tab (Recent Incidents and Open Incidents).
  const [lastSelectedIncidentOpen, setLastSelectedIncidentOpen] = useState(null)
  const [lastSelectedIncidentRecent, setLastSelectedIncidentRecent] =
    useState(null)

  useEffect(() => {
    // If selectedIncident is outside of the range of loaded incidents, we would need to fetch the next page, otherwise this will not work.
    // For that moment I will just pretend that I didn't realise this yet, as this seems like an edge case.
    // TODO make sure that selectedIncident is in the range of loaded incidents

    if (isLoading) return
    const focusIncident = (
      temporaryState,
      temporaryStateDispatcher,
      data,
      carouselRef
    ) => {
      temporaryStateDispatcher(selectedIncident)
      if (selectedIncident && selectedIncident !== temporaryState) {
        const index = data.findIndex(({ uuid }) => uuid === selectedIncident)
        if (index !== -1) {
          // If this is not wrapped in setTimeout it won't work. Probably some reference issue to DOM that are already unmounted in the React's virtual DOM.
          // Not worth investigating, just let it be.
          setTimeout(() => carouselRef.current?.focusItem?.(index, 'center'), 0)
        }
      }
    }

    switch (tabIndex) {
      case 0:
        return focusIncident(
          lastSelectedIncidentOpen,
          setLastSelectedIncidentOpen,
          openIncidentsData,
          refCarouselOpenIncidents
        )
      case 1:
        return focusIncident(
          lastSelectedIncidentRecent,
          setLastSelectedIncidentRecent,
          recentIncidentsData,
          refCarouselRecentIncidents
        )
      default:
    }
  }, [
    lastSelectedIncidentRecent,
    openIncidentsData,
    recentIncidentsData,
    selectedIncident,
    isLoading,
    tabIndex,
    lastSelectedIncidentOpen,
  ])

  const handleSelectIncident = (incidentUUID) => {
    dispatch(SharedActions.setSlideoutIncident(incidentUUID))
  }

  const handleEndReached = async () => {
    if (hasNextPage) await fetchNextPage()
  }

  const renderLoadingPlaceholder = () => (
    <VStack w="100%">
      <LoadingSpinner color="primary.500" />
      <Text>Loading, please wait...</Text>
    </VStack>
  )

  const renderEmptyPlaceholderForOpenIncidents = () => {
    if (isLoading) return renderLoadingPlaceholder()

    return (
      <VStack w="100%">
        <Text color="primary.500" fontWeight={600} as="span">
          No incidents to display
        </Text>
        <Text color="primary.dark.500" fontSize={12} as="span">
          All incidents have been taken care of
        </Text>
      </VStack>
    )
  }

  const renderEmptyPlaceholderForRecentIncidents = () => {
    if (isLoading) return renderLoadingPlaceholder()

    return (
      <VStack w="100%">
        <Text color="primary.500" fontWeight={600} as="span">
          No incidents to display
        </Text>
        <Text color="primary.dark.500" fontSize={12} as="span">
          You have no recent incidents
        </Text>
      </VStack>
    )
  }

  return (
    <Box as="section" {...props}>
      <Tabs tabIndex={tabIndex} onChange={setTabIndex}>
        <TabList>
          <Tab>Open incidents</Tab>
          <Tab>Recent incidents</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ScrollBasedHorizontalCarousel
              minH={170}
              empty={renderEmptyPlaceholderForOpenIncidents}
              onEndReached={handleEndReached}
              isDisabled={loadingOpen || tabIndex !== 0}
              onEndReachedOffset="250px"
              ref={refCarouselOpenIncidents}
            >
              {openIncidentsData.map((incident) => (
                <DashboardIncidentCard
                  key={incident.uuid}
                  eventType={incident.event_type}
                  anomalyEventTypes={incident.anomalies.map(
                    (a) => a.event_type
                  )}
                  durationInMinutes={incident.duration_in_minutes}
                  current={incident.current}
                  minimum={incident.min}
                  closed={false}
                  priority="low"
                  startDate={new Date(incident.when)}
                  isFunnel={incident.is_funnel}
                  isActive={incident.uuid === selectedIncident}
                  isHighlighted={incident.uuid === hoveredIncident}
                  onClick={() => handleSelectIncident(incident.uuid)}
                />
              ))}

              {!isLoading && isFetchingNextPage && renderLoadingPlaceholder()}
            </ScrollBasedHorizontalCarousel>
          </TabPanel>
          <TabPanel pt={0}>
            <Text
              as="span"
              display="block"
              fontSize={10}
              color="primary.dark.300"
              right={6}
              textAlign="right"
            >
              From{' '}
              {new Date(startDate).toLocaleDateString(undefined, {
                month: 'short',
                day: 'numeric',
              })}{' '}
              to{' '}
              {new Date(endDate).toLocaleDateString(undefined, {
                month: 'short',
                day: 'numeric',
              })}
            </Text>
            <ScrollBasedHorizontalCarousel
              minH={170}
              empty={renderEmptyPlaceholderForRecentIncidents}
              isDisabled={loadingRecent || tabIndex !== 1}
              onEndReachedOffset="250px"
              ref={refCarouselRecentIncidents}
            >
              {recentIncidentsData.map((incident) => (
                <DashboardIncidentCard
                  key={incident.uuid}
                  eventType={incident.event_type}
                  anomalyEventTypes={incident.anomalies.map(
                    (a) => a.event_type
                  )}
                  durationInMinutes={incident.duration_in_minutes}
                  current={incident.current}
                  minimum={incident.min}
                  closed
                  priority={incident.priority}
                  startDate={new Date(incident.when)}
                  isFunnel={incident.is_funnel}
                  isActive={incident.uuid === selectedIncident}
                  isHighlighted={incident.uuid === hoveredIncident}
                  onClick={() => handleSelectIncident(incident.uuid)}
                />
              ))}

              {!isLoading && isFetchingNextPage && renderLoadingPlaceholder()}
            </ScrollBasedHorizontalCarousel>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
