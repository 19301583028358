import axios from 'axios'
import { config } from '../../../config'

let lastToken = null
let lastApi = null

/**
 * Creates an axios instance for the Palantir API.
 *
 * Uses a cached one if exists, or creates a brand new one and saves to a one-slot cache if it doesn't.
 *
 * @param token {string} Authentication token.
 * @return {axios.AxiosInstance}
 */
export const api = (token) => {
  if (lastToken === token) return lastApi
  lastToken = token
  return (lastApi = axios.create({
    baseURL: config.palantirUrl,
    timeout: 60000,
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }))
}
