/* eslint-disable no-underscore-dangle */
// Based off of https://github.com/d3/d3-shape/blob/main/src/curve/step.js

export const preciseCurveLinear = (context) => new PreciseCurveLinear(context)

class PreciseCurveLinear {
  constructor(context) {
    this._context = context
  }

  areaStart() {
    this._line = 0
  }

  areaEnd() {
    this._line = NaN
  }

  lineStart() {
    this._x = this._y = NaN
    this._point = 0
  }

  lineEnd() {
    if (this._line || (this._line !== 0 && this._point === 1)) {
      this._context.closePath()
    }
    if (this._line >= 0) {
      this._line = 1 - this._line
    }
  }

  point(x, y) {
    x = +x
    y = +y
    switch (this._point) {
      case 0:
        this._point = 1
        if (this._line) {
          this._context.lineTo(x, y)
        } else {
          this._context.moveTo(x, y)
        }
        break
      case 1:
        this._point = 2 // proceed
      // eslint-disable-next-line no-fallthrough
      default: {
        this._context.lineTo(this._x, y)
        break
      }
    }
    this._x = x
    this._y = y
  }
}
