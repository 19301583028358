import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useAuthToken } from '../store/useAuthToken'
import { fetchDashboardProperties } from '../../services/api/palantir'
import { MINUTE_IN_MS } from '../../util/TimeUtils'

/**
 * @param customerUUID {string}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 */
export function useFetchDashboardProperties(
  { customerUUID },
  queryOptions = undefined
) {
  const token = useAuthToken()

  const isRequestValid = !!customerUUID

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchDashboardProperties: invalid request parameters.', {
        customerUUID,
      })
    }
  }, [isRequestValid, customerUUID])

  return useQuery({
    queryKey: ['dashboardProperties', { customerUUID }],
    queryFn: () =>
      fetchDashboardProperties(token, { customerUUID }).then((res) => res.data),
    enabled: !!token && isRequestValid,
    staleTime: 30 * MINUTE_IN_MS,
    ...queryOptions,
  })
}
