/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types'
import { Box, Button, Divider, HStack, VStack } from '@chakra-ui/react'
import { useMemo } from 'react'

export const HorizontalTabs = ({
  children: _children,
  activeIndex,
  onActiveIndexChange,
  ...rest
}) => {
  const children = useMemo(
    () =>
      Array.isArray(_children)
        ? _children.filter(Boolean)
        : [_children].filter(Boolean),
    [_children]
  )

  return (
    <HStack w="100%" h="100%" alignItems="start" {...rest}>
      <VStack
        alignItems="stretch"
        divider={<Divider my="0 !important" />}
        minW="12em"
        h="100%"
        position="fixed"
      >
        {children.map((it, index) => (
          <Button
            key={index}
            textAlign="left"
            fontSize={16}
            fontWeight={500}
            variant="unstyled"
            color={activeIndex === index ? 'primary.500' : 'text'}
            onClick={() => onActiveIndexChange(index)}
          >
            {it.props.label}
          </Button>
        ))}
      </VStack>
      <Box
        h="100%"
        w="80%"
        margin="0px 0px auto auto"
        maxH="100%"
        overflow="auto"
      >
        {children[activeIndex]}
      </Box>
    </HStack>
  )
}

// Just a wrapper to define the component name and propTypes
export const HorizontalTab = ({ children }) => children

HorizontalTabs.propTypes = {
  children: PropTypes.node.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onActiveIndexChange: PropTypes.func.isRequired,
}

HorizontalTab.propTypes = {
  // This prop is used via reflection
  // eslint-disable-next-line react/no-unused-prop-types
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}
