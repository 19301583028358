import { Box } from '@chakra-ui/react'
import { ReactComponent as _IllustrationOk } from './IllustrationOk.svg'
import { ReactComponent as _IllustrationSearch } from './IllustrationSearch.svg'
import { ReactComponent as _IllustrationEmptyBox } from './IllustrationEmptyBox.svg'

/**
 * @param Component
 * @return {import("react").FC<import("@chakra-ui/react").BoxProps>}
 */
const wrapper = (Component) => (props) => <Box as={Component} {...props} />

export const IllustrationOk = wrapper(_IllustrationOk)
export const IllustrationSearch = wrapper(_IllustrationSearch)
export const IllustrationEmptyBox = wrapper(_IllustrationEmptyBox)
