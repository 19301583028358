import { Box, Center, Text } from '@chakra-ui/react'
import { LoadingSpinner } from '../../ui-kit/LoadingSpinner'

export const SignupLoading = (props) => (
  <Box {...props}>
    <Center>
      <LoadingSpinner color="primary.500" mb="120px" mx="120px" />
    </Center>

    <Text textAlign="center">Creating your account, please wait...</Text>
  </Box>
)
