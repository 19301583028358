/**
 * Validates if startDate is before endDate.
 *
 * @param startDate {string|Date|number} A date string, Date object, or millis timestamp.
 * @param endDate {string|Date|number} A date string, Date object, or millis timestamp.
 * @param allowZeroDifference {boolean} If true, startDate and endDate can be the same date.
 * @return {boolean} True if startDate is before endDate, false otherwise.
 */
export const validateDateRange = (
  startDate,
  endDate,
  allowZeroDifference = true
) => {
  startDate = new Date(startDate)
  endDate = new Date(endDate)

  if (
    startDate.toString() === 'Invalid Date' ||
    endDate.toString() === 'Invalid Date'
  ) {
    throw new Error('Invalid date')
  }

  const diff = endDate.getTime() - startDate.getTime()
  if (diff === 0 && !allowZeroDifference) return false
  return diff > 0
}
