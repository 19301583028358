import { Box, Center, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { SettingsCard } from '../../SettingsCard'
import { usePriorityTiersStore } from './PriorityTiersTab.hooks'
import { PriorityTiersCreateSection } from './PriorityTiersCreateSection'
import { LoadingSpinner } from '../../../../ui-kit/LoadingSpinner'
import { PriorityTiersSection } from './PriorityTiersSection'

export const PriorityTiersTab = () => {
  const { tiers, dispatch, isLoading, isSaving, saveRemote, areTiersValid } =
    usePriorityTiersStore()

  function renderLoading() {
    return (
      <Center py={16}>
        <LoadingSpinner color="primary.500" />
      </Center>
    )
  }

  function renderPriorityTiers() {
    return (
      <VStack alignItems="stretch">
        {Object.entries(tiers).map(([uuid, config]) => (
          <PriorityTiersSection {...{ dispatch, uuid, config }} />
        ))}
      </VStack>
    )
  }

  return (
    <SettingsCard
      title="Priority Configuration"
      subtitle="Set the confidence range for each level of priority"
      isLoading={isSaving}
      saveFunction={saveRemote}
      isSavingDisabled={!areTiersValid}
      mb={16}
    >
      <Box
        position="sticky"
        top="86px"
        bg={isSaving ? '#E6E7E7' : 'surface.500'}
        mb={8}
        pt={4}
        zIndex={998}
      >
        {false && ( // Disabled for now, as the user shouldn't be able to add a priority level yet
          <PriorityTiersCreateSection tiers={tiers} dispatch={dispatch} />
        )}
        <HStack mt={2}>
          <Icon as={InfoOutlineIcon} color="primary.500" h={5} w={5} />
          <Text color="primary.dark.400" fontWeight={500}>
            Ensure the priority range is set accurately to capture every
            interval without any lapses.
          </Text>
        </HStack>
      </Box>
      {isLoading ? renderLoading() : renderPriorityTiers()}
    </SettingsCard>
  )
}
