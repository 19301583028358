import { api } from './core'

/**
 * Adds a user in Palantir, filling the user data with the given parameters.
 *
 * @param authToken {string}
 * @param firstName {string}
 * @param lastName {string}
 * @param position {string}
 * @param phone {string}
 * @param companyName {string}
 * @param companySize {string}
 * @param email {string}
 * @param auth0Id {string}
 * @param url {string}
 * @return {Promise<AxiosResponse<any>>}
 */
export const mutateSignup = (
  authToken,
  {
    firstName,
    lastName,
    position,
    phone,
    companyName,
    companySize,
    email,
    auth0Id,
    url,
  }
) =>
  api(authToken).post('/api/v1/signup', {
    firstName,
    lastName,
    position,
    phone,
    companyName,
    companySize,
    email,
    auth0Id,
    url,
  })

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @param size {string}
 * @param name {string}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateCompanySettings = async (
  authToken,
  { name, size, companyUUID }
) =>
  api(authToken).patch(`/api/v1/company/${companyUUID}`, {
    name,
    size,
  })

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @param userName {string}
 * @param userEmail {string}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateCompanyInvites = async (
  authToken,
  { companyUUID, userName, userEmail }
) =>
  api(authToken).post(`/api/v1/company/${companyUUID}/invites`, {
    user_name: userName,
    user_email: userEmail,
  })

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @param inviteUUID {string}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateCancelInvite = async (
  authToken,
  { companyUUID, inviteUUID }
) =>
  api(authToken).patch(
    `/api/v1/company/${companyUUID}/invites/${inviteUUID}/cancel`
  )

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @param propertyData {object}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateProperty = async (
  authToken,
  { companyUUID, propertyData }
) =>
  api(authToken).post(`/api/v1/company/${companyUUID}/property`, propertyData)

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @param propertyUUID {string}
 * @param propertyData {object}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateUpdateProperty = async (
  authToken,
  { companyUUID, propertyUUID, propertyData }
) =>
  api(authToken).patch(
    `/api/v1/company/${companyUUID}/property/${propertyUUID}`,
    propertyData
  )

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @param propertyUUID {string}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutatePropertyDeactivate = async (
  authToken,
  { companyUUID, propertyUUID }
) =>
  api(authToken).patch(
    `/api/v1/company/${companyUUID}/property/${propertyUUID}/deactivate`
  )

/**
 * @param authToken {string}
 * @param propertyUUID {string}
 * @param userName {string}
 * @param userEmail {string}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutatePropertyInvites = async (
  authToken,
  { propertyUUID, userName, userEmail }
) =>
  api(authToken).post(`/api/v1/property/${propertyUUID}/invites`, {
    user_name: userName,
    user_email: userEmail,
  })

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @param trackedEvents {Array.<string>}
 * @param eventLabels {Object}
 * @param incidentConfigurations {Object}
 * @param relevance {string}
 * @param ipBlacklist {Array.<string>}
 * @param priorityTiers {?Record.<string, { icon: string, name: string, lower_bound: number, upper_bound: number }>}
 * @param funnelEvents {Object}
 *
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateNotificationSettings = async (
  authToken,
  {
    customerUUID,
    trackedEvents,
    eventLabels,
    incidentConfigurations,
    relevance,
    ipBlacklist,
    priorityTiers,
    funnelEvents,
  }
) => {
  const payload = {}
  if (trackedEvents) {
    payload.tracked_events = trackedEvents
  }
  if (eventLabels) {
    payload.event_labels = eventLabels
  }
  if (incidentConfigurations) {
    payload.incident_configurations = incidentConfigurations
  }
  if (relevance) {
    payload.relevance = relevance
  }
  if (ipBlacklist) {
    payload.ip_blacklist = ipBlacklist
  }
  if (priorityTiers) {
    payload.priority_tiers = priorityTiers
  }
  if (funnelEvents) {
    payload.funnel_events = funnelEvents
  }

  return api(authToken).patch(
    `/api/v1/customers/${customerUUID}/notification-settings`,
    payload
  )
}

/**
 * @param authToken {string}
 * @param incidentUUID {string}
 * @param label {'confirmed'|'false_positive'}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateIncidentLabel = async (authToken, { incidentUUID, label }) =>
  api(authToken).patch(`/api/v1/dash/incident/${incidentUUID}/label`, {
    label,
  })

/**
 * @param authToken {string}
 * @param auth0Id {string}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateResendVerificationEmail = async (authToken, { auth0Id }) =>
  api(authToken).post(`/api/v1/resend-verification-email`, {
    auth0Id,
  })

/**
 * @typedef {Object} emailChannelConfig
 * @property {string} customerUUID
 * @property {Array<string>} emails
 * @property {boolean} notify
 * @property {boolean} notifyClose
 */

/**
 * @param {string} authToken
 * @param {emailChannelConfig} emailChannelConfig
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateEmailChannelConfig = async (
  authToken,
  { customerUUID, emails, notify, notifyClose }
) =>
  api(authToken).post(
    `/api/v1/customers/${customerUUID}/email-channel-config`,
    {
      emails,
      notify,
      notify_close: notifyClose,
    }
  )

/**
 * @param authToken {string}
 * @param data.customerUUID {string}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateGoogleDisconnect = async (authToken, { customerUUID }) =>
  api(authToken).delete(`/api/v1/customers/${customerUUID}/google-config`)

/**
 * @param authToken {string}
 * @param payload {import('../../../hooks/palantir/useMutateGoogleConfig').GoogleConfigPayload}
 * @return {Promise<AxiosResponse<void>>}
 */
export const mutateGoogleConfig = async (authToken, payload) =>
  api(authToken).post(
    `/api/v1/customers/${payload.propertyUUID}/google-config`,
    {
      property_uuid: payload.propertyUUID,
      user_consent: payload.userConsent,
      events: payload.events,
      measurement_id: payload.measurementId,
      google_ad_account: payload.googleAdAccount,
      login_customer_id: payload.loginCustomerId,
    }
  )
