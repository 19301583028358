import { useQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchGoogleConfigAccountInfo } from '../../services/api/palantir'

/**
 * Fetches google accounts informartion for the current customer.
 *
 */
export const useFetchGoogleAccountInfo = (
  { customerUUID, accountID, loginCustomerId },
  queryOptions = undefined
) => {
  const token = useAuthToken()

  return useQuery({
    queryKey: [
      'googleAccountsInfo',
      { customerUUID, accountID, loginCustomerId },
    ],
    queryFn: () =>
      fetchGoogleConfigAccountInfo(token, {
        accountID,
        loginCustomerId,
        customerUUID,
      }).then((res) => res.data),
    enabled: !!token || !!accountID,
    ...queryOptions,
  })
}
