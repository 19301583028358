export const calendarDateComparePickerStyles = (isDark) => {
  return {
    /* I am really sorry for what you're about to read */
    '& > *': { fontFamily: 'Manrope, Roboto, sans', color: 'text' },
    '& .react-calendar__month-view__days__day--weekend': { color: 'text' },
    '& .react-calendar__tile--range, .react-calendar__tile--hover': {
      color: 'white',
    },
    '& .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button':
      {
        display: 'none',
      },
    '& .react-calendar__navigation > button:enabled:hover, .react-calendar__navigation > button:enabled':
      {
        bg: 'transparent',
      },
    '& .react-calendar__navigation__arrow': {
      color: 'primary.500',
      fontSize: 36,
    },
    '& .react-calendar__navigation__label': {
      cursor: 'default !important',
    },
    '& .react-calendar__navigation__label__labelText--from': {
      fontWeight: 600,
      lineHeight: '56px',
    },
    '& .react-calendar__month-view__weekdays__weekday > abbr[title]': {
      textDecoration: 'none',
      color: 'primary.dark.300',
      fontWeight: 400,
    },
    '& .react-calendar__tile--range:not(.react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd), .react-calendar__tile--hover:not(.react-calendar__tile--hoverStart, .react-calendar__tile--hoverEnd)':
      {
        backgroundColor: isDark ? 'primary.dark.250' : 'primary.250',
      },
    '& .react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeEnd), .react-calendar__tile--hoverStart:not(.react-calendar__tile--hoverEnd)':
      {
        bg: 'transparent',
        bgColor: 'transparent',
        bgGradient: `linear(to-r, transparent 0%, transparent 49%, ${
          isDark ? 'primary.dark.250' : 'primary.250'
        } 50%, ${isDark ? 'primary.dark.250' : 'primary.250'} 100%)`,
      },
    '& .react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeStart), .react-calendar__tile--hoverEnd:not(.react-calendar__tile--hoverStart)':
      {
        bg: 'transparent',
        bgColor: 'transparent',
        bgGradient: `linear(to-l, transparent 0%, transparent 49%, ${
          isDark ? 'primary.dark.250' : 'primary.250'
        } 50%, ${isDark ? 'primary.dark.250' : 'primary.250'} 100%)`,
      },
    '& *:where(.react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd, .react-calendar__tile--hoverBothEnds, .react-calendar__tile--hoverEnd, .react-calendar__tile--hoverStart) > abbr':
      {
        bg: isDark ? 'primary.dark.400' : 'primary.500',
        borderRadius: '50%',
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 'translateY(-18px)',
        h: '36px',
        w: '36px',
      },
    '& .react-calendar__tile--now:not(.react-calendar__tile--range, .react-calendar__tile--hover) > abbr':
      {
        bg: '#ffffa9',
        borderRadius: '50%',
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 'translateY(-18px)',
        h: '36px',
        w: '36px',
      },
    '& .react-calendar__tile--hoverBothEnds, .react-calendar__tile--rangeBothEnds, .react-calendar__tile--now, .react-calendar__tile--active:enabled:hover':
      {
        bg: 'transparent',
      },
    '& .react-calendar__tile--range:not(.react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd):hover':
      {
        bg: isDark ? 'primary.dark.300' : 'primary.300 !important',
      },
    '& .react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeEnd):hover, .react-calendar__tile--hoverStart:not(.react-calendar__tile--hoverEnd):hover':
      {
        bg: 'transparent',
        bgGradient: `linear(to-r, transparent 0%, transparent 49%, ${
          isDark ? 'primary.dark.300' : 'primary.300'
        } 50%, ${isDark ? 'primary.dark.300' : 'primary.300'} 100%)`,
      },
    '& .react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeStart):hover, .react-calendar__tile--hoverEnd:not(.react-calendar__tile--hoverStart):hover':
      {
        bg: 'transparent',
        bgGradient: `linear(to-l, transparent 0%, transparent 49%, ${
          isDark ? 'primary.dark.300' : 'primary.300'
        } 50%, ${isDark ? 'primary.dark.300' : 'primary.300'} 100%)`,
      },
    '& .react-calendar__tile--hoverStart:not(.react-calendar__tile--hoverEnd):hover':
      {
        bg: 'transparent',
        bgGradient: `linear(to-r, transparent 0%, transparent 49%, ${
          isDark ? 'primary.dark.300' : 'primary.250'
        } 50%, ${isDark ? 'primary.dark.300' : 'primary.250'} 100%)`,
      },
    '& .react-calendar__tile--hoverEnd:not(.react-calendar__tile--hoverStart):hover':
      {
        bg: 'transparent',
        bgGradient: `linear(to-l, transparent 0%, transparent 49%, ${
          isDark ? 'primary.dark.300' : 'primary.250'
        } 50%, ${isDark ? 'primary.dark.300' : 'primary.250'} 100%)`,
      },
  }
}
