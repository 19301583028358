import { useEffect, useMemo, useState } from 'react'
import { bisector } from 'd3-array'
import { localPoint } from '@visx/event'

/**
 *
 * @param startTimestamp {number}
 * @param endTimestamp {number}
 * @param minAreaMs {number}
 * @param direction {'left' | 'right'}
 * @return {[number, number]}
 */
const calculateBoundariesCompensatingArea = (
  startTimestamp,
  endTimestamp,
  minAreaMs,
  direction
) => {
  if (Math.abs(endTimestamp - startTimestamp) >= minAreaMs) {
    return direction === 'right'
      ? [startTimestamp, endTimestamp]
      : [endTimestamp, startTimestamp]
  }

  const missingEachSide =
    (Math.abs(endTimestamp - startTimestamp) - minAreaMs) / 2

  return direction === 'right'
    ? [
        Math.trunc(startTimestamp + missingEachSide),
        Math.trunc(endTimestamp - missingEachSide),
      ]
    : [
        Math.trunc(endTimestamp - missingEachSide),
        Math.trunc(startTimestamp + missingEachSide),
      ]
}

/**
 * @typedef {{ mouseDown: function(*), hoverOn: function(*) }} ZoomHandlerHandlers
 * @typedef {{ startPointVirtualTimestamp: Date|null, endPointVirtualTimestamp: Date|null, startPointPx: number|null, endPointPx: number|null, handlers: ZoomHandlerHandlers }} ZoomHandler
 */

/**
 * @param xScale {import("d3-scale").ScaleTime<Date, Date>}
 * @param getX {function(*): Date}
 * @param data {Array<*>}
 * @param onSelectArea {function([Date, Date]) | undefined}
 * @param selectionMinAreaMs {number}
 * @return {ZoomHandler}
 */
export const useZoomHandler = ({
  xScale,
  getX,
  data,
  onSelectArea,
  selectionMinAreaMs,
}) => {
  const bisectDate = useMemo(() => bisector(getX).left, [getX])

  const [startPointPx, setstartPointPx] = useState(null)
  const [endPointPx, setendPointPx] = useState(null)

  const startPointTimestamp = useMemo(
    () =>
      startPointPx
        ? getX(data[bisectDate(data, xScale.invert(startPointPx), 1)])
        : null,
    [startPointPx, getX, data, bisectDate, xScale]
  )

  const endPointTimestamp = useMemo(
    () =>
      endPointPx
        ? getX(data[bisectDate(data, xScale.invert(endPointPx), 1)])
        : null,
    [endPointPx, getX, data, bisectDate, xScale]
  )

  const startPointVirtualTimestamp = useMemo(
    () => xScale.invert(startPointPx),
    [xScale, startPointPx]
  )

  const endPointVirtualTimestamp = useMemo(
    () => xScale.invert(endPointPx),
    [xScale, endPointPx]
  )

  const handleMouseDown = (event) => {
    // Do not allow zooming on charts that are too small x-wise
    if (
      !onSelectArea ||
      (data[data.length - 1]?.TIME || 0) - (data[0]?.TIME || 0) <=
        selectionMinAreaMs * 1.3
    ) {
      return
    }

    const { x } = localPoint(event) || { x: 0 }
    if (x) setstartPointPx(x)
  }

  const handleHoverOn = (event) => {
    if (startPointPx) {
      const { x } = localPoint(event) || { x: 0 }
      if (x) setendPointPx(x)
    }
  }

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      setendPointPx(null)
      setstartPointPx(null)
      if (!startPointTimestamp || !endPointTimestamp) return

      const [start, end] = calculateBoundariesCompensatingArea(
        startPointTimestamp.getTime(),
        endPointTimestamp.getTime(),
        selectionMinAreaMs,
        endPointPx > startPointPx ? 'right' : 'left'
      )

      onSelectArea([new Date(start), new Date(end)])
    }

    window.addEventListener('mouseup', handleGlobalMouseUp)
    return () => window.removeEventListener('mouseup', handleGlobalMouseUp)
  }, [
    data,
    endPointPx,
    endPointTimestamp,
    getX,
    onSelectArea,
    selectionMinAreaMs,
    startPointPx,
    startPointTimestamp,
  ])

  return {
    handlers: {
      mouseDown: handleMouseDown,
      hoverOn: handleHoverOn,
    },
    startPointVirtualTimestamp,
    endPointVirtualTimestamp,
    startPointPx,
    endPointPx,
  }
}
