import { useEffect } from 'react'
import { returning, useRedirect } from './core'
import { useAuth0IsRegistered } from '../hooks/auth0/useAuth0IsRegistered'
import { useAuthToken } from '../hooks/store/useAuthToken'
import { useCheckRouteActive } from '../hooks/router/useCheckRouteActive'
import { ROUTE_SIGNUP } from '../util/routes'

const whitelist = [{ priority: 0, expr: /^.*$/ }]

const blacklist = [{ priority: 1, expr: ROUTE_SIGNUP }]

export const NotRegisteredGuard = ({ children }) => {
  const token = useAuthToken()
  const active = useCheckRouteActive(whitelist, blacklist)
  const redirect = useRedirect()
  const { isRegistered, isLoading } = useAuth0IsRegistered()

  useEffect(() => {
    if (active && token && !isLoading && !isRegistered) {
      redirect(ROUTE_SIGNUP)
    }
  }, [active, isLoading, isRegistered, redirect, token])

  return returning(children, active, isLoading)
}
