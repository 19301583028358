import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

/**
 * Finds the current active route based on a list of routes
 *
 * @param routes {Array.<RegExp | string>}
 * @returns {RegExp | string | undefined}
 */
export const useFindActiveRoute = (routes) => {
  const location = useLocation()

  return useMemo(() => {
    const path = location.pathname.endsWith('/')
      ? location.pathname.slice(0, location.pathname.length - 1)
      : location.pathname

    return routes.find(
      (expr) => expr === path || path?.startsWith?.(expr) || expr.test?.(path)
    )
  }, [location.pathname, routes])
}
