import PropTypes from 'prop-types'
import { Flex, Input, Switch, Text, VStack } from '@chakra-ui/react'
import { useState, useEffect, useMemo } from 'react'
import { SettingsCard } from '../SettingsCard'
import { FloatLabel } from '../../../ui-kit/FloatLabel'
import { MultiSelect } from '../../../ui-kit/MultiSelect'

export const RelevanceConfigurationTab = ({
  eventTypes,
  relevance,
  saveConfiguration,
  isLoading,
}) => {
  const [configuration, setConfiguration] = useState({
    revenue_above: '',
    duration_above: '',
    event_types_included: [],
    is_revenue_above_active: false,
    is_duration_above_active: false,
    is_events_included_active: false,
  })

  useEffect(() => {
    const eventsIncluded = Array.isArray(relevance?.event_types_included)
      ? relevance.event_types_included.filter((events) =>
          eventTypes.includes(events)
        )
      : []

    setConfiguration({
      revenue_above: relevance?.revenue_above ?? '',
      duration_above: relevance?.duration_above ?? '',
      event_types_included: eventsIncluded,
      is_revenue_above_active: relevance?.is_revenue_above_active ?? false,
      is_duration_above_active: relevance?.is_duration_above_active ?? false,
      is_events_included_active: relevance?.is_events_included_active ?? false,
    })
  }, [relevance, eventTypes])

  const eventTypesFormatted = useMemo(
    () =>
      eventTypes.map((eventType) => {
        return {
          value: eventType,
          label: eventType,
        }
      }),
    [eventTypes]
  )

  const handleRevenueSwitch = (event) => {
    const newConfiguration = {
      ...configuration,
      is_revenue_above_active: event.target.checked,
    }
    setConfiguration(newConfiguration)
  }

  const handleDurationSwitch = (event) => {
    const newConfiguration = {
      ...configuration,
      is_duration_above_active: event.target.checked,
    }
    setConfiguration(newConfiguration)
  }

  const handleEventsSwitch = (event) => {
    const newConfiguration = {
      ...configuration,
      is_events_included_active: event.target.checked,
    }
    setConfiguration(newConfiguration)
  }

  const handleEventsIncluded = (newEventTypes) => {
    setConfiguration({ ...configuration, event_types_included: newEventTypes })
  }

  const handleSave = () => {
    saveConfiguration(configuration)
  }

  return (
    <SettingsCard
      title="Relevance configuration"
      subtitle="Consider incident high priority when:"
      isLoading={isLoading}
      saveFunction={handleSave}
    >
      <VStack spacing={7} overflowY="auto" align="start" mt={6}>
        <Flex alignItems="center" justifyContent="center" gap="10" width="60%">
          <Flex gap="3" flex="1" width="100%">
            <Switch
              ml="1"
              size="lg"
              isChecked={configuration.is_revenue_above_active}
              onChange={handleRevenueSwitch}
            />
            <Text fontSize="xs" fontWeight="500" whiteSpace="nowrap" mb="0">
              Revenue impact above
            </Text>
          </Flex>
          <Input
            type="number"
            flex="1"
            isDisabled={!configuration.is_revenue_above_active}
            value={configuration.revenue_above}
            onChange={(event) =>
              setConfiguration({
                ...configuration,
                revenue_above: event.target.value,
              })
            }
          />
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap="10" width="60%">
          <Flex gap="3" flex="1" width="100%">
            <Switch
              ml="1"
              size="lg"
              isChecked={configuration.is_duration_above_active}
              onChange={handleDurationSwitch}
            />
            <Text fontSize="xs" fontWeight="500" whiteSpace="nowrap" mb="0">
              Duration above
            </Text>
          </Flex>
          <Input
            flex="1"
            isDisabled={!configuration.is_duration_above_active}
            value={configuration.duration_above}
            onChange={(event) => {
              setConfiguration({
                ...configuration,
                duration_above: event.target.value,
              })
            }}
          />
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap="10" width="80%">
          <Flex gap="3" flex="1" width="100%">
            <Switch
              ml="1"
              size="lg"
              isChecked={configuration.is_events_included_active}
              onChange={handleEventsSwitch}
            />
            <Text fontSize="xs" fontWeight="500" whiteSpace="nowrap" mb="0">
              Metrics impacted includes
            </Text>
          </Flex>
          <FloatLabel label="Event Types" _wrapperProps={{ flexGrow: '70' }}>
            <MultiSelect
              onChange={handleEventsIncluded}
              options={eventTypesFormatted}
              values={configuration.event_types_included}
              isClearable
              minW="200px"
              isDisabled={isLoading || !configuration.is_events_included_active}
              isLoading={isLoading}
            />
          </FloatLabel>
        </Flex>
      </VStack>
    </SettingsCard>
  )
}

RelevanceConfigurationTab.propTypes = {
  eventTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  relevance: PropTypes.object,
  saveConfiguration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
