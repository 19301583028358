import { Box, Button, Flex, Grid, GridItem, Icon, Text } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { isBetween } from '../../util/etc/isBetween'
import { useDate } from '../../hooks/etc/useDate'

const MONTHS = new Array(12)
  .fill(0)
  .map((_, index) =>
    new Date(0, index).toLocaleDateString(undefined, { month: 'long' })
  )

export const MonthPicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  activeColor = 'primary.500',
  ...rest
}) => {
  const now = useDate()
  const selectedYear = value?.getFullYear() ?? now.getFullYear()
  const selectedMonth = value?.getMonth()

  const [year, setYear] = useState(selectedYear)

  const handleChange = useCallback(
    (newYear, newMonth) => {
      const valueCopy = new Date(value)
      valueCopy.setFullYear(newYear, newMonth)
      onChange(valueCopy)
    },
    [onChange, value]
  )

  return (
    <Box bg="white" h="182px" {...rest}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px={4}
        pt={2}
        pb={1}
      >
        <Button
          variant="ghost"
          onClick={() => setYear(year - 1)}
          isDisabled={minDate && year <= minDate.getFullYear()}
          aria-label="Select previous year"
        >
          <Icon as={FaChevronLeft} h={3} color={activeColor} />
        </Button>
        <Text as="span" fontWeight="bold">
          {year}
        </Text>
        <Button
          variant="ghost"
          onClick={() => setYear(year + 1)}
          isDisabled={maxDate && year >= maxDate.getFullYear()}
          aria-label="Select next year"
        >
          <Icon as={FaChevronRight} h={3} color={activeColor} />
        </Button>
      </Flex>
      <Grid templateColumns="repeat(3, 1fr)" gap={2} mx={1} mb={1}>
        {MONTHS.map((monthDisplayName, index) => {
          const isActive = index === selectedMonth && year === selectedYear
          const isSelectable = isBetween(
            +new Date(year, index),
            minDate?.getTime() ?? 0,
            maxDate?.getTime() ?? Number.MAX_SAFE_INTEGER
          )

          return (
            <GridItem w="100%" h={6}>
              <Button
                w="100%"
                h="100%"
                variant="outline"
                borderRadius="sm"
                colorScheme={isActive ? 'primary' : 'gray'}
                borderColor={isActive ? activeColor : 'transparent'}
                color={activeColor}
                onClick={() => handleChange(year, index)}
                isDisabled={!isSelectable}
                aria-label={`Select ${monthDisplayName}`}
              >
                {monthDisplayName}
              </Button>
            </GridItem>
          )
        })}
      </Grid>
    </Box>
  )
}

MonthPicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  activeColor: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
}
