import { useCallback, useState } from 'react'
import { Checkbox, Flex, Text, Textarea } from '@chakra-ui/react'
import { useCustomerUUID } from '../../../../hooks/store/useCustomerUUID'
import { SettingsCard } from '../SettingsCard'
import { useFetchEmailChannelConfig } from '../../../../hooks/palantir/useFetchEmailChannelConfig'
import { useMutateEmailChannelConfig } from '../../../../hooks/palantir/useMutateEmailChannelConfig'

export const NotificationsTab = () => {
  const [customerUUID] = useCustomerUUID()
  const [emailConfig, setEmailConfig] = useState({
    emails: '',
    notify: true,
    notify_close: false,
  })
  const [emailError, setEmailError] = useState(false)

  const { isLoading: fetchEmailLoading } = useFetchEmailChannelConfig(
    { customerUUID },
    {
      onSuccess(data) {
        const emailString = data?.emails?.join(',')
        setEmailConfig({
          emails: emailString,
          notify: data.notify,
          notify_close: data.notify_close,
        })
      },
    }
  )

  const { mutate: mutateEmailsConfig, isLoading: isMutateLoading } =
    useMutateEmailChannelConfig()

  const isLoading = fetchEmailLoading || isMutateLoading

  const handleSave = useCallback(() => {
    const emailsArray = emailConfig.emails
      .split(',')
      .map((email) => email.trim())

    const invalidEmail = emailsArray.find(
      (email) => !/(.+)@(.+){2,}\.(.+){2,}/.test(email)
    )

    if (invalidEmail) {
      setEmailError(true)
      return
    }

    mutateEmailsConfig({
      customerUUID,
      emails: emailsArray,
      notify: emailsArray.length > 0,
      notifyClose: emailConfig.notify_close,
    })
  }, [customerUUID, emailConfig, mutateEmailsConfig])

  const handleEmailsChange = (event) => {
    setEmailError(false)
    setEmailConfig({ ...emailConfig, emails: event.target.value })
  }

  const handleNotifyCloseChange = (event) => {
    setEmailConfig({ ...emailConfig, notify_close: event.target.checked })
  }

  return (
    <SettingsCard
      title="Notifications"
      subtitle="Manage the notifications you would like to receive"
      isLoading={isLoading}
      saveFunction={handleSave}
      mb={16}
    >
      <Flex mt={2} flex="1" width="100%" flexDirection="column">
        <Text
          fontWeight="600"
          color="primary.dark.500"
          fontSize="sm"
          lineHeight="140%"
        >
          Emails
        </Text>
        <Text
          fontWeight="400"
          color="primary.dark.300"
          fontSize="xs"
          lineHeight="140%"
          mt={2}
        >
          Enter email addresses that you want to be notified. Separate multiple
          emails by comma.
        </Text>
        <Textarea
          fontSize="xs"
          color="primary.dark.500"
          width="50%"
          placeholder="Separate multiple emails by comma"
          borderColor="primary.dark.300"
          _hover={{
            borderColor: emailError ? '' : 'primary.500',
            border: emailError ? '' : '1px',
          }}
          mt={4}
          value={emailConfig.emails}
          onChange={handleEmailsChange}
          isInvalid={emailError}
        />
        <Text
          fontWeight="600"
          color="primary.dark.500"
          fontSize="sm"
          lineHeight="140%"
          mt={4}
        >
          Other options
        </Text>
        <Checkbox
          name="notify"
          isChecked={emailConfig.notify_close}
          color="primary.dark.300"
          onChange={handleNotifyCloseChange}
          mt={2}
        >
          Notify emails above when the incident gets closed.
        </Checkbox>
      </Flex>
    </SettingsCard>
  )
}
