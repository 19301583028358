import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { fetchIncidentFunnel } from '../../services/api/palantir'
import { useAuthToken } from '../store/useAuthToken'

/**
 * @param incidentUUID {string}
 */
export const useFetchIncidentFunnel = ({ incidentUUID }) => {
  const token = useAuthToken()
  const isRequestValid = !!incidentUUID

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchIncidentFunnel: invalid request parameters.', {
        incidentUUID,
      })
    }
  }, [isRequestValid, incidentUUID])

  return useQuery({
    queryKey: ['incidentFunnel', incidentUUID],
    queryFn: () =>
      fetchIncidentFunnel(token, { incidentUUID })
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.status === 404) return null
          throw err
        }),
    enabled: !!token && isRequestValid,
  })
}
