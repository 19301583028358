import { MINUTE_IN_MS } from '../TimeUtils'
import { isBetween } from '../etc/isBetween'

/**
 * Checks if a timeframe is affected by an incident.
 *
 * There are 4 possible scenarios that the incident can affect the timeframe:
 * - Incident starts and ends in the middle of a timeframe:        [----XXXX----]
 * - Incident starts before and ends in the middle of a timeframe: [XXX---------]
 * - Incident starts in the middle of a timeframe and ends after:  [---------XXX]
 * - Incident starts before and ends after the timeframe:          [XXXXXXXXXXXX]
 * X = Incident; - = Not incident; [ = Start of timeframe; ] = End of timeframe
 *
 * This is a curried function that given the start and the end of the timeframe, returns a function that expects an incident and checks if it affects the timeframe.
 *
 * @param start {number} Start of the timeframe in milliseconds.
 * @param end {number} End of the timeframe in milliseconds.
 * @return {function({when: string, duration_in_minutes: number}): boolean} Curried function that checks if the incident affects the timeframe.
 */
export const yIsTimeframeAffectedByIncident =
  (start, end) =>
  ({ when, duration_in_minutes: durationInMinutes }) => {
    const incidentStart = Date.parse(when)
    const incidentEnd = incidentStart + durationInMinutes * MINUTE_IN_MS

    return (
      isBetween(incidentStart, start, end) ||
      isBetween(incidentEnd, start, end) ||
      (incidentStart < start && incidentEnd > end)
    )
  }
