const unitList = ['', 'K', 'M', 'G']

/**
 * Formats a number appending the unit.
 *
 * @see https://stackoverflow.com/a/67633870/9893963
 * @param number {number} Number to format
 * @return {`${number}${string}`} Formatted number
 */
export const abbreviateNumber = (number) => {
  const sign = Math.sign(number)
  let unit = 0

  while (Math.abs(number) >= 1000) {
    unit += 1
    number = Math.floor(Math.abs(number) / 100) / 10
  }

  return `${sign * Math.abs(number)}${unitList[unit]}`
}
