import { ChakraBaseProvider, extendTheme } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { selectTheme } from '../styles/chakra/selectTheme'
import { buttonTheme } from '../styles/chakra/buttonTheme'
import { colors } from '../styles/colors'
import { tabsTheme } from '../styles/chakra/tabsTheme'
import { cardTheme } from '../styles/chakra/cardTheme'
import { inputTheme } from '../styles/chakra/inputTheme'
import { stepperTheme } from '../styles/chakra/stepperTheme'

// TODO break this file in multiple pieces
const theme = extendTheme({
  styles: {
    global: {
      body: {
        h: '100vh',
      },
      label: {
        color: 'primary.dark.500',
        fontWeight: 700,
        fontSize: '0.8em',
      },
      'hr, h1, h2, h3, h4, h5, h6, p': {
        margin: 0,
      },
      img: {
        display: 'inline-block',
      },
      svg: {
        display: 'inline-block',
      },
      'input[type="text"]': {
        backgroundColor: 'white',
        borderColor: 'grey-lines',
      },
    },
  },
  components: {
    Avatar: {
      baseStyle: {
        container: {
          bg: 'primary.100',
          color: 'primary.500',
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'Manrope',
        fontWeight: 700,
        color: 'primary.dark.500',
      },
    },
    Text: {
      baseStyle: {
        fontFamily: 'Manrope',
      },
    },
    Divider: {
      baseStyle: {
        border: 'unset',
        borderBottomColor: '#EAEAF5',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
      },
    },
    Badge: {
      baseStyle: {
        verticalAlign: 'unset',
        textTransform: 'unset',
        px: 3,
        py: '2px',
      },
    },
    Checkbox: {
      sizes: {
        md: {
          label: {
            fontSize: 'xs',
          },
        },
      },
      baseStyle: {
        control: {
          _checked: {
            bg: 'primary.500',
            borderColor: 'primary.500',
            _hover: {
              bg: 'primary.500',
            },
          },
        },
        label: {
          fontWeight: 500,
        },
      },
    },
    Slider: {
      baseStyle: {
        thumb: {
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
        },
        track: {
          bg: 'primary.dark.100',
        },
        filledTrack: {
          bg: 'primary.500',
        },
      },
      sizes: {
        md: {
          thumb: {
            boxSize: '20px',
          },
          track: {
            h: '5px',
          },
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          bg: 'primary.dark.100',
          width: '41px',
          height: '20px',
          p: 0,
          _checked: {
            bg: 'primary.500',
          },
        },
        thumb: {
          width: '20px',
          height: '20px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    Tabs: tabsTheme,
    Select: selectTheme,
    Button: buttonTheme,
    Card: cardTheme,
    Input: inputTheme,
    Stepper: stepperTheme,
  },
  colors,
  fonts: {
    heading: `'Manrope', sans-serif`,
    body: `'Manrope', sans-serif`,
  },
})

export const ChakraProvider = ({ children }) => (
  <ChakraBaseProvider theme={theme}>{children}</ChakraBaseProvider>
)

ChakraProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
