import { isNumeric } from './isNumericString'

/**
 * Constructs a Date object from a string, number or another Date.
 *
 * The only difference from the native Date constructor is that this one accepts numeric strings as timestamps.
 *
 * @param input {string|number|Date}
 * @return {Date}
 */
export const mkDate = (input) =>
  new Date(
    typeof input === 'string' && isNumeric(input, false)
      ? parseInt(input, 10)
      : input
  )
