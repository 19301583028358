import { TimeUtils } from '../TimeUtils'

/**
 * Checks if the incident contains the anomaly.
 *
 * This is a curried function, supposed to be used with Array.prototype.filter or such.
 *
 * @param anomaly {{ timestamp: number, value: number }}
 * @return {function(incident: { anomalies: Array<{ current: { window_end: string, count: number } }> }): boolean}
 */
export const yIncidentContainsAnomaly = (anomaly) => (incident) =>
  incident.anomalies.some(
    ({ current: { window_end: windowEnd, count } }) =>
      count === anomaly.value &&
      TimeUtils.timeMs(windowEnd) === anomaly.timestamp
  )
