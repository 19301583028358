import { AlreadyRegisteredGuard } from './AlreadyRegisteredGuard'
import { NotRegisteredGuard } from './NotRegisteredGuard'
import { IsRegisteredLoadingGuard } from './IsRegisteredLoadingGuard'
import { IncidentDeeplinkGuard } from './IncidentDeeplinkGuard'
import { NoBillingPlanActive } from './NoBillingPlanActive'

const GUARDS = [
  IncidentDeeplinkGuard,
  IsRegisteredLoadingGuard,
  AlreadyRegisteredGuard,
  NotRegisteredGuard,
  NoBillingPlanActive,
]

export const AppGuards = ({ children }) =>
  GUARDS.reduce((acc, Guard) => <Guard>{acc}</Guard>, children)
