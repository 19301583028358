import PropTypes from 'prop-types'
import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
} from '@chakra-ui/react'
import { useState } from 'react'
import { DeleteOutline } from '@mui/icons-material'
import { InviteStatusTag } from '../../../shared/InviteStatusTag'
import { ConfirmationModal } from '../../../../ui-kit/ConfirmationModal'
import { useMutateCancelInvite } from '../../../../../hooks/palantir'

export const PropertyInviteTable = ({ invitesData, companyUUID }) => {
  const [openCancelConfirmationModal, setOpenCancelConfirmationModal] =
    useState(false)
  const [inviteUUID, setInviteUUID] = useState()

  const handleCancel = (uuid) => {
    setOpenCancelConfirmationModal(true)
    setInviteUUID(uuid)
  }

  const { mutate: mutateCancelInvite, isLoading: mutateCancelLoading } =
    useMutateCancelInvite()

  const handleOnCancelConfirmation = () => {
    mutateCancelInvite(
      {
        companyUUID,
        inviteUUID,
      },
      {
        onSuccess: () => {
          setOpenCancelConfirmationModal(false)
          setInviteUUID(null)
        },
      }
    )
  }

  return (
    <Box w="100%" mt="3">
      <ConfirmationModal
        titleText="Cancel invite?"
        subtitleText="You can resend this invite again in the future if you need it"
        applyBtnText="Yes, cancel"
        cancelBtnText="No, keep"
        isOpen={openCancelConfirmationModal}
        onClose={() => setOpenCancelConfirmationModal(false)}
        onApply={() => handleOnCancelConfirmation()}
        isLoading={mutateCancelLoading}
      />
      <Table maxW="100%" overflowX="hidden">
        <Thead>
          <Tr>
            <Th color="primary.dark.300" ps="1">
              Name
            </Th>
            <Th color="primary.dark.300">Email</Th>
            <Th color="primary.dark.300">Property</Th>
            <Th color="primary.dark.300">Status</Th>
            <Th color="primary.dark.300">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {invitesData.map((invite) => (
            <Tr key={invite.uuid} fontSize="12px" data-uuid={invite.uuid}>
              <Td
                ps="1"
                py="1"
              >{`${invite.first_name} ${invite.last_name}`}</Td>
              <Td py="1">{invite.email_invited}</Td>
              <Td py="1">{invite.property_name}</Td>
              <Td py="1">{InviteStatusTag(invite.status)}</Td>
              <Td py="1" w="100px" textAlign="center">
                <IconButton
                  aria-label="Cancel invite"
                  title="Cancel invite"
                  variant="ghost"
                  size="sm"
                  color="primary.dark.400"
                  icon={<DeleteOutline fontSize="small" />}
                  isDisabled={invite.status !== 'pending'}
                  onClick={() => handleCancel(invite.uuid)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

PropertyInviteTable.propTypes = {
  invitesData: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email_invited: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  companyUUID: PropTypes.string.isRequired,
}
