/* eslint-disable unicorn/filename-case */
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useMemo } from 'react'
import { useFindActiveRoute } from '../router/useFindActiveRoute'
import { normalizeUrlSlashes } from '../../util/etc/normalizeUrlSlashes'
import { joinUrl } from '../../util/etc/joinUrl'

/**
 * @param options string[]
 * @param _defaultOption string
 * @param _prefix string
 * @return {{ index: number, option: string, setByIndex: (function(number): void), setByOption: (function(string): void) }}
 */
export const useURLPathSwitcher = ({
  options: _options,
  defaultOption: _defaultOption = _options[0],
  prefix: _prefix = '',
}) => {
  const navigate = useNavigate()

  const prefix = useMemo(
    () => normalizeUrlSlashes(_prefix, { slashStart: true, slashEnd: true }),
    [_prefix]
  )

  const options = useMemo(
    () =>
      _options.map((option) =>
        normalizeUrlSlashes(joinUrl(prefix, option), {
          slashStart: false,
          slashEnd: false,
        })
      ),
    [_options, prefix]
  )

  const defaultOption = useMemo(
    () =>
      normalizeUrlSlashes(joinUrl(prefix, _defaultOption), {
        slashStart: false,
        slashEnd: false,
      }),
    [_defaultOption, prefix]
  )

  useEffect(() => {
    if (!options.includes(defaultOption)) {
      console.warn(
        `[useUrlPathSwitcher] Default option ${defaultOption} not found in ${options}`
      )
    }
  }, [defaultOption, options])

  const option = useFindActiveRoute(options)
  const index = useMemo(
    () => options.indexOf(option ?? defaultOption),
    [option, options, defaultOption]
  )

  useEffect(() => {
    if (option === undefined) navigate(defaultOption)
  }, [defaultOption, navigate, option, options])

  const setByIndex = useCallback(
    (index) => navigate(options[index]),
    [navigate, options]
  )

  const setByOption = useCallback(
    (option) => {
      if (options.includes(option)) {
        navigate(option)
      } else if (options.includes(`${prefix}${option}`)) {
        navigate(`${prefix}${option}`)
      } else {
        console.warn(
          `[useUrlPathSwitcher] Option ${option} not found in ${options}`
        )
      }
    },
    [navigate, options, prefix]
  )

  return useMemo(() => {
    return {
      option: option ?? defaultOption,
      index,
      setByIndex,
      setByOption,
    }
  }, [defaultOption, index, option, setByIndex, setByOption])
}
