import './sidebar.css'
import { Flex, Spacer } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Dashboard, Warning, AutoFixHigh, Settings } from '@mui/icons-material'
import { RoutedSideBarButton } from './SideBarButton'
import {
  ROUTE_DASHBOARD,
  ROUTE_INCIDENTS,
  ROUTE_ONBOARDING,
  ROUTE_SETTINGS,
} from '../../../util/routes'

export const SideBar = ({ ...rest }) => {
  const isOpen = useSelector((s) => s.layout.isSidebarOpen)

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--revend-sidebar-width',
      isOpen
        ? 'var(--revend-sidebar-open-width)'
        : 'var(--revend-sidebar-closed-width)'
    )
  }, [isOpen])

  return (
    <Flex
      flexShrink={0}
      flexDirection="column"
      borderRight="solid 1px"
      borderColor="gray.200"
      boxShadow="0px 1px 15px 4px rgba(216, 216, 216, 0.2)"
      w="var(--revend-sidebar-width)"
      overflowX="hidden"
      transition="all 250ms ease-in-out"
      {...rest}
    >
      <RoutedSideBarButton
        id="dashboard"
        label="Dashboard"
        icon={Dashboard}
        route={ROUTE_DASHBOARD}
      />
      <RoutedSideBarButton
        id="incidents"
        label="Incidents"
        icon={Warning}
        route={ROUTE_INCIDENTS}
      />
      <Spacer />

      <RoutedSideBarButton
        id="welcome"
        label="Onboarding"
        icon={AutoFixHigh}
        route={ROUTE_ONBOARDING}
      />
      <RoutedSideBarButton
        id="settings"
        label="Settings"
        icon={Settings}
        route={ROUTE_SETTINGS}
      />
    </Flex>
  )
}
