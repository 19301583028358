import PropTypes from 'prop-types'
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'

const COLORS = {
  normal: '#BAE2B9',
  anomaly: '#E7E981',
  incident: '#E2B9C3',
}

const COLORS_HOVER = {
  normal: '#A1CC9D',
  anomaly: '#D4D35C',
  incident: '#CEA2AD',
}

const Bar = ({ label, value, type }) => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    borderRadius="md"
    bgColor={COLORS[type]}
    px={4}
    py={1}
    _hover={{ bgColor: COLORS_HOVER[type] }}
  >
    <Text as="span" fontWeight={600} fontSize={14}>
      {label}
    </Text>
    <Text as="span" fontSize={12}>
      {value ? `${(value * 100).toFixed(2)}% conversion` : '-'}
    </Text>
  </Flex>
)

Bar.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.keys(COLORS_HOVER)).isRequired,
}

export const IncidentFunnel = ({
  detail,
  add,
  checkout,
  purchase,
  ...rest
}) => (
  <HStack alignItems="stretch" {...rest}>
    <Box>
      <svg
        width="16"
        height="134"
        viewBox="0 0 16 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 1.00018C9 0.447898 8.55228 0.000183081 8 0.000183105C7.44772 0.00018313 7 0.447898 7 1.00018L9 1.00018ZM7.2929 133.707C7.68342 134.098 8.31659 134.098 8.70711 133.707L15.0711 127.343C15.4616 126.953 15.4616 126.32 15.0711 125.929C14.6805 125.539 14.0474 125.539 13.6569 125.929L8.00001 131.586L2.34315 125.929C1.95263 125.539 1.31946 125.539 0.928938 125.929C0.538413 126.32 0.538413 126.953 0.928938 127.343L7.2929 133.707ZM7 1.00018L7.00001 133L9.00001 133L9 1.00018L7 1.00018Z"
          fill="#001334"
        />
      </svg>
    </Box>
    <VStack alignItems="stretch" w="100%">
      <Bar label="Detail" value={detail.value} type={detail.type} />
      <Bar label="Add" value={add.value} type={add.type} />
      <Bar label="Checkout" value={checkout.value} type={checkout.type} />
      <Bar label="Purchase" value={purchase.value} type={purchase.type} />
    </VStack>
  </HStack>
)

IncidentFunnel.propTypes = {
  detail: PropTypes.shape({
    type: PropTypes.oneOf(['normal', 'anomaly', 'incident']).isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  add: PropTypes.shape({
    type: PropTypes.oneOf(['normal', 'anomaly', 'incident']).isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  checkout: PropTypes.shape({
    type: PropTypes.oneOf(['normal', 'anomaly', 'incident']).isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  purchase: PropTypes.shape({
    type: PropTypes.oneOf(['normal', 'anomaly', 'incident']).isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
}
