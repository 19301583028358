import { useQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchProperties } from '../../services/api/palantir'

/**
 * Custom hook to fetch company settings.
 * @param {Object} queryOptions - Options for the query.
 * @returns {Object} - The result of the query.
 */
export function useFetchProperties(queryOptions = undefined) {
  const token = useAuthToken()

  return useQuery({
    queryKey: ['properties'],
    queryFn: () =>
      fetchProperties(token).then((res) => {
        console.log('fetching properties object', res.data)
        return res.data
      }),
    enabled: !!token,
    ...queryOptions,
  })
}
