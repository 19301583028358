import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { FloatLabel } from '../../ui-kit/FloatLabel'
import { SingleSelect } from '../../ui-kit/SingleSelect'
import { DateInput } from '../../ui-kit/DateInput'
import { DashboardActions } from '../../../store'
import { CalendarDateComparePicker } from '../../ui-kit/CalendarDateComparePicker'
import { useDashboardState } from '../../../hooks/store/useDashboardState'
import { useDate } from '../../../hooks/etc/useDate'
import { useCustomerUUID } from '../../../hooks/store/useCustomerUUID'
import { useFetchProperties } from '../../../hooks/palantir/useFetchProperties'

export const DashboardFilterNew = (props) => {
  const dispatch = useDispatch()
  const min = useDate('2020-01-01')
  const max = useDate()

  const [customerUUID, setCustomerUUID] = useCustomerUUID()
  const [availableCustomerUUIDs, setAvailableCustomerUUIDs] = useState([])
  const { startDate, endDate, compareStartDate, compareEndDate } =
    useDashboardState()

  const baseDates = useMemo(
    () => [new Date(startDate), new Date(endDate)],
    [startDate, endDate]
  )
  const compareDates = useMemo(
    () =>
      compareStartDate && compareEndDate
        ? [new Date(compareStartDate), new Date(compareEndDate)]
        : undefined,
    [compareStartDate, compareEndDate]
  )

  const { isFetching: isPropertiesFetching } = useFetchProperties({
    onSuccess(response) {
      setAvailableCustomerUUIDs(response.data)
    },
  })

  const onBaseChange = useCallback(
    ([start, end]) => {
      dispatch(
        DashboardActions.setDateRange({
          startDate: start.toISOString(),
          endDate: end.toISOString(),
        })
      )
    },
    [dispatch]
  )

  const onCompareChange = useCallback(
    ([date]) => {
      dispatch(
        DashboardActions.setCompareWithStartDate(date?.toISOString?.() ?? null)
      )
    },
    [dispatch]
  )

  // This is a hack to make the popover close when the user clicks outside of it
  // https://www.notion.so/revendai/Numenor-BUG-Clicking-outside-the-compare-with-dialog-does-not-close-the-dialog-after-clicking-a-d-b90ad778a7b9412bb53ad32ca2566467?pvs=4
  const popoverRef = useRef(null)
  useEffect(
    () => void popoverRef.current.focus(),
    [compareStartDate, compareEndDate]
  )

  return (
    <Flex gap={2} pt={4} {...props}>
      <FloatLabel label="Client">
        <SingleSelect
          onChange={setCustomerUUID}
          options={availableCustomerUUIDs ?? []}
          value={customerUUID}
          labelKey="name"
          valueKey="uuid"
          w="400px"
          isLoading={isPropertiesFetching}
          isDisabled={isPropertiesFetching}
        />
      </FloatLabel>
      <FloatLabel label="Start Date">
        <DateInput
          w="200px"
          value={startDate}
          onChange={(newStartDate) =>
            dispatch(
              DashboardActions.setDateRange({
                startDate: newStartDate.toISOString(),
              })
            )
          }
          min={min}
          max={max}
        />
      </FloatLabel>
      <FloatLabel label="End Date">
        <DateInput
          w="200px"
          value={endDate}
          onChange={(newEndDate) =>
            dispatch(
              DashboardActions.setDateRange({
                endDate: newEndDate.toISOString(),
              })
            )
          }
          min={min}
          max={max}
        />
      </FloatLabel>
      <Popover>
        <PopoverTrigger>
          <Button variant="outline.500" colorScheme="primary" borderRadius="sm">
            Compare with
          </Button>
        </PopoverTrigger>
        <PopoverContent
          ref={popoverRef}
          w="1200px"
          boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.75)"
        >
          <CalendarDateComparePicker
            min={min}
            max={max}
            baseDates={baseDates}
            comparingDates={compareDates}
            onBaseChange={onBaseChange}
            onCompareChange={onCompareChange}
          />
        </PopoverContent>
      </Popover>
    </Flex>
  )
}
