import { useMemo } from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { deviationPercentage } from '../../../util/etc/deviationPercentage'
import { IncidentSlideoutControlledChart } from './IncidentSlideoutControlledChart'
import { useEventTypeLabel } from '../../../hooks/etc/useEventTypeLabel'

export const IncidentSlideoutLineChartCard = ({
  incidentUUID,
  eventType,
  anomalies,
}) => {
  const eventLabel = useEventTypeLabel()

  // Copy/pasted from amonsul
  const { mean, current, lowestMin, highestMax } = useMemo(() => {
    const mean =
      anomalies.reduce((acc, anomaly) => acc + anomaly.model.mean, 0) /
      anomalies.length
    const current = anomalies[anomalies.length - 1].current.count

    // I'm still unsure about why lowestMin uses Math.max, but that's what the old code did and the tests only pass this way
    const lowestMin = anomalies.reduce(
      (acc, anomaly) => Math.max(anomaly.min, acc),
      0
    )
    const highestMax = anomalies.reduce(
      (acc, anomaly) => Math.max(anomaly.max, acc),
      0
    )

    return { mean, current, lowestMin, highestMax }
  }, [anomalies])

  const deviationMean = Math.round(
    Math.abs(deviationPercentage(current, mean, 1)) * 100
  )

  const deviationMin = Math.round(
    Math.abs(deviationPercentage(current, lowestMin, 1)) * 100
  )

  return (
    <Card variant="fullOutlined" borderRadius="xl">
      <CardHeader>
        <Text as="span" textTransform="uppercase" fontWeight={700}>
          {eventLabel(eventType)} in the last hour
        </Text>
      </CardHeader>
      <CardBody>
        <IncidentSlideoutControlledChart
          incidentUUID={incidentUUID}
          eventType={eventType}
        />
      </CardBody>
      <CardFooter pt={2} pb={4} px={0} color="primary.dark.400">
        <VStack alignItems="stretch" divider={<Divider my={4} />} w="100%">
          <Flex alignItems="top" justifyContent="space-between" px={6} w="100%">
            <Text as="span" fontWeight={700}>
              Current
            </Text>
            <Text as="span">
              {current.toFixed(0)} ({deviationMean}% avg, {deviationMin}% min)
            </Text>
          </Flex>
          <Flex alignItems="top" justifyContent="space-between" px={6}>
            <Text as="span" fontWeight={700} color="primary.dark.400">
              Average
            </Text>
            <Text as="span">{mean.toFixed(1)}</Text>
          </Flex>
          <Flex alignItems="top" justifyContent="space-between" px={6}>
            <Text as="span" fontWeight={700} color="primary.dark.400">
              Minimum
            </Text>
            <Text as="span">{lowestMin.toFixed(1)}</Text>
          </Flex>
          <Flex alignItems="top" justifyContent="space-between" px={6}>
            <Text as="span" fontWeight={700} color="primary.dark.400">
              Maximum
            </Text>
            <Text as="span">{highestMax.toFixed(1)}</Text>
          </Flex>
        </VStack>
      </CardFooter>
    </Card>
  )
}

IncidentSlideoutLineChartCard.propTypes = {
  incidentUUID: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  anomalies: PropTypes.arrayOf(
    PropTypes.shape({
      model: PropTypes.shape({
        mean: PropTypes.number.isRequired,
      }).isRequired,
      current: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}
