import { Box, Center, Flex, Skeleton, Text, VStack } from '@chakra-ui/react'
import AutoSizer from 'react-virtualized-auto-sizer'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { StatusCheckChart } from '../../revend-charts/StatusCheckChart'
import {
  useFetchEventCountData,
  useFetchIncident,
} from '../../../hooks/palantir'
import { MONTH_IN_MS, HOUR_IN_MS, MINUTE_IN_MS } from '../../../util/TimeUtils'

const HEIGHT = 80

const calculateUpDowntime = (eventCountData) => {
  if (!eventCountData) return {}

  const isValueGreaterThanZero = (item) => item.COUNT > 0
  const isZero = (item) => item.COUNT === 0

  const countMatching = (data, condition) => data.filter(condition).length

  const downtimeCount = countMatching(eventCountData, isZero)
  const uptimeCount = countMatching(eventCountData, isValueGreaterThanZero)
  const totalEvents = downtimeCount + uptimeCount

  const uptimePercentage = ((uptimeCount / totalEvents) * 100).toFixed(2)
  const downtimePercentage = ((downtimeCount / totalEvents) * 100).toFixed(2)

  let outage = 0
  for (const anomaly of eventCountData) {
    if (anomaly.COUNT === 0) {
      outage++
    }
  }

  return { uptimePercentage, downtimePercentage, outage }
}

export const IncidentSlideoutStatusCheckChart = ({
  incidentUUID,
  eventType,
}) => {
  const { data: incident, isLoading: isIncidentLoading } = useFetchIncident({
    incidentUUID,
  })

  const incidentTime = useMemo(
    () => (incident ? new Date(incident.when).getTime() : 0),
    [incident]
  )

  const start = incidentTime - HOUR_IN_MS
  const end = incidentTime + MINUTE_IN_MS * 10

  const { data: eventCountData, isLoading: isEventCountDataLoading } =
    useFetchEventCountData({
      customerUUID: incident?.customer_uuid,
      startDate: start || 0,
      endDate: end || 0,
      eventType,
    })

  const isLoading = isIncidentLoading || isEventCountDataLoading

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <Skeleton h={24} w={width} isLoaded={!isLoading} borderRadius="lg">
          {eventCountData?.length && incident ? (
            <Box>
              <StatusCheckChart
                width={width}
                height={HEIGHT}
                data={eventCountData}
              />
            </Box>
          ) : (
            <Box bg="#efefef" borderRadius="lg" h={HEIGHT} w={width}>
              <Center h="100%">
                <VStack>
                  <Text mb={0}>No data for this period yet.</Text>
                </VStack>
              </Center>
            </Box>
          )}
        </Skeleton>
      )}
    </AutoSizer>
  )
}

IncidentSlideoutStatusCheckChart.propTypes = {
  incidentUUID: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
}

export const IncidentSlideoutStatusCheckUpDowntime = ({
  incidentUUID,
  eventType,
}) => {
  const { data: incident, isLoading: isIncidentLoading } = useFetchIncident({
    incidentUUID,
  })

  const incidentTime = useMemo(
    () => (incident ? new Date(incident.when).getTime() : 0),
    [incident]
  )

  const start = incidentTime - MONTH_IN_MS * 3
  const end = incidentTime + MINUTE_IN_MS * 10

  const { data: eventCountData, isLoading: isEventCountDataLoading } =
    useFetchEventCountData({
      customerUUID: incident?.customer_uuid,
      startDate: start || 0,
      endDate: end || 0,
      eventType,
    })

  const { uptimePercentage, downtimePercentage, outage } =
    calculateUpDowntime(eventCountData)

  const isLoading = isIncidentLoading || isEventCountDataLoading

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <Skeleton h={24} w={width} isLoaded={!isLoading} borderRadius="lg">
          {eventCountData?.length && incident ? (
            <Flex
              alignItems="start"
              justifyContent="space-between"
              pt={6}
              pe={10}
            >
              <Box>
                <Text as="span" fontWeight={600} color="primary.dark.400">
                  Uptime
                </Text>
                <br />
                <Text fontWeight={300} color="primary.dark.500" py={2}>
                  {uptimePercentage}%
                </Text>
              </Box>
              <Box pe={10}>
                <Text as="span" fontWeight={600} color="primary.dark.400">
                  Downtime
                </Text>
                <br />
                <Text fontWeight={300} color="primary.dark.500" py={2}>
                  {downtimePercentage}% <br /> with {outage} outage
                </Text>
              </Box>
            </Flex>
          ) : (
            <Box bg="#efefef" borderRadius="lg" h={HEIGHT} w={width}>
              <Center h="100%">
                <VStack>
                  <Text mb={0}>No data for this period yet.</Text>
                </VStack>
              </Center>
            </Box>
          )}
        </Skeleton>
      )}
    </AutoSizer>
  )
}

IncidentSlideoutStatusCheckUpDowntime.propTypes = {
  incidentUUID: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
}
