/**
 * @param clickX {number}
 * @param clickY {number}
 * @param xScale {import("d3-scale").ScaleLinear<number>}
 * @param yScale {import("d3-scale").ScaleLinear<number>}
 * @param anomalies {{value: number, timestamp: number}[]}
 * @param radius {number}
 * @return {{value: number, timestamp: number}|undefined}
 */
export const findAnomalyByCoordinates = (
  clickX,
  clickY,
  xScale,
  yScale,
  anomalies,
  radius
) =>
  anomalies.find(({ timestamp, value }) => {
    const anomalyX = xScale(new Date(timestamp))
    const anomalyY = yScale(value)
    return (
      Math.abs(anomalyX - clickX) <= radius &&
      Math.abs(anomalyY - clickY) <= radius
    )
  })
