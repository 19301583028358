import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { BarRounded } from '@visx/shape'
import { Group } from '@visx/group'
import { AxisBottom } from '@visx/axis'
import { scaleBand } from '@visx/scale'
import { TimeUtils } from '../../util/TimeUtils'
import { colors } from '../../styles/colors'

// accessors
const getUptime = (d) => d.COUNT !== 0
const getDate = (d) => d.TIME

// scales
const colorScale = (prop) => (prop ? colors.green : colors.red)

export const BarsProps = {
  width: Number,
  height: Number,
}

export const StatusCheckChart = ({ width, height, data }) => {
  // bounds
  const xMax = width
  const yMax = 30

  data = data.slice(0, 13)

  // scales, memoize for performance
  const XScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: data.map(getDate),
        paddingInner: 0.45,
      }),
    [xMax, data]
  )

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <rect width={width} height={height} fill="none" rx={14} />
      <Group top={20}>
        {data.map((d) => {
          const key = getDate(d)
          const barWidth = 11
          const barHeight = 25
          const barX = XScale(key)
          const barY = yMax - barHeight
          return (
            <BarRounded
              key={`bar-${key}`}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              radius={20}
              all
              fill={colorScale(getUptime(d))}
              style={{ border: 1, borderRadius: `${20}px` }}
            />
          )
        })}
      </Group>
      <AxisBottom
        top={yMax + 20}
        scale={XScale}
        tickFormat={TimeUtils.formatHour}
        hideAxisLine
        hideTicks
        tickLabelProps={{
          dx: 12,
          fill: colors.primary.dark[400],
          fontSize: 11,
          textAnchor: 'end',
        }}
      />
    </svg>
  )
}

StatusCheckChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}
