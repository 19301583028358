export const ROUTE_DASHBOARD = '/dashboard'
export const ROUTE_INCIDENTS = '/incidents'
export const ROUTE_SETTINGS = '/settings'
export const ROUTE_ONBOARDING = '/welcome'
export const ROUTE_SIGNUP = '/signup'
export const ROUTE_INCIDENT_DEEPLINK = '/incident'
export const ROUTE_BILLING = '/billing'
export const ROUTE_BILLING_STATUS = '/billing/status'

export const ROUTE_SETTINGS_GLOBAL = `${ROUTE_SETTINGS}/global`
export const ROUTE_SETTINGS_NOTIFICATIONS = `${ROUTE_SETTINGS}/notifications`
export const ROUTE_SETTINGS_INTEGRATIONS = `${ROUTE_SETTINGS}/integrations`
export const TOP_LEVEL_SETTINGS_ROUTES = [
  ROUTE_SETTINGS_GLOBAL,
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_NOTIFICATIONS,
]

export const ALL_ROUTES = [
  ROUTE_DASHBOARD,
  ROUTE_SETTINGS,
  ROUTE_ONBOARDING,
  ROUTE_INCIDENTS,
  ROUTE_SIGNUP,
  ROUTE_INCIDENT_DEEPLINK,
  ROUTE_BILLING,
  ROUTE_BILLING_STATUS,
]
