import { useEffect } from 'react'
import { returning, useRedirect } from './core'
import { useAuth0IsRegistered } from '../hooks/auth0/useAuth0IsRegistered'
import { useAuthToken } from '../hooks/store/useAuthToken'
import { useCheckRouteActive } from '../hooks/router/useCheckRouteActive'
import {
  ROUTE_BILLING,
  ROUTE_DASHBOARD,
  ROUTE_ONBOARDING,
  ROUTE_SIGNUP,
} from '../util/routes'
import { useFetchBillings } from '../hooks/palantir/useFetchBillings'
import { BILLING_ACTIVE } from '../util/billingStatus'
import { useSharedState } from '../hooks/store/useSharedState'

const whitelist = [
  { priority: 0, expr: ROUTE_SIGNUP },
  { priority: 0, expr: ROUTE_BILLING },
]

const blacklist = []

export const AlreadyRegisteredGuard = ({ children }) => {
  const token = useAuthToken()
  const active = useCheckRouteActive(whitelist, blacklist)
  const redirect = useRedirect()
  const { onboarding } = useSharedState()
  const { isRegistered, isLoading: isRegisterLoading } = useAuth0IsRegistered()
  const { billings, isLoading: isBillingLoading } = useFetchBillings()
  const isBillingActive =
    billings && billings?.some((a) => a?.status === BILLING_ACTIVE)
  const isLoading = isRegisterLoading || isBillingLoading
  useEffect(() => {
    if (active && token && !isLoading && isRegistered && isBillingActive) {
      redirect(onboarding ? ROUTE_ONBOARDING : ROUTE_DASHBOARD)
    }
  }, [
    active,
    isLoading,
    isRegistered,
    redirect,
    token,
    isBillingActive,
    onboarding,
  ])

  return returning(children, active, isLoading)
}
