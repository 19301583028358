export const colors = Object.freeze({
  primary: {
    900: '#000033',
    800: '#080B66',
    700: '#11199F',
    600: '#1C27CC',
    500: '#2350FF',
    400: '#5A79F8',
    300: '#91A5FA',
    250: '#A9BBFF',
    200: '#C8D2FD',
    100: '#D6DEFF',
    dark: {
      500: '#001334',
      400: '#424E66',
      300: '#808899',
      250: '#BCC2D1',
      200: '#C0C3CC',
      100: '#D8D8D8',
    },
  },
  surface: {
    1000: '#0B0C0E',
    900: '#1D2023',
    800: '#2F3337',
    700: '#41464B',
    600: '#53585F',
    500: '#F9FBFC',
    400: '#FAFCFD',
    300: '#FBFDFF',
    200: '#FCFEFF',
    100: '#FEFFFF',
  },
  text: '#001334',
  'grey-lines': '#EAEAF5',
  success: '#22C074',
  warning: '#F7C96F',
  alert: '#F7B06F',
  danger: '#A52543',
  'success.500': '#22C074', // Compat with Chakra's color scheme
  'warning.500': '#F7C96F',
  'alert.500': '#F7B06F',
  'danger.500': '#A52543',
  green: '#9EDD9D',
  red: '#E0A6B4',
})
