import { useQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchSignedUp } from '../../services/api/palantir'
/**
 * Fetches when the user signed up from Palantir.
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 */
export function useFetchSignedUp(queryOptions = undefined) {
  const token = useAuthToken()
  return useQuery({
    queryKey: ['signedUp'],
    queryFn: () => fetchSignedUp(token).then((res) => res.data),
    ...queryOptions,
  })
}
