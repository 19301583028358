import { Box, Text, Flex, useClipboard } from '@chakra-ui/react'
import { CheckIcon, CopyIcon } from '@chakra-ui/icons'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

export const CopyButton = ({
  copyText,
  fontSize = '12px',
  iconSize = '12px',
}) => {
  const { onCopy, setValue, hasCopied } = useClipboard('')

  useEffect(() => {
    setValue(copyText)
  }, [copyText, setValue])

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Text fontSize={fontSize}>{copyText}</Text>
        <Box
          onClick={() => {
            setValue(copyText)
            onCopy()
          }}
        >
          {hasCopied ? (
            <CheckIcon boxSize={iconSize} color="primary.500" />
          ) : (
            <CopyIcon boxSize={iconSize} color="primary.500" />
          )}
        </Box>
      </Flex>
    </Box>
  )
}

CopyButton.propTypes = {
  copyText: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  iconSize: PropTypes.string,
}
