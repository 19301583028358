/* eslint-disable unicorn/filename-case */
import { useEffect, useState } from 'react'
import { useCustomerUUID } from '../hooks/store/useCustomerUUID'
import { useFetchProperties } from '../hooks/palantir/useFetchProperties'

export const CustomerUUIDLoader = () => {
  const [customers, setCustomers] = useState([])

  const { isFetching } = useFetchProperties({
    onSuccess(response) {
      setCustomers(response.data)
    },
  })

  const [customerUUID, setCustomerUUID] = useCustomerUUID()

  useEffect(() => {
    if (isFetching) return
    if (!customers) return setCustomerUUID(undefined)

    if (!customerUUID || !customers.find(({ uuid }) => uuid === customerUUID)) {
      const uuid = customers[0]?.uuid
      if (uuid) setCustomerUUID(uuid)
    }
  }, [customerUUID, customers, setCustomerUUID, isFetching])

  return null
}
