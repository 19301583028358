import {
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Center,
  Box,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

/**
 * ConfirmationModal component displays a modal dialog with a confirmation message and buttons for cancel and apply actions.
 *
 * @component
 * @param {string} titleText - The title of the confirmation modal.
 * @param {string} subtitleText - The subtitle text of the confirmation modal. By default, it is set to 'Are you sure you want to continue with your action?'.
 * @param {string} applyBtnText - The text for the apply button. By default, it is set to 'OK'.
 * @param {string} cancelBtnText - The text for the cancel button. By default, it is set to 'Cancel'.
 * @param {boolean} isOpen - Determines whether the modal is open or closed. Required
 * @param {Function} onClose - The function to be called when the modal is closed. Required
 * @param {Function} onApply - The function to be called when the apply button is clicked. Required
 * @returns {JSX.Element} The rendered ConfirmationModal component.
 */
export const ConfirmationModal = ({
  titleText,
  subtitleText = 'Are you sure you want to continue with your action?',
  applyBtnText = 'OK',
  cancelBtnText = 'Cancel',
  isOpen,
  onClose,
  onApply,
  isLoading,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent w="400px">
      <ModalCloseButton />
      <ModalBody m={4}>
        <Box
          py={2}
          fontSize="16px"
          color="primary.dark.400"
          fontWeight="700"
          textAlign="center"
        >
          {titleText}
        </Box>
        <Box
          px={8}
          pb={8}
          fontSize="12px"
          color="primary.dark.400"
          fontWeight="500"
          textAlign="center"
        >
          {subtitleText}
        </Box>
        <Center>
          <ButtonGroup size="sm" display="flex" gap={2}>
            <Button
              onClick={onClose}
              variant="outline"
              color="primary.500"
              rounded={2}
              minW="100px"
            >
              {cancelBtnText}
            </Button>
            <Button
              onClick={() => onApply()}
              colorScheme="primary"
              rounded={2}
              minW="100px"
              isLoading={isLoading}
            >
              {applyBtnText}
            </Button>
          </ButtonGroup>
        </Center>
      </ModalBody>
    </ModalContent>
  </Modal>
)

ConfirmationModal.propTypes = {
  titleText: PropTypes.string,
  subtitleText: PropTypes.string,
  applyBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}
