import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useAuthToken } from '../store/useAuthToken'
import { fetchEventTypes } from '../../services/api/palantir'
import { MINUTE_IN_MS } from '../../util/TimeUtils'

/**
 * Fetches event types data from Palantir.
 *
 * @param customerUUID {string}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 */
export function useFetchEventTypes({ customerUUID }, queryOptions = undefined) {
  const token = useAuthToken()

  const isRequestValid = !!customerUUID

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchEventTypes: invalid request parameters.', {
        customerUUID,
      })
    }
  }, [isRequestValid, customerUUID])

  return useQuery({
    queryKey: ['eventTypes', { customerUUID }],
    queryFn: () =>
      fetchEventTypes(token, { customerUUID }).then((res) => res.data.data),
    enabled: !!token && isRequestValid,
    staleTime: 5 * MINUTE_IN_MS,
    ...queryOptions,
  })
}
