import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Link,
  Select,
  VStack,
} from '@chakra-ui/react'
import { useState } from 'react'

export const SignupForm = ({ onSubmit, ...rest }) => {
  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    position: '',
    phone: '',
    url: '',
    companyName: '',
    companySize: '',
    terms: false,
  })

  const exchangeCamelCaseToUpperFirst = (camelCaseWord) => {
    const result = camelCaseWord.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase()
  }

  const validateSignupFields = (fields) => {
    const errors = {
      ...formErrors,
    }
    for (const key of fields) {
      if (errors[key]) {
        delete errors[key]
      }
      if (!formData[key]) {
        const errorMessage = `${exchangeCamelCaseToUpperFirst(
          key
        )} is required.`
        errors[key] = errorMessage
      }
    }
    setFormErrors(errors)
    return errors
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const errors = validateSignupFields([
      'firstName',
      'lastName',
      'position',
      'phone',
      'companyName',
      'companySize',
      'url',
      'terms',
    ])
    if (Object.keys(errors).length === 0) {
      onSubmit(event)
    }
  }

  const checkboxOnChange = (event) => {
    setFormData({ ...formData, terms: event.target.checked })
    if (event.target.checked) {
      const errors = { ...formErrors }
      if (errors.terms) {
        delete errors.terms
      }
      setFormErrors(errors)
    }
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit}
      gap={{ base: 1, '2xl': 2 }}
      alignItems="stretch"
      {...rest}
    >
      <HStack gap={5}>
        <FormControl
          isInvalid={formErrors.firstName}
          mb={!formErrors.firstName ? '18px' : 0}
        >
          <Input
            type="text"
            name="firstName"
            placeholder="First Name"
            aria-label="First Name"
            onBlur={() => validateSignupFields(['firstName'])}
            onChange={(event) => {
              setFormData({ ...formData, firstName: event.currentTarget.value })
            }}
          />
          <FormErrorMessage fontSize="0.8rem" mt={0}>
            {formErrors.firstName}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={formErrors.lastName}
          mb={!formErrors.lastName ? '18px' : 0}
        >
          <Input
            type="text"
            name="lastName"
            placeholder="Last Name"
            aria-label="Last Name"
            onBlur={() => validateSignupFields(['lastName'])}
            onChange={(event) => {
              setFormData({ ...formData, lastName: event.currentTarget.value })
            }}
          />
          <FormErrorMessage h="16px" fontSize="0.8rem" mt={0}>
            {formErrors.lastName}
          </FormErrorMessage>
        </FormControl>
      </HStack>
      <FormControl
        isInvalid={formErrors.position}
        mb={!formErrors.position ? '18px' : 0}
      >
        <Input
          type="text"
          name="position"
          placeholder="Position"
          aria-label="Position"
          onBlur={() => validateSignupFields(['position'])}
          onChange={(event) => {
            setFormData({ ...formData, position: event.currentTarget.value })
          }}
        />

        <FormErrorMessage fontSize="0.8rem" mt={0}>
          {formErrors.position}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.url} mb={!formErrors.url ? '18px' : 0}>
        <Input
          type="text"
          name="url"
          placeholder="Website URL"
          aria-label="Website URL"
          onBlur={() => validateSignupFields(['url'])}
          onChange={(event) => {
            setFormData({ ...formData, url: event.currentTarget.value })
          }}
        />
        <FormErrorMessage fontSize="0.8rem" mt={0}>
          {formErrors.url}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.companyName}>
        <Input
          type="text"
          name="companyName"
          placeholder="Company Name"
          aria-label="Company Name"
          onBlur={() => validateSignupFields(['companyName'])}
          onChange={(event) => {
            setFormData({ ...formData, companyName: event.currentTarget.value })
          }}
        />
        {!formErrors.companyName && <Box h="18px" />}
        <FormErrorMessage fontSize="0.8rem" mt={0}>
          {formErrors.companyName}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={formErrors.phone}
        mb={!formErrors.phone ? '18px' : 0}
      >
        <Input
          type="text"
          name="phone"
          placeholder="Phone"
          aria-label="Phone"
          onBlur={() => validateSignupFields(['phone'])}
          onChange={(event) => {
            setFormData({ ...formData, phone: event.currentTarget.value })
          }}
        />
        <FormErrorMessage fontSize="0.8rem" mt={0}>
          {formErrors.phone}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={formErrors.companySize}
        mb={!formErrors.companySize ? '18px' : 0}
      >
        <Select
          name="companySize"
          aria-label="Company Size"
          defaultValue=""
          onBlur={() => validateSignupFields(['companySize'])}
          onChange={(event) => {
            setFormData({ ...formData, companySize: event.currentTarget.value })
          }}
        >
          <option color="grey" selected hidden disabled value="">
            Company Size
          </option>
          <option value="0">1-10 employees</option>
          <option value="1">11-50 employees</option>
          <option value="2">51-200 employees</option>
          <option value="3">200+ employees</option>
        </Select>
        <FormErrorMessage fontSize="0.8rem" mt={0}>
          {formErrors.companySize}
        </FormErrorMessage>
      </FormControl>

      <Box pb={8} pt={2}>
        <FormControl
          isInvalid={formErrors.terms}
          mb={!formErrors.terms ? '18px' : 0}
        >
          <Checkbox
            name="terms"
            alignItems="start"
            onChange={(event) => checkboxOnChange(event)}
          >
            I accept the&nbsp;
            <Link href="https://revend.ai/legal" target="blank">
              terms and conditions, EULA and DPA.
            </Link>
          </Checkbox>
          <FormErrorMessage fontSize="0.8rem" mt={0}>
            You must accept the terms before signing up.
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Button
        type="submit"
        bg="primary.500"
        color="white"
        textTransform="uppercase"
        borderRadius="2px"
        _hover={{ bg: 'primary.600' }}
      >
        Sign Up
      </Button>
    </VStack>
  )
}

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
