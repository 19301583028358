import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { SearchIcon } from '@chakra-ui/icons'
import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { SettingsCard } from '../SettingsCard'
import { FloatLabel } from '../../../ui-kit/FloatLabel'

export const EventsNameTab = ({
  eventTypes,
  eventLabels,
  saveConfiguration,
  isLoading,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [eventTypesWithValues, setEventTypesWithValues] = useState([])

  useEffect(() => {
    setEventTypesWithValues(
      eventTypes.map((eventType) => {
        return {
          label: eventType,
          value: eventLabels[eventType] || '',
        }
      })
    )
  }, [eventTypes, eventLabels])

  const filteredEventTypes = useMemo(() => {
    if (searchQuery.length > 0) {
      return eventTypesWithValues.filter((eventType) =>
        eventType.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }
    return eventTypesWithValues
  }, [searchQuery, eventTypesWithValues])

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const handleEventLabeling = (value, label) => {
    setEventTypesWithValues(
      eventTypesWithValues.map((eventTypeWithValue) => {
        if (eventTypeWithValue.label === label) {
          return {
            label,
            value,
          }
        }
        return eventTypeWithValue
      })
    )
  }

  const handleSave = () => {
    const saveEventLabels = eventTypesWithValues.reduce(
      (acc, eventTypeWithValue) => {
        if (!eventTypeWithValue.value) {
          return acc
        }
        acc[eventTypeWithValue.label] = eventTypeWithValue.value
        return acc
      },
      {}
    )
    saveConfiguration({ ...eventLabels, ...saveEventLabels })
  }

  return (
    <SettingsCard
      title="Events name"
      subtitle="Label events"
      isLoading={isLoading}
      saveFunction={handleSave}
      stickyHeader
    >
      <Flex>
        <InputGroup size="md" width="50%">
          <Input
            placeholder="Search event"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <InputRightElement pointerEvents="none" size="md">
            <SearchIcon color="primary.500" />
          </InputRightElement>
        </InputGroup>
      </Flex>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={3}
        overflowY="auto"
        align="start"
        mt={6}
        paddingTop={2}
      >
        {filteredEventTypes.map((eventType) => (
          <Flex
            key={eventType.value}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Text flex="1" fontSize="xs" fontWeight="500" mb="0">
              {eventType.label}
            </Text>
            <FloatLabel label="Name" _wrapperProps={{ flex: '2' }}>
              <Input
                value={eventType.value}
                onChange={(event) => {
                  handleEventLabeling(event.target.value, eventType.label)
                }}
              />
            </FloatLabel>
          </Flex>
        ))}
      </VStack>
    </SettingsCard>
  )
}

EventsNameTab.propTypes = {
  eventTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  eventLabels: PropTypes.object.isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
