import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  HStack,
  Input,
  FormControl,
  FormErrorMessage,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Center,
  ButtonGroup,
  ModalHeader,
} from '@chakra-ui/react'
import { useMutatePropertyInvites } from '../../../../../hooks/palantir'

export const InviteCreateModal = ({ propertyUUID, isOpen, onClose }) => {
  const [newCollaborator, setNewCollaborator] = useState({
    userName: '',
    userEmail: '',
  })

  const [errors, setValidationErrors] = useState({})
  const isValidEmail = (email) => /(.+)@(.+){2,}\.(.+){2,}/.test(email)

  const { mutate: mutateInvites, isLoading: mutateInvitesLoading } =
    useMutatePropertyInvites()

  const handleInputChange = (event) => {
    const { value } = event.target
    const { name } = event.target

    setValidationErrors({})
    setNewCollaborator({ ...newCollaborator, [name]: value })
  }

  const sendInvite = (event) => {
    event.preventDefault()

    const errors = {}

    const { userName, userEmail } = newCollaborator

    if (userName === '') {
      errors.userName = 'Required field'
    }

    if (!isValidEmail(userEmail)) {
      errors.userEmail = 'Invalid e-mail'
    }

    if (userEmail === '') {
      errors.userEmail = 'Required field'
    }

    setValidationErrors(errors)

    if (Object.keys(errors).length > 0) {
      return
    }

    mutateInvites(
      {
        propertyUUID,
        userName,
        userEmail,
      },
      {
        onSuccess: () => {
          onClose()
        },
      }
    )
  }

  const cleanFields = () => {
    setNewCollaborator({
      userName: '',
      userEmail: '',
    })
    setValidationErrors({})
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={cleanFields}
      isCentered
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="sm">Invite to property</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={8}>
          <Box>
            <HStack mt="0.75rem" alignItems="baseline">
              <FormControl
                variant="floating"
                id="user-name"
                isRequired
                isInvalid={errors?.userName}
                w="auto"
              >
                <Input
                  name="userName"
                  width="320px"
                  placeholder="Name"
                  value={newCollaborator.userName}
                  onChange={handleInputChange}
                />
                <FormErrorMessage fontSize={10}>
                  {errors?.userName}
                </FormErrorMessage>
              </FormControl>
            </HStack>
            <HStack mt="0.75rem" alignItems="baseline">
              <FormControl
                variant="floating"
                id="user-email"
                isRequired
                isInvalid={errors?.userEmail}
                w="auto"
                pe={2}
              >
                <Input
                  name="userEmail"
                  type="email"
                  width="320px"
                  placeholder="E-mail"
                  value={newCollaborator.userEmail}
                  onChange={handleInputChange}
                />
                <FormErrorMessage fontSize={10}>
                  {errors?.userEmail}
                </FormErrorMessage>
              </FormControl>
            </HStack>
          </Box>
          <Center>
            <ButtonGroup size="sm" display="flex" gap={2} my={8}>
              <Button
                onClick={onClose}
                variant="outline"
                color="primary.500"
                rounded={2}
                minW="100px"
              >
                Cancel
              </Button>
              <Button
                isLoading={mutateInvitesLoading}
                onClick={sendInvite}
                colorScheme="primary"
                rounded={2}
                minW="100px"
              >
                Add property
              </Button>
            </ButtonGroup>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

InviteCreateModal.propTypes = {
  propertyUUID: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
