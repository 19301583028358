const eventsPossible = ['ec.detail', 'ec.add', 'ec.checkout', 'ec.purchase']

/**
 * @param eventTypes string[]
 * @param eventTypeLabelTranslator {EventTypeLabelTranslator}
 * @return {string}
 */
export const createFunnelEventMessage = (
  eventTypes,
  eventTypeLabelTranslator
) =>
  eventTypes
    .map((e) => (e.startsWith('ec.checkout') ? 'ec.checkout' : e))
    .filter((e) => eventsPossible.includes(e))
    .filter((e, idx, array) => array.indexOf(e) === idx)
    .map((type) => eventTypeLabelTranslator(type))
    .join(', ')
    .replace(/, ([^,]+)$/, ' and $1')
