const numbers = new Set(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'])

/**
 * Verifies if a string is numeric. Allows negative and floating point numbers.
 *
 * @param str {string} String to operate on.
 * @param allowFloat {boolean} If false, won't allow float-point numeric strings. Default is true.
 */
export const isNumeric = (str, allowFloat = true) => {
  const dotsCount = str.split('.').length - 1
  if (dotsCount > 2) return false
  if (dotsCount === 2 && !allowFloat) return false

  if (str[0] === '-') {
    str = str.substring(1)
  }

  return [...str].every((n) => numbers.has(n))
}
