import PropTypes from 'prop-types'
import { AreaClosed, LinePath } from '@visx/shape'
import { useMemo } from 'react'
import { scaleLinear, scaleTime } from '@visx/scale'
import { extent, max } from 'd3-array'
import * as allCurves from '@visx/curve'
import { Group } from '@visx/group'
import { LinearGradient } from '@visx/gradient'

const CURVE = allCurves.curveCardinalOpen
const getX = (d) => d.timestamp
const getY = (d) => d.value

export const SparklineChart = ({ width, height, data, color, ...rest }) => {
  const xScale = useMemo(
    () => scaleTime({ domain: extent(data, getX) }),
    [data]
  )
  const yScale = useMemo(
    () => scaleLinear({ domain: [0, max(data, getY)] }),
    [data]
  )

  xScale.range([0, width])
  yScale.range([height, 0])

  return (
    <svg width={width} height={height} {...rest}>
      <LinearGradient
        id={`sparkline_gradient_${color}`}
        from={color}
        to={color}
        fromOpacity={0.5}
        toOpacity={0}
      />
      <Group>
        <AreaClosed
          data={data}
          x={(d) => xScale(getX(d)) ?? 0}
          y={(d) => yScale(getY(d)) ?? 0}
          yScale={yScale}
          fill={`url(#sparkline_gradient_${color})`}
          curve={CURVE}
        />
      </Group>
      <LinePath
        curve={CURVE}
        data={data}
        x={(d) => xScale(getX(d)) ?? 0}
        y={(d) => yScale(getY(d)) ?? 0}
        stroke={color}
        strokeWidth={1}
        strokeOpacity={1}
        shapeRendering="geometricPrecision"
      />
    </svg>
  )
}

SparklineChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}
