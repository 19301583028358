import { api } from './core'

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @param eventType {string}
 * @param startDate {number}
 * @param endDate {number}
 * @return {Promise<AxiosResponse<any>>}
 */
export const fetchEventCountData = (
  authToken,
  { customerUUID, eventType, startDate, endDate }
) =>
  api(authToken).get(
    `/api/v1/dash/events/${encodeURIComponent(
      customerUUID
    )}/${startDate}/${endDate}/${encodeURIComponent(eventType)}`
  )

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @return {Promise<AxiosResponse<any>>}
 */
export const fetchEventTypes = (authToken, { customerUUID }) =>
  api(authToken).get(
    `/api/v1/dash/event/types/${encodeURIComponent(customerUUID)}`
  )

/**
 * @param authToken {string}
 * @param incidentUUID {string}
 * @return {Promise<AxiosResponse<any>>}
 */
export const fetchIncident = (authToken, { incidentUUID }) =>
  api(authToken).get(`/api/v1/dash/incident/${incidentUUID}`)

/**
 * @param authToken {string}
 * @param incidentUUID {string}
 * @return {Promise<AxiosResponse<any>>}
 */
export const fetchIncidentFunnel = (authToken, { incidentUUID }) =>
  api(authToken).get(`/api/v1/dash/incident/${incidentUUID}/funnel`)

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @param startDate {number}
 * @param endDate {number}
 * @param eventType {string}
 * @return {Promise<AxiosResponse<any>>}
 */
export const fetchIncidents = (
  authToken,
  { customerUUID, startDate, endDate, eventType }
) =>
  api(authToken).get(
    `/api/v1/dash/incidents/${encodeURIComponent(
      customerUUID
    )}/${startDate}/${endDate}/${encodeURIComponent(eventType)}`
  )

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @param page {number|undefined}
 * @return {Promise<AxiosResponse<any>>}
 */
export const fetchOpenedIncidents = (authToken, { customerUUID, page = 0 }) => {
  const query = new URLSearchParams({ page })
  return api(authToken).get(
    `/api/v1/dash/incidents/${encodeURIComponent(
      customerUUID
    )}/open?${query.toString()}`
  )
}

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @param date {number|undefined}
 * @return {Promise<AxiosResponse<any>>}
 */
export const fetchWeeklyIncidents = async (
  authToken,
  { customerUUID, date }
) => {
  const query = new URLSearchParams()
  if (date) query.set('date', date.toString())

  return api(authToken).get(
    `/api/v1/dash/weekly-incidents/${customerUUID}?${query.toString()}`
  )
}

export const fetchDashboardProperties = async (authToken, { customerUUID }) =>
  api(authToken).get(`/api/v1/dash/${customerUUID}/properties`)

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchCompanies = async (authToken) =>
  api(authToken).get(`/api/v1/company/`)

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchCompanyInvites = async (
  authToken,
  { companyUUID, page, pageSize }
) => {
  const query = new URLSearchParams()
  query.set('page', page)
  query.set('page_size', pageSize)
  return api(authToken).get(
    `/api/v1/company/${companyUUID}/invites?${query.toString()}`
  )
}

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchCompanyPropertiesInvites = async (
  authToken,
  { companyUUID, page, pageSize }
) => {
  const query = new URLSearchParams()
  query.set('page', page)
  query.set('page_size', pageSize)
  return api(authToken).get(
    `/api/v1/company/${companyUUID}/properties/invites?${query.toString()}`
  )
}

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchCompanyProperty = async (authToken, { companyUUID }) =>
  api(authToken).get(`/api/v1/company/${companyUUID}/property`)

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchNotificationSettings = async (authToken, { customerUUID }) =>
  api(authToken).get(`/api/v1/customers/${customerUUID}/notification-settings`)

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @param eventType {string}
 * @param warmupTime {number}
 * @param windowLength {number}
 * @param incidentSensitivity {number}
 * @return {Promise<AxiosResponse<Object|void>>}
 */
export const fetchConfigPreviewerIncidents = async (
  authToken,
  { customerUUID, eventType, warmupTime, windowLength, incidentSensitivity }
) => {
  const query = new URLSearchParams()
  query.set('warmup_time', warmupTime)
  query.set('window_length', windowLength)
  query.set('incident_sensitivity', incidentSensitivity)

  return api(authToken).get(
    `/api/v1/customers/${customerUUID}/config-previewer/incidents/${eventType}?${query.toString()}`
  )
}

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @param eventType {string}
 * @param windowLength {number}
 * @param incidentSensitivity {number}
 * @return {Promise<AxiosResponse<Object|void>>}
 */
export const fetchConfigPreviewerEvents = async (
  authToken,
  { customerUUID, eventType, windowLength, incidentSensitivity }
) => {
  const query = new URLSearchParams()
  query.set('window_length', windowLength)
  query.set('incident_sensitivity', incidentSensitivity)

  return api(authToken).get(
    `/api/v1/customers/${customerUUID}/config-previewer/events/${eventType}?${query.toString()}`
  )
}

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchIntegrationSettings = async (authToken, { customerUUID }) =>
  api(authToken).get(`/api/v1/customers/${customerUUID}/integration-settings`)

/**
 * @param authToken {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchBilling = async (authToken) =>
  api(authToken).get(`/api/v1/billing/`)

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchEmailChannelConfig = async (authToken, { customerUUID }) =>
  api(authToken).get(`/api/v1/customers/${customerUUID}/email-channel-config`)

/**
 * @param authToken {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchSignedUp = async (authToken) =>
  api(authToken).get(`/api/v1/signup`)

/**
 * @param authToken {string}
 * @param companyUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchProperties = async (authToken) =>
  api(authToken).get(`/api/v1/property`)

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchGoogleConfigAccounts = async (authToken, { customerUUID }) =>
  api(authToken).get(`/api/v1/customers/${customerUUID}/google-config/accounts`)

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @param accountID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchGoogleConfigAccountInfo = async (
  authToken,
  { customerUUID, accountID, loginCustomerId }
) =>
  api(authToken).get(
    `/api/v1/customers/${customerUUID}/google-config/accounts/${loginCustomerId}/${accountID}`
  )

/**
 * @param authToken {string}
 * @param customerUUID {string}
 * @return {Promise<AxiosResponse<object>>}
 */
export const fetchGoogleConfig = async (authToken, { customerUUID }) =>
  api(authToken).get(`/api/v1/customers/${customerUUID}/google-config`)
