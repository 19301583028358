import { useAuth0User } from './useAuth0User'

/**
 * @return {import("@tanstack/react-query").UseQueryResult<void, object>}
 */
export const useAuth0UserMetadata = () => {
  const queryReturn = useAuth0User()

  return {
    ...queryReturn,
    data: queryReturn.data?.user_metadata ?? null,
  }
}
