import { Badge } from '@chakra-ui/react'
import PropTypes from 'prop-types'

const COLOR_SCHEME = {
  low: 'warning',
  medium: 'alert',
  high: 'danger',
}

const COLOR = {
  low: 'primary.dark.500',
  medium: 'primary.dark.500',
  high: undefined,
}

const LABEL = {
  low: 'Low Priority',
  medium: 'Medium Priority',
  high: 'High Priority',
}

export const IncidentPriorityBadge = ({ priority, ...rest }) => (
  <Badge
    display="inline"
    colorScheme={COLOR_SCHEME[priority]}
    color={COLOR[priority]}
    variant="solid"
    rounded="2xl"
    {...rest}
  >
    {LABEL[priority]}
  </Badge>
)

IncidentPriorityBadge.propTypes = {
  priority: PropTypes.oneOf(['low', 'medium', 'high']).isRequired,
}
