/**
 * @param incident {{start_ts: number, end_ts: number}}
 * @return {object & {when: string, duration_in_minutes: number}}
 */
export const normalizeIncidentMessageFromConfigPreview = (incident) => {
  const durationInMinutes = Math.floor(
    (new Date(incident.end_ts * 1000) - new Date(incident.start_ts * 1000)) /
      1000 /
      60
  )
  const when = new Date(incident.start).toLocaleString()

  return { ...incident, when, duration_in_minutes: durationInMinutes }
}
