export const warmupTimeOptions = [
  '5m',
  '10m',
  '15m',
  '30m',
  '45m',
  '60m',
  '120m',
]

export const warmupTimeOptionsNumber = warmupTimeOptions
  .map((it) => it.replace('m', ''))
  .map((it) => parseInt(it, 10))

export const defaultWarmupTime = warmupTimeOptionsNumber[0]

export const sensitivityOptions = {
  0.005: 'Very low',
  0.01: 'Low',
  0.03: 'Medium',
  0.05: 'High',
  0.1: 'Very high',
}

export const sensitivityOptionsLabels = Object.values(sensitivityOptions)

export const sensitivityOptionsNumber =
  Object.keys(sensitivityOptions).map(parseFloat)

export const defaultSensitivity = sensitivityOptionsNumber[0]

export const windowSizeOptions = [
  '1h',
  '2h',
  '4h',
  '6h',
  '8h',
  '10h',
  '12h',
  '14h',
  '16h',
  '18h',
  '20h',
  '24h',
]

export const windowSizeOptionsNumber = windowSizeOptions
  .map((it) => it.replace('h', ''))
  .map((it) => parseInt(it, 10))

export const defaultWindowSize = windowSizeOptionsNumber[0]
