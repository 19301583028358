import PropTypes from 'prop-types'
import { LoadingSpinner } from '../../ui-kit/LoadingSpinner'
import { colors } from '../../../styles/colors'

// This component is not written with ChakraUI because it is also used in contexts before the initialization of the ChakraProvider (Auth0 redirects)
export const SpinnerWithText = ({ children = null, _styles }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontWeight: '700',
      color: colors.primary['500'],
      lineHeight: '25px',
      fontSize: 18,
      fontFamily: 'Manrope',
      marginTop: '10%',
      ..._styles,
    }}
  >
    <LoadingSpinner />
    {children}
  </div>
)

SpinnerWithText.propTypes = {
  children: PropTypes.node,
  _styles: PropTypes.object,
}
