import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFindActiveRoute } from '../../../hooks/router/useFindActiveRoute'
import { ALL_ROUTES } from '../../../util/routes'

export const SideBarButton = ({ id, label, icon, active, ...rest }) => {
  const isOpen = useSelector((s) => s.layout.isSidebarOpen)

  return (
    <Tooltip
      label={label}
      placement="right"
      bg="gray.200"
      color="primary.dark.500"
      px={1}
      borderRadius={8}
      isDisabled={isOpen}
    >
      <Flex
        as="button"
        alignItems="center"
        px={isOpen ? 8 : 4}
        py={4}
        fontFamily="Manrope"
        _hover={{ bg: 'gray.200' }}
        transition="all 250ms ease-in-out"
        role="tab"
        aria-roledescription={label}
        aria-selected={active}
        data-testid={`rev-sidebar-button-${id}`}
        {...rest}
      >
        {isOpen ? (
          <>
            <Icon
              as={icon}
              style={{ width: '24px', height: '24px' }}
              fill={active ? 'primary.500' : undefined}
            />
            <Text
              as="label"
              ml={2}
              fontWeight={500}
              fontSize={14}
              color={active ? 'primary.500' : undefined}
            >
              {label}
            </Text>
          </>
        ) : (
          <Icon
            as={icon}
            style={{ width: '24px', height: '24px' }}
            fill={active ? 'primary.500' : undefined}
          />
        )}
      </Flex>
    </Tooltip>
  )
}

SideBarButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func,
  active: PropTypes.bool.isRequired,
}

export const RoutedSideBarButton = ({ route, ...rest }) => {
  const activeRoute = useFindActiveRoute(ALL_ROUTES)
  const navigate = useNavigate()

  const isActive = useMemo(() => {
    if (activeRoute === undefined) return false
    if (typeof activeRoute === 'string' && typeof route === 'string') {
      return activeRoute === route
    }
    if (activeRoute instanceof RegExp && route instanceof RegExp) {
      return activeRoute.toString() === route.toString()
    }
    return activeRoute.test?.(route) || route.test?.(activeRoute)
  }, [activeRoute, route])

  return (
    <SideBarButton
      onClick={() => navigate(route)}
      active={isActive}
      {...rest}
    />
  )
}

RoutedSideBarButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
    .isRequired,
}
