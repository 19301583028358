import Select from 'react-select'
import { useMemo, useEffect } from 'react'
import makeAnimated from 'react-select/animated'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'
import { isDistinct } from '../../util/etc/isDistinct'

const componentStyles = {
  control: (styles) => {
    return {
      ...styles,
      cursor: 'pointer',
      width: '100%',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.101961)',
    }
  },
  option: (styles) => {
    return {
      ...styles,
      color: 'var(--chakra-colors-primary-500)',
      ':active': {
        color: 'var(--chakra-colors-primary-500)',
      },
    }
  },
  singleValue: (styles) => {
    return {
      ...styles,
      color: 'var(--chakra-colors-primary-500)',
    }
  },
}

export const SingleSelect = ({
  options,
  value,
  onChange,
  labelKey = 'label',
  valueKey = 'value',
  visible = true,
  ...rest
}) => {
  const formattedOptions = useMemo(() => {
    const formattedOptionsValues = options.map((option) =>
      typeof option === 'string'
        ? { label: option, value: option }
        : { label: option[labelKey], value: option[valueKey] }
    )

    if (
      formattedOptionsValues.some(
        ({ label, value: optionValue }) =>
          label === undefined || optionValue === undefined
      )
    ) {
      console.error(
        'SingleSelect: options must be string or have label and value keys'
      )
    }

    return formattedOptionsValues
  }, [options, labelKey, valueKey])

  const formattedValue = useMemo(() => {
    if (!value) return
    const labelObject = options.find((option) =>
      typeof option === 'string' ? option === value : option[valueKey] === value
    )
    const label =
      typeof labelObject === 'string' ? labelObject : labelObject?.[labelKey]

    if (!label) {
      console.error('SingleSelect: value must be one of the options')
      return
    }

    return { value, label }
  }, [value, options, labelKey, valueKey])

  useEffect(() => {
    if (!isDistinct(formattedOptions.map((option) => option.value))) {
      console.error('MultiSelect: options must be unique')
    }
  }, [formattedOptions])

  const handleChange = (event) => {
    onChange(event.value)
  }

  return (
    <Box
      as={Select}
      visibility={visible ? 'visible' : 'hidden'}
      options={formattedOptions}
      onChange={handleChange}
      value={formattedValue}
      components={makeAnimated()}
      styles={componentStyles}
      {...rest}
      sx={{
        '& *[id*="listbox"]': { zIndex: '999 !important' },
        ...rest?.sx,
      }}
    />
  )
}

SingleSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  visible: PropTypes.bool,
}
