import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'
import { useAuthToken } from '../store/useAuthToken'
import { useSub } from '../etc/useSub'
import { auth0Retrier } from './core'

/**
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptio}
 * @param enabled {boolean}
 * @return {import("@tanstack/react-query").UseQueryResult}
 */
export const useAuth0User = (
  queryOptions = undefined,
  { enabled = true } = {}
) => {
  const token = useAuthToken()
  const sub = useSub()

  return useQuery({
    queryKey: ['auth0-getUser', sub],
    enabled: !!sub && enabled,
    queryFn: () =>
      Axios.get(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${sub}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) => res.data),
    retryDelay: (_, error) => auth0Retrier(error, 'useAuth0User'),
    ...queryOptions,
  })
}
