import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { batch, useDispatch } from 'react-redux'

import { useFetchIncident } from '../hooks/palantir'
import { SharedActions } from '../store/slices/sharedSlice'
import { DashboardActions } from '../store'
import { ROUTE_DASHBOARD } from '../util/routes'
import { HOUR_IN_MS, MINUTE_IN_MS } from '../util/TimeUtils'

export const IncidentDeeplinkHandler = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const incidentUUID = params.get('uuid')

  const { data: incident } = useFetchIncident({ incidentUUID }, undefined, {
    enabled: !!incidentUUID,
  })

  useEffect(() => {
    if (incident) {
      const start = Date.parse(incident.when)
      const end = start + incident.duration_in_minutes * MINUTE_IN_MS

      batch(() => {
        dispatch(SharedActions.setCustomerUUID(incident.customer_uuid))
        dispatch(SharedActions.setSlideoutIncident(incidentUUID))
        dispatch(DashboardActions.setSelectedIncident(incidentUUID))
        dispatch(
          DashboardActions.setDateRange({
            startDate: new Date(start - HOUR_IN_MS * 12).toISOString(),
            endDate: new Date(end + HOUR_IN_MS * 12).toISOString(),
          })
        )
      })

      console.info(
        `[IncidentDeeplinkHandler] Valid deeplink found, redirecting...`,
        { incidentUUID }
      )
      navigate(ROUTE_DASHBOARD)
    }
  }, [dispatch, incident, incidentUUID, navigate])
}
