import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import {
  mutateCompanyInvites,
  mutateCancelInvite,
} from '../../services/api/palantir'

/**
 * Sends the user invite payload to Palantir.
 *
 * Also, refetches the "companyInvites" query if the request finishes successfully.
 *
 * @typedef {Object} CompanyInviteSettingsPayload
 * @property {string} companyUUID
 * @property {string} userName
 * @property {string} userEmail
 * */

export const useMutateCompanyInviteSettings = () => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['companyInvites', token],
    /**
     * @param {CompanyInviteSettingsPayload} payload
     */
    mutationFn: (payload) => mutateCompanyInvites(token, payload),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ['companyInvites'],
      })
    },
  })
}

/**
 * Send cancel user invite payload to Palantir.
 *
 * Also, refetches the "companyInvites" query if the request finishes successfully.
 *
 * @typedef {Object} CompanyCancelInvitePayload
 * @property {string} companyUUID
 * @property {string} inviteUUID
 * */

export const useMutateCancelInvite = () => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['propertyInvites', token],
    /**
     * @param {CompanyCancelInvitePayload} payload
     */
    mutationFn: (payload) => mutateCancelInvite(token, payload),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ['companyInvites'],
      })
      client.invalidateQueries({
        queryKey: ['propertyInvites'],
      })
    },
  })
}
