import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const mdfont12 = definePartsStyle({
  field: {
    fontSize: '0.8rem',
    color: 'primary.dark.500',
    border: '1px solid',
    borderColor: 'grey-lines',
    background: 'white',
    borderRadius: '4px',

    _hover: {
      boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.10)',
    },

    _focus: {
      borderColor: 'primary.500',
      boxShadow: 0,
    },

    _readOnly: {
      boxShadow: 0,
      userSelect: 'all',
    },

    _invalid: {
      borderColor: 'red.500',
    },
  },
  element: {
    fontSize: '1rem',
  },
})

export const inputTheme = defineMultiStyleConfig({
  defaultProps: {
    variant: 'md.font12',
  },
  variants: { 'md.font12': mdfont12 },
})
