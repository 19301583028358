import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Text,
  useTheme,
} from '@chakra-ui/react'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'
import { SparklineChart } from '../../revend-charts/SparklineChart'

export const DashboardWeeklyIncidentCard = ({
  title,
  currentWeekCount,
  lastWeekCount,
  histogram,
  ...rest
}) => {
  const theme = useTheme()
  const deviation = Number.isNaN(
    (currentWeekCount - lastWeekCount) / lastWeekCount
  )
    ? 0
    : (currentWeekCount - lastWeekCount) / (lastWeekCount || 1)

  return (
    <Card as="article" px={4} py={4} overflow="auto" {...rest}>
      <CardHeader p={0} pb={4}>
        <Heading fontSize={16} mb={0} flexGrow={1}>
          {title}
        </Heading>
      </CardHeader>

      <CardBody p={1}>
        <Flex>
          <Box>
            <Text color="primary.dark.500" fontWeight={700} fontSize={32} m={0}>
              {currentWeekCount}
            </Text>
            <Text
              display="flex"
              alignItems="center"
              color="primary.dark.300"
              fontSize={12}
              fontWeight={700}
              m={0}
            >
              {!!deviation && (
                <Icon
                  as={deviation < 0 ? BsArrowDown : BsArrowUp}
                  color={deviation <= 0 ? 'success' : 'danger'}
                  aria-label={deviation < 0 ? 'Arrow Up' : 'Arrow Down'}
                />
              )}
              <Text
                as="span"
                color={deviation <= 0 ? 'success' : 'danger'}
                mr={1}
              >
                {(Math.abs(deviation) * 100).toFixed(0)}%
              </Text>
              vs last week
            </Text>
          </Box>
          <Box>
            <SparklineChart
              data={histogram}
              color={
                deviation <= 0 ? theme.colors.success : theme.colors.danger
              }
              width={128}
              height={66}
              target={lastWeekCount}
            />
          </Box>
        </Flex>
      </CardBody>
    </Card>
  )
}

DashboardWeeklyIncidentCard.propTypes = {
  title: PropTypes.string.isRequired,
  currentWeekCount: PropTypes.number.isRequired,
  lastWeekCount: PropTypes.number.isRequired,
  histogram: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}
