import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mutateGoogleDisconnect } from '../../services/api/palantir'
import { useAuthToken } from '../store/useAuthToken'
/**
 * Sends a request to disconnect the Google account from the property, cleaning all the data.
 * @typedef {Object} GoogleDisconnectPayload
 * @property {string} companyUUID
 */
export const useMutateGoogleDisconnect = () => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['googleDisconnect', token],
    /**
     * @param {GoogleDisconnectPayload} payload
     */
    mutationFn: (payload) => mutateGoogleDisconnect(token, payload),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ['googleAccounts'],
      })
      client.invalidateQueries({
        queryKey: ['googleConfig'],
      })
      client.invalidateQueries({
        queryKey: ['googleAccountsInfo'],
      })
    },
  })
}
