import { createContext, useContext, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'

const GlobalStatecontext = createContext({})

export const GlobalStateProvider = ({ children }) => {
  const [value, setValue] = useReducer(reducer, {})

  function reducer(state, { key, data }) {
    if (data === undefined || data === null) {
      const { [key]: _, ...rest } = state
      return rest
    }

    return {
      ...state,
      [key]: data,
    }
  }

  const valueArray = useMemo(
    () => [value, (key) => (data) => setValue({ key, data })],
    [value, setValue]
  )

  return (
    <GlobalStatecontext.Provider value={valueArray}>
      {children}
    </GlobalStatecontext.Provider>
  )
}

GlobalStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

/**
 * @param key {string}
 * @return {[any, (value: any) => void]|undefined}
 */
export const useChartSharedTooltipData = (key) => {
  const ctx = useContext(GlobalStatecontext)
  if (!ctx) {
    throw new Error(
      'useChartSharedTooltipData must be used within ChartSharedTooltipProvider'
    )
  }

  const [value, setValue] = ctx
  return [value[key] ?? null, setValue(key)]
}
