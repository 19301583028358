import { useMemo, useRef } from 'react'
import { BiExitFullscreen, BiFullscreen } from 'react-icons/bi'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { useDate } from '../../../hooks/etc/useDate'
import { useFullscreen } from '../../../hooks/etc/useFullscreen'
import { useFetchEventTypes } from '../../../hooks/palantir'
import { useCustomerUUID } from '../../../hooks/store/useCustomerUUID'
import { useIncidentsPageState } from '../../../hooks/store/useIncidentsPageState'
import { GlobalStateProvider } from '../../../providers/GlobalStateProvider'
import { compareEventTypes } from '../../../util/revend/compareEventTypes'
import { IllustrationSearch } from '../../illustrations'
import { LoadingSpinner } from '../../ui-kit/LoadingSpinner'
import { ChartXAxis } from '../shared/ChartXAxis'
import { IncidentsControlledChart } from './IncidentsControlledChart'

export const IncidentsChartsSection = (props) => {
  const [customerUUID] = useCustomerUUID()
  const {
    data: availableEventTypes,
    isLoading: areAvailableEventTypesLoading,
  } = useFetchEventTypes(
    { customerUUID },
    { select: (data) => data?.map(({ id }) => id) }
  )
  const { zoomStartDate, zoomEndDate, eventTypes } = useIncidentsPageState()
  const ref = useRef(null)

  const start = useDate(zoomStartDate)
  const end = useDate(zoomEndDate)
  const { isFullscreen, toggleFullscreen } = useFullscreen(ref)

  const selectedEventTypes = useMemo(() => {
    const types = eventTypes.length ? eventTypes : availableEventTypes ?? []

    return [...types].sort(compareEventTypes)
  }, [eventTypes, availableEventTypes])

  return (
    <Flex
      as="section"
      flexDirection="column"
      px={6}
      pt={4}
      ref={ref}
      borderRadius={isFullscreen ? 0 : undefined}
      overflow="auto"
      bg="white"
      {...props}
    >
      <Flex alignItems="center" justifyContent="end">
        <Button
          onClick={toggleFullscreen}
          variant="unstyled"
          aria-label="Fullscreen"
        >
          <Tooltip
            label={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
            placement="left"
          >
            <span>
              <Icon
                as={isFullscreen ? BiExitFullscreen : BiFullscreen}
                fontSize={isFullscreen ? 24 : undefined}
                justifySelf="end"
              />
            </span>
          </Tooltip>
        </Button>
      </Flex>

      {!selectedEventTypes.length && (
        <Center flexGrow={1}>
          {areAvailableEventTypesLoading ? (
            <VStack>
              <LoadingSpinner color="primary.500" />
              <Heading fontSize={16} color="primary.500">
                Loading...
              </Heading>
            </VStack>
          ) : (
            <VStack>
              <IllustrationSearch />
              <Heading fontSize={16} color="primary.500">
                You don&apos;t have enough data to display this section yet.
              </Heading>
              <Text>Please change your filters or try again later</Text>
            </VStack>
          )}
        </Center>
      )}

      {customerUUID && !!selectedEventTypes.length && (
        <Box flexGrow={1}>
          <AutoSizer>
            {({ width, height }) => (
              <GlobalStateProvider>
                <VStack
                  w={`${width}px`}
                  h={`${height}px`}
                  gap={4}
                  alignItems="stretch"
                  overflowY="auto"
                >
                  {selectedEventTypes.map((eventType) => (
                    <IncidentsControlledChart
                      height={Math.max(
                        (height - 90) / selectedEventTypes.length,
                        82
                      )}
                      key={eventType}
                      eventType={eventType}
                      width={width}
                    />
                  ))}
                  <Box flexShrink={0} position="sticky" bottom={0} bg="white">
                    <ChartXAxis width={width} start={start} end={end} />
                  </Box>
                </VStack>
              </GlobalStateProvider>
            )}
          </AutoSizer>
        </Box>
      )}
    </Flex>
  )
}
