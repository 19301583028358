import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { extent, max, bisector } from 'd3-array'
import { Area, Line, LinePath } from '@visx/shape'
import { scaleTime, scaleLinear } from '@visx/scale'
import { AxisBottom, AxisLeft } from '@visx/axis'
import PropTypes from 'prop-types'
import {
  defaultStyles,
  Tooltip,
  TooltipWithBounds,
  withTooltip,
} from '@visx/tooltip'
import { localPoint } from '@visx/event'
import { Text } from '@chakra-ui/react'
import { abbreviateNumber } from '../../util/etc/abbreviateNumber'
import { ChartIncidents } from './fragments/ChartIncidents'
import { colors } from '../../styles/colors'
import { HOUR_IN_MS } from '../../util/TimeUtils'
import { abbreviateTime } from '../../util/etc/abbreviateTime'
import { ChartAnomalies } from './fragments/ChartAnomalies'
import { yIsInstantAffectedByIncident } from '../../util/revend/isInstantAffectedByIncident'
import { preciseCurveLinear } from '../../util/d3/preciseCurveLinear'
import { findIncidentByCoordinates } from '../../util/d3/revend/findIncidentByCoordinates'
import { findAnomalyByCoordinates } from '../../util/d3/revend/findAnomalyByCoordinates'
import { useZoomHandler } from './hooks/useZoomHandler'

const getX = (d) => new Date(d.TIME)
const getY = (d) => d.COUNT

const ANOMALY_CIRCLE_RADIUS = 3
const SELECTION_MIN_AREA_MS = HOUR_IN_MS

const bisectDate = bisector((d) => new Date(d.TIME)).left

const xAxisFormatter = (date) =>
  date.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  })

const SingleLineIncidentChartComponent = ({
  // Layout
  width,
  height,
  chartMarginLeft = 0,
  chartMarginBottom = 0,
  curve = preciseCurveLinear,
  // Datum
  data,
  incidents,
  anomalies,
  // Axis
  showAxisLeft,
  showAxisBottom,
  // Custom event emitters
  onChartHover,
  onSelectArea,
  onDoubleClick,
  onIncidentHover,
  onIncidentClick,
  onAnomalyClick,
  // Tooltip handler
  tooltipX,
  showTooltip,
  hideTooltip,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  ...rest
}) => {
  const xScale = useMemo(
    () => scaleTime({ domain: extent(data, getX) }),
    [data]
  )
  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [
          0,
          Math.max(
            max(data, getY),
            max(data, (d) => d.COUNT_MAX)
          ),
        ],
      }),
    [data]
  )

  // update scale output ranges
  xScale.range([chartMarginLeft, width])
  yScale.range([height - chartMarginBottom, 20])

  useEffect(() => {
    if (tooltipX == null) return void hideTooltip()

    const x0 = xScale.invert(tooltipX)
    const index = bisectDate(data, x0, 1)
    const d = data[index]

    if (!d) return void hideTooltip()

    showTooltip({
      tooltipData: d,
      tooltipLeft: tooltipX,
      tooltipTop: yScale(getY(d)),
    })
  }, [data, hideTooltip, showTooltip, tooltipX, xScale, yScale])

  const lastIncidentHoverTriggered = useRef(undefined)
  const processIncidentHover = useCallback(
    (event) => {
      if (!event) {
        if (lastIncidentHoverTriggered.current) {
          onIncidentHover?.(null)
          lastIncidentHoverTriggered.current = undefined
        }
        return
      }

      const { x } = localPoint(event) || { x: 0 }
      const time = xScale.invert(x)

      const incident = incidents.find(yIsInstantAffectedByIncident(+time))
      if (incident?.uuid !== lastIncidentHoverTriggered.current) {
        onIncidentHover?.(incident?.uuid ?? null)
        lastIncidentHoverTriggered.current = incident?.uuid
      }
    },
    [incidents, onIncidentHover, xScale]
  )

  const [isHovering, setHovering] = useState(false)

  const zoomHandler = useZoomHandler({
    selectionMinAreaMs: SELECTION_MIN_AREA_MS,
    onSelectArea,
    xScale,
    data,
    getX,
  })

  const handleHoverOn = (event) => {
    onChartHover?.(event)
    setHovering(true)
    processIncidentHover(event)
    zoomHandler.handlers.hoverOn(event)
  }

  const handleHoverOff = () => {
    onChartHover?.(null)
    setHovering(false)
    processIncidentHover(null)
  }

  const axisLeftComponent = ({ y, formattedValue }) => (
    <g>
      <text x={0} y={y} fontSize={10} fill="#808899">
        {formattedValue}
      </text>
    </g>
  )

  const axisBottomComponent = ({ x, formattedValue }) => (
    <g className="visx-group visx-axis-tick">
      <text x={x} fontSize={10} fill="#808899" textAnchor="middle">
        {formattedValue}
      </text>
    </g>
  )

  const handleMouseDown = (event) => {
    zoomHandler.handlers.mouseDown(event)
  }

  const handleClick = (event) => {
    const { x, y } = localPoint(event) || { x: 0, y: 0 }
    if (x && y) {
      const incident =
        onIncidentClick && findIncidentByCoordinates(x, xScale, incidents)
      const anomaly =
        onAnomalyClick &&
        findAnomalyByCoordinates(
          x,
          y,
          xScale,
          yScale,
          anomalies,
          ANOMALY_CIRCLE_RADIUS
        )
      if (incident) onIncidentClick?.(incident.uuid)
      if (anomaly) onAnomalyClick?.(anomaly)
    }
  }

  return (
    <>
      <svg width={width} height={height} {...rest}>
        <rect
          width={width - chartMarginLeft}
          x={chartMarginLeft}
          height={height - chartMarginBottom}
          fill="#efefef"
          rx={14}
          ry={14}
        />
        <Area
          data={data}
          x={(d) => xScale(new Date(d.TIME))}
          y0={(d) => yScale(d.COUNT_MIN)}
          y1={(d) => yScale(d.COUNT_MAX)}
          fill="#ADBEFF"
        />
        <ChartIncidents
          incidents={incidents}
          xScale={xScale}
          height={height - chartMarginBottom}
        />
        <ChartAnomalies
          data={anomalies}
          color={colors.primary.dark['500']}
          radius={ANOMALY_CIRCLE_RADIUS}
          xScale={xScale}
          yScale={yScale}
        />
        {showAxisLeft && (
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yScale}
            tickComponent={axisLeftComponent}
            tickFormat={abbreviateNumber}
            numTicks={Math.round(height / 30)}
            tickClassName="noUserSelect"
          />
        )}
        {showAxisBottom && (
          <AxisBottom
            top={height - 4}
            hideAxisLine
            hideTicks
            scale={xScale}
            tickComponent={axisBottomComponent}
            tickFormat={xAxisFormatter}
            numTicks={Math.round(width / 100)}
          />
        )}

        <LinePath
          curve={curve}
          data={data}
          x={(d) => xScale(getX(d)) ?? 0}
          y={(d) => yScale(getY(d)) ?? 0}
          stroke="#2350FF"
          strokeWidth={1}
          strokeOpacity={1}
          shapeRendering="geometricPrecision"
        />
        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: 0 }}
              to={{ x: tooltipLeft, y: height - chartMarginBottom }}
              stroke="black"
              strokeWidth={2}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop + 1}
              r={4}
              fill="black"
              fillOpacity={0.1}
              stroke="black"
              strokeOpacity={0.1}
              strokeWidth={2}
              pointerEvents="none"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={4}
              fill="black"
              stroke="white"
              strokeWidth={2}
              pointerEvents="none"
            />
          </g>
        )}
        {zoomHandler.startPointPx && zoomHandler.endPointPx && (
          <rect
            width={Math.abs(zoomHandler.endPointPx - zoomHandler.startPointPx)}
            height={height - chartMarginBottom}
            x={
              zoomHandler.startPointPx > zoomHandler.endPointPx
                ? zoomHandler.endPointPx
                : zoomHandler.startPointPx
            }
            fill="grey"
            fillOpacity={0.2}
          />
        )}
        <rect
          width={width - chartMarginLeft}
          x={chartMarginLeft}
          height={height - chartMarginBottom}
          fill="transparent"
          rx={14}
          ry={14}
          onTouchStart={handleHoverOn}
          onTouchMove={handleHoverOn}
          onMouseMove={handleHoverOn}
          onMouseLeave={handleHoverOff}
          onMouseDown={handleMouseDown}
          onClick={handleClick}
          onDoubleClick={onDoubleClick}
        />
      </svg>
      {tooltipData && (
        <div>
          <TooltipWithBounds
            key={Math.random()}
            top={Math.min(tooltipTop - 36, height - 72)}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              backgroundColor: 'transparent',
              boxShadow: 'none',
              color: colors.text,
              userSelect: 'none',
            }}
          >
            {abbreviateNumber(Math.trunc(tooltipData.COUNT_MAX))}
            <Text my={1}>{tooltipData.COUNT}</Text>
            {abbreviateNumber(Math.trunc(tooltipData.COUNT_MIN))}
          </TooltipWithBounds>
          {isHovering && (
            <Tooltip
              top={height - 8}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 72,
                textAlign: 'center',
                transform: 'translateX(-50%)',
                userSelect: 'none',
              }}
            >
              {getX(tooltipData).toLocaleDateString(undefined, {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Tooltip>
          )}
        </div>
      )}
      {zoomHandler.startPointPx && zoomHandler.endPointPx && (
        <Tooltip
          top={0}
          left={
            zoomHandler.startPointPx > zoomHandler.endPointPx
              ? zoomHandler.startPointPx - 72
              : zoomHandler.startPointPx
          }
          style={{
            ...defaultStyles,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: colors.text,
            userSelect: 'none',
          }}
        >
          {abbreviateTime(
            Math.abs(
              zoomHandler.startPointVirtualTimestamp -
                zoomHandler.endPointVirtualTimestamp
            )
          )}
        </Tooltip>
      )}
    </>
  )
}

export const SingleLineIncidentChart = withTooltip(
  SingleLineIncidentChartComponent
)

SingleLineIncidentChartComponent.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  chartMarginLeft: PropTypes.number,
  chartMarginBottom: PropTypes.number,
  curve: PropTypes.func,

  onChartHover: PropTypes.func,

  onSelectArea: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onIncidentHover: PropTypes.func,
  onIncidentClick: PropTypes.func,
  onAnomalyClick: PropTypes.func,

  data: PropTypes.arrayOf(
    PropTypes.shape({
      TIME: PropTypes.number.isRequired,
      COUNT: PropTypes.number.isRequired,
      COUNT_MIN: PropTypes.number.isRequired,
      COUNT_MAX: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,

  incidents: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      current: PropTypes.number.isRequired,
      when: PropTypes.string.isRequired,
      duration_in_minutes: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,

  anomalies: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,

  showAxisLeft: PropTypes.bool,
  showAxisBottom: PropTypes.arrayOf(PropTypes.oneOf(['A', 'B'])),

  tooltipX: PropTypes.number,
  tooltipData: PropTypes.shape({
    COUNT: PropTypes.number.isRequired,
    COUNT_MIN: PropTypes.number.isRequired,
    COUNT_MAX: PropTypes.number.isRequired,
  }),
  tooltipTop: PropTypes.number,
  tooltipLeft: PropTypes.number,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
}
