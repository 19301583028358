import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchWeeklyIncidents } from '../../services/api/palantir'
import { mkDate } from '../../util/etc/mkDate'

/**
 * Fetches weekly incidents data from Palantir.
 *
 * @param customerUUID {string}
 * @param date {Date|string|number|undefined|null}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 */
export function useFetchWeeklyIncidents(
  { customerUUID, date },
  queryOptions = undefined
) {
  const token = useAuthToken()

  if (date) date = mkDate(date).getTime()
  const isRequestValid = typeof customerUUID === 'string' && !!customerUUID

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchWeeklyIncidents: invalid request parameters.', {
        customerUUID,
        date,
      })
    }
  }, [customerUUID, date, isRequestValid])

  return useQuery({
    queryKey: ['weeklyIncidents', { customerUUID, date }],
    queryFn: () =>
      fetchWeeklyIncidents(token, { customerUUID, date }).then(
        (res) => res.data
      ),
    enabled: !!token && isRequestValid,
    ...queryOptions,
  })
}
