import { createSlice } from '@reduxjs/toolkit'

const CUSTOMER_UUID_LOCALSTORAGE_KEY = 'revend:customerUUID'
const ONBOARDING_LOCALSTORAGE_KEY = 'revend:onboarding'
const BILLING_STARTED_LOCALSTORAGE_KEY = 'revend:billingStarted'

const initialState = {
  customerUUID: localStorage.getItem(CUSTOMER_UUID_LOCALSTORAGE_KEY) ?? '',
  hoveredIncident: null,
  slideoutIncident: null,
  onboarding: localStorage.getItem(ONBOARDING_LOCALSTORAGE_KEY) === 'true',
  billingStarted:
    localStorage.getItem(BILLING_STARTED_LOCALSTORAGE_KEY) === 'true',
}

const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setCustomerUUID(state, action) {
      localStorage.setItem(CUSTOMER_UUID_LOCALSTORAGE_KEY, action.payload)
      state.customerUUID = action.payload
    },
    setHoveredIncident(state, action) {
      state.hoveredIncident = action.payload
    },
    setSlideoutIncident(state, action) {
      state.slideoutIncident = action.payload
    },
    setOnboarding(state, action) {
      localStorage.setItem(ONBOARDING_LOCALSTORAGE_KEY, action.payload)
      state.onboarding = action.payload
    },
    setBillingStarted(state, action) {
      localStorage.setItem(BILLING_STARTED_LOCALSTORAGE_KEY, action.payload)
      state.billingStarted = action.payload
    },
  },
})

export const {
  reducer: sharedReducer,
  actions: SharedActions,
  ONBOARDING_LOCALSTORAGE_KEY: onboardingKey,
} = sharedSlice
