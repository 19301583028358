import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mutateGoogleConfig } from '../../services/api/palantir'
import { useAuthToken } from '../store/useAuthToken'
/**
 *
 * @typedef {Object} GoogleConfigEventsPayload
 * @property {string} event_suffix
 * @property {string} action_id
 * @property {string} action_name
 *
 * @typedef {Object} GoogleConfigPayload
 * @property {string} propertyUUID
 * @property {string} userConsent
 * @property {Array<GoogleConfigEventsPayload>} events
 * @property {string} measurementId
 * @property {string} googleAdAccount
 */
export const useMutateGoogleConfig = () => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['googleDisconnect', token],
    /**
     * @param {GoogleConfigPayload} payload
     */
    mutationFn: (payload) => mutateGoogleConfig(token, payload),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ['googleConfig'],
      })
    },
  })
}
