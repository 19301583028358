import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchIncidents } from '../../services/api/palantir'
import { validateDateRange } from '../../util/validations/validateDateRange'
import { mkDate } from '../../util/etc/mkDate'

/**
 * Fetches incidents data from Palantir.
 *
 * Rules for valid request:
 * - startDate and endDate must be valid dates, and a valid range (startDate < endDate)
 * - customerUUID must be a non-empty string
 *
 * @param customerUUID {string}
 * @param eventType {string|undefined}
 * @param startDate {number|string}
 * @param endDate {number|string}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 * @param defaultDisabled {boolean} If true, the query will be disabled by default.
 */
export function useFetchIncidents(
  { customerUUID, eventType, startDate, endDate },
  queryOptions = undefined,
  { defaultDisabled = false } = {}
) {
  const token = useAuthToken()

  startDate = mkDate(startDate).getTime()
  endDate = mkDate(endDate).getTime()

  const isRequestValid =
    validateDateRange(startDate, endDate, false) &&
    typeof customerUUID === 'string' &&
    !!customerUUID &&
    !Number.isNaN(startDate) &&
    !Number.isNaN(endDate)

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchIncidents: invalid request parameters.', {
        customerUUID,
        eventType,
        startDate,
        endDate,
      })
    }
  }, [customerUUID, endDate, eventType, isRequestValid, startDate])

  return useQuery({
    queryKey: ['incidents', { customerUUID, eventType, startDate, endDate }],
    queryFn: () =>
      fetchIncidents(token, {
        customerUUID,
        startDate,
        endDate,
        eventType: eventType ?? '',
      }).then((res) => res.data),
    enabled: !!token && isRequestValid && !defaultDisabled,
    ...queryOptions,
  })
}
