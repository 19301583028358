import { Box, Button } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

/**
 * Renders a pagination component.
 *
 * @component
 * @param {number} itemsPerPage - The number of items per page. By default is 7.
 * @param {number} length - The total length of the items.
 * @param {Function} handlePagination - The function to handle pagination.
 * @param {number} currentPage - The current page number.
 * @param {string} align - The alignment of the pagination component. By default is center.
 * @returns {JSX.Element} The pagination component.
 */
export const Pagination = ({
  itemsPerPage = 7,
  length,
  handlePagination,
  currentPage,
  align = 'center',
}) => {
  const paginationNumber = []
  length = length === 0 ? 1 : length
  const pages = Math.ceil(length / itemsPerPage)

  for (let i = 1; i <= pages; i++) {
    paginationNumber.push(i)
  }
  return (
    <Box w="100%" my="3" textAlign={align}>
      <Button
        size="sm"
        variant="ghost"
        onClick={() => handlePagination(currentPage > 1 ? currentPage - 1 : 1)}
        color={currentPage === 1 ? 'primary.dark.100' : 'primary.400'}
        _hover={{
          color: currentPage === 1 ? 'primary.dark.100' : 'primary.600',
        }}
      >
        <ChevronLeftIcon boxSize={6} />
      </Button>
      {paginationNumber.map((data) => (
        <Button
          mx="2px"
          size="xs"
          bgColor={currentPage === data ? 'primary.400' : 'primary.dark.200'}
          key={data}
          onClick={() => handlePagination(data)}
        >
          {data}
        </Button>
      ))}
      <Button
        size="sm"
        variant="ghost"
        onClick={() =>
          handlePagination(currentPage < pages ? currentPage + 1 : pages)
        }
        color={currentPage === pages ? 'primary.dark.100' : 'primary.400'}
        _hover={{
          color: currentPage === pages ? 'primary.dark.100' : 'primary.600',
        }}
      >
        <ChevronRightIcon boxSize={6} />
      </Button>
    </Box>
  )
}

Pagination.propTypes = {
  align: PropTypes.string,
  itemsPerPage: PropTypes.number,
  length: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
}
