import PropTypes from 'prop-types'
import { Box, Center, Heading, Skeleton, Text, VStack } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useCustomerUUID } from '../../../../../hooks/store/useCustomerUUID'
import {
  useFetchConfigPreviewerIncidents,
  useFetchConfigPreviewerEvents,
} from '../../../../../hooks/palantir'
import {
  defaultSensitivity,
  defaultWarmupTime,
  defaultWindowSize,
} from './const'
import { TimeUtils } from '../../../../../util/TimeUtils'
import { SingleLineIncidentChart } from '../../../../revend-charts/SingleLineIncidentChart'
import { prepareIncidentAnomaliesForChart } from '../../../../../util/revend/prepareIncidentAnomaliesForChart'
import { IllustrationEmptyBox } from '../../../../illustrations'

const HEIGHT = 200
const WIDTH = HEIGHT * 1.777 // 16:9 ratio

export const EventTypeIncidentSettingPreview = ({
  eventType,
  eventTypeSettings,
}) => {
  const [customerUUID] = useCustomerUUID()

  const { data: incidents, isLoading: areIncidentsLoading } =
    useFetchConfigPreviewerIncidents({
      customerUUID,
      eventType,
      incidentSensitivity:
        eventTypeSettings.incident_sensitivity ?? defaultSensitivity,
      windowLength:
        eventTypeSettings.sliding_window_length_hours ?? defaultWindowSize,
      warmupTime: eventTypeSettings.warmup_time ?? defaultWarmupTime,
    })

  const [startDate, endDate] = useMemo(() => {
    if (areIncidentsLoading) return []
    if (incidents === null) return []
    if (incidents.length === 0) {
      return TimeUtils.getWeekRange(TimeUtils.addDays(new Date(), -7))
    }
    return TimeUtils.getWeekRange(new Date(incidents[0].start_ts * 1000))
  }, [areIncidentsLoading, incidents])

  const anomalies = useMemo(
    () =>
      incidents && startDate && endDate
        ? prepareIncidentAnomaliesForChart(incidents, {
            start: startDate.getTime(),
            end: endDate.getTime(),
            eventType,
          })
        : [],
    [incidents, startDate, endDate, eventType]
  )

  const { data: events, isLoading: areEventsLoading } =
    useFetchConfigPreviewerEvents(
      {
        customerUUID,
        eventType,
        incidentSensitivity:
          eventTypeSettings.incident_sensitivity ?? defaultSensitivity,
        windowLength:
          eventTypeSettings.sliding_window_length_hours ?? defaultWindowSize,
      },
      { enabled: !!startDate && !!endDate }
    )

  const isLoading = areIncidentsLoading || areEventsLoading

  function renderEmpty() {
    return (
      <Center h={HEIGHT - 32}>
        <VStack>
          <IllustrationEmptyBox />
          <Heading fontSize={16} color="primary.500">
            No data available yet.
          </Heading>
          <Text textAlign="center">
            We are still training your Revend model to read your data and
            generate your estimation.
          </Text>
        </VStack>
      </Center>
    )
  }

  function renderContent() {
    if (isLoading) return <Skeleton w={WIDTH} h={HEIGHT} />
    return (
      <Box
        mt={incidents ? undefined : 2}
        bg="white"
        border="1px solid"
        borderColor="grey-lines"
        h={HEIGHT}
        w={WIDTH}
        borderRadius="md"
        px={incidents ? 2 : 4}
        py={4}
      >
        {incidents && events?.length ? (
          <SingleLineIncidentChart
            width={WIDTH - 16}
            height={HEIGHT - 32}
            chartMarginLeft={20}
            data={events}
            incidents={incidents}
            anomalies={anomalies}
            showAxisLeft
          />
        ) : (
          renderEmpty()
        )}
      </Box>
    )
  }

  const incidentsPerDay = Math.ceil((incidents?.length ?? 0) / 7)

  return (
    <Box w="20rem" pt={7}>
      <Heading fontSize={14}>Preview</Heading>
      <Skeleton
        isLoaded={!isLoading}
        mb={2}
        w={WIDTH}
        display={!isLoading && !incidents ? 'none' : undefined}
      >
        <Text fontSize={14} color="primary.dark.300">
          According to your settings, you would get around {incidentsPerDay}{' '}
          incidents per day.
        </Text>
      </Skeleton>
      {renderContent()}
    </Box>
  )
}

EventTypeIncidentSettingPreview.propTypes = {
  eventType: PropTypes.string.isRequired,
  eventTypeSettings: PropTypes.shape({
    incident_sensitivity: PropTypes.number.isRequired,
    sliding_window_length_hours: PropTypes.number.isRequired,
    warmup_time: PropTypes.number.isRequired,
  }).isRequired,
}
