import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Circle,
  Flex,
  Link,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

export const ScriptTagStep = ({ eventTypes, previous, next }) => {
  const [eventsPresent, setEventsPresent] = useState([])

  useEffect(() => {
    setEventsPresent(eventTypes)
  }, [eventTypes])

  const defaultEvents = [
    {
      label: 'Product page view',
      value: 'ec.detail',
    },
    {
      label: 'Add to cart',
      value: 'ec.add',
    },
    {
      label: 'Checkout',
      value: 'ec.checkout',
    },
    {
      label: 'Purchase',
      value: 'ec.purchase',
    },
  ]

  const circleColor = (eventType) =>
    eventsPresent.some((event) => event.includes(eventType))
      ? 'success'
      : 'danger'

  return (
    <>
      <Flex pr={14} flexDirection="column">
        <Text fontWeight="700" color="primary.500" fontSize="lg">
          Test your setup
        </Text>
        <Text fontSize="sm" mt={4}>
          <strong>Well done!</strong> Revend is now ready to receive events from
          your shop.
          <br />
          <br />
          Try placing a test order, and refresh this screen. You should see all
          the steps you went through go from red to green. If your store is
          currently busy, the lights will turn on as customers take their steps
          along your customer journey.
          <br />
          <br />
          Don&apos;t worry if the lights never turn green for some checkout
          steps - they should match your checkout flow. Shopify customers:
          checkout steps are not trackable. The Revend Shopify app is coming
          soon to fix that.
        </Text>
        <Text fontSize="sm" mt={4}>
          Events Revend can track:
        </Text>
        <VStack
          spacing={4}
          overflowY="auto"
          align="start"
          mt={6}
          flexGrow={0}
          divider={<StackDivider borderColor="white" />}
        >
          {defaultEvents.map((defaultEvent) => (
            <Flex gap="4" key={defaultEvent.value} alignItems="center">
              <Circle size="16px" bg={circleColor(defaultEvent.value)} />
              <Text fontSize="sm" fontWeight="500" mb="0">
                {defaultEvent.label}
              </Text>
            </Flex>
          ))}
        </VStack>
        {!defaultEvents.find((e) => e.value === 'success') && (
          <Text fontSize="sm" mt={6}>
            Lights not turning green?
            <ul>
              <li>Make sure you published the changes you made in GTM</li>
              <li>
                Verify you used the active GTM container (check the source code
                in a browser)
              </li>
              <li>
                If you installed to your theme, try moving the script tag to
                <i>theme.liquid</i>
                &nbsp;or the file containing the &lt;head&gt; tag in your
                platform.
              </li>
              <li>
                Still no luck? No problem!&nbsp;
                <Link
                  href="https://calendly.com/peter-wellens/revend-30-min"
                  target="_blank"
                  colorScheme="primary"
                >
                  Book a call with our specialists
                </Link>
              </li>
            </ul>
          </Text>
        )}
      </Flex>

      <Box alignSelf="end" mt={10}>
        <Button
          variant="outline.500"
          colorScheme="primary"
          bg="white"
          borderRadius="sm"
          width="110px"
          height="32px"
          onClick={previous}
        >
          Previous
        </Button>
        <Button
          borderRadius="sm"
          width="110px"
          height="32px"
          ml={4}
          onClick={next}
        >
          Next
        </Button>
      </Box>
    </>
  )
}

ScriptTagStep.propTypes = {
  eventTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
}
