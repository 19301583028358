import { useMemo, useState } from 'react'
import { Input, useConst } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { TimeUtils } from '../../util/TimeUtils'

export const DateInput = ({ value, min, max, onChange, ...rest }) => {
  const key = useConst(Math.random().toString())
  const [isFocused, setFocused] = useState(false)

  const valueString = useMemo(
    () => TimeUtils.toLocalFullDateString(value),
    [value]
  )
  const minString = useMemo(
    () => min && TimeUtils.toLocalFullDateString(min),
    [min]
  )
  const maxString = useMemo(
    () => max && TimeUtils.toLocalFullDateString(max),
    [max]
  )

  const handleChange = (event) => {
    const { value } = event.target
    if (!value) return

    try {
      const [year, month, day] = value
        .split('-')
        .map((number) => parseInt(number, 10))

      const date = new Date(year, month - 1, day, 0, 0, 0, 0)
      if (Number.isNaN(date.getTime())) return
      if (min && +date < TimeUtils.timeMs(min)) return
      if (max && +date > TimeUtils.timeMs(max)) return

      onChange(date)
    } catch (e) {
      /* ignore */
    }
  }

  return (
    <Input
      key={isFocused ? key : Math.random()}
      type="date"
      variant="outline"
      defaultValue={valueString}
      onChange={handleChange}
      color="primary.500"
      min={minString}
      max={maxString}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...rest}
    />
  )
}

DateInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]),
  min: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]),
  max: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
}
