import { useEffect } from 'react'
import LogRocketSdk from 'logrocket'
import { useAuth0User } from '../hooks/auth0'

const LOGROCKET_ID = process.env.REACT_APP_LOGROCKET_ID

export const LogRocket = () => {
  const { data } = useAuth0User()
  const { user_id: uid, email } = data || {}

  useEffect(() => {
    if (!uid || !LOGROCKET_ID) return

    console.debug(`Initializing LogRocket SDK`, {
      LOGROCKET_ID,
      uid,
      email,
    })
    LogRocketSdk.init(LOGROCKET_ID)
    LogRocketSdk.identify(uid, { email })
  }, [uid, email])
  return null
}
