import { useState, Children } from 'react'
import { Box, Flex, Circle } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import PropTypes from 'prop-types'

export const Carousel = ({ children, ...rest }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const slidesCount = Children.count(children)

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1))
  }
  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1))
  }
  const setSlide = (slide) => {
    setCurrentSlide(slide)
  }
  const carouselStyle = {
    transition: 'all .5s',
    ml: `-${currentSlide * 100}%`,
  }

  return (
    <Flex
      w="100%"
      bg="white"
      flexDirection="column"
      borderRadius="md"
      {...rest}
    >
      <Flex w="100%" borderRadius="md">
        <Flex w="100%" {...carouselStyle}>
          {Children.map(children, (child, id) => {
            const key = `slide-${id}`
            return (
              <Box key={key} boxSize="100%" flex="none" borderRadius="md">
                {child}
              </Box>
            )
          })}
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" w="full" px={6} pb={6}>
        <ChevronLeftIcon onClick={prevSlide} color="primary.500" boxSize={6} />
        <Box>
          {Array.from({ length: slidesCount }).map((count, slide) => {
            const key = `dots-${slide}`
            return (
              <Circle
                key={key}
                cursor="pointer"
                size={2}
                ml={2}
                bg={currentSlide === slide ? 'primary.500' : 'primary.200'}
                display="inline-block"
                transition="background-color 0.6s ease"
                _hover={{ bg: 'primary.500' }}
                onClick={() => setSlide(slide)}
              />
            )
          })}
        </Box>
        <ChevronRightIcon onClick={nextSlide} color="primary.500" boxSize={6} />
      </Flex>
    </Flex>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
}
