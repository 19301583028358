import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Flex,
  Input,
  Divider,
  Text,
  VStack,
  Button,
  IconButton,
} from '@chakra-ui/react'
import { DeleteOutline } from '@mui/icons-material'
import { SettingsCard } from '../SettingsCard'

export const FunnelConfigurationTab = ({
  funnelEvents,
  saveConfiguration,
  isLoading,
}) => {
  const [eventTypeLists, setEventTypeLists] = useState({
    detail: [],
    add: [],
    checkout: [],
    purchase: [],
  })
  const [newEventTypes, setNewEventTypes] = useState({
    detail: '',
    add: '',
    checkout: '',
    purchase: '',
  })

  useEffect(() => {
    setEventTypeLists(funnelEvents)
  }, [funnelEvents])

  const handleEventTypeChange = (stage) => (event) => {
    setNewEventTypes({ ...newEventTypes, [stage]: event.target.value })
  }

  const addToEventTypeList = (stage) => {
    if (newEventTypes[stage].length > 0) {
      setEventTypeLists({
        ...eventTypeLists,
        [stage]: [...eventTypeLists[stage], newEventTypes[stage]],
      })
      setNewEventTypes({ ...newEventTypes, [stage]: '' })
    }
  }

  const handleSave = () => {
    const configToSave = {
      detail: eventTypeLists.detail.length > 0 ? eventTypeLists.detail : [],
      add: eventTypeLists.add.length > 0 ? eventTypeLists.add : [],
      checkout:
        eventTypeLists.checkout.length > 0 ? eventTypeLists.checkout : [],
      purchase:
        eventTypeLists.purchase.length > 0 ? eventTypeLists.purchase : [],
    }
    saveConfiguration(configToSave)
  }

  const deleteEventType = (stage, index) => {
    const tempList = [...eventTypeLists[stage]]
    tempList.splice(index, 1)
    setEventTypeLists({ ...eventTypeLists, [stage]: tempList })
  }

  return (
    <SettingsCard
      title="Funnel Configuration"
      subtitle="Configure event types for each funnel stage"
      isLoading={isLoading}
      saveFunction={handleSave}
      stickyHeader
      mb={16}
    >
      {['detail', 'add', 'checkout', 'purchase'].map((stage) => (
        <div key={stage}>
          <Text mt="6" fontSize="xs" fontWeight={700} color="primary.dark.400">
            {stage.charAt(0).toUpperCase() + stage.slice(1)}
          </Text>
          <Flex>
            <Input
              placeholder={`add "${
                stage.charAt(0) + stage.slice(1)
              }" event types`}
              flex="1"
              value={newEventTypes[stage]}
              onChange={handleEventTypeChange(stage)}
            />
            <Flex flex="1" justifyContent="start" alignItems="center" ml="6">
              <Button
                isLoading={isLoading}
                h="28px"
                w="120px"
                bgColor="primary.500"
                color="white"
                borderRadius="2px"
                fontWeight="700"
                fontSize="xs"
                onClick={() => addToEventTypeList(stage)}
              >
                Add
              </Button>
            </Flex>
          </Flex>
          <Text
            mx="1"
            mt="8"
            fontSize="xs"
            fontWeight={700}
            color="primary.dark.300"
          >
            Event types to: {stage.charAt(0).toUpperCase() + stage.slice(1)}
          </Text>
          <VStack spacing={3} align="stretch" mt="4">
            {eventTypeLists[stage] && eventTypeLists[stage].length > 0 ? (
              eventTypeLists[stage].map((prefix, index) => (
                <Flex
                  key={prefix}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text
                    fontWeight="500"
                    color="primary.dark.500"
                    fontSize="xs"
                    m="0"
                    pl="1"
                  >
                    {prefix}
                  </Text>
                  <IconButton
                    variant="link"
                    onClick={() => deleteEventType(stage, index)}
                    color="primary.dark.400"
                    icon={<DeleteOutline fontSize="small" />}
                    aria-label="Delete"
                  />
                </Flex>
              ))
            ) : (
              <Text fontSize="sm" color="gray.500">
                No event types added yet.
              </Text>
            )}
          </VStack>
          <Divider borderColor="gray.200" mt="8" />
        </div>
      ))}
    </SettingsCard>
  )
}

FunnelConfigurationTab.propTypes = {
  funnelEvents: PropTypes.shape({
    detail: PropTypes.arrayOf(PropTypes.string).isRequired,
    add: PropTypes.arrayOf(PropTypes.string).isRequired,
    checkout: PropTypes.arrayOf(PropTypes.string).isRequired,
    purchase: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
