import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  StackDivider,
  VStack,
} from '@chakra-ui/react'
import { MinusIcon, SearchIcon } from '@chakra-ui/icons'
import { SettingsCard } from '../SettingsCard'

export const EventsToMonitorTab = ({
  eventTypes,
  trackedEvents,
  isLoading,
  saveConfiguration,
}) => {
  const [selectedEvents, setSelectedEvents] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    setSelectedEvents(trackedEvents)
  }, [trackedEvents])

  const filteredEventTypes = useMemo(() => {
    if (searchQuery.length > 0) {
      return eventTypes.filter((eventType) =>
        eventType.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }
    return eventTypes
  }, [searchQuery, eventTypes])

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedEvents(
        selectedEvents.filter(
          (selectedEvent) => !filteredEventTypes.includes(selectedEvent)
        )
      )
    } else {
      setSelectedEvents([...filteredEventTypes])
    }

    setSelectAll(!selectAll)
  }

  const handleSave = () => {
    saveConfiguration(selectedEvents)
  }

  const handleEventToggle = (eventName) => {
    if (selectedEvents.includes(eventName)) {
      setSelectedEvents(selectedEvents.filter((item) => item !== eventName))
    } else {
      setSelectedEvents([...selectedEvents, eventName])
    }
  }

  const isEventChecked = (event) =>
    selectedEvents &&
    Array.isArray(selectedEvents) &&
    selectedEvents.includes(event)

  return (
    <SettingsCard
      title="Events to monitor"
      subtitle="Events that should be tracked"
      isLoading={isLoading}
      saveFunction={handleSave}
      stickyHeader
      mb={16}
    >
      <Flex>
        <InputGroup size="md" flex="2">
          <Input
            placeholder="Search event"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <InputRightElement pointerEvents="none" size="md">
            <SearchIcon color="primary.500" />
          </InputRightElement>
        </InputGroup>
        <Checkbox
          flex="1"
          isChecked={selectAll}
          onChange={handleSelectAll}
          cursor="pointer"
          icon={<MinusIcon color="white" />}
          ml={5}
        >
          Select all events
        </Checkbox>
      </Flex>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        overflowY="auto"
        align="start"
        mt={6}
      >
        {filteredEventTypes.map((event) => (
          <Checkbox
            key={event}
            name="selectedEvents"
            isChecked={isEventChecked(event)}
            onChange={() => handleEventToggle(event)}
          >
            {event}
          </Checkbox>
        ))}
      </VStack>
    </SettingsCard>
  )
}

EventsToMonitorTab.propTypes = {
  eventTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  trackedEvents: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
