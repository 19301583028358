import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { mutateSignup } from '../../services/api/palantir'
import { useAuthToken } from '../store/useAuthToken'
import { SharedActions } from '../../store/slices/sharedSlice'
/**
 * Sends the signup payload to Palantir.
 *
 * Also, refetches the "auth0-getUser" query if the request finishes successfully.
 *
 * @typedef {Object} SignupPayload
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} position
 * @property {string} phone
 * @property {string} companyName
 * @property {string} companySize
 * @property {string} email
 * @property {string} url
 * @property {string} auth0Id
 * */

export const useMutateSignup = () => {
  const token = useAuthToken()
  const dispatch = useDispatch()
  return useMutation({
    mutationKey: ['signup', token],
    async onMutate() {
      // Little interaction between AlreadyRegisteredGuard and useMutateSignup happens here
      // useMutateSignup sets the state to true
      // and Onboarding last step sets it back to false before redirecting
      dispatch(SharedActions.setOnboarding(true))
      dispatch(SharedActions.setBillingStarted(false))
    },
    /**
     * @param {SignupPayload} payload
     */
    mutationFn: (payload) => mutateSignup(token, payload),
  })
}
