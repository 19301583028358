import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Flex,
  Input,
  StackDivider,
  Text,
  VStack,
  Button,
  IconButton,
} from '@chakra-ui/react'
import { DeleteOutline } from '@mui/icons-material'
import { SettingsCard } from '../SettingsCard'

export const IpBlacklistConfigurationTab = ({
  ipBlacklist,
  saveConfiguration,
  isLoading,
}) => {
  const [ipList, setIpList] = useState([])
  const [ipAddress, setIpAddress] = useState('')

  useEffect(() => {
    setIpList(ipBlacklist)
  }, [ipBlacklist])

  const addToIpList = () => {
    if (ipAddress.length > 0) {
      setIpList([...ipList, ipAddress])
      setIpAddress('')
    }
  }

  const handleSave = () => {
    saveConfiguration(ipList)
  }

  const handleIpAddress = (event) => {
    setIpAddress(event.target.value)
  }

  const deleteIp = (index) => {
    const tempList = [...ipList]
    tempList.splice(index, 1)
    setIpList(tempList)
  }

  return (
    <SettingsCard
      title="IP Blacklist"
      subtitle="Description"
      isLoading={isLoading}
      saveFunction={handleSave}
      stickyHeader
      mb={16}
    >
      <Text mt="6" fontSize="xs" fontWeight={700} color="primary.dark.400">
        Add IP
      </Text>
      <Flex>
        <Input
          placeholder="IP address"
          flex="1"
          value={ipAddress}
          onChange={handleIpAddress}
        />
        <Flex flex="1" justifyContent="start" alignItems="center" ml="6">
          <Button
            isLoading={isLoading}
            h="28px"
            w="120px"
            bgColor="primary.500"
            color="white"
            borderRadius="2px"
            fontWeight="700"
            fontSize="xs"
            onClick={addToIpList}
          >
            Add
          </Button>
        </Flex>
      </Flex>
      <Text
        mx="1"
        mt="8"
        fontSize="xs"
        fontWeight={700}
        color="primary.dark.300"
      >
        IP address
      </Text>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={3}
        align="stretch"
      >
        {ipList.map((ip, index) => (
          <Flex key={ip} alignItems="center" justifyContent="space-between">
            <Text
              fontWeight="500"
              color="primary.dark.500"
              fontSize="xs"
              m="0"
              pl="1"
            >
              {ip}
            </Text>
            <IconButton
              variant="link"
              onClick={() => deleteIp(index)}
              color="primary.dark.400"
              icon={<DeleteOutline fontSize="small" />}
              aria-label="Delete"
            />
          </Flex>
        ))}
      </VStack>
    </SettingsCard>
  )
}

IpBlacklistConfigurationTab.propTypes = {
  ipBlacklist: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
