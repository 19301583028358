import { Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'

// Base by Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL

export const LoadingSpinner = ({ speed = 1, ...props }) => (
  <Box {...props}>
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#EAEAF5">
        <circle cx="42.601" cy="11.462" r="5" />
        <circle cx="49.063" cy="27.063" r="5" />
        <circle cx="42.601" cy="42.663" r="5" />
        <circle cx="27" cy="49.125" r="5" />
        <circle cx="11.399" cy="42.663" r="5" />
        <circle cx="4.938" cy="27.063" r="5" />
        <circle cx="11.399" cy="11.462" r="5" />
        <circle cx="27" cy="5" r="5" />
      </g>

      <g transform="translate(2 1)" fill="currentColor">
        <circle cx="42.601" cy="11.462" r="5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur={`${speed}s`}
            values="1;0;0;0;0;0;0;0"
            calcMode="discrete"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="49.063" cy="27.063" r="5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur={`${speed}s`}
            values="0;1;0;0;0;0;0;0"
            calcMode="discrete"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="42.601" cy="42.663" r="5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur={`${speed}s`}
            values="0;0;1;0;0;0;0;0"
            calcMode="discrete"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="27" cy="49.125" r="5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur={`${speed}s`}
            values="0;0;0;1;0;0;0;0"
            calcMode="discrete"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="11.399" cy="42.663" r="5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur={`${speed}s`}
            values="0;0;0;0;1;0;0;0"
            calcMode="discrete"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="4.938" cy="27.063" r="5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur={`${speed}s`}
            values="0;0;0;0;0;1;0;0"
            calcMode="discrete"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="11.399" cy="11.462" r="5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur={`${speed}s`}
            values="0;0;0;0;0;0;1;0"
            calcMode="discrete"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="27" cy="5" r="5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur={`${speed}s`}
            values="0;0;0;0;0;0;0;1"
            calcMode="discrete"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  </Box>
)

LoadingSpinner.propTypes = {
  speed: PropTypes.number,
}
