import jwtDecode from 'jwt-decode'
import { useMemo } from 'react'
import { useAuthToken } from '../store/useAuthToken'

/**
 * @return {string|null} The sub of the current auth token.
 */
export function useSub() {
  const token = useAuthToken()
  return useMemo(() => (!token ? null : jwtDecode(token).sub), [token])
}
