import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useAuthToken } from '../store/useAuthToken'
import { fetchCompanyProperty } from '../../services/api/palantir'

/**
 * Fetches properties for a given company.
 *
 * @param companyUUID {string}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 * @return {import("@tanstack/react-query").UseQueryResult<object, unknown>}
 */
export function useFetchPropertySettings(
  { companyUUID },
  queryOptions = undefined
) {
  const token = useAuthToken()

  const isRequestValid = !!companyUUID

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchPropertySettings: invalid request parameters.', {
        companyUUID,
      })
    }
  }, [isRequestValid, companyUUID])

  return useQuery({
    queryKey: ['propertySettings', { companyUUID }],
    queryFn: () =>
      fetchCompanyProperty(token, { companyUUID }).then((res) => res.data),
    enabled: !!token && isRequestValid,
    ...queryOptions,
  })
}
