import { yIsInstantAffectedByIncident } from '../../revend/isInstantAffectedByIncident'

/**
 * @param clickX {number}
 * @param xScale {d3.scale<number>}
 * @param incidents {{when: string, duration_in_minutes: number}[]}
 * @return {object|undefined}
 */
export const findIncidentByCoordinates = (clickX, xScale, incidents) => {
  const time = xScale.invert(clickX)
  return incidents.find(yIsInstantAffectedByIncident(+time))
}
