import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers([
    'stepper',
    'step',
    'title',
    'description',
    'indicator',
    'separator',
    'icon',
    'number',
  ])

const $size = cssVar('stepper-indicator-size')
const $iconSize = cssVar('stepper-icon-size')
const $titleFontSize = cssVar('stepper-title-font-size')
const $descFontSize = cssVar('stepper-description-font-size')
const $accentColor = cssVar('stepper-accent-color')

const baseStyle = definePartsStyle(({ colorScheme: c }) => {
  return {
    indicator: {
      color: `${c}.400`,
      borderColor: `${c}.400`,
      '&[data-status=active]': {
        borderWidth: '2px',
        borderColor: $accentColor.reference,
        bg: `${c}.300`,
        color: 'white',
      },
      '&[data-status=complete]': {
        bg: $accentColor.reference,
        color: 'chakra-inverse-text',
      },
      '&[data-status=incomplete]': {
        borderWidth: '2px',
      },
    },
  }
})

export const stepperTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    'md-2': definePartsStyle({
      stepper: {
        [$size.variable]: 'sizes.10',
        [$iconSize.variable]: 'sizes.6',
        [$titleFontSize.variable]: 'fontSizes.md',
        [$descFontSize.variable]: 'fontSizes.sm',
      },
    }),
  },
  defaultProps: {
    size: 'md-2',
    colorScheme: 'primary',
  },
})
