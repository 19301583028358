import Select from 'react-select'
import { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'
import { isDistinct } from '../../util/etc/isDistinct'

const componentStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'var(--chakra-colors-primary-100)',
      marginTop: '0.4em',
      marginBottom: '0em',
    }
  },
  multiValueLabel: (styles) => {
    return {
      ...styles,
      fontSize: '0.7em',
      width: '100%',
      padding: '0.1em',
      color: 'var(--chakra-colors-primary-500)',
    }
  },
  control: (styles) => {
    return {
      ...styles,
      cursor: 'pointer',
      width: '100%',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.101961)',
    }
  },
  option: (styles) => {
    return {
      ...styles,
      color: 'var(--chakra-colors-primary-500)',
      ':active': {
        color: 'var(--chakra-colors-primary-500)',
      },
    }
  },
}

export const MultiSelect = ({
  options,
  values,
  onChange,
  labelKey = 'label',
  valueKey = 'value',
  ...rest
}) => {
  const formattedOptions = useMemo(() => {
    const formattedOptionsValues = options.map((option) =>
      typeof option === 'string'
        ? { label: option, value: option }
        : { label: option[labelKey], value: option[valueKey] }
    )

    if (
      formattedOptionsValues.some(
        ({ label, value }) => label === undefined || value === undefined
      )
    ) {
      console.error(
        'MultiSelect: options must be string or have label and value keys'
      )
    }

    return formattedOptionsValues
  }, [options, labelKey, valueKey])

  const formattedValues = useMemo(
    () =>
      values.map((value) => {
        const labelObject = options.find((option) =>
          typeof option === 'string'
            ? option === value
            : option[valueKey] === value
        )
        const label =
          typeof labelObject === 'string'
            ? labelObject
            : labelObject?.[labelKey]

        if (!label) {
          console.error('MultiSelect: value must be one of the options')
          return []
        }

        return { value, label }
      }),
    [values, options, labelKey, valueKey]
  )

  useEffect(() => {
    if (!isDistinct(formattedOptions.map((option) => option.value))) {
      console.error('MultiSelect: options must be unique')
    }
  }, [formattedOptions])

  useEffect(() => {
    if (!isDistinct(formattedValues.map((value) => value.value))) {
      console.error('MultiSelect: values must be unique')
    }
  }, [formattedValues])

  const handleChange = (event) => {
    onChange(event.map((option) => option.value))
  }

  return (
    <Box
      as={Select}
      options={formattedOptions}
      onChange={handleChange}
      value={formattedValues}
      styles={componentStyles}
      isMulti
      {...rest}
      sx={{
        '& *[id*="listbox"]': { zIndex: '999 !important' },
        ...rest?.sx,
      }}
    />
  )
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
}
