/**
 * @param array {any[]}
 * @param keySelector {(element: any, index: number, array: any[])=>string}
 * @return {Object.<string, any>}
 */
export const groupBy = (array, keySelector) =>
  array.reduce((acc, value, index, workingArray) => {
    const key = keySelector(value, index, workingArray)

    return key in acc
      ? Object.assign(acc, { [key]: [...acc[key], value] })
      : Object.assign(acc, { [key]: [value] })
  }, {})
