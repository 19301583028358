import PropTypes from 'prop-types'
import {
  Box,
  Flex,
  Input,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderThumb,
  RangeSliderTrack,
  Select,
  Tooltip,
} from '@chakra-ui/react'
import { useState } from 'react'
import { FloatLabel } from '../../../../ui-kit/FloatLabel'

export const MaybeFloatLabel = ({ children, label, flag, ...rest }) => (
  <Box {...rest}>
    {flag ? <FloatLabel label={label}>{children}</FloatLabel> : children}
  </Box>
)

MaybeFloatLabel.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  flag: PropTypes.bool.isRequired,
}

const rangeLabelStyles = {
  mt: '3',
  ml: '-2.5',
  fontSize: 'sm',
}

export const PriorityTiersInput = ({
  name,
  icon,
  lowerBound,
  upperBound,
  setName,
  setIcon,
  setLowerBound,
  setUpperBound,
  withFloatLabels = false,
  ...rest
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleBoundariesChange = ([newLowerBound, newUpperBound]) => {
    if (newLowerBound !== lowerBound) setLowerBound(newLowerBound)
    if (newUpperBound !== upperBound) setUpperBound(newUpperBound)
  }

  return (
    <Flex gap={4} pr={6} {...rest}>
      <MaybeFloatLabel
        flag={withFloatLabels}
        label="Priority level"
        flexGrow="1"
      >
        <Input
          placeholder="Priority level"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </MaybeFloatLabel>
      <MaybeFloatLabel flag={withFloatLabels} label="Icon" flexGrow="1">
        <Select value={icon} onChange={(e) => setIcon(e.currentTarget.value)}>
          <option color="grey" hidden disabled value="">
            Icon
          </option>
          <option value="⚪️">⚪️</option>
          <option value="🔵">🔵</option>
          <option value="🟣">🟣</option>
          <option value="🟡">🟡</option>
          <option value="🟠">🟠</option>
          <option value="🔴">🔴</option>
          <option value="🟤">🟤</option>
          <option value="⚫">⚫</option>
          <option value="ℹ️">ℹ️</option>
          <option value="⚠️">⚠️</option>
          <option value="🚨">🚨</option>
        </Select>
      </MaybeFloatLabel>
      <MaybeFloatLabel flag={withFloatLabels} label="Boundaries" flexGrow="1">
        <Box h="40px">
          <RangeSlider
            value={[lowerBound, upperBound]}
            onChange={handleBoundariesChange}
            h="40px"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>

            <RangeSliderMark value={0} {...rangeLabelStyles}>
              0%
            </RangeSliderMark>
            <RangeSliderMark value={20} {...rangeLabelStyles}>
              20%
            </RangeSliderMark>
            <RangeSliderMark value={40} {...rangeLabelStyles}>
              40%
            </RangeSliderMark>
            <RangeSliderMark value={60} {...rangeLabelStyles}>
              60%
            </RangeSliderMark>
            <RangeSliderMark value={80} {...rangeLabelStyles}>
              80%
            </RangeSliderMark>
            <RangeSliderMark value={100} {...rangeLabelStyles}>
              100%
            </RangeSliderMark>

            <Tooltip
              hasArrow
              bg="primary.500"
              color="white"
              placement="top"
              isOpen={showTooltip}
              label={`${lowerBound}%`}
            >
              <RangeSliderThumb index={0} />
            </Tooltip>
            <Tooltip
              hasArrow
              bg="primary.500"
              color="white"
              placement="top"
              isOpen={showTooltip}
              label={`${upperBound}%`}
            >
              <RangeSliderThumb index={1} />
            </Tooltip>
          </RangeSlider>
        </Box>
      </MaybeFloatLabel>
    </Flex>
  )
}

PriorityTiersInput.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  lowerBound: PropTypes.number.isRequired,
  upperBound: PropTypes.number.isRequired,
  setName: PropTypes.func.isRequired,
  setIcon: PropTypes.func.isRequired,
  setLowerBound: PropTypes.func.isRequired,
  setUpperBound: PropTypes.func.isRequired,
  withFloatLabels: PropTypes.bool,
}
