import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { useMutateIncidentLabel } from '../../../hooks/palantir'

export const IncidentSlideoutLabelIncident = ({ incidentUUID }) => {
  const { mutate, isLoading } = useMutateIncidentLabel()

  const handleConfirm = () => mutate({ incidentUUID, label: 'confirmed' })
  const handleReportFalsePositive = () =>
    mutate({ incidentUUID, label: 'false_positive' })

  return (
    <Card variant="fullOutlined" borderColor="primary.300" bg="grey-lines">
      <CardHeader px={6} py={4}>
        <Heading textTransform="uppercase" fontSize={14} fontWeight={700}>
          What now?
        </Heading>
      </CardHeader>
      <CardBody px={6} py={4} borderColor="primary.300">
        <Box fontSize={12}>
          <UnorderedList pl={0}>
            <ListItem>
              Investigate using your Analytics platform, back-office systems and
              data;
            </ListItem>
            <ListItem>
              Confirm the issue or report a false positive and help Revend get
              better at knowing your shop&apos;s data.
            </ListItem>
          </UnorderedList>
          <Text>
            If you need help starting to investigate, visit our guide{' '}
            <Link href="https://revendai.notion.site/Anatomy-of-a-Revend-Notification-9df46defc1ca43dc9a894b5df059451b">
              here
            </Link>
            .
          </Text>
        </Box>
        <HStack mt={6}>
          <Button
            onClick={handleConfirm}
            colorScheme="success"
            size="sm"
            isLoading={isLoading}
          >
            Confirm issue
          </Button>
          <Button
            onClick={handleReportFalsePositive}
            colorScheme="danger"
            size="sm"
            isLoading={isLoading}
          >
            Report as false positive
          </Button>
        </HStack>
      </CardBody>
    </Card>
  )
}

IncidentSlideoutLabelIncident.propTypes = {
  incidentUUID: PropTypes.string.isRequired,
}
