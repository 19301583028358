import { useAuth0User } from './useAuth0User'

/**
 * Retrieves the list of companies from Auth0
 *
 * @param {import("@tanstack/react-query").UseQueryOptions} queryOptions
 * @param enabled {boolean}
 * @return {import("@tanstack/react-query").UseQueryResult<void, { name: string, uuid: string }[]>}
 */
export const useAuth0Companies = (
  queryOptions = undefined,
  { enabled = true } = {}
) => {
  const queryReturn = useAuth0User(queryOptions, { enabled })

  return {
    ...queryReturn,
    data: queryReturn.data && (queryReturn.data.app_metadata?.companies ?? []),
  }
}
