import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTheme } from '@chakra-ui/react'
import { MINUTE_IN_MS } from '../../../util/TimeUtils'

export const ChartIncidents = ({
  incidents,
  height,
  xScale,
  boxColor: _boxColor,
}) => {
  const theme = useTheme()
  const boxColor = _boxColor ?? theme.colors.danger

  return (
    <g>
      {incidents.map(
        ({ uuid, duration_in_minutes: durationInMinutes, when }) => {
          const start = Date.parse(when)
          const end = start + durationInMinutes * MINUTE_IN_MS
          return (
            <Rect
              key={uuid}
              boxColor={boxColor}
              xScale={xScale}
              start={start}
              end={end}
              height={height}
            />
          )
        }
      )}
    </g>
  )
}

const Rect = ({ xScale, start, end, height, boxColor }) => {
  const [opacity, setOpacity] = useState(0.2)

  return (
    <rect
      x={xScale(start)}
      height={height}
      width={xScale(end) - xScale(start)}
      fill={boxColor}
      fillOpacity={opacity}
      onMouseEnter={() => setOpacity(0.4)}
      onMouseLeave={() => setOpacity(0.2)}
    />
  )
}

Rect.propTypes = {
  xScale: PropTypes.func.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  boxColor: PropTypes.string.isRequired,
}

ChartIncidents.propTypes = {
  xScale: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  boxColor: PropTypes.string,
  incidents: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      current: PropTypes.number.isRequired,
      when: PropTypes.string.isRequired,
      duration_in_minutes: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}
