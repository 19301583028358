import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { fetchConfigPreviewerEvents } from '../../services/api/palantir'
import { useAuthToken } from '../store/useAuthToken'

/**
 * @param customerUUID {string}
 * @param eventType {string}
 * @param windowLength {number}
 * @param incidentSensitivity {number}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 */
export const useFetchConfigPreviewerEvents = (
  { customerUUID, eventType, windowLength, incidentSensitivity },
  queryOptions = undefined
) => {
  const token = useAuthToken()
  const isRequestValid =
    !!customerUUID &&
    !!eventType &&
    !!token &&
    !!windowLength &&
    !!incidentSensitivity

  useEffect(() => {
    if (!isRequestValid) {
      console.warn(
        'useFetchConfigPreviewerEvents: invalid request parameters.',
        {
          customerUUID,
          eventType,
          windowLength,
          incidentSensitivity,
        }
      )
    }
  }, [
    isRequestValid,
    customerUUID,
    eventType,
    windowLength,
    incidentSensitivity,
  ])

  return useQuery({
    queryKey: [
      'config-previewer-events',
      {
        customerUUID,
        eventType,
        windowLength,
        incidentSensitivity,
      },
    ],
    queryFn: () =>
      fetchConfigPreviewerEvents(token, {
        customerUUID,
        eventType,
        windowLength,
        incidentSensitivity,
      }).then((res) => res.data),
    enabled: !!token && isRequestValid,
    ...queryOptions,
  })
}
