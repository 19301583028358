import { Box, Flex } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCheckRouteActive } from '../../../hooks/router/useCheckRouteActive'
import { SideBar } from './SideBar'
import { ROUTE_BILLING_STATUS } from '../../../util/routes'

const whitelist = [{ priority: 0, expr: /^.*$/ }]

const blacklist = [
  { priority: 1, expr: /^\/signup$/ },
  { priority: 1, expr: /^\/billing$/ },
  { priority: 1, expr: ROUTE_BILLING_STATUS },
]

export const SideBarProvider = ({ children }) => {
  const isActive = useCheckRouteActive(whitelist, blacklist)
  return (
    <Flex h="calc(100vh - 56px)">
      {isActive && <SideBar />}
      <Box flexGrow={1} overflow="auto">
        {children}
      </Box>
    </Flex>
  )
}

SideBarProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
