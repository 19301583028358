import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { mutateIncidentLabel } from '../../services/api/palantir'

/**
 * @typedef {Object} IncidentLabelPayload
 * @property {string} incidentUUID
 * @property label {'confirmed'|'false_positive'}
 * */

/**
 * @param _ {undefined}
 * @param mutateOptions {import("@tanstack/react-query").UseMutationOptions}
 * @param onSuccess {(payload: IncidentLabelPayload)=>Promise<void>} hook called when the mutation succeeded.
 * @return {import("@tanstack/react-query").UseMutationResult<AxiosResponse<void>, unknown, IncidentLabelPayload, unknown>}
 */
export const useMutateIncidentLabel = (
  _ = undefined,
  mutateOptions = undefined,
  { onSuccess } = {}
) => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['incidentLabel', token],
    /**
     * @param {IncidentLabelPayload} payload
     */
    mutationFn: (payload) => mutateIncidentLabel(token, payload),
    async onSuccess(_, payload) {
      const { incidentUUID, label } = payload
      const data = client.getQueryData(['incident', { incidentUUID }])
      if (data) {
        client.setQueryData(['incident', { incidentUUID }], { ...data, label })
      }

      await onSuccess?.(payload)
    },
    ...mutateOptions,
  })
}
