import PropTypes from 'prop-types'
import { useMemo } from 'react'

export const ChartAnomalies = ({ xScale, yScale, data, color, radius }) => {
  const scaledData = useMemo(
    () =>
      data.map(({ timestamp, value }) => {
        return {
          y: yScale(value),
          x: xScale(timestamp),
        }
      }),
    [data, xScale, yScale]
  )

  /* eslint-disable react/no-array-index-key */
  return (
    <g>
      {scaledData.map(({ x, y }, index) => (
        <circle
          key={index}
          cx={x}
          cy={y}
          r={radius}
          fill="black"
          stroke={color}
          strokeWidth={1}
          pointerEvents="none"
        />
      ))}
    </g>
  )
  /* eslint-enable react/no-array-index-key */
}

ChartAnomalies.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  radius: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}
