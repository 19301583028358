import { useDispatch } from 'react-redux'
import {
  Box,
  Center,
  Flex,
  Heading,
  Skeleton,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { localPoint } from '@visx/event'
import {
  useFetchEventCountData,
  useFetchIncidents,
} from '../../../hooks/palantir'
import { Dot } from '../../ui-kit/Dot'
import { HOUR_IN_MS, MINUTE_IN_MS, TimeUtils } from '../../../util/TimeUtils'
import { useChartSharedTooltipData } from '../../../providers/GlobalStateProvider'
import { isBetween } from '../../../util/etc/isBetween'
import { useDashboardState } from '../../../hooks/store/useDashboardState'
import { DashboardActions, SharedActions } from '../../../store'
import { DoubleLineIncidentChart } from '../../revend-charts/DoubleLineIncidentChart'
import { yIsTimeframeAffectedByIncident } from '../../../util/revend/isTimeframeAffectedByIncident'
import { prepareIncidentAnomaliesForChart } from '../../../util/revend/prepareIncidentAnomaliesForChart'
import { useCustomerUUID } from '../../../hooks/store/useCustomerUUID'
import { yIncidentContainsAnomaly } from '../../../util/revend/incidentContainsAnomaly'

export const DashboardControlledComparisonChart = ({
  eventType,
  label,
  width,
  height,
  isExternallyLoading = false,
}) => {
  const dispatch = useDispatch()
  const [customerUUID] = useCustomerUUID()
  const {
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    zoomStartDate,
    zoomEndDate,
  } = useDashboardState()

  const compareStartWithZoom = TimeUtils.addMs(
    compareStartDate,
    Date.parse(zoomStartDate) - Date.parse(startDate)
  )
  const compareEndWithZoom = TimeUtils.addMs(
    compareEndDate,
    (Date.parse(endDate) - Date.parse(zoomEndDate)) * -1
  )

  const { data, isFetching: areEventsLoading } = useFetchEventCountData(
    { customerUUID, startDate: zoomStartDate, endDate: zoomEndDate, eventType },
    {
      keepPreviousData: true,
      staleTime: 5 * MINUTE_IN_MS,
      enabled: !isExternallyLoading,
    }
  )
  const { data: incidentsData, isFetching: areIncidentsLoading } =
    useFetchIncidents(
      { customerUUID, startDate, endDate, eventType },
      { enabled: !isExternallyLoading }
    )

  const { data: dataCompare, isFetching: areEventsLoadingCompare } =
    useFetchEventCountData(
      {
        customerUUID,
        startDate: compareStartWithZoom,
        endDate: compareEndWithZoom,
        eventType,
      },
      {
        keepPreviousData: true,
        staleTime: 5 * MINUTE_IN_MS,
        enabled: !isExternallyLoading,
      }
    )
  const { data: incidentsDataCompare, isFetching: areIncidentsLoadingCompare } =
    useFetchIncidents(
      {
        customerUUID,
        startDate: compareStartDate,
        endDate: compareEndDate,
        eventType,
      },
      { enabled: !isExternallyLoading }
    )

  const isZoomed = startDate !== zoomStartDate || endDate !== zoomEndDate
  const isLoading = isZoomed
    ? areIncidentsLoading || areIncidentsLoadingCompare || isExternallyLoading
    : areEventsLoading ||
      areIncidentsLoading ||
      areEventsLoadingCompare ||
      areIncidentsLoadingCompare ||
      isExternallyLoading

  const isUnderIncident = useMemo(() => {
    if (isLoading || !incidentsData) return false
    return [...incidentsData.opened].reverse().some((incident) => {
      const endsAt =
        TimeUtils.timeMs(incident.when) +
        incident.duration_in_minutes * MINUTE_IN_MS +
        HOUR_IN_MS
      return endsAt > new Date(endDate)
    })
  }, [endDate, incidentsData, isLoading])

  const [tooltipX, setTooltipX] = useChartSharedTooltipData('dashboard-charts')

  const handleChartHover = (event) => {
    if (!event) return setTooltipX(null)
    const { x } = localPoint(event) || { x: 0 }
    setTooltipX(x)
  }

  const handleChartSelectArea = ([start, end]) =>
    dispatch(
      DashboardActions.setZoomDateRange({
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      })
    )
  const handleDoubleClick = () =>
    dispatch(DashboardActions.resetZoomDateRange())

  const handleHoverIncident = (uuid) => {
    dispatch(SharedActions.setHoveredIncident(uuid))
  }

  const handleClickIncident = (uuid) => {
    dispatch(DashboardActions.setSelectedIncident(uuid))
  }

  const selectionAppliedData = useMemo(() => {
    return {
      base:
        data?.filter(({ TIME }) =>
          isBetween(TIME, Date.parse(zoomStartDate), Date.parse(zoomEndDate))
        ) ?? null,
      compare:
        dataCompare?.filter(({ TIME }) =>
          isBetween(
            TIME,
            compareStartWithZoom.getTime(),
            compareEndWithZoom.getTime()
          )
        ) ?? null,
    }
  }, [
    data,
    dataCompare,
    zoomStartDate,
    zoomEndDate,
    compareStartWithZoom,
    compareEndWithZoom,
  ])

  const selectionAppliedIncidentsData = useMemo(() => {
    return {
      base: (() => {
        const filter = yIsTimeframeAffectedByIncident(
          Date.parse(zoomStartDate),
          Date.parse(zoomEndDate)
        )
        const open = incidentsData?.opened?.filter(filter) ?? []
        const closed = incidentsData?.closed?.filter(filter) ?? []
        return open.concat(closed)
      })(),
      compare: (() => {
        const filter = yIsTimeframeAffectedByIncident(
          compareStartWithZoom.getTime(),
          compareEndWithZoom.getTime()
        )
        const open = incidentsDataCompare?.opened?.filter(filter) ?? []
        const closed = incidentsDataCompare?.closed?.filter(filter) ?? []
        return open.concat(closed)
      })(),
    }
  }, [
    incidentsData,
    incidentsDataCompare,
    zoomEndDate,
    zoomStartDate,
    compareStartWithZoom,
    compareEndWithZoom,
  ])

  const selectionAppliedAnomaliesData = useMemo(() => {
    return {
      base: prepareIncidentAnomaliesForChart(
        selectionAppliedIncidentsData.base,
        {
          start: Date.parse(zoomStartDate),
          end: Date.parse(zoomEndDate),
          eventType,
        }
      ),
      compare: prepareIncidentAnomaliesForChart(
        selectionAppliedIncidentsData.compare,
        {
          start: compareStartWithZoom.getTime(),
          end: compareEndWithZoom.getTime(),
          eventType,
        }
      ),
    }
  }, [
    selectionAppliedIncidentsData,
    zoomStartDate,
    zoomEndDate,
    eventType,
    compareStartWithZoom,
    compareEndWithZoom,
  ])

  const handleClickAnomaly = (anomaly) => {
    const incident = [
      ...selectionAppliedIncidentsData.base,
      ...selectionAppliedIncidentsData.compare,
    ].find(yIncidentContainsAnomaly(anomaly))

    if (incident) {
      handleClickIncident(incident.uuid)
    }
  }

  return (
    <Box>
      <Flex alignItems="center" gap={1} mb={4}>
        <Dot
          bgColor={
            // eslint-disable-next-line no-nested-ternary
            isUnderIncident ? 'danger' : isLoading ? 'grey-lines' : 'success'
          }
        />
        <Heading
          fontSize={14}
          textTransform="uppercase"
          mb={0}
          userSelect="none"
        >
          {label}
        </Heading>
      </Flex>

      <Skeleton h={height} isLoaded={!isLoading} borderRadius="lg">
        {data?.length || dataCompare?.length ? (
          <Box marginLeft="-24px">
            {!isLoading && (areEventsLoading || areEventsLoadingCompare) && (
              <Flex justifyContent="end" pr={2}>
                <Box position="absolute" zIndex={2}>
                  <Spinner w={4} h={4} />
                </Box>
              </Flex>
            )}
            <DoubleLineIncidentChart
              key={`${width}:${height}`}
              width={width + 24}
              height={height}
              chartMarginLeft={24}
              dataA={selectionAppliedData.base ?? []}
              dataB={selectionAppliedData.compare ?? []}
              incidentsA={
                areEventsLoading ? [] : selectionAppliedIncidentsData.base
              }
              incidentsB={
                areEventsLoading ? [] : selectionAppliedIncidentsData.compare
              }
              anomaliesA={
                areEventsLoading ? [] : selectionAppliedAnomaliesData.base
              }
              anomaliesB={
                areEventsLoading ? [] : selectionAppliedAnomaliesData.compare
              }
              tooltipX={tooltipX}
              onSelectArea={handleChartSelectArea}
              onChartHover={handleChartHover}
              onDoubleClick={handleDoubleClick}
              onIncidentHover={handleHoverIncident}
              onAnomalyClick={handleClickAnomaly}
              onIncidentClick={handleClickIncident}
              showAxisLeft={height >= 50}
            />
          </Box>
        ) : (
          <Box bg="#efefef" borderRadius="lg" h={height} w={width}>
            <Center h="100%">
              <VStack>
                <Text mb={0}>No data for this period yet.</Text>
                <Text fontSize={12}>
                  Change your filters or try again later.
                </Text>
              </VStack>
            </Center>
          </Box>
        )}
      </Skeleton>
    </Box>
  )
}

DashboardControlledComparisonChart.propTypes = {
  eventType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isExternallyLoading: PropTypes.bool,
}
