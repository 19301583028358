import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import jwtDecode from 'jwt-decode'
import { useAuthToken } from '../hooks/store/useAuthToken'
import { useAuth0UpdateAccessToken } from '../hooks/auth0/useAuth0UpdateAccessToken'

export const Auth0TokenProvider = ({ children }) => {
  const token = useAuthToken()
  const { isAuthenticated } = useAuth0()
  const updateToken = useAuth0UpdateAccessToken()

  useEffect(() => {
    const expiresIn = Math.max(
      token ? Date.now() - jwtDecode(token).exp * 1000 : 0,
      0
    )
    const timeout = setTimeout(() => {
      if (token) console.debug('Auth0TokenProvider: token expired, updating')
      if (isAuthenticated) updateToken()
    }, expiresIn)

    return () => clearTimeout(timeout)
  }, [isAuthenticated, token, updateToken])

  return children
}
