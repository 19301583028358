import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, HStack } from '@chakra-ui/react'
import { useFetchCompanyPropertiesInvites } from '../../../../../hooks/palantir'
import { PropertyInviteTable } from './InviteTable'
import { Pagination } from '../../../../ui-kit/Pagination'

export const PropertyInviteSettings = ({ companyUUID, onLoading }) => {
  const [invitesData, setInvitesData] = useState({
    total: 0,
    data: [],
  })

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 7

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const { isFetching: isInviteSettingsLoading } =
    useFetchCompanyPropertiesInvites(
      {
        companyUUID,
        page: currentPage - 1,
        pageSize: itemsPerPage,
        length: invitesData.total,
      },
      {
        onSuccess(data) {
          setInvitesData(data)
        },
      }
    )

  useEffect(() => {
    onLoading(isInviteSettingsLoading)
  }, [isInviteSettingsLoading, onLoading])

  return (
    <Box w="100%">
      <PropertyInviteTable
        invitesData={invitesData.data}
        companyUUID={companyUUID}
      />
      <HStack mt="1rem">
        <Pagination
          length={invitesData.total}
          handlePagination={handlePagination}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
      </HStack>
    </Box>
  )
}

PropertyInviteSettings.propTypes = {
  companyUUID: PropTypes.string,
  onLoading: PropTypes.func,
}
