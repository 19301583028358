import { useSelector } from 'react-redux'

/**
 * @return {{
 * startDate: string,
 * endDate: string,
 * zoomStartDate: string,
 * zoomEndDate: string,
 * eventTypes: string[]
 * }}
 */
export const useIncidentsPageState = () =>
  useSelector((state) => state.incidents)
