/**
 * @param needle {number}
 * @param values {number[]}
 * @return {number}
 */
export const closest = (needle, values) =>
  values.reduce((a, b) => {
    const aDiff = Math.abs(a - needle)
    const bDiff = Math.abs(b - needle)

    if (aDiff === bDiff) {
      return a > b ? a : b
    }
    return bDiff < aDiff ? b : a
  })

/**
 * @param needle {number}
 * @param values {number[]}
 * @return {number}
 */
export const closestIndex = (needle, values) =>
  values.indexOf(closest(needle, values))
