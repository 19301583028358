import PropTypes from 'prop-types'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ROUTE_DASHBOARD } from '../../../util/routes'
import { SharedActions } from '../../../store'

export const SummaryStep = ({ previous }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <>
      <Flex pr={14} flexDirection="column">
        <Text fontWeight="700" color="primary.500" fontSize="lg">
          The wait
        </Text>
        <Text mt={4} fontWeight="600" color="primary.dark.500" fontSize="md">
          You{`'`}re all set!
        </Text>
        <Text fontSize="sm" mt={4}>
          Now Revend needs{' '}
          <Text as="span" fontWeight="bold">
            21 days to train your AI model
          </Text>{' '}
          on what the normal activity on your shop looks like, before we can
          start monitoring and sending you alerts. Once your AI model is ready,
          you will receive your first notification from us.
        </Text>
        <Text fontSize="sm" mt={4}>
          Meanwhile you can access your dashboard to follow up with all metrics
          of your shop in real time.
        </Text>
      </Flex>
      <Box alignSelf="end" mt={10}>
        <Button
          variant="outline.500"
          colorScheme="primary"
          bg="white"
          borderRadius="sm"
          width="110px"
          height="32px"
          onClick={previous}
        >
          Previous
        </Button>
        <Button
          borderRadius="sm"
          width="190px"
          height="32px"
          ml={4}
          onClick={() => {
            dispatch(SharedActions.setOnboarding(false))
            navigate(ROUTE_DASHBOARD)
          }}
        >
          Go to dashboard
        </Button>
      </Box>
    </>
  )
}

SummaryStep.propTypes = {
  previous: PropTypes.func.isRequired,
}
