import PropTypes from 'prop-types'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { IoMdCalendar } from 'react-icons/io'
import { useMemo } from 'react'
import { IncidentPriorityBadge } from '../shared/incidents/IncidentPriorityBadge'
import { formatAlertTypeMessage } from '../../../util/revend/formatAlertTypeMessage'
import { deviationPercentage } from '../../../util/etc/deviationPercentage'
import { formatIncidentDateTimeRange } from '../../../util/revend/formatIncidentDateTimeRange'
import { createFunnelEventMessage } from '../../../util/revend/createFunnelEventMessage'
import { useEventTypeLabel } from '../../../hooks/etc/useEventTypeLabel'

export const DashboardIncidentCard = ({
  priority,
  current,
  minimum,
  eventType,
  anomalyEventTypes,
  startDate,
  durationInMinutes,
  closed,
  isFunnel,
  isActive,
  isHighlighted,
  ...rest
}) => {
  const eventTypeLabel = useEventTypeLabel()

  const alertType = useMemo(
    () =>
      isFunnel
        ? createFunnelEventMessage(anomalyEventTypes, eventTypeLabel)
        : eventTypeLabel(eventType),
    [anomalyEventTypes, eventType, eventTypeLabel, isFunnel]
  )

  const title = useMemo(() => {
    const verb = closed ? 'were' : 'are'
    const number = Math.round(
      Math.abs(deviationPercentage(current, minimum, 1)) * 100
    )
    const ending = formatAlertTypeMessage({ current, minimum })
    return `${alertType} ${verb} ${number}% ${ending}`
  }, [alertType, closed, current, minimum])

  const timestampString = useMemo(
    () =>
      formatIncidentDateTimeRange({
        when: startDate.toISOString(),
        duration_in_minutes: durationInMinutes,
      }),
    [startDate, durationInMinutes]
  )

  // Switch-expression equivalent. Needs to be written reversed.
  const BG_MAP = {
    [isHighlighted]: '#E9EEFF',
    [isActive]: 'grey-lines',
  }

  return (
    <Card
      borderColor={isActive ? 'primary.500' : 'grey-lines'}
      bg={BG_MAP.true}
      borderStyle="solid"
      borderWidth="1px"
      borderRadius="md"
      display="flex"
      w="256px"
      minH="158px"
      p={4}
      _hover={{ bg: '#E9EEFF' }}
      {...rest}
    >
      <CardHeader p={0}>
        <IncidentPriorityBadge priority={priority} />
        <Heading fontSize={12} fontWeight={600} mt={2}>
          {title}
        </Heading>
      </CardHeader>
      <CardBody p={0} mb={4} />
      <Spacer />
      <CardFooter as={Flex} alignItems="center" p={0}>
        <Icon as={IoMdCalendar} color="primary.dark.300" />
        <Text as="span" fontSize={12} color="primary.dark.300">
          {timestampString}
        </Text>
      </CardFooter>
    </Card>
  )
}

DashboardIncidentCard.propTypes = {
  priority: PropTypes.oneOf(['low', 'medium', 'high']).isRequired,
  current: PropTypes.number.isRequired,
  minimum: PropTypes.number.isRequired,
  eventType: PropTypes.string.isRequired,
  anomalyEventTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  durationInMinutes: PropTypes.number.isRequired,
  closed: PropTypes.bool.isRequired,
  isFunnel: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
}
