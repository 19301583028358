import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { BiExitFullscreen, BiFullscreen } from 'react-icons/bi'
import { DashboardControlledChart } from './DashboardControlledChart'
import { ChartXAxis } from '../shared/ChartXAxis'
import { GlobalStateProvider } from '../../../providers/GlobalStateProvider'
import { useDashboardState } from '../../../hooks/store/useDashboardState'
import { DashboardControlledComparisonChart } from './DashboardControlledComparisonChart'
import { useDate } from '../../../hooks/etc/useDate'
import { useCustomerUUID } from '../../../hooks/store/useCustomerUUID'
import { useEventTypeLabel } from '../../../hooks/etc/useEventTypeLabel'
import { useFullscreen } from '../../../hooks/etc/useFullscreen'
import { useFetchDashboardProperties } from '../../../hooks/palantir/useFetchDashboardProperties'

export const DashboardChartsSectionNew = (props) => {
  const [customerUUID] = useCustomerUUID()
  const { data: dashboardProperties } = useFetchDashboardProperties({
    customerUUID,
  })
  const eventLabel = useEventTypeLabel()
  const { zoomStartDate, zoomEndDate, compareStartDate } = useDashboardState()
  const ref = useRef(null)

  const start = useDate(zoomStartDate)
  const end = useDate(zoomEndDate)
  const { isFullscreen, toggleFullscreen } = useFullscreen(ref)

  const [chartHeight, setChartHeight] = useState(0)
  useEffect(() => {
    // Calculates the height of the container excluding the height of the four charts and the axis. The 8 is the margin of the axis,
    const [header, body] = [...ref.current.children] // Need to calculate the height for the header and for the body separately
    const containerHeight =
      [header, ...body.children]
        .map((it) => it.clientHeight)
        .reduce((a, b) => a + b, 0) - // Calculate the height of the container
      chartHeight * 4 + // Subtract the height of the four charts
      8 // Add the margin of the axis. This is not included in clientHeight or offsetHeight, so we need to do it manually.

    const run = () =>
      setChartHeight(
        ref.current
          ? Math.max((ref.current.offsetHeight - containerHeight - 32) / 4, 50)
          : 0
      )

    run()
    window.addEventListener('resize', run)
    return () => window.removeEventListener('resize', run)
  }, [chartHeight, isFullscreen])

  return (
    <Card
      as="section"
      px={6}
      py={4}
      ref={ref}
      borderRadius={isFullscreen ? 0 : undefined}
      overflow="auto"
      {...props}
    >
      <CardHeader p={0} pb={4} display={isFullscreen ? 'none' : undefined}>
        <Flex alignItems="center">
          <Heading fontSize={16} mb={0} flexGrow={1}>
            Funnel
          </Heading>
          <Button
            onClick={toggleFullscreen}
            variant="unstyled"
            aria-label="Fullscreen"
          >
            <Tooltip label="Fullscreen" placement="left">
              <span>
                <Icon as={BiFullscreen} justifySelf="end" />
              </span>
            </Tooltip>
          </Button>
        </Flex>
      </CardHeader>

      <CardBody p={1}>
        {isFullscreen && (
          <Button
            onClick={toggleFullscreen}
            variant="unstyled"
            aria-label="Exit Fullscreen"
            position="fixed"
            right={0}
            top={0}
          >
            <Icon as={BiExitFullscreen} fontSize={24} />
          </Button>
        )}
        {customerUUID && (
          <AutoSizer disableHeight>
            {({ width }) => (
              <GlobalStateProvider>
                <VStack w={width} gap={4} alignItems="stretch">
                  {compareStartDate ? (
                    <>
                      <DashboardControlledComparisonChart
                        width={width}
                        height={chartHeight}
                        eventType="ec.detail"
                        label={eventLabel('ec.detail', 'Detail')}
                      />
                      <DashboardControlledComparisonChart
                        width={width}
                        height={chartHeight}
                        eventType="ec.add"
                        label={eventLabel('ec.add', 'Add')}
                      />
                      <DashboardControlledComparisonChart
                        width={width}
                        height={chartHeight}
                        eventType={dashboardProperties?.checkoutEvent}
                        isExternallyLoading={!dashboardProperties}
                        label={eventLabel('ec.checkout', 'Checkout')}
                      />
                      <DashboardControlledComparisonChart
                        width={width}
                        height={chartHeight}
                        eventType="ec.purchase"
                        label={eventLabel('ec.purchase', 'Purchase')}
                      />
                    </>
                  ) : (
                    <>
                      <DashboardControlledChart
                        width={width}
                        height={chartHeight}
                        eventType="ec.detail"
                        label={eventLabel('ec.detail', 'Detail')}
                      />
                      <DashboardControlledChart
                        width={width}
                        height={chartHeight}
                        eventType="ec.add"
                        label={eventLabel('ec.add', 'Add')}
                      />
                      <DashboardControlledChart
                        width={width}
                        height={chartHeight}
                        eventType={dashboardProperties?.checkoutEvent}
                        isExternallyLoading={!dashboardProperties}
                        label={eventLabel('ec.checkout', 'Checkout')}
                      />
                      <DashboardControlledChart
                        width={width}
                        height={chartHeight}
                        eventType="ec.purchase"
                        label={eventLabel('ec.purchase', 'Purchase')}
                      />
                    </>
                  )}

                  <ChartXAxis width={width} start={start} end={end} />
                </VStack>
              </GlobalStateProvider>
            )}
          </AutoSizer>
        )}
      </CardBody>
    </Card>
  )
}
