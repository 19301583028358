import { Box, Divider, Flex } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import posthog from 'posthog-js'
import { DashboardFilterNew } from '../components/domain/dashboard/DashboardFilterNew'
import { DashboardChartsSectionNew } from '../components/domain/dashboard/DashboardChartsSectionNew'
import { DashboardIncidentsSection } from '../components/domain/dashboard/DashboardIncidentsSection'
import { DashboardWeeklyIncidentsSection } from '../components/domain/dashboard/DashboardWeeklyIncidentsSection'
import { DashboardIncidentDetailsCard } from '../components/domain/dashboard/DashboardIncidentDetailsCard'
import { useDashboardState } from '../hooks/store/useDashboardState'
import { DashboardActions } from '../store'

export const NewDashboardPage = () => {
  const dispatch = useDispatch()
  const { selectedIncident } = useDashboardState()
  const incidentSectionRef = useRef()

  useEffect(() => {
    posthog.capture('dashboard_viewed', {
      incident: incidentSectionRef?.current?.textContent,
    })
  }, [incidentSectionRef])

  useEffect(() => {
    if (selectedIncident && incidentSectionRef.current) {
      incidentSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedIncident])

  return (
    <Flex mx={6} h="full" flexDirection="column">
      <Flex h="full" gap={8} flexDirection="column">
        <DashboardFilterNew flexShrink={0} />
        <Flex flexGrow={1} gap={5} overflow={['initial', 'hidden']}>
          <DashboardChartsSectionNew
            flexGrow={1}
            bg="surface.500"
            overflow={['initial', 'auto']}
          />
          <DashboardWeeklyIncidentsSection bg="surface.500" flexShrink={0} />
        </Flex>
        <DashboardIncidentsSection flexShrink={0} />
      </Flex>
      {selectedIncident && (
        <>
          <Divider ref={incidentSectionRef} />
          <Box as="section" py={4}>
            <DashboardIncidentDetailsCard
              incidentUUID={selectedIncident}
              onClose={() =>
                dispatch(DashboardActions.setSelectedIncident(null))
              }
            />
          </Box>
        </>
      )}
    </Flex>
  )
}
