import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useAuthToken } from '../store/useAuthToken'
import {
  fetchCompanyInvites,
  fetchCompanyPropertiesInvites,
} from '../../services/api/palantir'

/**
 * Fetches invite settings for a given company.
 *
 * @param companyUUID {string}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 * @return {import("@tanstack/react-query").UseQueryResult<object, unknown>}
 */
export function useFetchCompanyInvites(
  { companyUUID, page, pageSize },
  queryOptions = undefined
) {
  const token = useAuthToken()

  const isRequestValid = !!companyUUID

  useEffect(() => {
    if (!isRequestValid) {
      console.warn('useFetchCompanyInvites: invalid request parameters.', {
        companyUUID,
      })
    }
  }, [isRequestValid, companyUUID])

  return useQuery({
    queryKey: ['companyInvites', { companyUUID, page }],
    queryFn: () =>
      fetchCompanyInvites(token, { companyUUID, page, pageSize }).then(
        (res) => res.data
      ),
    enabled: !!token && isRequestValid,
    ...queryOptions,
  })
}

/**
 * Fetches all property invites for a given company.
 *
 * @param companyUUID {string}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 * @return {import("@tanstack/react-query").UseQueryResult<object, unknown>}
 */
export function useFetchCompanyPropertiesInvites(
  { companyUUID, page, pageSize },
  queryOptions = undefined
) {
  const token = useAuthToken()

  const isRequestValid = !!companyUUID

  useEffect(() => {
    if (!isRequestValid) {
      console.warn(
        'fetchCompanyPropertiesInvites: invalid request parameters.',
        {
          companyUUID,
        }
      )
    }
  }, [isRequestValid, companyUUID])

  return useQuery({
    queryKey: ['propertyInvites', { companyUUID, page }],
    queryFn: () =>
      fetchCompanyPropertiesInvites(token, {
        companyUUID,
        page,
        pageSize,
      }).then((res) => res.data),
    enabled: !!token && isRequestValid,
    ...queryOptions,
  })
}
