import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useAuthToken } from '../store/useAuthToken'
import { fetchNotificationSettings } from '../../services/api/palantir'

/**
 * Fetches notifications settings.
 *
 * @param customerUUID {string}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 * @return {import("@tanstack/react-query").UseQueryResult<object, unknown>}
 */
export function useFetchNotificationSettings(
  { customerUUID },
  queryOptions = undefined
) {
  const token = useAuthToken()

  const isRequestValid = !!customerUUID && customerUUID !== 'undefined'

  useEffect(() => {
    if (!isRequestValid) {
      console.warn(
        'useFetchNotificationSettings: invalid request parameters.',
        { customerUUID }
      )
    }
  }, [isRequestValid, customerUUID])

  return useQuery({
    queryKey: ['notificationSettings', { customerUUID }],
    queryFn: () =>
      fetchNotificationSettings(token, { customerUUID }).then(
        (res) => res.data
      ),
    enabled: !!token && isRequestValid,
    ...queryOptions,
  })
}
