import { useQuery } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { fetchCompanies } from '../../services/api/palantir'

/**
 * Custom hook to fetch company settings.
 * @param {Object} queryOptions - Options for the query.
 * @returns {Object} - The result of the query.
 */
export function useFetchCompanies(queryOptions = undefined) {
  const token = useAuthToken()

  return useQuery({
    queryKey: ['companySettings'],
    queryFn: () => fetchCompanies(token).then((res) => res.data),
    enabled: !!token,
    ...queryOptions,
  })
}
