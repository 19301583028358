import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const outline500 = defineStyle(({ colorScheme, disabled }) => {
  if (disabled) {
    return {
      background: 'transparent',
      color: `${colorScheme}.300`,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: `${colorScheme}.300`,
      _dark: {
        color: `${colorScheme}.600`,
        borderColor: `${colorScheme}.600`,
      },
    }
  }

  return {
    background: 'transparent',
    color: `${colorScheme}.500`,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: `${colorScheme}.500`,
    _dark: {
      color: `${colorScheme}.800`,
      borderColor: `${colorScheme}.800`,
    },
    _hover: {
      color: `${colorScheme}.600`,
      borderColor: `${colorScheme}.600`,
      _dark: {
        color: `${colorScheme}.700`,
        borderColor: `${colorScheme}.700`,
      },
    },
  }
})

export const buttonTheme = defineStyleConfig({
  variants: { 'outline.500': outline500 },
  defaultProps: {
    colorScheme: 'primary',
  },
})
