import {
  Divider,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FaChevronDown } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { MonthPicker } from './FullMonthPicker'
import { useDate } from '../../hooks/etc/useDate'

export const MonthInput = ({
  value,
  onChange,
  pickerProps,
  minDate,
  maxDate,
  ...rest
}) => {
  const disclosure = useDisclosure()
  const now = useDate()

  return (
    <Popover {...disclosure} {...rest} key={Math.random()}>
      <PopoverTrigger>
        <Flex
          alignItems="center"
          borderRadius="md"
          backgroundColor="white"
          color="primary.500"
          fontWeight="normal"
          textAlign="left"
          px={2}
          py={0.5}
          w="100%"
          h={9}
          border="solid 1px"
          borderColor={disclosure.isOpen ? 'primary.500' : '#CCCCCC'}
          role="button"
        >
          <Text as="span">
            {value
              ? value.toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'long',
                })
              : ''}
          </Text>
          <Spacer />
          <Divider h="75%" orientation="vertical" />
          <Icon as={FaChevronDown} ml={2} h={3} color="#CCCCCC" />
        </Flex>
      </PopoverTrigger>
      <PopoverContent w="fit-content">
        <MonthPicker
          value={value ?? now}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          {...pickerProps}
        />
      </PopoverContent>
    </Popover>
  )
}

MonthInput.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  pickerProps: PropTypes.object,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
}
