import {
  Avatar,
  Box,
  Center,
  Circle,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@chakra-ui/react'
import { FaBars, FaChevronDown, FaRegUserCircle } from 'react-icons/fa'
import { HiOutlineLogout } from 'react-icons/hi'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../../assets/logo.svg'
import { LayoutActions } from '../../../store'

const NavBar = (props) => {
  const dispatch = useDispatch()
  const { user, logout } = useAuth0()

  const handleToggleSidebar = () => dispatch(LayoutActions.toggleSidebar())
  const isSidebarOpen = useSelector((s) => s.layout.isSidebarOpen)

  return (
    <Flex
      py={2}
      px={4}
      boxShadow="0px 1px 15px 4px rgba(216, 216, 216, 0.2)"
      {...props}
    >
      <Box flexGrow={1}>
        <Flex alignItems="center">
          <Box>
            <Circle
              as="button"
              bg="primary.500"
              p={1.5}
              transform={`rotateZ(${isSidebarOpen ? 0 : 180}deg)`}
              transition="all 250ms ease-in-out"
              onClick={handleToggleSidebar}
              cursor="pointer"
            >
              <Icon as={FaBars} fill="white" w={3} h={3} />
            </Circle>
          </Box>
          <Image src={logo} h="40px" alt="Revend logo" />
        </Flex>
      </Box>
      <Box>
        <Menu>
          <MenuButton
            as={Flex}
            alignItems="center"
            h="100%"
            cursor="pointer"
            tabIndex={0}
          >
            <Icon as={FaRegUserCircle} mr={2} w={6} h={6} />
            <Icon as={FaChevronDown} />
          </MenuButton>
          <Portal>
            <MenuList
              border="solid 1px"
              bg="white"
              borderColor="gray.200"
              borderRadius={8}
            >
              <MenuItem py={1} px={2}>
                <Flex gap={2}>
                  <Center w={8}>
                    <Avatar name={user?.name} w={8} h={8} />
                  </Center>
                  <Flex flexDirection="column">
                    <Text
                      as="span"
                      fontWeight="semibold"
                      fontSize="12px"
                      color="gray.700"
                    >
                      {user?.name}
                    </Text>
                    <Text as="span" fontSize="12px" color="gray.500">
                      {user?.email}
                    </Text>
                  </Flex>
                </Flex>
              </MenuItem>
              <MenuDivider my={1} mx={2} />
              <MenuItem
                as="button"
                px={2}
                py={1}
                _hover={{ bg: 'gray.200' }}
                onClick={() =>
                  logout({
                    logoutParams: {
                      returnTo: `${window.location.protocol}//${window.location.host}`,
                    },
                  })
                }
              >
                <Flex alignItems="center">
                  <HiOutlineLogout />
                  <Text as="span" ml={2}>
                    Logout
                  </Text>
                </Flex>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    </Flex>
  )
}

export { NavBar }
