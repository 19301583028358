import { isBetween } from '../etc/isBetween'
import { TimeUtils } from '../TimeUtils'

/**
 * Prepare the incident's anomalies to be used in the chart.
 *
 * This process includes flattening, filtering by time frame and event type, and mapping the values to the format.
 *
 * @param incidents {Array<{ anomalies: Array<{ event_type: string, current: { window_end: string, count: number } }> }>} Incidents to be prepared.
 * @param start {number} Start of the timeframe in milliseconds.
 * @param end {number} End of the timeframe in milliseconds.
 * @param eventType {string} Event type to filter the anomalies.
 * @return {{ timestamp: number, value: number }[]}
 */
export const prepareIncidentAnomaliesForChart = (
  incidents,
  { start, end, eventType }
) =>
  incidents
    .flatMap((incident) => incident.anomalies)
    .filter((anomaly) => anomaly.event_type === eventType)
    .flatMap((anomaly) => anomaly.current)
    .filter(({ window_end: windowEnd }) =>
      isBetween(TimeUtils.timeMs(windowEnd), start, end)
    )
    .map((anomaly) => {
      return {
        timestamp: TimeUtils.timeMs(anomaly.window_end),
        value: anomaly.count,
      }
    })
