import { useEffect } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAuth0 } from '@auth0/auth0-react'
import { useIntercom } from 'react-use-intercom'
import {
  SignupPage,
  SettingsPage,
  OnboardingPage,
  NewDashboardPage,
} from './pages'
import { IncidentsPage } from './pages/IncidentsPage'
import { IncidentDeeplinkHandler } from './pages/IncidentDeeplinkHandler'
import {
  ROUTE_DASHBOARD,
  ROUTE_INCIDENT_DEEPLINK,
  ROUTE_INCIDENTS,
  ROUTE_ONBOARDING,
  ROUTE_SETTINGS,
  ROUTE_SIGNUP,
  ROUTE_BILLING,
  ROUTE_BILLING_STATUS,
} from './util/routes'
import { BillingPage } from './pages/BillingPage'
import { BillingStatusPage } from './pages/BillingStatusPage'

export const AppRouter = () => {
  const flags = useFlags()
  const { user } = useAuth0()
  const { update } = useIntercom()
  const location = useLocation()
  useEffect(() => {
    const userData = user['https://app.revend.ai/user_data']
    update({
      userId: user?.sub,
      userHash: userData?.intercom_user_hash,
      avatar: {
        type: 'avatar',
        image_url: user?.picture,
      },
      name: `${userData?.first_name} ${userData?.last_name}`,
      phone: userData?.phone,
      company: {
        name: userData?.company_name,
        website: userData?.company_url,
        companyId: user?.properties?.length ? user?.properties[0]?.uuid : null,
        size: userData?.company_size,
      },
      email: user?.email,
      customAttributes: {
        verified_email: user?.email_verified,
      },
    })
  }, [location, update, user])

  return useRoutes([
    { path: '/', element: <Navigate to="/signup" replace /> },
    { path: ROUTE_SIGNUP, element: <SignupPage /> },
    { path: ROUTE_ONBOARDING, element: <OnboardingPage /> },
    { path: ROUTE_DASHBOARD, element: <NewDashboardPage /> },
    { path: ROUTE_INCIDENTS, element: <IncidentsPage /> },
    ...(flags.incidentDeeplink
      ? [
          {
            path: ROUTE_INCIDENT_DEEPLINK,
            element: <IncidentDeeplinkHandler />,
          },
        ]
      : []),
    {
      path: '/oldDashboard', // backwards compat, remove later
      element: <Navigate to={ROUTE_DASHBOARD} replace />,
    },
    {
      path: '/newDashboard', // backwards compat, remove later
      element: <Navigate to={ROUTE_DASHBOARD} replace />,
    },
    { path: `${ROUTE_SETTINGS}/*`, element: <SettingsPage /> },
    { path: ROUTE_BILLING, element: <BillingPage /> },
    { path: ROUTE_BILLING_STATUS, element: <BillingStatusPage /> },
  ])
}
