import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { mutateEmailChannelConfig } from '../../services/api/palantir'

export const useMutateEmailChannelConfig = () => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['emailChannelConfig', token],
    /**
     * @param {import('../../services/api/palantir').emailChannelConfig} payload
     */
    mutationFn: (payload) => mutateEmailChannelConfig(token, payload),
    async onSuccess(_, { customerUUID }) {
      await client.invalidateQueries({
        queryKey: ['emailChannelConfig', { customerUUID }],
      })
    },
  })
}
