import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const overridenLinesVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props

  return {
    tab: {
      _selected: {
        color: mode(`${c}.500`, `${c}.300`)(props),
        borderColor: `${c}.500`,
        borderBottom: 'solid 2px',
        mb: '-2px',
      },
    },
    tabpanel: {
      borderTop: '2px solid',
      borderColor: 'inherit',
    },
  }
})

const fullBackgroundNoBorder = definePartsStyle((props) => {
  const { colorScheme: c } = props

  return {
    tab: {
      color: `${c}.500`,
      border: 0,
      borderRadius: '6px',
      fontWeight: '500',
      _selected: {
        color: 'white',
        border: 0,
        bg: `${c}.500`,
        fontWeight: 'bold',
      },
    },
    tabpanel: {
      bg: `grey-lines`,
      borderRadius: 2,
    },
  }
})

export const tabsTheme = defineMultiStyleConfig({
  defaultProps: {
    variant: 'overridenLines',
    colorScheme: 'primary',
  },
  variants: {
    overridenLines: overridenLinesVariant,
    'fullbg.noborder': fullBackgroundNoBorder,
  },
})
