import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  HStack,
  Input,
  FormControl,
  FormErrorMessage,
  Select,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Center,
  ButtonGroup,
  ModalHeader,
} from '@chakra-ui/react'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import { useMutatePropertySettings } from '../../../../../hooks/palantir'

export const PropertyCreateModal = ({
  companyUUID,
  property,
  isOpen,
  onClose,
}) => {
  const isEdit = !!property
  const [newProperty, setNewProperty] = useState({
    name: '',
    url: '',
    timezone: 'Europe/Brussels',
    currency: 'EUR',
  })
  const [errors, setValidationErrors] = useState({})
  const isValidURL = (url) =>
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/.test(
      url
    )
  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({
    labelStyle: 'original',
    allTimezones,
  })

  useEffect(() => {
    setNewProperty({
      name: property?.name || '',
      url: property?.url || '',
      timezone: property?.timezone || 'Europe/Brussels',
      currency: property?.currency || 'EUR',
    })
  }, [property])

  const currencyOptions = ['USD', 'EUR']

  const { mutate: mutateProperty, isLoading: mutatePropertyLoading } =
    useMutatePropertySettings()

  const handleInputChange = (event) => {
    let { value } = event.target
    const { name } = event.target

    if (name === 'timezone') {
      value = parseTimezone(event.currentTarget.value).value
    }

    setValidationErrors({})
    setNewProperty({ ...newProperty, [name]: value })
  }

  const createOrUpdateProperty = (event) => {
    event.preventDefault()

    const errors = {}

    const { name, url } = newProperty

    if (name === '') {
      errors.name = 'Required field'
    }

    if (!isValidURL(url)) {
      errors.url = 'Invalid URL'
    }

    if (url === '') {
      errors.url = 'Required field'
    }

    setValidationErrors(errors)

    if (Object.keys(errors).length > 0) {
      return
    }

    mutateProperty(
      {
        companyUUID,
        propertyUUID: isEdit ? property?.uuid : null,
        propertyData: newProperty,
      },
      {
        onSuccess: () => {
          onClose()
        },
      }
    )
  }

  const cleanFields = () => {
    setNewProperty({
      name: '',
      url: '',
      timezone: 'Europe/Brussels',
      currency: 'EUR',
    })
    setValidationErrors({})
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={cleanFields}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="sm">
          {isEdit ? 'Update property' : 'New property'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={8}>
          <Box>
            <HStack my="1rem" alignItems="baseline">
              <FormControl
                variant="floating"
                id="property-name"
                isRequired
                isInvalid={errors?.name}
                w="auto"
              >
                <Input
                  name="name"
                  width="250px"
                  h="40px"
                  placeholder="Name"
                  value={newProperty.name}
                  onChange={handleInputChange}
                />
                <FormErrorMessage fontSize={10}>
                  {errors?.name}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                variant="floating"
                id="property-url"
                isRequired
                isInvalid={errors?.url}
                w="auto"
              >
                <Input
                  name="url"
                  type="email"
                  width="205px"
                  h="40px"
                  placeholder="URL"
                  value={newProperty.url}
                  onChange={handleInputChange}
                />
                <FormErrorMessage fontSize={10}>{errors?.url}</FormErrorMessage>
              </FormControl>
            </HStack>
            <HStack my="1rem" alignItems="baseline">
              <Select
                id="locationInfo"
                name="timezone"
                value={newProperty.timezone}
                onChange={handleInputChange}
                h="40px"
              >
                {timezoneOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <Select
                name="currency"
                value={newProperty.currency}
                onChange={handleInputChange}
                width="300px"
                h="40px"
              >
                {currencyOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </HStack>
          </Box>
          <Center>
            <ButtonGroup size="sm" display="flex" gap={2} my={8}>
              <Button
                onClick={onClose}
                variant="outline"
                color="primary.500"
                rounded={2}
                minW="100px"
              >
                Cancel
              </Button>
              <Button
                isLoading={mutatePropertyLoading}
                onClick={createOrUpdateProperty}
                colorScheme="primary"
                rounded={2}
                minW="100px"
              >
                {isEdit ? 'Update' : 'Add property'}
              </Button>
            </ButtonGroup>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

PropertyCreateModal.propTypes = {
  companyUUID: PropTypes.string,
  property: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
