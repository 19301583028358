import { configureStore } from '@reduxjs/toolkit'
import { tokenReducer } from './slices/tokenSlice'
import { layoutReducer } from './slices/layoutSlice'
import { dashboardReducer } from './slices/dashboardSlice'
import { sharedReducer } from './slices/sharedSlice'
import { incidentsReducer } from './slices/incidentsSlice'

export { DashboardActions } from './slices/dashboardSlice'
export { IncidentsActions } from './slices/incidentsSlice'
export { LayoutActions } from './slices/layoutSlice'
export { SharedActions } from './slices/sharedSlice'
export { TokenActions } from './slices/tokenSlice'

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    layout: layoutReducer,
    dashboard: dashboardReducer,
    incidents: incidentsReducer,
    shared: sharedReducer,
  },
})
