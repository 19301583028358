import PropTypes from 'prop-types'
import { HStack, IconButton } from '@chakra-ui/react'
import { FaTrash } from 'react-icons/fa'
import { PriorityTiersInput } from './PriorityTiersInput'
import { StoreActions } from './PriorityTiersTab.hooks'

export const PriorityTiersSection = ({ uuid, config, dispatch, ...rest }) => {
  const {
    icon,
    name,
    lower_bound: lowerBound,
    upper_bound: upperBound,
  } = config

  return (
    <HStack>
      <PriorityTiersInput
        w="100%"
        withFloatLabels
        setIcon={(value) =>
          dispatch([StoreActions.SET_KEY, [uuid, { ...config, icon: value }]])
        }
        setName={(value) =>
          dispatch([StoreActions.SET_KEY, [uuid, { ...config, name: value }]])
        }
        setLowerBound={(value) =>
          dispatch([StoreActions.SET_LOWER_BOUND, [uuid, value]])
        }
        setUpperBound={(value) =>
          dispatch([StoreActions.SET_UPPER_BOUND, [uuid, value]])
        }
        {...{ name, icon, lowerBound, upperBound }}
        {...rest}
      />
      {false && ( // Disabled for now, as the user shouldn't be able to delete a priority level yet
        <IconButton
          aria-label="Delete"
          variant="ghost"
          icon={<FaTrash />}
          onClick={() => dispatch([StoreActions.DELETE_KEY, uuid])}
        />
      )}
    </HStack>
  )
}

PriorityTiersSection.propTypes = {
  uuid: PropTypes.string.isRequired,
  config: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    lower_bound: PropTypes.number.isRequired,
    upper_bound: PropTypes.number.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
}
