import { createSlice } from '@reduxjs/toolkit'
import { validateDateRange } from '../../util/validations/validateDateRange'
import { TimeUtils, WEEK_IN_MS } from '../../util/TimeUtils'

const START_DATE = TimeUtils.startOfLocalDay(
  Date.now() - WEEK_IN_MS
).toISOString()

const END_DATE = TimeUtils.endOfLocalDay(Date.now()).toISOString()

const initialState = {
  startDate: START_DATE,
  endDate: END_DATE,
  zoomStartDate: START_DATE,
  zoomEndDate: END_DATE,
  compareStartDate: null,
  compareEndDate: null,
  selectedIncident: null,
}

const dashboardSlice = createSlice({
  name: 'dashboardFilter',
  initialState,
  reducers: {
    resetZoomDateRange(state) {
      state.zoomStartDate = state.startDate
      state.zoomEndDate = state.endDate
    },
    resetDateRange(state) {
      state.zoomStartDate = state.startDate
      state.zoomEndDate = state.endDate
    },
    setDateRange(state, action) {
      const { startDate, endDate } = action.payload
      if (startDate) {
        state.startDate = state.zoomStartDate = new Date(
          startDate
        ).toISOString()
      }
      if (endDate) {
        state.endDate = state.zoomEndDate = new Date(endDate).toISOString()
      }

      if (state.compareStartDate && state.compareEndDate) {
        if (!startDate && !endDate) {
          state.compareEndDate = state.compareStartDate = null
        }
        const selectedDateRangeInMs =
          new Date(state.endDate) - new Date(startDate)
        state.compareEndDate = TimeUtils.addMs(
          state.compareStartDate,
          selectedDateRangeInMs
        ).toISOString()
      }

      if (
        startDate &&
        endDate &&
        !validateDateRange(startDate, endDate, false)
      ) {
        console.warn('Attempting to set invalid date range', {
          startDate,
          endDate,
        })
      }
    },
    setZoomDateRange(state, action) {
      const { startDate, endDate } = action.payload
      if (startDate) state.zoomStartDate = new Date(startDate).toISOString()
      if (endDate) state.zoomEndDate = new Date(endDate).toISOString()

      if (
        startDate &&
        endDate &&
        !validateDateRange(startDate, endDate, false)
      ) {
        console.warn('Attempting to set invalid date range', {
          startDate,
          endDate,
        })
      }
    },
    setCompareWithStartDate(state, action) {
      if (!action.payload) {
        return void (state.compareEndDate = state.compareStartDate = null)
      }

      const selectedDateRangeInMs =
        new Date(state.endDate) - new Date(state.startDate)
      state.compareStartDate = new Date(action.payload).toISOString()
      state.compareEndDate = TimeUtils.addMs(
        action.payload,
        selectedDateRangeInMs
      ).toISOString()
    },
    setSelectedIncident(state, action) {
      state.selectedIncident = action.payload
    },
  },
})

export const { reducer: dashboardReducer, actions: DashboardActions } =
  dashboardSlice
