export const abbreviateTime = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  const abbreviatedTime = []
  if (days > 0) {
    abbreviatedTime.push(`${days}d`)
  }
  if (hours % 24 > 0 && days <= 15) {
    abbreviatedTime.push(`${hours % 24}h`)
  }
  if (minutes % 60 > 0 && hours <= 15 && !days) {
    abbreviatedTime.push(`${minutes % 60}m`)
  }
  if (seconds % 60 > 0 && minutes <= 15 && !hours && !days) {
    abbreviatedTime.push(`${seconds % 60}s`)
  }

  return abbreviatedTime.join('')
}
