import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { extent, max, bisector } from 'd3-array'
import { Line, LinePath } from '@visx/shape'
import { scaleTime, scaleLinear } from '@visx/scale'
import { AxisLeft } from '@visx/axis'
import PropTypes from 'prop-types'
import {
  defaultStyles,
  Tooltip,
  TooltipWithBounds,
  withTooltip,
} from '@visx/tooltip'
import { localPoint } from '@visx/event'
import { useTheme } from '@chakra-ui/react'
import { abbreviateNumber } from '../../util/etc/abbreviateNumber'
import { ChartIncidents } from './fragments/ChartIncidents'
import { HOUR_IN_MS } from '../../util/TimeUtils'
import { abbreviateTime } from '../../util/etc/abbreviateTime'
import { ChartAnomalies } from './fragments/ChartAnomalies'
import { yIsInstantAffectedByIncident } from '../../util/revend/isInstantAffectedByIncident'
import { preciseCurveLinear } from '../../util/d3/preciseCurveLinear'
import { findIncidentByCoordinates } from '../../util/d3/revend/findIncidentByCoordinates'
import { findAnomalyByCoordinates } from '../../util/d3/revend/findAnomalyByCoordinates'
import { useZoomHandler } from './hooks/useZoomHandler'

const getX = (d) => new Date(d.TIME)
const getY = (d) => d.COUNT
const SELECTION_MIN_AREA_MS = HOUR_IN_MS
const ANOMALY_CIRCLE_RADIUS = 3

const bisectDate = bisector((d) => new Date(d.TIME)).left

const DoubleLineIncidentChartComponent = ({
  // layout
  width,
  height,
  chartMarginLeft = 0,
  curve = preciseCurveLinear,
  // datum
  dataA,
  incidentsA,
  dataB,
  incidentsB,
  anomaliesA,
  anomaliesB,
  // Axis
  showAxisLeft,
  // custom event emitters
  onChartHover,
  onSelectArea,
  onIncidentHover,
  onDoubleClick,
  onIncidentClick,
  onAnomalyClick,
  // tooltip handler
  tooltipX,
  showTooltip,
  hideTooltip,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  ...rest
}) => {
  const { colors } = useTheme()

  const xScaleA = useMemo(
    () => scaleTime({ domain: extent(dataA, getX) }),
    [dataA]
  )
  const xScaleB = useMemo(
    () => scaleTime({ domain: extent(dataB, getX) }),
    [dataB]
  )
  const yScale = useMemo(
    () => scaleLinear({ domain: [0, max(dataA.concat(dataB), getY)] }),
    [dataA, dataB]
  )

  // update scale output ranges
  xScaleA.range([chartMarginLeft, width])
  xScaleB.range([chartMarginLeft, width])
  yScale.range([height, 20])

  const dedupedMergedIncidents = useMemo(() => {
    const incidents = [...incidentsA, ...incidentsB]
    const uuids = incidents.map(({ uuid }) => uuid)
    return incidents
      .filter(({ uuid }, index) => !uuids.includes(uuid, index + 1))
      .sort((a, z) => Date.parse(a.when) - Date.parse(z.when))
  }, [incidentsA, incidentsB])

  useEffect(() => {
    if (tooltipX == null) return void hideTooltip()

    const a = dataA[bisectDate(dataA, xScaleA.invert(tooltipX), 1)]
    const b = dataB[bisectDate(dataB, xScaleB.invert(tooltipX), 1)]

    if (!a && !b) return void hideTooltip()

    showTooltip({
      tooltipData: { a, b },
      tooltipLeft: tooltipX,
      tooltipTop: {
        a: a ? yScale(getY(a)) : undefined,
        b: b ? yScale(getY(b)) : undefined,
      },
    })
  }, [
    dataA,
    dataB,
    hideTooltip,
    showTooltip,
    tooltipX,
    xScaleA,
    xScaleB,
    yScale,
  ])

  const lastIncidentHoverTriggered = useRef(undefined)
  const processIncidentHover = useCallback(
    (event) => {
      if (!event) {
        if (lastIncidentHoverTriggered.current) {
          onIncidentHover?.(null)
          lastIncidentHoverTriggered.current = undefined
        }
        return
      }

      const { x } = localPoint(event) || { x: 0 }
      const timeA = xScaleA.invert(x)
      const timeB = xScaleB.invert(x)

      const filter = (incident) =>
        yIsInstantAffectedByIncident(+timeA)(incident) ||
        yIsInstantAffectedByIncident(+timeB)(incident)

      const incident = dedupedMergedIncidents.find(filter)
      if (incident?.uuid !== lastIncidentHoverTriggered.current) {
        onIncidentHover?.(incident?.uuid ?? null)
        lastIncidentHoverTriggered.current = incident?.uuid
      }
    },
    [dedupedMergedIncidents, onIncidentHover, xScaleA, xScaleB]
  )

  const [isHovering, setHovering] = useState(false)

  const zoomHandler = useZoomHandler({
    selectionMinAreaMs: SELECTION_MIN_AREA_MS,
    xScale: xScaleA,
    onSelectArea,
    data: dataA,
    getX,
  })

  const handleHoverOn = (event) => {
    onChartHover?.(event)
    setHovering(true)
    processIncidentHover(event)
    zoomHandler.handlers.hoverOn(event)
  }

  const handleHoverOff = () => {
    onChartHover?.(null)
    setHovering(false)
    processIncidentHover(null)
  }

  const axisLeftComponent = ({ y, formattedValue }) => (
    <g>
      <text x={0} y={y} fontSize={10} fill="#808899">
        {formattedValue}
      </text>
    </g>
  )

  const handleMouseDown = (event) => {
    zoomHandler.handlers.mouseDown(event)
  }

  const handleClick = (event) => {
    const { x, y } = localPoint(event) || { x: 0, y: 0 }
    if (x && y) {
      const incident =
        onIncidentClick &&
        (findIncidentByCoordinates(x, xScaleA, incidentsA) ??
          findIncidentByCoordinates(x, xScaleB, incidentsB))
      const anomaly =
        onAnomalyClick &&
        (findAnomalyByCoordinates(
          x,
          y,
          xScaleA,
          yScale,
          anomaliesA,
          ANOMALY_CIRCLE_RADIUS
        ) ??
          findAnomalyByCoordinates(
            x,
            y,
            xScaleB,
            yScale,
            anomaliesB,
            ANOMALY_CIRCLE_RADIUS
          ))
      if (incident && !anomaly) onIncidentClick?.(incident.uuid)
      if (anomaly) onAnomalyClick?.(anomaly)
    }
  }

  return (
    <>
      <svg width={width} height={height} {...rest}>
        <rect
          width={width - chartMarginLeft}
          x={chartMarginLeft}
          height={height}
          fill="#efefef"
          rx={14}
          ry={14}
        />
        <ChartIncidents
          incidents={incidentsA}
          xScale={xScaleA}
          height={height}
        />
        <ChartIncidents
          incidents={incidentsB}
          xScale={xScaleB}
          height={height}
        />
        <ChartAnomalies
          data={anomaliesA}
          xScale={xScaleA}
          yScale={yScale}
          color={colors.primary['500']}
          radius={ANOMALY_CIRCLE_RADIUS}
        />
        <ChartAnomalies
          data={anomaliesB}
          xScale={xScaleB}
          yScale={yScale}
          color={colors.primary.dark['500']}
          radius={ANOMALY_CIRCLE_RADIUS}
        />
        {showAxisLeft && (
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yScale}
            tickComponent={axisLeftComponent}
            tickFormat={abbreviateNumber}
            numTicks={Math.round(height / 30)}
            tickClassName="noUserSelect"
          />
        )}
        <LinePath
          curve={curve}
          data={dataA}
          x={(d) => xScaleA(getX(d)) ?? 0}
          y={(d) => yScale(getY(d)) ?? 0}
          stroke={colors.primary['500']}
          strokeWidth={1}
          strokeOpacity={1}
          shapeRendering="geometricPrecision"
        />
        <LinePath
          curve={curve}
          data={dataB}
          x={(d) => xScaleB(getX(d)) ?? 0}
          y={(d) => yScale(getY(d)) ?? 0}
          stroke={colors.primary.dark['500']}
          strokeWidth={1}
          strokeOpacity={1}
          shapeRendering="geometricPrecision"
        />
        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: 0 }}
              to={{ x: tooltipLeft, y: height }}
              stroke="black"
              strokeWidth={2}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
            {tooltipTop.a && (
              <>
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop.a + 1}
                  r={4}
                  fill={colors.primary['500']}
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop.a}
                  r={4}
                  fill={colors.primary['500']}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </>
            )}
            {tooltipTop.b && (
              <>
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop.b + 1}
                  r={4}
                  fill={colors.primary.dark['500']}
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop.b}
                  r={4}
                  fill={colors.primary.dark['500']}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </>
            )}
          </g>
        )}
        {zoomHandler.startPointPx && zoomHandler.endPointPx && (
          <rect
            width={Math.abs(zoomHandler.endPointPx - zoomHandler.startPointPx)}
            height={height}
            x={
              zoomHandler.startPointPx > zoomHandler.endPointPx
                ? zoomHandler.endPointPx
                : zoomHandler.startPointPx
            }
            fill="grey"
            fillOpacity={0.2}
          />
        )}
        <rect
          width={width - chartMarginLeft}
          x={chartMarginLeft}
          height={height}
          fill="transparent"
          rx={14}
          ry={14}
          onTouchStart={handleHoverOn}
          onTouchMove={handleHoverOn}
          onMouseMove={handleHoverOn}
          onMouseLeave={handleHoverOff}
          onMouseDown={handleMouseDown}
          onClick={handleClick}
          onDoubleClick={onDoubleClick}
        />
      </svg>
      {tooltipData && (
        <div>
          {tooltipData.a && (
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop.a - 22}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                color: colors.primary.text,
                userSelect: 'none',
              }}
            >
              {tooltipData.a.COUNT}
            </TooltipWithBounds>
          )}
          {tooltipData.b && (
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop.b - 22}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                color: colors.primary.text,
                userSelect: 'none',
              }}
            >
              {tooltipData.b.COUNT}
            </TooltipWithBounds>
          )}
          {isHovering && (
            <Tooltip
              top={height - 8}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 72,
                textAlign: 'center',
                transform: 'translateX(-50%)',
                userSelect: 'none',
              }}
            >
              {tooltipData.a && (
                <p
                  style={{ color: colors.primary['500'], marginBottom: '2px' }}
                >
                  {getX(tooltipData.a).toLocaleDateString(undefined, {
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </p>
              )}
              {tooltipData.b && (
                <p
                  style={{
                    color: colors.primary.dark['500'],
                    marginBottom: '0',
                  }}
                >
                  {getX(tooltipData.b).toLocaleDateString(undefined, {
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </p>
              )}
            </Tooltip>
          )}
        </div>
      )}
      {zoomHandler.startPointPx && zoomHandler.endPointPx && (
        <Tooltip
          top={0}
          left={
            zoomHandler.startPointPx > zoomHandler.endPointPx
              ? zoomHandler.startPointPx - 72
              : zoomHandler.startPointPx
          }
          style={{
            ...defaultStyles,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: colors.text,
            userSelect: 'none',
          }}
        >
          {abbreviateTime(
            Math.abs(
              zoomHandler.startPointVirtualTimestamp -
                zoomHandler.endPointVirtualTimestamp
            )
          )}
        </Tooltip>
      )}
    </>
  )
}

export const DoubleLineIncidentChart = withTooltip(
  DoubleLineIncidentChartComponent
)

DoubleLineIncidentChartComponent.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  chartMarginLeft: PropTypes.number,
  curve: PropTypes.func,

  onChartHover: PropTypes.func,
  onSelectArea: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onIncidentHover: PropTypes.func,
  onIncidentClick: PropTypes.func,
  onAnomalyClick: PropTypes.func,

  dataA: PropTypes.arrayOf(
    PropTypes.shape({
      TIME: PropTypes.number.isRequired,
      COUNT: PropTypes.number.isRequired,
      COUNT_MIN: PropTypes.number.isRequired,
      COUNT_MAX: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,

  dataB: PropTypes.arrayOf(
    PropTypes.shape({
      TIME: PropTypes.number.isRequired,
      COUNT: PropTypes.number.isRequired,
      COUNT_MIN: PropTypes.number.isRequired,
      COUNT_MAX: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,

  incidentsA: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      current: PropTypes.number.isRequired,
      when: PropTypes.string.isRequired,
      duration_in_minutes: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,

  incidentsB: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      current: PropTypes.number.isRequired,
      when: PropTypes.string.isRequired,
      duration_in_minutes: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,

  anomaliesA: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,

  anomaliesB: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,

  showAxisLeft: PropTypes.bool,

  tooltipX: PropTypes.number,
  tooltipData: PropTypes.shape({
    a: PropTypes.shape({ COUNT: PropTypes.number.isRequired }),
    b: PropTypes.shape({ COUNT: PropTypes.number.isRequired }),
  }),
  tooltipTop: PropTypes.shape({
    a: PropTypes.number,
    b: PropTypes.number,
  }),
  tooltipLeft: PropTypes.number,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
}
