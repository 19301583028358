import { QueryClient } from '@tanstack/react-query'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
    },
  },
})

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
})
