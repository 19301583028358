const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_PALANTIR_API_URL,
  REACT_APP_GOOGLE_ADAPTER_URL,
} = process.env

const config = {
  googleAdapterUrl: REACT_APP_GOOGLE_ADAPTER_URL,
  palantirUrl: REACT_APP_PALANTIR_API_URL,
  auth: {
    domain: REACT_APP_AUTH0_DOMAIN,
    clientId: REACT_APP_AUTH0_CLIENT_ID,
  },
}

export { config }
