import PropTypes from 'prop-types'
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'

const SettingsCard = ({
  children,
  subtitle,
  title,
  _headerProps,
  isLoading = false,
  isSavingDisabled = false,
  saveFunction,
  stickyHeader,
  ...rest
}) => (
  <Box
    position="relative"
    ml="4"
    bg="surface.500"
    maxW="100%"
    mr="10"
    borderRadius="10px"
  >
    <Box p={8} pt={0} {...rest}>
      <Box
        as="header"
        pb="3"
        position={stickyHeader ? 'sticky' : 'relative'}
        top={0}
        bg={isLoading ? '#E6E7E7' : 'surface.500'}
        pt={8}
        zIndex={998}
        {..._headerProps}
      >
        <Text
          fontWeight="500"
          color="primary.dark.500"
          fontSize="lg"
          lineHeight="140%"
        >
          {title}
        </Text>
        <Text
          fontWeight="400"
          color="primary.dark.300"
          fontSize="xs"
          lineHeight="140%"
          maxW="550px"
        >
          {subtitle}
        </Text>
      </Box>
      {children}
      {saveFunction && (
        <Flex
          alignItems="flex-end"
          flexDirection="column"
          position="fixed"
          bottom="0"
          right="15px"
          width="calc(100vw - var(--revend-sidebar-width) - 15px)"
          marginLeft="var(--revend-sidebar-width)"
          borderTop="1px"
          borderColor="#ccc"
          backgroundColor="white"
          paddingX={32}
          paddingY={6}
          zIndex={1000}
        >
          <Button
            isLoading={isLoading}
            h="28px"
            bgColor="primary.500"
            color="white"
            borderRadius="2px"
            fontWeight="700"
            fontSize="12px"
            onClick={saveFunction}
            isDisabled={isSavingDisabled}
          >
            Save changes
          </Button>
        </Flex>
      )}
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          bg="rgba(216, 216, 216, 0.6)"
          height="100%"
          width="100%"
          zIndex={4}
          borderRadius="10px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* TODO: Change spinner to default spinner when merged */}
          <Spinner />
        </Box>
      )}
    </Box>
  </Box>
)

SettingsCard.propTypes = {
  children: PropTypes.node.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isSavingDisabled: PropTypes.bool,
  saveFunction: PropTypes.func,
  _headerProps: PropTypes.object,
  stickyHeader: PropTypes.bool,
}

export { SettingsCard }
