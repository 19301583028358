import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import {
  mutateProperty,
  mutatePropertyDeactivate,
  mutatePropertyInvites,
  mutateUpdateProperty,
} from '../../services/api/palantir'
import { useAuth0UpdateAccessToken } from '../auth0/useAuth0UpdateAccessToken'

/**
 * Sends property payload to Palantir.
 *
 * Also, refetches the "propertySettings" query if the request finishes successfully.
 *
 * @typedef {Object} PropertySettingsPayload
 * @property {string} companyUUID
 * @property {string} userName
 * @property {string} userEmail
 * */

export const useMutatePropertySettings = () => {
  const token = useAuthToken()
  const client = useQueryClient()
  const refreshToken = useAuth0UpdateAccessToken()

  return useMutation({
    mutationKey: ['propertySettings', token],
    /**
     * @param {PropertySettingsPayload} payload
     */
    mutationFn: (payload) =>
      payload.propertyUUID
        ? mutateUpdateProperty(token, payload)
        : mutateProperty(token, payload),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ['propertySettings'],
      })
      refreshToken('off')
    },
  })
}

/**
 * Send deactivate property to Palantir.
 *
 * Also, refetches the "companyInvites" query if the request finishes successfully.
 *
 * @typedef {Object} CompanyCancelInvitePayload
 * @property {string} companyUUID
 * @property {string} propertyUUID
 * */

export const useMutatePropertyDeactivate = () => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['propertySettings', token],
    /**
     * @param {Object} payload
     */
    mutationFn: (payload) => mutatePropertyDeactivate(token, payload),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ['propertySettings'],
      })
    },
  })
}

/**
 * Sends the property invite payload to Palantir.
 *
 * Also, refetches the "propertiesInvites" query if the request finishes successfully.
 *
 * @typedef {Object} PropertyInvitePayload
 * @property {string} propertyUUID
 * @property {string} userName
 * @property {string} userEmail
 * */

export const useMutatePropertyInvites = () => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['propertyInvites', token],
    /**
     * @param {CompanyInviteSettingsPayload} payload
     */
    mutationFn: (payload) => mutatePropertyInvites(token, payload),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ['propertyInvites'],
      })
    },
  })
}
