import {
  Box,
  Spacer,
  Tab,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'

import { useEffect, useState } from 'react'
import posthog from 'posthog-js'
import { FloatLabel } from '../components/ui-kit/FloatLabel'
import { SingleSelect } from '../components/ui-kit/SingleSelect'
import { useAuth0Companies } from '../hooks/auth0'
import { NotificationSettings } from '../components/domain/customerSettings/NotificationSettings/NotificationSettings'
import { GlobalSettings } from '../components/domain/customerSettings/GlobalSettings'
import { IntegrationSettings } from '../components/domain/customerSettings/IntegrationSettings'
import { useCustomerUUID } from '../hooks/store/useCustomerUUID'
import { useURLPathSwitcher } from '../hooks/etc/useURLPathSwitcher'
import { TOP_LEVEL_SETTINGS_ROUTES } from '../util/routes'
import { useFetchCompanies } from '../hooks/palantir'
import { useFetchProperties } from '../hooks/palantir/useFetchProperties'

const SettingsPage = () => {
  const [customers, setCustomers] = useState([])
  const { data: companies } = useAuth0Companies()

  const [customerUUID, setCustomerUUID] = useCustomerUUID()
  const [companyUUID, setCompanyUUID] = useState(0)
  const [companiesData, setCompaniesData] = useState([])
  const [selectedCompany, setSelectedCompany] = useState()

  const { index: tabIndex, setByIndex } = useURLPathSwitcher({
    options:
      companies && companies.length > 0
        ? TOP_LEVEL_SETTINGS_ROUTES
        : TOP_LEVEL_SETTINGS_ROUTES.slice(1), // The order of the routes match the order of the tabs
  })

  const { isFetching: isFetchingProperties } = useFetchProperties({
    onSuccess(response) {
      setCustomers(response.data)
    },
  })

  const { isFetching: isFetchCompanyLoading } = useFetchCompanies({
    onSuccess(response) {
      setCompaniesData(response.data)
      setCompanyUUID(response.data?.[0]?.uuid ?? '')
      setSelectedCompany(
        companiesData.find((company) => company.uuid === companyUUID)
      )
    },
  })

  useEffect(() => {
    setSelectedCompany(
      companiesData.find((company) => company.uuid === companyUUID)
    )
  }, [companiesData, companyUUID])

  useEffect(() => {
    posthog.capture('visit_settings', { customerUUID, tabIndex })
  }, [customerUUID, tabIndex])

  return (
    <Tabs
      index={tabIndex}
      onChange={setByIndex}
      pt={2}
      h="full"
      overflow="hidden"
    >
      <TabList borderBottom="solid 2px var(--chakra-colors-chakra-border-color)">
        {companies && companies.length > 0 && <Tab>Global Settings</Tab>}
        <Tab>Integration Settings</Tab>
        <Tab>Notification Settings</Tab>
        <Spacer />
        <Box mr={2}>
          <Flex>
            {tabIndex === 0 && companies.length > 1 && (
              <FloatLabel label="Company">
                <SingleSelect
                  onChange={setCompanyUUID}
                  options={companiesData ?? []}
                  value={companyUUID}
                  labelKey="name"
                  valueKey="uuid"
                  w="200px"
                  mr="2"
                />
              </FloatLabel>
            )}
            {((companies && companies.length === 0) || tabIndex > 0) && (
              <FloatLabel label="Client">
                <SingleSelect
                  onChange={setCustomerUUID}
                  options={customers ?? []}
                  value={customerUUID}
                  labelKey="name"
                  valueKey="uuid"
                  w="200px"
                  isLoading={isFetchingProperties}
                  isDisabled={isFetchingProperties}
                />
              </FloatLabel>
            )}
          </Flex>
        </Box>
      </TabList>

      <TabPanels
        h="calc(100% - 48px)"
        overflow="auto"
        sx={{
          '& > div': {
            borderWidth: 0,
            h: '100%',
            p: '3rem 3rem 1rem 3rem',
          },
        }}
      >
        {companies && companies.length > 0 && (
          <TabPanel>
            <GlobalSettings
              selectedCompany={selectedCompany}
              isFetchCompanyLoading={isFetchCompanyLoading}
            />
          </TabPanel>
        )}
        <TabPanel>
          <IntegrationSettings customerUUID={customerUUID} />
        </TabPanel>
        <TabPanel>
          <NotificationSettings customerUUID={customerUUID} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export { SettingsPage }
