import { MINUTE_IN_MS } from '../TimeUtils'
import { isBetween } from '../etc/isBetween'

/**
 * Checks if a point on time is affected by an incident.
 *
 * This is a curried function.
 *
 * @param time {number}
 * @return {function({when: string, duration_in_minutes: number}): boolean} Curried function that checks if the incident affects the timeframe.
 */
export const yIsInstantAffectedByIncident =
  (time) =>
  ({ when, duration_in_minutes: durationInMinutes }) => {
    const incidentStart = Date.parse(when)
    const incidentEnd = incidentStart + durationInMinutes * MINUTE_IN_MS

    return isBetween(time, incidentStart, incidentEnd)
  }
