import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Text } from '@chakra-ui/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { returning, useRedirect } from './core'
import { useCheckRouteActive } from '../hooks/router/useCheckRouteActive'
import { useFetchIncident } from '../hooks/palantir'
import { ROUTE_DASHBOARD, ROUTE_INCIDENT_DEEPLINK } from '../util/routes'
import { FullscreenIllustration } from '../components/domain/shared/FullscreenIllustration'
import { IllustrationSearch } from '../components/illustrations'
import { useFetchProperties } from '../hooks/palantir/useFetchProperties'

const whitelist = [{ priority: 0, expr: ROUTE_INCIDENT_DEEPLINK }]

const blacklist = []

export const IncidentDeeplinkGuard = ({ children }) => {
  const { incidentDeeplink: isLdActive } = useFlags()

  const isGuardActive = useCheckRouteActive(whitelist, blacklist)
  const redirect = useRedirect()
  const navigate = useNavigate()

  const [params] = useSearchParams()
  const incidentUUID = params.get('uuid')
  const isActive = isGuardActive && isLdActive

  const [customers, setCustomers] = useState([])
  const { isFetching: isFetchingProperties } = useFetchProperties({
    onSuccess(response) {
      setCustomers(response.data)
    },
    enabled: !!incidentUUID && isActive,
  })

  const { data: incident, isLoading: isIncidentLoading } = useFetchIncident(
    { incidentUUID },
    undefined,
    { enabled: !!incidentUUID && isActive }
  )

  const isLoading = isFetchingProperties || isIncidentLoading

  useEffect(() => {
    if (isActive && !incidentUUID) {
      redirect(ROUTE_DASHBOARD)
    }
  }, [isActive, incidentUUID, redirect])

  const isForbidden = useMemo(() => {
    if (!incident || !customers) return false
    const { customer_uuid: customerUUID } = incident
    const hasCustomer = customers.some(({ uuid }) => uuid === customerUUID)
    return !hasCustomer
  }, [customers, incident])

  const isNotFound = !incident && !isLoading

  const backToDashboard = useCallback(
    () => navigate(ROUTE_DASHBOARD),
    [navigate]
  )

  const returningView = useMemo(() => {
    switch (true) {
      case !isActive: {
        return children
      }
      case isLoading: {
        return <IllustrationSearch />
      }
      case isForbidden: {
        return (
          <FullscreenIllustration
            illustration={IllustrationSearch}
            title="Forbidden"
            subtitle={
              <Text>
                You do not have access to this incident.{' '}
                <Text
                  as="a"
                  role="button"
                  aria-roledescription="Back to Dashboard"
                  color="primary.500"
                  onClick={backToDashboard}
                >
                  Back to Dashboard
                </Text>
                .
              </Text>
            }
          />
        )
      }
      case isNotFound: {
        return (
          <FullscreenIllustration
            illustration={IllustrationSearch}
            title="Not Found"
            subtitle={
              <Text>
                You do not have access to this incident.{' '}
                <Text
                  as="a"
                  role="button"
                  aria-roledescription="Back to Dashboard"
                  color="primary.500"
                  onClick={backToDashboard}
                >
                  Back to Dashboard
                </Text>
                .
              </Text>
            }
          />
        )
      }
      default:
        return children
    }
  }, [isActive, isLoading, isForbidden, isNotFound, children, backToDashboard])

  return returning(returningView, isGuardActive, isLoading)
}
