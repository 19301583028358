import { SECOND_IN_MS } from '../../util/TimeUtils'

/**
 * @param error {import("axios").AxiosError}
 * @param hookName {string}
 * @return {number}
 */
export const auth0Retrier = (error, hookName) => {
  const resetsAt = error?.response?.headers?.['x-ratelimit-reset']
  const resetsIn = resetsAt
    ? resetsAt * 1000 - Date.now() + 1000
    : 5 * SECOND_IN_MS

  console.debug(`${hookName}: Retrying in ${resetsIn}ms`)
  return resetsIn
}
