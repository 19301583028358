import { Flex } from '@chakra-ui/react'
import { IncidentsFilter } from '../components/domain/incidents/IncidentsFilter'
import { IncidentsChartsSection } from '../components/domain/incidents/IncidentsChartSection'
import { IncidentsTableSection } from '../components/domain/incidents/IncidentsTableSection'

export const IncidentsPage = () => (
  <Flex mx={6} pb={4} h="full" gap={2} flexDirection="column">
    <IncidentsFilter flexShrink={0} />
    <Flex
      flexDirection="column"
      flexGrow={1}
      gap={5}
      overflow={['initial', 'hidden']}
    >
      <IncidentsChartsSection h="50%" flexGrow={1} />
      <IncidentsTableSection h="50%" flexGrow={1} />
    </Flex>
  </Flex>
)
