import { useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { config } from '../../config'
import { TokenActions } from '../../store/slices/tokenSlice'

/**
 * @return {function(cacheMode?: 'on'|'off'|'cache-only'): Promise<void>}
 */
export const useAuth0UpdateAccessToken = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  return useCallback(
    (cacheMode) =>
      getAccessTokenSilently({
        cacheMode,
        authorizationParams: {
          audience: `https://${config.auth.domain}/api/v2/`,
          scope:
            'openid profile email read:current_user read:current_user_metadata',
        },
      })
        .then((newToken) => dispatch(TokenActions.setToken(newToken)))
        .catch(() => dispatch(TokenActions.setToken(''))),
    [dispatch, getAccessTokenSilently]
  )
}
