import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useSub } from '../hooks/etc/useSub'

export const LdLoader = () => {
  const sub = useSub()
  const ldClient = useLDClient()

  useEffect(() => {
    if (!sub) return
    ldClient
      .identify({ kind: 'user', key: sub })
      .then(() => console.info(`[LaunchDarkly] Identified as ${sub}`))
      .catch(console.error)
  }, [ldClient, sub])
}
