import { Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const FloatLabel = ({
  label,
  children,
  _wrapperProps,
  visible = true,
  ...rest
}) => (
  <Box position="relative" {..._wrapperProps}>
    {children}
    <Box
      as="label"
      position="absolute"
      top="0"
      left="0"
      transform="translate(1.1em, -50%) scale(1)"
      bg="white"
      cursor="pointer"
      px={1}
      zIndex={1}
      visibility={visible ? 'visible' : 'hidden'}
      {...rest}
    >
      {label}
    </Box>
  </Box>
)

FloatLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  _wrapperProps: PropTypes.object,
  visible: PropTypes.bool,
}
