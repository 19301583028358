import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  HStack,
  Input,
  Button,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  useFetchCompanyInvites,
  useMutateCompanyInviteSettings,
} from '../../../../../hooks/palantir'
import { CompanyInviteTable } from './InviteTable'
import { Pagination } from '../../../../ui-kit/Pagination'

export const CompanyInviteSettings = ({ companyUUID, onLoading }) => {
  const [invitesData, setInvitesData] = useState({
    total: 0,
    data: [],
  })

  const [newCollaborator, setNewCollaborator] = useState({
    userName: '',
    userEmail: '',
  })

  const [errors, setValidationErrors] = useState({})
  const isValidEmail = (email) => /(.+)@(.+){2,}\.(.+){2,}/.test(email)

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 7

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const flags = useFlags()

  const { isFetching: isInviteSettingsLoading } = useFetchCompanyInvites(
    {
      companyUUID,
      page: currentPage - 1,
      pageSize: itemsPerPage,
      length: invitesData.total,
    },
    {
      onSuccess(data) {
        setInvitesData(data)
      },
    }
  )

  const { mutate: mutateInvites, isLoading: mutateInvitesLoading } =
    useMutateCompanyInviteSettings()

  const handleInputChange = (event) => {
    const { value } = event.target
    const { name } = event.target

    setValidationErrors({})
    setNewCollaborator({ ...newCollaborator, [name]: value })
  }

  useEffect(() => {
    onLoading(isInviteSettingsLoading || mutateInvitesLoading)
  }, [isInviteSettingsLoading, mutateInvitesLoading, onLoading])

  const sendInvite = (event) => {
    event.preventDefault()

    const errors = {}

    const { userName, userEmail } = newCollaborator

    if (userName === '') {
      errors.userName = 'Required field'
    }

    if (!isValidEmail(userEmail)) {
      errors.userEmail = 'Invalid e-mail'
    }

    if (userEmail === '') {
      errors.userEmail = 'Required field'
    }

    setValidationErrors(errors)

    if (Object.keys(errors).length > 0) {
      return
    }

    mutateInvites({
      companyUUID,
      userName,
      userEmail,
      page: currentPage - 1,
      pageSize: itemsPerPage,
    })

    newCollaborator.userName = ''
    newCollaborator.userEmail = ''
  }

  return (
    flags?.numenorInvites && (
      <Box>
        <label htmlFor="addUsers">Add users</label>
        <HStack mt="0.75rem" alignItems="baseline">
          <FormControl
            variant="floating"
            id="user-name"
            isRequired
            isInvalid={errors?.userName}
            w="auto"
          >
            <Input
              name="userName"
              width="300px"
              placeholder="Name"
              value={newCollaborator.userName}
              onChange={handleInputChange}
            />
            <FormErrorMessage fontSize={10}>
              {errors?.userName}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            variant="floating"
            id="user-email"
            isRequired
            isInvalid={errors?.userEmail}
            w="auto"
            pe={2}
          >
            <Input
              name="userEmail"
              type="email"
              width="300px"
              placeholder="E-mail"
              value={newCollaborator.userEmail}
              onChange={handleInputChange}
            />
            <FormErrorMessage fontSize={10}>
              {errors?.userEmail}
            </FormErrorMessage>
          </FormControl>
          <Button
            h="30px"
            w="120px"
            ms={3}
            bgColor="primary.500"
            color="white"
            borderRadius="2px"
            fontWeight="700"
            fontSize="12px"
            onClick={sendInvite}
          >
            Send invite
          </Button>
        </HStack>
        <HStack mt="0.75rem">
          <CompanyInviteTable
            invitesData={invitesData.data}
            companyUUID={companyUUID ?? ''}
          />
        </HStack>
        <HStack mt="1rem">
          <Pagination
            length={invitesData.total}
            handlePagination={handlePagination}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />
        </HStack>
      </Box>
    )
  )
}

CompanyInviteSettings.propTypes = {
  companyUUID: PropTypes.string,
  onLoading: PropTypes.func,
}
