import { useDispatch } from 'react-redux'
import { Box, Divider, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { FloatLabel } from '../../ui-kit/FloatLabel'
import { SingleSelect } from '../../ui-kit/SingleSelect'
import { MultiSelect } from '../../ui-kit/MultiSelect'
import { useFetchEventTypes } from '../../../hooks/palantir'
import { useDate } from '../../../hooks/etc/useDate'
import { useCustomerUUID } from '../../../hooks/store/useCustomerUUID'
import { useIncidentsPageState } from '../../../hooks/store/useIncidentsPageState'
import { DateInput } from '../../ui-kit/DateInput'
import { IncidentsActions } from '../../../store'
import { useFetchProperties } from '../../../hooks/palantir/useFetchProperties'

const ecDetailObject = { id: 'ec.detail', name: 'Product detail page views' }

export const IncidentsFilter = (props) => {
  const dispatch = useDispatch()
  const min = useDate('2020-01-01')
  const max = useDate()

  const [customerUUID, setCustomerUUID] = useCustomerUUID()
  const [availableCustomerUUIDs, setAvailableCustomerUUIDs] = useState([])

  const { isFetching: isPropertiesFetching } = useFetchProperties({
    onSuccess(response) {
      setAvailableCustomerUUIDs(response.data)
    },
  })

  const { startDate, endDate, eventTypes } = useIncidentsPageState()
  const {
    data: availableEventTypes,
    isFetching: isAvailableEventTypesLoading,
  } = useFetchEventTypes(
    { customerUUID },
    {
      keepPreviousData: false,
      select(data) {
        // We are manually adding 'ec.detail' because as a business rule its always required to be available
        return eventTypes.includes('ec.detail') &&
          !data.some(({ id }) => id === 'ec.detail')
          ? [ecDetailObject, ...data]
          : data
      },
    }
  )

  return (
    <Flex gap={2} pt={4} {...props}>
      <FloatLabel
        label="Client"
        _wrapperProps={{ w: '30%', minW: '128px', maxW: '333px' }}
      >
        <SingleSelect
          onChange={(uuid) => {
            setCustomerUUID(uuid)
            dispatch(IncidentsActions.resetEventTypes())
          }}
          options={availableCustomerUUIDs ?? []}
          value={customerUUID}
          labelKey="name"
          valueKey="uuid"
          w="100%"
          minW="128px"
          maxW="333px"
          isDisabled={isPropertiesFetching}
          isLoading={isPropertiesFetching}
        />
      </FloatLabel>
      <FloatLabel
        label="Event Types"
        _wrapperProps={{ w: '30%', minW: '128px', maxW: '333px' }}
      >
        <MultiSelect
          onChange={(eventTypes) =>
            dispatch(IncidentsActions.setEventTypes(eventTypes))
          }
          options={availableEventTypes ?? [ecDetailObject]}
          values={eventTypes}
          labelKey="name"
          valueKey="id"
          isDisabled={isAvailableEventTypesLoading}
          isLoading={isAvailableEventTypesLoading}
          placeholder="Select one or more event types"
          isClearable
          w="100%"
        />
      </FloatLabel>
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <FloatLabel label="Start Date">
        <DateInput
          value={startDate}
          onChange={(startDate) =>
            dispatch(
              IncidentsActions.setDateRange({
                startDate: startDate.toISOString(),
              })
            )
          }
          min={min}
          max={max}
        />
      </FloatLabel>
      <FloatLabel label="End Date">
        <DateInput
          value={endDate}
          onChange={(endDate) =>
            dispatch(
              IncidentsActions.setDateRange({ endDate: endDate.toISOString() })
            )
          }
          min={min}
          max={max}
        />
      </FloatLabel>
    </Flex>
  )
}
