import { useMutation } from '@tanstack/react-query'
import { mutateResendVerificationEmail } from '../../services/api/palantir'
import { useAuthToken } from '../store/useAuthToken'
import { useAuth0UpdateAccessToken } from '../auth0/useAuth0UpdateAccessToken'

/**
 * Sends a call to palantir for resending the verification email.
 *
 * Also, refetches the "auth0-getUser" query if the request finishes successfully.
 *
 * @typedef {Object} VerificationEmailPayload
 * @property {string} auth0Id
 * */

export const useMutateResendVerificationEmail = () => {
  const token = useAuthToken()
  const updateToken = useAuth0UpdateAccessToken()

  return useMutation({
    mutationKey: ['verification_email', token],
    /**
     * @param {VerificationEmailPayload} payload
     */
    mutationFn: (payload) => mutateResendVerificationEmail(token, payload),
    onSuccess() {
      updateToken('off')
    },
  })
}
