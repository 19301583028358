import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useFetchIncidents } from '../../../hooks/palantir'
import { useCustomerUUID } from '../../../hooks/store/useCustomerUUID'
import { useIncidentsPageState } from '../../../hooks/store/useIncidentsPageState'
import { IncidentsTable } from './IncidentsTable'
import { LoadingSpinner } from '../../ui-kit/LoadingSpinner'
import { IllustrationOk } from '../../illustrations'

export const IncidentsTableSection = (props) => {
  const [customerUUID] = useCustomerUUID()
  const { startDate, endDate, eventTypes } = useIncidentsPageState()
  const { data: allIncidents, isLoading } = useFetchIncidents({
    customerUUID,
    startDate,
    endDate,
    eventType: eventTypes.join(','),
  })

  const { open, closed, all } = useMemo(() => {
    const open = allIncidents?.opened ?? []
    const closed = allIncidents?.closed ?? []
    const all = [...open, ...closed].sort(
      (a, b) => Date.parse(b.when) - Date.parse(a.when)
    )
    return { open, closed, all }
  }, [allIncidents])

  const renderTab = (label, incidents, height) => {
    if (isLoading) {
      return (
        <Flex
          h={height}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <LoadingSpinner color="primary.500" />
          <Heading fontSize={16} color="primary.500">
            Loading...
          </Heading>
        </Flex>
      )
    }

    if (!incidents.length) {
      return (
        <Flex
          h={height}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <IllustrationOk />
          <Heading fontSize={16} color="primary.500">
            You don&apos;t have any{label ? ` ${label} ` : ' '}incident.
          </Heading>
          <Text>Please change your filters or try again later</Text>
        </Flex>
      )
    }

    return (
      <IncidentsTable
        incidents={incidents}
        transform="translateY(-16px)"
        h={height}
      />
    )
  }

  return (
    <Box {...props}>
      <AutoSizer>
        {({ width, height }) => (
          <Box w={width} h={height}>
            <Tabs isLazy>
              <TabList>
                <Tab>All</Tab>
                <Tab>Unresolved</Tab>
                <Tab>Resolved</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>{renderTab(null, all, height - 40)}</TabPanel>
                <TabPanel>{renderTab(null, open, height - 40)}</TabPanel>
                <TabPanel>{renderTab(null, closed, height - 40)}</TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </AutoSizer>
    </Box>
  )
}
