import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Flex,
  Image,
  StackDivider,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import slackSmall from '../../../assets/slack-small.png'
import teamsSmall from '../../../assets/teams-small.png'
import gmail from '../../../assets/gmail.svg'
import { useFetchEmailChannelConfig } from '../../../hooks/palantir/useFetchEmailChannelConfig'
import { useMutateEmailChannelConfig } from '../../../hooks/palantir/useMutateEmailChannelConfig'

export const OtherIntegrationsStep = ({ customerUUID, previous, next }) => {
  const urlSlack = 'https://alerts.revend.ai/'
  const urlTeams =
    'https://revendai.notion.site/Microsoft-Teams-Integration-aa802e14a550492d9e358ba863d5245b'
  const [emailSwitch, setEmailSwitch] = useState(false)

  const { user } = useAuth0()

  const onSwitchChange = () => {
    const newSwitchValue = !emailSwitch
    mutate({
      customerUUID,
      emails:
        newSwitchValue && emailChannelConfig.emails.length === 0
          ? [user.email]
          : emailChannelConfig.emails,
      notify: newSwitchValue,
      notifyClose: emailChannelConfig.notifyClose,
    })
    setEmailSwitch(newSwitchValue)
  }

  const [emailChannelConfig, setEmailChannelConfig] = useState({
    emails: [],
    notify: false,
    notifyClose: false,
  })

  const { isFetching } = useFetchEmailChannelConfig(
    { customerUUID },
    {
      onSuccess(data) {
        setEmailChannelConfig({
          emails: data.emails,
          notify: data.notify,
          notifyClose: data.notify_close,
        })
        setEmailSwitch(data.notify)
      },
    }
  )

  const { mutate, isLoading: mutateLoading } = useMutateEmailChannelConfig()

  return (
    <>
      <Flex pr={14} flexDirection="column">
        <Text fontWeight="700" color="primary.500" fontSize="lg">
          Notification apps
        </Text>
        <Text fontSize="sm" mt={4}>
          Revend sends alerts when things are not looking normal on your shop.
          You can always find them in your Revend dashboard, but we recommend
          adding Revend to your Teams or Slack workspace to receive them as fast
          as possible and allow the whole team to huddle around potential
          problems quickly.
        </Text>
      </Flex>
      <Flex
        bg="white"
        padding={6}
        mt={10}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        borderRadius="md"
      >
        <VStack width="100%" spacing={6} divider={<StackDivider />}>
          <Flex
            justifyContent="space-between"
            width="100%"
            flexDirection="column"
          >
            <Flex alignItems="center">
              <Box
                width="fit-content"
                padding={2}
                boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.25);"
                borderRadius="md"
              >
                <Image src={slackSmall} />
              </Box>
              <Flex flexDirection="column" ml={4} mt={1}>
                <Text fontSize="sm" fontWeight="bold">
                  Slack
                </Text>
                <Text fontSize="xs" color="primary.dark.300">
                  Business communication
                </Text>
              </Flex>
              <Button
                variant="outline.500"
                colorScheme="primary"
                bg="surface.500"
                borderRadius="sm"
                width="99px"
                height="28px"
                onClick={() => window.open(urlSlack, '_blank')}
                ml="auto"
                fontSize="xs"
                lineHeight="130%"
              >
                Install
              </Button>
            </Flex>
          </Flex>
          <Flex
            justifyContent="space-between"
            width="100%"
            flexDirection="column"
          >
            <Flex alignItems="center">
              <Box
                width="fit-content"
                padding={2}
                boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.25);"
                borderRadius="md"
              >
                <Image src={teamsSmall} />
              </Box>
              <Flex flexDirection="column" ml={4} mt={1}>
                <Text fontSize="sm" fontWeight="bold">
                  Microsoft Teams
                </Text>
                <Text fontSize="xs" color="primary.dark.300">
                  Call. Chat. Colaborate.
                </Text>
              </Flex>
              <Button
                variant="outline.500"
                colorScheme="primary"
                bg="surface.500"
                borderRadius="sm"
                width="99px"
                height="28px"
                onClick={() => window.open(urlTeams, '_blank')}
                ml="auto"
                fontSize="xs"
                lineHeight="130%"
              >
                Download
              </Button>
            </Flex>
          </Flex>
        </VStack>
      </Flex>
      <Flex
        bg="white"
        padding={6}
        mt={6}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        borderRadius="md"
      >
        <Flex
          justifyContent="space-between"
          width="100%"
          flexDirection="column"
        >
          <Flex alignItems="center">
            <Box
              width="fit-content"
              padding={2}
              boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.25);"
              borderRadius="md"
            >
              <Image
                src={gmail}
                alt="Gmail logo"
                w="24px"
                display="block"
                margin="auto"
              />
            </Box>
            <Flex flexDirection="column" ml={4} mt={1}>
              <Text fontSize="sm" fontWeight="bold">
                Email
              </Text>
              <Text fontSize="xs" color="primary.dark.300">
                I want to get notifications about my shop on the email address
                provided during the registration
              </Text>
            </Flex>
            <Switch
              size="lg"
              isChecked={emailSwitch}
              isDisabled={isFetching}
              ml="auto"
              onChange={() => onSwitchChange()}
              disabled={mutateLoading}
            />
          </Flex>
        </Flex>
      </Flex>
      <Box alignSelf="end" mt={10}>
        <Button
          variant="outline.500"
          colorScheme="primary"
          bg="white"
          borderRadius="sm"
          width="110px"
          height="32px"
          onClick={previous}
        >
          Previous
        </Button>
        <Button
          borderRadius="sm"
          width="110px"
          height="32px"
          ml={4}
          isLoading={isFetching || mutateLoading}
          onClick={next}
        >
          Next
        </Button>
      </Box>
    </>
  )
}

OtherIntegrationsStep.propTypes = {
  customerUUID: PropTypes.string.isRequired,
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
}
