import { Box, Center, Circle, Flex, HStack, Icon } from '@chakra-ui/react'
import {
  useEffect,
  useMemo,
  useRef,
  useState,
  useReducer,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import PropTypes from 'prop-types'
import unitsCss from 'units-css'

export const ScrollBasedHorizontalCarousel = forwardRef(
  (
    {
      children: _children,
      isDisabled = false,
      empty,
      onEndReached,
      onEndReachedOffset,
      ...rest
    },
    ref
  ) => {
    const carouselId = useMemo(() => Math.random(), [])
    const containerRef = useRef(null)
    const itemsRef = useRef([])
    const endReachedLockedRef = useRef(false)

    const children = useMemo(() => {
      if (Array.isArray(_children)) return _children.flat().filter(Boolean)
      if (_children) return [_children.flat()].filter(Boolean)
      return []
    }, [_children])

    const [isPreviousEnabled, setIsPreviousEnabled] = useState(false)
    const [isNextEnabled, setIsNextEnabled] = useState(false)

    const [intersections, setIntersection] = useReducer(
      (state, { index, value }) => {
        const stateCopy = [...state]
        stateCopy[index] = value

        if (index === 0) setIsPreviousEnabled(!value)
        if (index === state.length - 1) setIsNextEnabled(!value)

        return stateCopy
      },
      [...new Array(children.length).fill(false)]
    )

    useEffect(() => {
      for (let i = intersections.length; i < children.length; i++) {
        setIntersection({ index: i, value: false })
      }
    }, [children.length, intersections.length])

    const observerFunction = useCallback(
      (entries) => {
        for (const entry of entries) {
          const index = parseInt(
            entry.target.id.replace(`${carouselId}-`, ''),
            10
          )
          setIntersection({ index, value: entry.isIntersecting })
        }
      },
      [carouselId]
    )

    useEffect(() => {
      if (!children.length || isDisabled) return

      const observer = new IntersectionObserver(observerFunction, {
        root: containerRef.current,
        rootMargin: '0px',
        threshold: 1.0,
      })

      for (const item of itemsRef.current.filter(Boolean)) {
        observer.observe(item)
      }
      return () => observer.disconnect()
    }, [children, children.length, isDisabled, observerFunction])

    useEffect(() => {
      if (!onEndReached || isDisabled) return

      const container = containerRef.current
      const offset = onEndReachedOffset
        ? unitsCss.convert('px', onEndReachedOffset, container, 'width')
        : 0

      const onScroll = () => {
        const isUnderOffset =
          container.scrollWidth -
            container.clientWidth -
            container.scrollLeft <=
          offset
        if (isUnderOffset && !endReachedLockedRef.current) {
          const res = onEndReached()
          endReachedLockedRef.current = true
          if (res instanceof Promise) {
            res.finally(() => (endReachedLockedRef.current = false))
          } else {
            setTimeout(() => (endReachedLockedRef.current = false), 2000)
          }
        }
      }

      onScroll()
      container.addEventListener('scroll', onScroll)
      return () => container.removeEventListener('scroll', onScroll)
    }, [children, onEndReached, isDisabled, onEndReachedOffset])

    /** @type {function(index: number, align: 'start'|'end'|'neareast'|'center'): void} */
    const focusItem = useCallback((index, align = 'start') => {
      itemsRef.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: align,
      })

      setIntersection({ index, value: true })
    }, [])

    const handlePrevious = () => focusItem(intersections.findIndex(Boolean) - 1)

    const handleNext = () => focusItem(intersections.findIndex(Boolean) + 1)

    useImperativeHandle(
      ref,
      () => {
        return {
          focusItem,
        }
      },
      [focusItem]
    )

    return (
      <Flex {...rest}>
        <Center flexShrink={0} pr={4}>
          <CarouselButton
            orientation="left"
            onClick={handlePrevious}
            isEnabled={isPreviousEnabled}
          />
        </Center>
        <HStack
          ref={containerRef}
          flexGrow={1}
          overflowX="auto"
          gap={2}
          className="hideScrollbar"
          tabIndex={-1}
          p={1}
        >
          {
            /* eslint-disable react/no-array-index-key */
            children.length
              ? (Array.isArray(children) ? children : [children]).map(
                  (it, idx) => (
                    <Box
                      id={`${carouselId}-${idx}`}
                      w="fit-content"
                      minW="fit-content"
                      key={idx}
                      ref={(element) => (itemsRef.current[idx] = element)}
                      tabIndex={0}
                    >
                      {it}
                    </Box>
                  )
                )
              : empty?.()
            /* eslint-enable react/no-array-index-key */
          }
        </HStack>
        <Center flexShrink={0} pl={4}>
          <CarouselButton
            orientation="right"
            onClick={handleNext}
            isEnabled={isNextEnabled}
          />
        </Center>
      </Flex>
    )
  }
)

const CarouselButton = ({ isEnabled, orientation, onClick }) => (
  <Circle
    bg="surface.500"
    p={3}
    cursor="pointer"
    onClick={(e) => isEnabled && onClick(e)}
  >
    <Icon
      as={orientation === 'left' ? FaChevronLeft : FaChevronRight}
      fill={isEnabled ? 'primary.500' : 'primary.dark.200'}
    />
  </Circle>
)

CarouselButton.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func.isRequired,
}

ScrollBasedHorizontalCarousel.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  empty: PropTypes.bool,
  onEndReached: PropTypes.func,
  onEndReachedOffset: PropTypes.string,
}
