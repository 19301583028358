import { useLocation, useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import { SpinnerWithText } from '../components/domain/shared/SpinnerWithText'

export const useRedirect = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const guardName = useMemo(() => {
    const { stack } = new Error()
    return stack.split('\n')[5].split('@')[0]
  }, [])

  return (to) => {
    console.debug(
      `[${guardName}] Redirecting from ${location.pathname} to ${to}`
    )
    navigate(to)
  }
}

/**
 * @param children {JSX.Element}
 * @param children
 * @param active {boolean}
 * @param isLoading {boolean}
 * @return {JSX.Element}
 */
export const returning = (children, active, isLoading) =>
  !active || !isLoading ? (
    children
  ) : (
    // eslint-disable-next-line react/jsx-filename-extension
    <SpinnerWithText>Loading...</SpinnerWithText>
  )
