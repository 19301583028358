import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const fullOutlinedVariant = definePartsStyle({
  container: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'chakra-border-color',
    boxShadow: 'none',
  },
  body: {
    borderStyle: 'solid none solid none',
    borderWidth: '1px',
    borderColor: 'chakra-border-color',
  },
})

export const cardTheme = defineMultiStyleConfig({
  variants: {
    fullOutlined: fullOutlinedVariant,
  },
})
