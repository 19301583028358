import dayjs from 'dayjs'

dayjs.extend(require('dayjs/plugin/utc'))

export const SECOND_IN_MS = 1000
export const MINUTE_IN_MS = 60 * SECOND_IN_MS
export const HOUR_IN_MS = 60 * MINUTE_IN_MS
export const DAY_IN_MS = 24 * HOUR_IN_MS
export const WEEK_IN_MS = 7 * DAY_IN_MS
export const MONTH_IN_MS = 30 * DAY_IN_MS

export class TimeUtils {
  /**
   * Timezone offset of the current browser locale, in milliseconds, relative to UTC.
   * E.g.:
   * - -10800000 for America/Sao_Paulo
   * - 0 for Etc/Greenwich
   * - +3600000 for Europe/Brussels
   */
  static TZ_OFFSET = new Date().getTimezoneOffset() * MINUTE_IN_MS * -1

  /**
   * Parses a Data object, number or date string into number of milliseconds since epoch, in UTC.
   *
   * The difference between this and Date.parse() from stdlib, is that Date.parse() assumes the current timezone if it's not explicitly identified in the string.
   *
   * @param {Date|number|string} timestamp Date string in ISO format, or milliseconds since epoch, or Date object.
   * @returns {number} Milliseconds since epoch, in UTC.
   */
  static timeMs = (timestamp) => {
    // eslint-disable-next-line default-case
    switch (typeof timestamp) {
      case 'number':
        return timestamp
      case 'string':
        return dayjs.utc(timestamp).valueOf()
    }

    if (timestamp instanceof Date) {
      return timestamp.getTime()
    }

    return NaN
  }

  /**
   * Compares two dates.
   * @param a {Date|string|number}
   * @param b {Date|string|number}
   * @return {-1|0|1}
   */
  static compare = (a, b) => {
    a = new Date(a)
    b = new Date(b)

    if (a.getTime() === b.getTime()) return 0
    return a < b ? -1 : 1
  }

  /**
   * Calculates the difference in calendar days between two dates. Doesn't return negative values.
   * @param start {Date|string|number} Start date
   * @param end {Date|string|number} End date
   * @return {number} {number} Amount of days between start and end, rounded up.
   */
  static differenceInCalendarDays = (start, end) => {
    const date1 = new Date(start)
    const date2 = new Date(end)
    const timeDiff = Math.abs(date2.getTime() - date1.getTime())
    return Math.ceil(timeDiff / DAY_IN_MS)
  }

  /**
   * Sets a date to the start of the day, in the local timezone.
   * @param date {Date|string|number}
   * @return {Date}
   */
  static startOfLocalDay = (date) => {
    date = new Date(date)
    date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
    date.setHours(0, 0, 0, 0)
    return date
  }

  /**
   * Sets a date to the end of the day, in the local timezone.
   * @param date {Date|string|number}
   * @return {Date}
   */
  static endOfLocalDay = (date) => {
    date = new Date(date)
    date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
    date.setHours(23, 59, 59, 999)
    return date
  }

  /**
   * Sets a date to the start of the week, in the local timezone.
   * @param date {Date|string|number}
   * @return {Date}
   */
  static startOfLocalWeek = (date) => {
    date = new Date(date)
    date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
    date.setHours(0, 0, 0, 0)
    date.setDate(date.getDate() - date.getDay())
    return date
  }

  /**
   * Sets a date to the end of the week, in the local timezone.
   * @param date {Date|string|number}
   * @return {Date}
   */
  static endOfLocalWeek = (date) => {
    date = new Date(date)
    date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
    date.setHours(23, 59, 59, 999)
    date.setDate(date.getDate() + (6 - date.getDay()))
    return date
  }

  /**
   * Sets a date to the start of the month, in the local timezone.
   * @param date {Date|string|number}
   * @return {Date}
   */
  static startOfLocalMonth = (date) => {
    date = new Date(date)
    date.setFullYear(date.getFullYear(), date.getMonth(), 1)
    date.setHours(0, 0, 0, 0)
    return date
  }

  /**
   * Sets a date to the end of the month, in the local timezone.
   * @param date {Date|string|number}
   * @return {Date}
   */
  static endOfLocalMonth = (date) => {
    date = new Date(date)
    date.setFullYear(date.getFullYear(), date.getMonth() + 1, 0)
    date.setHours(23, 59, 59, 999)
    return date
  }

  /**
   * Adds milliseconds to a date.
   * @param date {Date|string|number}
   * @param millis {number}
   * @return {Date}
   */
  static addMs = (date, millis) => new Date(new Date(date).getTime() + millis)

  /**
   * Adds days to a date.
   * @param date {Date|string|number}
   * @param days {number}
   * @return {Date}
   */
  static addDays = (date, days) => TimeUtils.addMs(date, DAY_IN_MS * days)

  /**
   * Returns the provided date in the full-date format: YYYY-MM-DD.
   *
   * @see https://www.w3.org/TR/2011/WD-html-markup-20110405/input.date.html#input.date.attrs.value
   * @see https://www.rfc-editor.org/rfc/rfc3339#section-5.6
   *
   * @param date {Date|string|number}
   * @return {string}
   */
  static toLocalFullDateString = (date) => {
    if (date instanceof Date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    }

    return TimeUtils.toLocalFullDateString(new Date(TimeUtils.timeMs(date)))
  }

  /**
   * Calculates the start and end of the week (7 days interval) for a given date.
   *
   * @param {Date} date - The date for which the week range is to be calculated.
   * @return {[Date, Date]} An array containing two Date objects. The first element
   * is the Date object representing Monday of the week, and the second element is
   * the Date object representing Sunday of the same week.
   */
  static getWeekRange = (date) => {
    const currentDate = new Date(date.getTime())
    const currentDay = currentDate.getUTCDay()
    const daysToMonday = currentDay === 0 ? -6 : 1 - currentDay
    currentDate.setUTCDate(currentDate.getUTCDate() + daysToMonday)

    const monday = new Date(currentDate)
    currentDate.setUTCDate(monday.getUTCDate() + 6)
    const sunday = new Date(currentDate)

    return [monday, sunday]
  }

  /**
   * Returns a date object formated as 24 hours and 2 digits minutes (HH:MM)
   *
   * @static
   * @param ts {number}
   * @param timezone {string|object|array}
   * @return { Date }
   */
  static formatHour = (ts) => {
    const formatDate = new Date(ts).toLocaleString([], {
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit',
    })

    return formatDate
  }
}
