import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  Text,
  Image,
  ModalHeader,
  Input,
  Select,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  IconButton,
  Td,
  TableContainer,
} from '@chakra-ui/react'
import { DeleteOutline } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import googleLogo from '../../../../assets/google-logo.svg'
import { config } from '../../../../config'
import { useFetchGoogleAccountInfo } from '../../../../hooks/palantir/useFetchGoogleAccountInfo'
import { useFetchGoogleAccounts } from '../../../../hooks/palantir/useFetchGoogleAccounts'
import { useFetchGoogleConfig } from '../../../../hooks/palantir/useFetchGoogleConfig'
import { useMutateGoogleConfig } from '../../../../hooks/palantir/useMutateGoogleConfig'
import { useMutateGoogleDisconnect } from '../../../../hooks/palantir/useMutateGoogleDisconnect'
import { FloatLabel } from '../../../ui-kit/FloatLabel'

const googleAdapterURL = config.googleAdapterUrl
/**
 * GoogleModal component displays a modal dialog with google configurations.
 *
 * @component
 * @param {string} titleText - The title of the confirmation modal.
 * @param {string} subtitleText - The subtitle text of the confirmation modal. By default, it is set to 'Are you sure you want to continue with your action?'.
 * @param {string} applyBtnText - The text for the apply button. By default, it is set to 'OK'.
 * @param {string} cancelBtnText - The text for the cancel button. By default, it is set to 'Cancel'.
 * @param {boolean} isOpen - Determines whether the modal is open or closed. Required
 * @param {Function} onClose - The function to be called when the modal is closed. Required
 * @param {Function} onApply - The function to be called when the apply button is clicked. Required
 * @returns {JSX.Element} The rendered ConfirmationModal component.
 */
export const GoogleModal = ({ isOpen, onClose, customerUUID }) => {
  const [isConnected, setIsConnected] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const handleConnect = () => {
    setIsConnecting(true)
    openPopup()
  }

  const { mutate: mutateDisconnect, isLoading: isDisconnecting } =
    useMutateGoogleDisconnect()
  const { mutate: mutateConfig, isLoading: loadingMutateConfig } =
    useMutateGoogleConfig()
  const handleDisconnect = () => {
    mutateDisconnect({ customerUUID })
  }

  const userIdentificationOptions = ['On', 'Off', 'Only with consent']
  const [conversionActions, setConversionActions] = useState([])
  const [allEvents, setAllEvents] = useState([])
  const [availableEvents, setAvailableEvents] = useState([])
  const [selectedConversionAction, setSelectedConversionAction] = useState('')
  const [selectedEvent, setSelectedEvent] = useState('')
  const [accountEvents, setAccountEvents] = useState({})
  const [googleAccountData, setGoogleAccountData] = useState({
    googleAdAccount: '',
    loginCustomerId: '',
    measurementId: '',
    events: [],
    userConsent: 'On',
    propertyUUID: customerUUID,
  })

  const [popupWindow, setPopupWindow] = useState(null)
  const [accounts, setAccounts] = useState([])

  const { refetch: refetchAccounts, isFetching: isFetchingAccounts } =
    useFetchGoogleAccounts(
      { customerUUID },
      {
        onSuccess(data) {
          setIsConnected(data.connected)
          setAccounts(data.accounts)
        },
      }
    )

  useFetchGoogleConfig(
    { customerUUID },
    {
      onSuccess(data) {
        const accountData = {
          measurementId: data?.measurement_id || '',
          userConsent: data?.user_consent || 'On',
          googleAdAccount: data?.google_ad_account || '',
          loginCustomerId: data?.login_customer_id || '',
          propertyUUID: customerUUID,
        }

        // Initialize the account events map with the stored events
        if (data?.google_ad_account && data?.events) {
          setAccountEvents((prev) => {
            return {
              ...prev,
              [data.google_ad_account]: data.events,
            }
          })
        }

        // Only set the account if it exists in the available accounts
        if (data?.google_ad_account) {
          const accountExists = accounts.find(
            (account) => account.account_id === data.google_ad_account
          )
          if (!accountExists) {
            accountData.googleAdAccount = ''
            accountData.loginCustomerId = ''
          }
        }

        setGoogleAccountData(accountData)
      },
    }
  )
  const { refetch: refetchGAInfo, isFetching: isFetchingGAInfo } =
    useFetchGoogleAccountInfo(
      {
        customerUUID,
        accountID: googleAccountData.googleAdAccount,
        loginCustomerId: googleAccountData.loginCustomerId,
      },
      {
        enabled: false,
        onSuccess(data) {
          if (data?.conversion_actions) {
            setConversionActions(data.conversion_actions)
            if (data?.conversion_actions?.length > 0) {
              setSelectedConversionAction(data?.conversion_actions?.[0]?.id)
            }
          }
          if (data?.events) {
            setAllEvents(data.events)
            if (data?.events?.length > 0) {
              setSelectedEvent(data.events[0].id)
            }
          }
        },
      }
    )
  useEffect(() => {
    if (
      googleAccountData.googleAdAccount.length === 0 ||
      !googleAccountData.googleAdAccount
    ) {
      return
    }

    refetchGAInfo()
  }, [googleAccountData.googleAdAccount, refetchGAInfo, accounts])

  useEffect(() => {
    const currentAccountEvents =
      accountEvents[googleAccountData.googleAdAccount] || []
    const availableEvents = allEvents.filter(
      (event) => !currentAccountEvents.find((e) => e.event_suffix === event.id)
    )

    setAvailableEvents(availableEvents)
    setSelectedEvent(availableEvents[0]?.id || '')
  }, [allEvents, accountEvents, googleAccountData.googleAdAccount])

  const openPopup = () => {
    const width = 500
    const height = 600
    const left = window.screen.width / 2 - width / 2
    const top = window.screen.height / 2 - height / 2
    const popup = window.open(
      `${googleAdapterURL}/authorize?property_uuid=${customerUUID}`,
      'Google Login',
      `width=${width},height=${height},left=${left},top=${top},popup=1,toolbar=0`
    )
    setPopupWindow(popup)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        clearInterval(interval)
        setIsConnecting(false)
        refetchAccounts()
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [popupWindow, refetchAccounts])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setGoogleAccountData({
      ...googleAccountData,
      [name]: value,
    })
  }

  const handleSelectGoogleAccount = (event) => {
    const selectedAccountId = event.target.value
    if (!selectedAccountId) {
      setGoogleAccountData({
        ...googleAccountData,
        googleAdAccount: '',
        loginCustomerId: '',
      })
      setSelectedConversionAction('')
      setSelectedEvent('')
      setConversionActions([])
      setAllEvents([])
      return
    }

    const loginCustomerId = accounts.find(
      (account) => account.account_id === selectedAccountId
    )?.login_customer_id

    setGoogleAccountData({
      ...googleAccountData,
      googleAdAccount: selectedAccountId,
      loginCustomerId,
    })
  }

  const addConversionAction = () => {
    const actionLabel = conversionActions.find(
      (ca) => ca.id === selectedConversionAction
    ).name

    const currentAccountId = googleAccountData.googleAdAccount
    const currentEvents = accountEvents[currentAccountId] || []

    setAccountEvents({
      ...accountEvents,
      [currentAccountId]: [
        ...currentEvents,
        {
          event_suffix: selectedEvent,
          action_id: selectedConversionAction,
          action_name: actionLabel,
        },
      ],
    })
  }

  const handleRemoveEvent = (uuid) => {
    const currentAccountId = googleAccountData.googleAdAccount
    const currentEvents = accountEvents[currentAccountId] || []

    setAccountEvents({
      ...accountEvents,
      [currentAccountId]: currentEvents.filter(
        (event, index) => index !== uuid
      ),
    })
  }

  // Update the handleSave function to use the current account's events
  const handleSave = () => {
    const currentAccountEvents =
      accountEvents[googleAccountData.googleAdAccount] || []
    mutateConfig({
      ...googleAccountData,
      events: currentAccountEvents,
    })
  }

  // In the render method, use the current account's events for the table
  const currentAccountEvents =
    accountEvents[googleAccountData.googleAdAccount] || []

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent px={10} py={6}>
        <ModalHeader p={0}>
          <Flex gap="4" alignItems="center">
            <Flex
              width="40px"
              height="40px"
              boxShadow="md"
              borderRadius="md"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="24px" h="24px" src={googleLogo} />
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize="18">
                Google
              </Text>
            </Box>
          </Flex>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody px={0} py={4}>
          <Flex
            alignItems="center"
            px={6}
            py={3}
            borderRadius="md"
            justifyContent="space-between"
            background="grey-lines"
          >
            <Text fontSize="sm">
              {isConnected
                ? 'This application is now active'
                : 'This application is disconnected'}
            </Text>
            <Box>
              {isConnected ? (
                <Button
                  h="30px"
                  w="120px"
                  variant="outline"
                  borderRadius="2px"
                  fontWeight="700"
                  fontSize="12px"
                  onClick={handleDisconnect}
                  isLoading={isDisconnecting}
                  loadingText="Disconnecting"
                  isDisabled={isDisconnecting}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  h="30px"
                  w="120px"
                  bgColor="primary.500"
                  color="white"
                  borderRadius="2px"
                  fontWeight="700"
                  fontSize="12px"
                  onClick={handleConnect}
                  isLoading={isConnecting || isFetchingAccounts}
                  loadingText="Connecting"
                  isDisabled={isConnecting}
                >
                  Connect
                </Button>
              )}{' '}
            </Box>
          </Flex>
          <Box mt={4}>
            <Text fontSize="md" fontWeight="bold">
              About
            </Text>
            <Text fontSize="xs" color="primary.dark.300">
              Optimize online advertising campaigns by making data-driven
              decisions on which ads to boost and which to scale back, ensuring
              maximum return on investment and keep track of your customers.
            </Text>
          </Box>
          <Box mt={6}>
            <Text fontSize="md" fontWeight="bold">
              Analytics
            </Text>
            <Text fontSize="xs" color="primary.dark.300">
              Connect your Google Analytics account to track your store&apos;s
              customers and performance.
            </Text>
            <Flex mt={3} gap={6}>
              <Box width="50%">
                <Text fontSize="xs" fontWeight={700} color="primary.dark.400">
                  Measurement Id
                </Text>
                <Input
                  name="measurementId"
                  type="text"
                  width="300px"
                  placeholder="Measurement ID"
                  value={googleAccountData.measurementId}
                  onChange={handleInputChange}
                />
              </Box>
              <Box width="50%">
                <Text fontSize="xs" fontWeight={700} color="primary.dark.400">
                  User Consent
                </Text>
                <Select
                  name="userConsent"
                  value={googleAccountData.userConsent}
                  onChange={handleInputChange}
                >
                  {userIdentificationOptions.map((option) => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex>
          </Box>
          <Box mt={4}>
            <Text fontSize="md" fontWeight="bold">
              Ads
            </Text>
            <Text fontSize="xs" color="primary.dark.300">
              Connect your Google Ads account to optimize your ads through
              Revend.
            </Text>

            <Flex gap={6}>
              <Box width="44%">
                <Text
                  mt="2"
                  fontSize="xs"
                  fontWeight={700}
                  color="primary.dark.400"
                >
                  Account
                </Text>
                <Select
                  name="googleAdAccount"
                  value={googleAccountData.googleAdAccount}
                  onChange={handleSelectGoogleAccount}
                  isDisabled={isFetchingAccounts || !isConnected}
                >
                  {accounts &&
                    accounts.map((account) => (
                      <option
                        value={account.account_id}
                        key={`${account.account_id}_${account.login_customer_id}`}
                      >
                        {account.name}
                      </option>
                    ))}
                </Select>
              </Box>
            </Flex>
            <Box pl={2} mt={6}>
              <Text fontSize="sm" fontWeight="bold">
                Conversion Actions
              </Text>
              <Text fontSize="xs" color="primary.dark.300">
                Manage your conversion actions that Revend is interacting with.
              </Text>
              <Flex my={3} gap={6} w="100%" alignContent="center">
                <Flex gap={10}>
                  <FloatLabel label="Action">
                    <Select
                      name="conversionAction"
                      isDisabled={
                        conversionActions.length === 0 || isFetchingGAInfo
                      }
                      onChange={setSelectedConversionAction}
                      value={selectedConversionAction}
                      minW="200px"
                    >
                      {conversionActions &&
                        conversionActions.map((option) => (
                          <option value={option.id} key={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </Select>
                  </FloatLabel>
                  <FloatLabel label="Related Event">
                    <Select
                      name="actionEvent"
                      value={selectedEvent}
                      isDisabled={
                        availableEvents.length === 0 || isFetchingGAInfo
                      }
                      onChange={(e) => setSelectedEvent(e.target.value)}
                      minW="200px"
                    >
                      {availableEvents &&
                        availableEvents.map((option) => (
                          <option value={option.id} key={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </Select>
                  </FloatLabel>
                </Flex>
                <Button
                  h="30px"
                  w="60px"
                  bgColor="primary.500"
                  color="white"
                  borderRadius="2px"
                  fontWeight="700"
                  fontSize="12px"
                  alignSelf="center"
                  isDisabled={
                    availableEvents.length === 0 ||
                    !isConnected ||
                    conversionActions.length === 0
                  }
                  onClick={() => addConversionAction()}
                >
                  Add
                </Button>
              </Flex>
              <TableContainer h="200px" overflowY="scroll">
                <Table>
                  <Thead position="sticky" top={0} bgColor="white">
                    <Tr>
                      <Th color="primary.dark.300">Conversion Action</Th>
                      <Th color="primary.dark.300">Related Event</Th>
                      <Th color="primary.dark.300">Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentAccountEvents.map((event, index) => (
                      <Tr
                        key={`${event.action_id}${event.event_suffix}`}
                        fontSize="12px"
                      >
                        <Td ps="1" py="1">
                          {event.action_name}
                        </Td>
                        <Td py="1">{event.event_suffix}</Td>
                        <Td py="1" w="100px" textAlign="center">
                          <IconButton
                            aria-label="Remove action"
                            title="Remove action"
                            variant="ghost"
                            position="static"
                            size="sm"
                            color="primary.dark.400"
                            icon={<DeleteOutline fontSize="small" />}
                            onClick={() => handleRemoveEvent(index)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Flex mt={6} justifyContent="flex-end">
            <Button
              h="30px"
              w="120px"
              bgColor="primary.500"
              color="white"
              borderRadius="2px"
              fontWeight="700"
              fontSize="12px"
              isLoading={loadingMutateConfig}
              onClick={handleSave}
            >
              Save
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

GoogleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customerUUID: PropTypes.string.isRequired,
}
