import PropTypes from 'prop-types'
import { Center, Heading, Text, VStack } from '@chakra-ui/react'

export const FullscreenIllustration = ({ illustration, title, subtitle }) => (
  <Center w="100vw" h="100vh">
    <VStack>
      {typeof illustration === 'function' ? illustration() : illustration}
      {typeof title === 'string' ? (
        <Heading color="primary.500">{title}</Heading>
      ) : (
        title
      )}
      {typeof subtitle === 'string' ? <Text>{subtitle}</Text> : subtitle}
    </VStack>
  </Center>
)

FullscreenIllustration.propTypes = {
  illustration: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}
