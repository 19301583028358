import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  Link,
  ListItem,
  Text,
  UnorderedList,
  useClipboard,
} from '@chakra-ui/react'
import { CheckIcon, CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons'

export const TagManagerStep = ({ customerUUID, next }) => {
  const scriptTag = `<script async src="https://track.revend.ai/script?apiKey=${customerUUID}"></script>`

  const { onCopy, setValue, hasCopied } = useClipboard('')
  const {
    onCopy: onCopy2,
    setValue: setValue2,
    hasCopied: hasCopied2,
  } = useClipboard('')

  return (
    <>
      <Flex flex={1} width="100%" pr={14}>
        <Flex flexDirection="column" alignItems="center">
          <Circle bg="primary.500" color="white" size={10}>
            1
          </Circle>
          <Divider
            orientation="vertical"
            borderColor="primary.dark.100"
            marginBottom="-8"
          />
        </Flex>
        <Flex flex={1} flexDirection="column" mt={2} ml={4} width="100%">
          <Text fontWeight="700" color="primary.500" fontSize="lg">
            Create a new Tag in Google Tag Manager
          </Text>
          <UnorderedList ml={0} mt={6} mb={0}>
            <ListItem>
              <Text fontSize="sm" color="primary.dark.500">
                <Text as="b">Name: </Text>
                Revend
              </Text>
            </ListItem>
            <ListItem mt={2}>
              <Text fontSize="sm" color="primary.dark.500">
                <Text as="b">Type: </Text>
                Custom HTML Tag
              </Text>
            </ListItem>
            <ListItem mt={2}>
              <Text fontSize="sm" color="primary.dark.500">
                <Text as="b">Content: </Text>
                Paste the tag below:
              </Text>
            </ListItem>
          </UnorderedList>

          <Flex
            bg="white"
            padding={4}
            my={6}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            borderRadius="md"
          >
            <Text fontSize="xs">{scriptTag}</Text>
            <Box
              onClick={() => {
                setValue(scriptTag)
                onCopy()
              }}
            >
              {hasCopied ? (
                <CheckIcon boxSize={6} color="primary.500" />
              ) : (
                <CopyIcon boxSize={6} color="primary.500" />
              )}
            </Box>
          </Flex>
          <UnorderedList m={0}>
            <ListItem>
              <Text fontSize="sm" color="primary.dark.500">
                <Text as="b">Trigger: </Text>
                All Pages / Pageview (Comes already configured in GTM)
              </Text>
            </ListItem>
          </UnorderedList>
        </Flex>
      </Flex>
      <Flex mt={8} width="100%" pr={14}>
        <Flex flexDirection="column" alignItems="center">
          <Circle bg="primary.500" color="white" size={10}>
            2
          </Circle>
          <Divider
            orientation="vertical"
            borderColor="primary.dark.100"
            marginBottom="-8"
          />
        </Flex>
        <Flex flex={1} flexDirection="column" mt={2} ml={4}>
          <Text fontWeight="700" color="primary.500" fontSize="lg">
            Publish the changes
          </Text>
          <Text color="primary.dark.500" fontSize="sm" mt={6}>
            (*) Alternatively, if you do not use Google Tag Manager yet or are
            not comfortable making changes there, edit your theme&apos;s code
            and add this to the
            <Text as="span" color="primary.500">{` <head> `}</Text>element
          </Text>
          <Flex
            bg="white"
            padding={4}
            mt={6}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            borderRadius="md"
          >
            <Text fontSize="xs">{scriptTag}</Text>
            <Box
              onClick={() => {
                setValue2(scriptTag)
                onCopy2()
              }}
            >
              {hasCopied2 ? (
                <CheckIcon boxSize={6} color="primary.500" />
              ) : (
                <CopyIcon boxSize={6} color="primary.500" />
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex mt={8} width="100%" pr={14}>
        <Flex flexDirection="column">
          <Circle bg="primary.500" color="white" size={10}>
            3
          </Circle>
        </Flex>
        <Flex flex={1} flexDirection="column" mt={2} ml={4}>
          <Text fontWeight="700" color="primary.500" fontSize="lg">
            Also add to Order Page Scripts
          </Text>
          <Text color="primary.dark.500" fontSize="sm" mt={6}>
            If you use Shopify, also add the script to the Order Status Page
            scripts, as described here
            <Link
              href="https://help.shopify.com/en/manual/orders/status-tracking/customize-order-status"
              isExternal
              color="primary.500"
            >
              {' '}
              Customize order status <ExternalLinkIcon mx="2px" />
            </Link>
            , so we can track your successful vs unsuccessful checkouts.
          </Text>
        </Flex>
      </Flex>
      <Box alignSelf="end" mt={10}>
        <Button
          borderRadius="sm"
          width="110px"
          height="32px"
          ml={4}
          onClick={next}
        >
          Next
        </Button>
      </Box>
    </>
  )
}

TagManagerStep.propTypes = {
  customerUUID: PropTypes.string.isRequired,
  next: PropTypes.func.isRequired,
}
