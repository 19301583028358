import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { fetchConfigPreviewerIncidents } from '../../services/api/palantir'
import { useAuthToken } from '../store/useAuthToken'
import { normalizeIncidentMessageFromConfigPreview } from '../../util/revend/normalizeIncidentMessageFromConfigPreview'

/**
 * @param customerUUID {string}
 * @param eventType {string}
 * @param warmupTime {number}
 * @param windowLength {number}
 * @param incidentSensitivity {number}
 * @param queryOptions {import("@tanstack/react-query").UseQueryOptions}
 */
export const useFetchConfigPreviewerIncidents = (
  { customerUUID, eventType, warmupTime, windowLength, incidentSensitivity },
  queryOptions = undefined
) => {
  const token = useAuthToken()
  const isRequestValid =
    !!customerUUID &&
    !!eventType &&
    !!token &&
    !!warmupTime &&
    !!windowLength &&
    !!incidentSensitivity

  useEffect(() => {
    if (!isRequestValid) {
      console.warn(
        'useFetchConfigPreviewerIncidents: invalid request parameters.',
        {
          customerUUID,
          eventType,
          warmupTime,
          windowLength,
          incidentSensitivity,
        }
      )
    }
  }, [
    isRequestValid,
    customerUUID,
    eventType,
    warmupTime,
    windowLength,
    incidentSensitivity,
  ])

  return useQuery({
    queryKey: [
      'config-previewer-incidents',
      {
        customerUUID,
        eventType,
        warmupTime,
        windowLength,
        incidentSensitivity,
      },
    ],
    queryFn: () =>
      fetchConfigPreviewerIncidents(token, {
        customerUUID,
        eventType,
        warmupTime,
        windowLength,
        incidentSensitivity,
      }).then((res) => {
        // Backwards compatibility: add duration in minutes to each incident
        // TODO remove this once we normalise the incident messages on palantir, they are messed up
        if (!res.data) return res.data
        return res.data.map(normalizeIncidentMessageFromConfigPreview)
      }),
    enabled: !!token && isRequestValid,
    ...queryOptions,
  })
}
