import {
  Flex,
  Icon,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { FaChevronRight } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Dot } from '../../ui-kit/Dot'
import { useEventTypeLabel } from '../../../hooks/etc/useEventTypeLabel'
import { formatAlertTypeMessage } from '../../../util/revend/formatAlertTypeMessage'
import { useSharedState } from '../../../hooks/store/useSharedState'
import { IncidentPriorityBadge } from '../shared/incidents/IncidentPriorityBadge'
import { SharedActions } from '../../../store'
import { formatIncidentDateTimeRange } from '../../../util/revend/formatIncidentDateTimeRange'

export const IncidentsTable = ({ incidents, ...rest }) => {
  const dispatch = useDispatch()
  const eventLabel = useEventTypeLabel()
  const { slideoutIncident, hoveredIncident } = useSharedState()

  const COLOR_SCHEME = {
    low: 'warning',
    medium: 'alert',
    high: 'danger',
  }

  const onIncidentSelected = (incidentUUID) => {
    dispatch(SharedActions.setSlideoutIncident(incidentUUID))
  }

  return (
    <TableContainer
      overflowY="auto"
      overflowX="hidden"
      whiteSpace="normal"
      {...rest}
    >
      <Table
        variant="unstyled"
        fontSize={12}
        color="text"
        maxW="100%"
        style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }}
      >
        <Thead textTransform="uppercase" bg="white" position="sticky" top={0}>
          <Tr>
            <Th>New Incident</Th>
            <Th>Event Type</Th>
            <Th>Alert Type</Th>
            <Th>When</Th>
            <Th /> {/* priority badge */}
            <Th /> {/* chevron */}
          </Tr>
        </Thead>
        <Tbody>
          {incidents.map((incident) => {
            const isActive = slideoutIncident === incident.uuid
            const isHovered = hoveredIncident === incident.uuid
            const { priority, uuid } = incident
            const { event_type_desc: eventTypeDesc, event_type: eventType } =
              incident
            return (
              <Tr
                key={uuid}
                borderLeftWidth={4}
                borderLeftColor="danger"
                sx={{
                  // It's not possible to directly style <td/>s, so we have to use the child selector
                  '& > td': {
                    borderStyle: 'solid none',
                    borderWidth: '1px',
                    borderColor: isActive ? 'primary.500' : 'grey-lines',
                    bg: isActive || isHovered ? '#E9EEFF' : 'surface.500',
                  },
                  '&:hover > td': {
                    bg: '#E9EEFF',
                  },
                  '& > td:first-of-type': {
                    borderLeftStyle: 'solid',
                    borderLeftWidth: 4,
                    borderLeftRadius: 'lg',
                    borderLeftColor: COLOR_SCHEME[priority],
                  },
                  '& > td:last-of-type': {
                    borderRightStyle: 'solid',
                    borderRightRadius: 'lg',
                  },
                }}
              >
                <Td>
                  <Flex alignItems="center" gap={2}>
                    <Dot
                      bg={incident.status === 'closed' ? 'success' : 'danger'}
                    />
                    <Text as="span" wordBreak="break-all">
                      {eventTypeDesc}{' '}
                      {incident.status === 'closed' ? 'were' : 'are'}{' '}
                      {formatAlertTypeMessage(incident)}
                    </Text>
                  </Flex>
                </Td>
                <Td>{eventLabel(eventType)}</Td>
                <Td sx={{ '&::first-letter': { textTransform: 'uppercase' } }}>
                  {formatAlertTypeMessage(incident)}
                </Td>
                <Td>
                  <Text as="span" fontSize={12}>
                    {formatIncidentDateTimeRange(incident)}
                  </Text>
                </Td>
                <Td>
                  <IncidentPriorityBadge priority={priority} fontSize={10} />
                </Td>
                <Td maxW="64px">
                  <Tooltip label="View Details" placement="left">
                    <Flex alignItems="center">
                      <IconButton
                        h="1em"
                        p={0}
                        minW="unset"
                        aria-label="Details"
                        variant="unstyled"
                        position="initial"
                        marginTop={-1}
                        marginRight={-2}
                        icon={<Icon as={FaChevronRight} color="primary.500" />}
                        onClick={() => onIncidentSelected(uuid)}
                      />
                    </Flex>
                  </Tooltip>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

IncidentsTable.propTypes = {
  incidents: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      when: PropTypes.string.isRequired,
      current: PropTypes.number.isRequired,
      minimum: PropTypes.number.isRequired,
      event_type: PropTypes.string.isRequired,
      event_type_desc: PropTypes.string.isRequired,
      priority: PropTypes.oneOf(['low', 'medium', 'high']).isRequired,
      status: PropTypes.string.isRequired,
      last_anomaly: PropTypes.shape({
        window_end: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}
