import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import {
  Box,
  Center,
  Divider,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { useCustomerUUID } from '../../../../../hooks/store/useCustomerUUID'
import {
  useFetchEventTypes,
  useFetchNotificationSettings,
} from '../../../../../hooks/palantir'
import { useEventTypeLabel } from '../../../../../hooks/etc/useEventTypeLabel'
import { LoadingSpinner } from '../../../../ui-kit/LoadingSpinner'
import { EventTypeSection } from './EventTypeSection'
import { compareEventTypes } from '../../../../../util/revend/compareEventTypes'
import { SettingsCard } from '../../SettingsCard'
import { useMutateNotificationSettings } from '../../../../../hooks/palantir/useMutateNotificationSettings'

/**
 * @typedef {{
 *   event_type: string,
 *   incident_sensitivity: number,
 *   notify_only_when_test_fails: boolean,
 *   notify_when_above_average: boolean,
 *   notify_when_above_maximum: boolean,
 *   notify_when_below_average: boolean,
 *   notify_when_below_minimum: boolean,
 *   sliding_window_length_hours: number,
 *   snooze_until: null,
 *   updated_at: string,
 *   uuid: string,
 *   warmup_time: number,
 *  }} IncidentConfiguration
 */

/**
 * Just a wrapper to facilitate typings
 *
 * @return {{
 *   value: IncidentConfiguration,
 *   setAll: (payload: IncidentConfiguration)=>void,
 *   setKey: (payload: [string, IncidentConfiguration])=>void
 * }}
 */
const useLocalConfigStore = () => {
  const [localConfig, setLocalConfig] = useReducer(
    (state, payload) =>
      Array.isArray(payload) ? { ...state, [payload[0]]: payload[1] } : payload,
    {}
  )

  return useMemo(() => {
    return {
      setAll: setLocalConfig,
      setKey: setLocalConfig,
      value: localConfig,
    }
  }, [localConfig])
}

export const EventTypeTab = () => {
  const [customerUUID] = useCustomerUUID()
  const label = useEventTypeLabel()

  const {
    value: localConfig,
    setAll: resetLocalConfig,
    setKey: setLocalConfig,
  } = useLocalConfigStore()

  const { data: allEventTypes, isLoading: areEventsLoading } =
    useFetchEventTypes(
      { customerUUID },
      {
        select(data) {
          return data.map(({ id }) => id).sort(compareEventTypes)
        },
      }
    )

  const {
    data: remoteNotificationSettings,
    isLoading: areRemoteNotificationSettingsLoading,
  } = useFetchNotificationSettings(
    { customerUUID },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  )

  const { mutate: mutateNotificationSettings, isLoading: isMutateLoading } =
    useMutateNotificationSettings()

  const isFetchLoading =
    areEventsLoading || areRemoteNotificationSettingsLoading

  const handleSave = useCallback(() => {
    mutateNotificationSettings({
      customerUUID,
      incidentConfigurations: localConfig,
    })
  }, [customerUUID, localConfig, mutateNotificationSettings])

  const [search, setSearch] = useState('')
  const filteredEventTypes = useMemo(() => {
    if (!allEventTypes) return []
    if (!search) return allEventTypes
    return allEventTypes.filter((type) =>
      label(type).toLowerCase().includes(search.trim().toLowerCase())
    )
  }, [allEventTypes, label, search])

  useEffect(() => {
    if (remoteNotificationSettings?.incident_configurations) {
      resetLocalConfig(remoteNotificationSettings.incident_configurations)
      setSearch('')
    }
  }, [resetLocalConfig, remoteNotificationSettings?.incident_configurations])

  function renderLoading() {
    return (
      <Center py={16}>
        <LoadingSpinner color="primary.500" />
      </Center>
    )
  }

  function renderEventTypes() {
    const lastActive = filteredEventTypes[filteredEventTypes.length - 1]

    return (
      <VStack alignItems="stretch">
        {allEventTypes.map((eventType) => {
          const isActive = filteredEventTypes.includes(eventType)
          return (
            <Box
              maxH={isActive ? '100vh' : 0}
              transition=".25s ease"
              overflowY="hidden"
            >
              <EventTypeSection
                key={eventType}
                eventType={eventType}
                config={localConfig[eventType] || {}}
                setConfig={setLocalConfig}
                pl={4}
                py={2}
              />
              {isActive && eventType !== lastActive && (
                <Box py={4} borderBottom="none">
                  <Divider borderColor="border-color" />
                </Box>
              )}
            </Box>
          )
        })}
      </VStack>
    )
  }

  return (
    <SettingsCard
      title="Event Type"
      subtitle="Set the configuration below for each event type"
      isLoading={isMutateLoading}
      saveFunction={handleSave}
      stickyHeader
      mb={16}
    >
      <Box
        position="sticky"
        top="86px"
        bg={isMutateLoading ? '#E6E7E7' : 'surface.500'}
        pb={8}
        pt={4}
        zIndex={998}
      >
        <InputGroup w="25%" minW="512px">
          <Input
            placeholder="Search event"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            isDisabled={isFetchLoading}
          />
          <InputRightElement>
            <SearchIcon color="primary.500" />
          </InputRightElement>
        </InputGroup>
      </Box>
      {isFetchLoading ? renderLoading() : renderEventTypes()}
    </SettingsCard>
  )
}
